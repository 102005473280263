import React, { useState, useEffect } from 'react';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Alert,
  Form,
  Spinner,
  Card,
  CardBody,
  FormGroup,
  Label,
} from 'reactstrap';
import axios from 'axios';
import { isEU } from '../../helpers/utils';
import Switch from 'react-switch';
import TeamHeader from './TeamHeader';

import PersonalInfo from './PersonalInfo';
import Address from './Address';
import Rates from './Rates';
import RatesAdmin from './RatesAdmin';
import Photo from './Photo';
import Documents from './Documents';
import {
  fetchUserProfile,
  fetchUserProfileSuccessAction,
  fetchTeam,
} from '../../store/team/actions';
import BusinessInfo from './BusinessInfo';
import { renderField, required } from './validation';
import { toast } from 'react-toastify';

const Profile = ({
  profile,
  isNewProfile,
  ownProfile,
  isLoading,
  fetchTeam,
  fetchUserProfile,
  fetchUserProfileSuccess,
  handleSubmit,
  submitting,
  pristine,
  invalid,
  change,
  formCountryISO,
  formTaxID,
  formCurrency,
  formUserType,
  history,
}) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmittingWorkAgreement, setIsSubmittingWorkAgreement] = useState(false);
  const [isSubmittingWorkAssignment, setIsSubmittingWorkAssignment] = useState(false);

  useEffect(() => {
    if (profile && profile.userID && !isNewProfile) {
      fetchUserProfile(profile.userID);
    }
  }, [profile && profile.userID, isNewProfile]); // eslint-disable-line

  if (!profile && !isNewProfile) return null;

  const onSubmit = async values => {
    setError(null);
    setSuccess(null);

    try {
      if (isNewProfile) {
        await axios('/api/profiles', {
          method: 'POST',
          data: values,
        });
        fetchTeam();
        history.goBack();
      } else {
        const response = await axios(`/api/profiles/${profile.userID}`, {
          method: 'PUT',
          data: values,
        });
        setSuccess(true);
        if (response.data.profile) {
          fetchUserProfileSuccess(response.data.profile);
        }
        if (response.data.message) {
          console.log(response.data.message);
          setError(response.data.message);
        }
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      setError(data ? data.message : true);
    }
  };

  const getVIES = async () => {
    try {
      if (!profile || !formCountryISO || !formTaxID) {
        toast.error(
          'Missing required profile parameters (fill in country and tax ID in Business section).'
        );
        return;
      }
      toast.info('🇪🇺 Talking to eurocrats in Brussels, please wait...');
      const response = await axios('/api/services/euvat', {
        method: 'POST',
        data: { countryISO: formCountryISO, taxID: formTaxID },
      });

      if (response.data.success) {
        change('businessName', response.data.details.businessName);
        change('officeAddress.line1', response.data.details.officeAddress.line1);
        change('officeAddress.line2', response.data.details.officeAddress.line2);
        change('officeAddress.line3', response.data.details.officeAddress.line3);
        change('officeAddress.postcode', response.data.details.officeAddress.postcode);
        change('officeAddress.city', response.data.details.officeAddress.city);
        change('officeAddress.countryISO', formCountryISO);
        toast.success('Business details confirmed with VIES.');
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      toast.error(
        data ? data.message : error ? error.message : 'Something went wrong with VIES system.'
      );
    }
  };

  const getPayee = async () => {
    try {
      if (!profile || !formCountryISO || !formTaxID) {
        toast.error(
          'Missing required profile parameters (fill in country and tax ID in Business section).'
        );
        return;
      }
      toast.info('👨‍💼 Talking to serious people at The Bank, please wait...');
      const response = await axios(`/api/profiles/payee/${profile.userID}`, {
        method: 'GET',
      });

      if (response.data.error === false && response.data.profile) {
        change('financials.transferwiseIDs', response.data.profile.financials.transferwiseIDs);
        toast.success('🏦 Payment details validated.');
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      toast.error(
        data
          ? data.message
          : error
          ? error.message
          : 'Something went wrong during bank account verification.'
      );
    }
  };

  const requestDocument = async documentType => {
    setSuccess(false);
    setError(null);
    if (documentType === 'WORK_AGREEMENT') setIsSubmittingWorkAgreement(true);
    if (documentType === 'SCHEDULE1_ASSIGNMENT') setIsSubmittingWorkAssignment(true);

    try {
      if (!profile || !formCountryISO || !formTaxID) {
        setError(
          'Missing required profile parameters (fill in country and tax ID in Business section).'
        );
        return;
      }
      const response = await axios('/api/signature/request', {
        method: 'POST',
        data: { worker: profile, documentType: documentType },
      });

      if (!response.data.error) {
        setSuccess(response.data.message);
      } else {
        setError(response.message);
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      setError(
        data ? data.message : error ? error.message : 'Digital signature system returned an error.'
      );
    }

    window.scrollTo(0, 0);
    setIsSubmittingWorkAgreement(false);
    setIsSubmittingWorkAssignment(false);
  };

  const onDelete = async () => {
    setError(null);
    setSuccess(null);

    // eslint-disable-next-line no-restricted-globals
    if (confirm('Are you sure you want to delete this profile?')) {
      try {
        if (isNewProfile) {
          history.goBack();
        } else {
          const response = await axios(`/api/profiles/${profile.userID}`, {
            method: 'DELETE',
          });

          if (response.data.message) {
            console.log(response.data.message);
            setError(response.data.message);
          }

          if (response.data.success) {
            fetchTeam();
            history.goBack();
          }
        }
      } catch (error) {
        const data = error && error.response && error.response.data;
        setError(data ? data.message : true);
      }
    }
  };

  if (isLoading)
    return (
      <div className="text-center" style={{ paddingTop: 100 }}>
        <Spinner color="primary" />
      </div>
    );

  return (
    <Container fluid className="team position-relative">
      <Form onSubmit={handleSubmit(onSubmit)}>
        <div className="sticky-title">
          <Row className="align-items-center">
            <Col sm={ownProfile.isAdmin ? '8' : '10'}>
              {isNewProfile ? (
                <h5 className="page-title">New Profile</h5>
              ) : (
                <h5 className="page-title">
                  {profile.firstName} {profile.lastName}'s Profile
                </h5>
              )}
            </Col>
            {ownProfile.isAdmin && (
              <Col sm="2">
                <button
                  className="btn btn-danger btn-block"
                  disabled={submitting}
                  type="button"
                  onClick={() => onDelete()}
                >
                  Delete
                </button>
              </Col>
            )}
            <Col sm={ownProfile.isAdmin ? '2' : '4'}>
              <button
                className="btn btn-primary btn-block"
                type="submit"
                disabled={submitting || pristine || invalid}
              >
                {submitting ? 'Submitting...' : 'Submit'}
              </button>
            </Col>
          </Row>

          <Alert
            color="danger"
            className="m-t-20"
            isOpen={!submitting && !!error}
            toggle={() => setError(null)}
          >
            <span role="img" aria-label="see no evil" className="font-18 m-r-5">
              🙈
            </span>
            {error || 'Sorry, something went wrong'}
          </Alert>

          <Alert
            color="info"
            className="m-t-20"
            isOpen={!submitting && !!success}
            toggle={() => setSuccess(null)}
          >
            <span role="img" aria-label="sucess" className="font-18 m-r-5">
              👍
            </span>
            {typeof success === 'string' ? success : 'The profile was updated successfully'}
          </Alert>
        </div>

        {isNewProfile && (
          <Row>
            <Col lg={6}>
              <Card>
                <CardBody>
                  <FormGroup>
                    <Label for="firstName">
                      User ID <span className="text-danger">*</span>
                    </Label>
                    <Field
                      component={renderField}
                      className="form-control"
                      id="userID"
                      name="userID"
                      type="text"
                      validate={required}
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        <Row>
          <Col lg={6}>
            <PersonalInfo isAdmin={ownProfile.isAdmin} />

            <Card>
              <CardBody>
                <Address
                  title="Postal Address"
                  subtitle="Where do we send test devices or Xmas presents?"
                  field="postalAddress"
                  iconname="home"
                  alertText="We will be using this for UPS and DHL. Keep this up to date."
                  change={change}
                />
              </CardBody>
            </Card>
          </Col>

          <Col lg={6}>
            {!isNewProfile && (
              <>
                <Photo src={profile.photo} userID={profile.userID} />

                {ownProfile.isAdmin ? (
                  <RatesAdmin profile={profile} />
                ) : (
                  !!(formUserType !== 'candidate' && formUserType !== 'intern') && (
                    <Rates profile={profile} />
                  )
                )}
              </>
            )}

            {ownProfile.isAdmin && (
              <Card>
                <CardBody>
                  <FormGroup>
                    <TeamHeader
                      icon={'clock1'}
                      title={'Shifts and Scheduling'}
                      subtitle={
                        'Each colleague can have their of pattern of work, part-time or full-time.'
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Alert color="primary">
                      When Day Pass mode is enabled, then {profile?.firstName} will see an
                      additional tab under Timetracker. {profile?.firstName} will be able to clock
                      in when {profile?.firstName} starts day of work. This will add today as a
                      whole day of work done to the timesheet (see max number of hours in the Rates
                      section.)
                    </Alert>
                    <Label for="dayPassAllowed">Day Pass</Label>
                    <Field
                      component={({ input: { onChange, checked } }) => (
                        <Switch
                          className="m-l-15 align-middle"
                          onColor="#626ed4"
                          onChange={onChange}
                          checked={checked}
                        />
                      )}
                      className="form-control"
                      id="dayPassAllowed"
                      name="dayPassAllowed"
                      type="checkbox"
                    />
                  </FormGroup>
                </CardBody>
              </Card>
            )}

            <Documents
              isAdmin={ownProfile.isAdmin}
              url={profile && profile.documentsUrl}
              requestDocument={requestDocument}
              isSubmittingWorkAgreement={isSubmittingWorkAgreement}
              isSubmittingWorkAssignment={isSubmittingWorkAssignment}
            />

            {!!(
              formUserType !== 'candidate' &&
              formUserType !== 'intern' &&
              formUserType !== 'agent'
            ) && (
              <BusinessInfo
                isAdmin={ownProfile.isAdmin}
                getVIES={getVIES}
                getPayee={getPayee}
                currencyCode={formCurrency}
                officeCountryISO={formCountryISO}
                isPaymentApproved={
                  profile &&
                  profile.financials &&
                  (profile.financials?.transferwiseIDs?.length ||
                    profile.financials.transferwiseID ||
                    profile.financials.revolutID)
                }
                workerType={formUserType}
              />
            )}

            <Card>
              <CardBody>
                <Address
                  title="Business Address"
                  subtitle="Official legal address of the business, will be shown in all documents."
                  field="officeAddress"
                  canSelectOnlyCountry={isEU(formCountryISO) && !ownProfile.isAdmin}
                  iconname="office"
                  alertText={
                    isEU(formCountryISO) ? 'Please set business address by verifying EU VAT.' : ''
                  }
                  change={change}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Form>
    </Container>
  );
};

const mapStateToProps = (state, props) => {
  const team = state.Team;
  const profiles = team && team.profiles;
  const formProfile = state.form.profile;
  const ownProfileID = state.Auth.userID;
  const ownProfile = ownProfileID && profiles && profiles[ownProfileID];
  const userID = props.match && props.match.params && props.match.params.userID;
  const profile =
    ownProfile && userID && ownProfile.userID === userID
      ? ownProfile
      : profiles && profiles[userID];

  const financials = formProfile && formProfile.values && formProfile.values.financials;
  const officeAddress = formProfile && formProfile.values && formProfile.values.officeAddress;
  const formTaxID = (financials && financials.taxID) || '';
  const formCountryISO = (officeAddress && officeAddress.countryISO) || '';
  const formCurrency = (financials && financials.currency) || '';
  const formUserType = formProfile && formProfile.values && formProfile.values.type;

  return {
    isNewProfile: userID === 'new',
    profile,
    ownProfile,
    isLoading: team.isLoading,
    initialValues: profile,
    formTaxID,
    formCountryISO,
    formCurrency,
    formUserType,
  };
};

const mapDispatchToProps = dispatch => ({
  fetchTeam: () => dispatch(fetchTeam()),
  fetchUserProfile: id => dispatch(fetchUserProfile(id)),
  fetchUserProfileSuccess: profile => dispatch(fetchUserProfileSuccessAction(profile)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    form: 'profile',
    enableReinitialize: true,
    forceUnregisterOnUnmount: true,
  })(Profile)
);
