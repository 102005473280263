import React from 'react';
import { Card, CardBody, FormGroup, Label, Row, Col } from 'reactstrap';
import { formatCurrency } from '../../helpers/numbers';
import TeamHeader from './TeamHeader';

const Rates = ({ profile }) => {
  const rate = profile.financials && profile.financials.rates && profile.financials.rates[0];

  if (!rate) return null;

  return (
    <Card>
      <CardBody>
        <FormGroup>
          <TeamHeader
            icon="pound"
            title="Your Rates"
            subtitle="Hourly rates you are receiving for your services."
          />
        </FormGroup>
        <Row form>
          <Col sm={12}>
            <FormGroup>
              <Label for="officeAddress.line1">Basic Hourly Rate</Label>
              <input
                disabled
                className="form-control"
                type="text"
                value={`${formatCurrency(rate.BHR, rate.currency)}`}
              />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Label for="officeAddress.line1">Maximum Daily Hours</Label>
          <input disabled className="form-control" type="text" value={rate.maxDailyHours} />
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default Rates;
