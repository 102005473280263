import React from 'react';
import { Field } from 'redux-form';
import { Card, CardBody, FormGroup, Label, Alert } from 'reactstrap';
import TeamHeader from './TeamHeader';

const Documents = ({
  isAdmin,
  url,
  requestDocument,
  isSubmittingWorkAgreement,
  isSubmittingWorkAssignment,
}) => {
  return (
    <>
      <Card>
        <CardBody>
          <FormGroup>
            <TeamHeader
              icon="documents"
              title="Documents"
              subtitle="All the paperwork is kept in one place for easy access when needed."
            />
          </FormGroup>
          <FormGroup>
            {isAdmin ? (
              <Alert color="primary">
                Press the button below to edit the list of worker's documents, including submitted
                ID card, passport, active work agreement, NDA and other documents. You will be
                redirected to worker's personal OneDrive folder.
              </Alert>
            ) : (
              !!url && (
                <Alert color="primary">
                  Press the button below to view the list of your documents, including your
                  submitted ID card, passport, your active work agreement, NDA and other documents.
                  You will be redirected to your personal OneDrive folder.
                </Alert>
              )
            )}
          </FormGroup>
          {isAdmin ? (
            <FormGroup>
              <Label for="documentsUrl">Shared OneDrive Folder URL</Label>
              <Field
                component={field => {
                  return (
                    <div className="d-flex">
                      <input {...field.input} type="text" className="form-control" />
                      <a
                        href={field.input.value}
                        className="btn btn-primary text-white flex-shrink-0 m-l-10"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Open
                      </a>
                    </div>
                  );
                }}
                className="form-control"
                id="documentsUrl"
                name="documentsUrl"
                type="text"
              />
            </FormGroup>
          ) : (
            !!url && (
              <a
                href={url}
                className="btn btn-primary text-white flex-shrink-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                View
              </a>
            )
          )}
          <FormGroup>
            {isAdmin ? (
              <span>
                {/* <p>
                  Generate a work agreement for this worker, sing it in the name of London App Works
                  and email it for signature by the worker.
                </p>
                <Button
                  className="btn-primary"
                  disabled={isSubmittingWorkAgreement || isSubmittingWorkAssignment}
                  onClick={() => requestDocument('WORK_AGREEMENT')}
                >
                  {isSubmittingWorkAgreement
                    ? 'Please wait...'
                    : 'Request Work Agreement Signature'}
                </Button>
                <p className="m-t-10">
                  Generate a work assigment (formerly Schedule 1) for this worker with their rates
                  and payment schedule, sing it in the name of London App Works and email it for
                  signature by the worker.
                </p>
                <Button
                  className="btn-primary"
                  disabled={isSubmittingWorkAgreement || isSubmittingWorkAssignment}
                  onClick={() => requestDocument('SCHEDULE1_ASSIGNMENT')}
                >
                  {isSubmittingWorkAssignment
                    ? 'Please wait...'
                    : 'Request Work Assigment Signature'}
                </Button> */}
              </span>
            ) : (
              <>
                <p className="m-t-20">
                  If you need your work agreement or assigment re-issued please
                </p>
                <a
                  href="https://londonappworks.3cx.co.uk:5001/webclient/#/call?phone=234"
                  className="btn btn-primary text-white flex-shrink-0"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Call 234
                </a>
              </>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="workExpiry">Work Agreement Expiry</Label>
            <Field
              component="input"
              className="form-control"
              id="workExpiry"
              name="workExpiry"
              type="date"
              parse={value => value && new Date(value).toISOString()}
              format={value => (value ? new Date(value).toISOString().substr(0, 10) : '')}
              disabled={!isAdmin}
            />
          </FormGroup>
          <FormGroup>
            <Label for="schedule1Expiry">Schedule 1 Expiry</Label>
            <Field
              component="input"
              className="form-control"
              id="schedule1Expiry"
              name="schedule1Expiry"
              type="date"
              parse={value => value && new Date(value).toISOString()}
              format={value => (value ? new Date(value).toISOString().substr(0, 10) : '')}
              disabled={!isAdmin}
            />
          </FormGroup>
          <FormGroup>
            <Label for="NDAExpiry">NDA Expiry</Label>
            <Field
              component="input"
              className="form-control"
              id="NDAExpiry"
              name="NDAExpiry"
              type="date"
              parse={value => value && new Date(value).toISOString()}
              format={value => (value ? new Date(value).toISOString().substr(0, 10) : '')}
              disabled={!isAdmin}
            />
          </FormGroup>
        </CardBody>
      </Card>
    </>
  );
};

export default Documents;
