export default [
  {
    Header: '# Week',
    accessor: 'weekNumber',
  },
  {
    Header: 'Period Start',
    accessor: row => (row.weekStart ? new Date(row.weekStart).toLocaleDateString() : ''),
  },
  {
    Header: 'Period End',
    accessor: row => (row.weekEnd ? new Date(row.weekEnd).toLocaleDateString() : ''),
  },
  {
    Header: 'Purchase Order',
    accessor: 'purchaseOrder',
  },
  {
    Header: 'Project Code',
    accessor: 'projectCode',
  },
  {
    Header: 'Approved Capacity (hours)',
    accessor: 'approvedCapacity',
  },
  {
    Header: 'Used Capacity (hours)',
    accessor: 'usedCapacity',
  },
  {
    Header: 'Remaining Capacity (hours)',
    accessor: 'remainingCapacity',
  },
];
