import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import Layout from './layout/reducer';
import Auth from './auth/reducer';
import Dashboard from './dashboard/reducer';
import Timesheets from './timesheets/reducer';
import Admin from './admin/reducer';
import Team from './team/reducer';
import Timetracker from './timetracker/reducer';

const rootReducer = combineReducers({
  Layout,
  Auth,
  Dashboard,
  Timesheets,
  Admin,
  Team,
  Timetracker,
  form: formReducer,
});

export default rootReducer;
