import {
  FETCH_ADMIN_TIMESHEETS,
  FETCH_ADMIN_TIMESHEETS_SUCCESS,
  FETCH_ADMIN_TIMESHEETS_FAILURE,
  SET_ADMIN_USER,
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_ADMIN_TIMETRACKERS,
  FETCH_ADMIN_TIMETRACKERS_FAILURE,
  FETCH_ADMIN_TIMETRACKERS_SUCCESS,
  FETCH_DAILY_REPORT,
  FETCH_DAILY_REPORT_FAILURE,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_DAILY_REPORT_UPDATE,
  FETCH_DAILY_REPORT_UPDATE_FAILURE,
  FETCH_DAILY_REPORT_UPDATE_SUCCESS,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_FAILURE,
  ADD_SCHEDULED_PAYMENT,
  REMOVE_SCHEDULED_PAYMENT,
  FETCH_PAYMENTS_CARDS,
  FETCH_PAYMENTS_CARDS_FAILURE,
  FETCH_PAYMENTS_CARDS_SUCCESS,
} from './actionTypes';
import { SIGN_OUT } from '../auth/actionTypes';

const initialState = {
  data: {
    timesheets: null,
    timetrackers: null,
    projects: null,
    payments: null,
    paymentCards: null,
    companies: null,
    reports: {
      daily: { entries: [], date: null, generatedAt: null },
    },
  },
  isLoading: false,
  isAdminUser: false,
  error: null,
};

const admin = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_USER:
      return {
        ...state,
        isAdminUser: action.isAdminUser,
      };

    case FETCH_PROJECTS:
    case FETCH_ADMIN_TIMESHEETS:
    case FETCH_ADMIN_TIMETRACKERS:
    case FETCH_DAILY_REPORT:
    case FETCH_DAILY_REPORT_UPDATE:
    case FETCH_COMPANIES:
    case FETCH_PAYMENTS_CARDS:
    case FETCH_PAYMENTS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FETCH_ADMIN_TIMESHEETS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          timesheets: action.data,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_ADMIN_TIMESHEETS_FAILURE:
    case FETCH_PROJECTS_FAILURE:
    case FETCH_ADMIN_TIMETRACKERS_FAILURE:
    case FETCH_DAILY_REPORT_FAILURE:
    case FETCH_DAILY_REPORT_UPDATE_FAILURE:
    case FETCH_COMPANIES_FAILURE:
    case FETCH_PAYMENTS_FAILURE:
    case FETCH_PAYMENTS_CARDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case FETCH_PROJECTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          projects: action.data,
        },
      };

    case FETCH_PAYMENTS_CARDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          paymentCards: action.cards,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_PAYMENTS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_ADMIN_TIMETRACKERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          timetrackers: action.data,
        },
      };

    case FETCH_DAILY_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          reports: {
            ...state.data.reports,
            daily: {
              entries: action.data.entries,
              date: action.data.date,
              generatedAt: action.data.generatedAt,
            },
          },
        },
      };

    case FETCH_COMPANIES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          companies: action.data,
        },
      };

    case FETCH_DAILY_REPORT_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null,
        data: {
          ...state.data,
          message: action.data.message,
        },
      };

    case ADD_SCHEDULED_PAYMENT: {
      const scheduled = new Set(state.scheduled);
      scheduled.add(action.item);

      return { ...state, scheduled };
    }

    case REMOVE_SCHEDULED_PAYMENT: {
      const scheduled = new Set(state.scheduled);
      scheduled.delete(action.item);

      return { ...state, scheduled };
    }

    case SIGN_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default admin;
