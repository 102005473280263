import React from 'react';
import cn from 'classnames';

import './SuperBadge.scss';

function SuperBadge({ color, leftText, rightText, className }) {
  if (!leftText || !rightText) return null;

  return (
    <div className={cn('super-badge', className)}>
      <span className="project" style={{ backgroundColor: color || '#000' }}>
        {leftText}
      </span>
      <span className="task">{rightText}</span>
    </div>
  );
}

export default SuperBadge;
