import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Container,
  Row,
  Col,
  Label,
  Button,
  UncontrolledAlert,
  CardBody,
  Card,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import DocumentPreview from '../../../components/DocumentPreview/DocumentPreview';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import { fetchProjects, fetchCompanies } from '../../../store/admin/actions';
import { getSchedule1, saveFileFromUrl, shareDocument } from '../../../helpers/utils';

const Schedule1 = () => {
  const reactTooltip = useRef(null);
  const [company, setCompany] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedProjects, setselectedProjects] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [shouldIncludeAllTickets, setShouldIncludeAllTickets] = useState(false);
  const [applySignature, setApplySignature] = useState(false);
  const [isDraft, setIsDraft] = useState(true);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [documentRef, setDocumentRef] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);

  const admin = useSelector(state => state.Admin);
  const { profiles } = useSelector(state => state.Team);
  const { data } = useSelector(state => state.Admin);
  const projects = data && data.projects;
  const companies = (data?.companies && Object.values(data.companies)) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    if (!data.companies) dispatch(fetchCompanies());
    if (!projects) dispatch(fetchProjects());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (projects) {
      setAvailableProjects(filterProjectsByUser(userFilter, projects));
    }
  }, [userFilter]); // eslint-disable-line

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  const generateSchedule1 = useCallback(
    async function() {
      if (!selectedProjects) {
        throw new Error('Please select a project(s) for Schedule 1 document');
      }
      setIsLoading(true);
      getSchedule1(
        selectedProjects.join(','),
        company,
        userFilter,
        isDraft,
        shouldIncludeAllTickets,
        applySignature,
        (response, error) => {
          if (response) {
            setPdfBlob(response.url);
            setDocumentRef(response.documentRef);
          }
          if (error) {
            setError(error);
          }
          setIsLoading(false);
        }
      );
      setIsLoading(true);
    },
    [selectedProjects, shouldIncludeAllTickets, isDraft, applySignature] // eslint-disable-line
  );

  const downloadSchedule1 = useCallback(
    async function() {
      if (!pdfBlob) {
        throw new Error('Please generate a document first');
      }
      setIsLoading(true);
      saveFileFromUrl(pdfBlob, `${userFilter}-${selectedProjects.join('-')}-Schedule1.pdf`, () => {
        setIsLoading(false);
      });
    },
    [pdfBlob, selectedProjects, userFilter]
  );

  const issueForSignature = useCallback(
    async function() {
      if (!pdfBlob || !documentRef) {
        throw new Error('Please generate a document first');
      }
      setIsLoading(true);
      shareDocument(documentRef, response => {
        setIsLoading(false);
        if (response?.error) {
          setPreviewUrl(null);
          setError(response?.error?.message ?? 'Could not share the document.');
        } else {
          setPreviewUrl(response?.data?.previewUrl);
          setSuccess(true);
        }
      });
    },
    [documentRef, pdfBlob]
  );

  function checkCompanyMismatch(company, projectID) {
    let project = projects[projectID];
    if (company && project && project.billingCompanyID && project.billingCompanyID !== company) {
      setError(
        `Project ${project.title} should be billed via ${
          companies.find(entry => entry.id === project.billingCompanyID)?.legalName
        }`
      );
    } else {
      setError(null);
    }
  }

  function filterProjectsByUser(userID, projects) {
    const filtered = Object.values(projects)?.filter(project => {
      return Object.keys(project.workers)?.includes(userID);
    });
    return filtered;
  }

  function isUmbrellaWorker(userID) {
    const workerProfile = profiles[userID];
    return workerProfile?.type === 'umbrella';
  }

  if (!admin.isAdminUser) return <AccessDeniedPoster />;

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Generate "Schedule 1" Work Assigment</h4>
          </Col>
        </Row>
      </div>
      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          Schedule 1 generated successfully!
        </UncontrolledAlert>
      )}
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {error}
        </Alert>
      )}

      {userFilter && isUmbrellaWorker(userFilter) && (
        <Alert color="warning">
          <span role="img" aria-label="see umbrella" className="font-18 m-r-5">
            ☔️
          </span>
          {profiles[userFilter]?.firstName} is an umbrella worker. Generating Schedule1 will include{' '}
          {profiles[userFilter]?.firstName}'s name, but the umbrella company's will be responsible
          for its approval.
        </Alert>
      )}

      <Card>
        <CardBody>
          <Row>
            <Col sm="4" lg="4">
              <Label for="company" className="card-title">
                <span className="order bg-primary">1</span>Select a company
              </Label>
              <select
                id="company"
                className="form-control d-inline-block m-r-10"
                value={company}
                onChange={async e => {
                  const value = e.target.value;
                  setCompany(value);
                  setUserFilter('');
                  setselectedProjects([]);
                  setPdfBlob(null);
                  setDocumentRef(null);
                  setPreviewUrl(null);
                }}
              >
                <option>Please select</option>
                {companies &&
                  Object.values(companies)
                    .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                    .map(company => (
                      <option key={company.id} value={company.id}>
                        {company.legalName}
                      </option>
                    ))}
              </select>
            </Col>
            <Col sm="4" lg="4">
              <Label for="worker" className="card-title">
                <span className="order bg-primary">2</span>Select a worker
              </Label>
              <select
                id="worker"
                className="form-control d-inline-block m-r-10"
                value={userFilter}
                onChange={async e => {
                  const value = e.target.value;
                  setselectedProjects([]);
                  setUserFilter(value);
                  setIsLoading(true);
                  setSuccess(false);
                  setPdfBlob(null);
                  setDocumentRef(null);
                  setPreviewUrl(null);
                  try {
                  } catch (error) {
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                  setIsLoading(false);
                }}
              >
                <option>Please select</option>
                {profiles &&
                  Object.values(profiles)
                    .sort((a, b) => (b.firstName < a.firstName ? 1 : -1))
                    .map(profile => (
                      <option key={profile.userID} value={profile.userID}>
                        {profile.firstName} {profile.lastName}
                      </option>
                    ))}
              </select>
            </Col>
            <Col sm="4" lg="4">
              <Label for="project" className="card-title">
                <span className="order bg-primary">3</span>Select project(s)
              </Label>
              {availableProjects?.length > 0 && (
                <select
                  multiple
                  id="project"
                  className="form-control d-inline-block m-r-10 align-middle"
                  value={selectedProjects}
                  onChange={e => {
                    let value = Array.from(e.target.selectedOptions, option => option.value);
                    setselectedProjects(value);
                    setPdfBlob(null);
                    setDocumentRef(null);
                    setPreviewUrl(null);
                    checkCompanyMismatch(company, e.target.value);
                  }}
                >
                  {availableProjects &&
                    Object.values(availableProjects)
                      .sort((a, b) => (b.title < a.title ? 1 : -1))
                      .map(project => {
                        return (
                          <option key={project.title} value={project.id}>
                            {project.id} - {project.title}
                          </option>
                        );
                      })}
                </select>
              )}
              {availableProjects?.length === 0 && (
                <Alert color="dark">Please select a company and a worker</Alert>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Label className="card-title">
            <span className="order bg-primary">4</span>Select Document Options
          </Label>
          <Row>
            <Col sm={4}>
              <label className="m-l-15 d-flex align-items-center">
                <input
                  disabled
                  data-tip="Not available in this version of Figaro"
                  data-effect="solid"
                  data-delay-show="200"
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={shouldIncludeAllTickets}
                  onChange={e => setShouldIncludeAllTickets(!shouldIncludeAllTickets)}
                />
                <span
                  className="align-middle"
                  data-tip="When ON: All tickets for projects are listed in the document. When OFF: only project titles and delivery dates are listed."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Include all tickets
                </span>
              </label>
              <Alert color="primary">
                When ON: All tickets for projects are listed in the document. When OFF: only project
                titles and delivery dates are listed.
              </Alert>
            </Col>
            <Col sm={4}>
              <label className="m-l-15 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={isDraft}
                  onChange={e => {
                    setIsDraft(!isDraft);
                  }}
                />
                <span
                  className="align-middle"
                  data-tip="Generates document with DRAFT watermark on it."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Place DRAFT Watermark
                </span>
              </label>
              <Alert color="primary">
                Generates document with beautiful artisanal DRAFT watermark on it.
              </Alert>
            </Col>
            <Col sm={4}>
              <label className="m-l-15 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={applySignature}
                  onChange={e => {
                    setApplySignature(!applySignature);
                  }}
                />
                <span
                  className="align-middle"
                  data-tip="Sign with digital signature in company's name."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Apply Hot Stamp
                </span>
              </label>
              <Alert color="primary">
                Hot Stamp marks document ready for digital signature. Once the document is accepted
                a digital signature is placed into it.
              </Alert>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col sm="6" lg="12">
              <Label className="card-title">
                <span className="order bg-primary">5</span>Issue the Document
                <p className="m-t-10 m-l-10">
                  The document will be generated as a PDF and displayed in the preview below.
                  History entry will be made on all projects selected and the file will be sent to
                  S3 storage.
                </p>
              </Label>
              <Button
                color="primary"
                className="m-l-10"
                disabled={isLoading || !company || !userFilter || !selectedProjects.length}
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  setPdfBlob(null);
                  setPreviewUrl(null);
                  try {
                    setError(false);
                    await generateSchedule1();
                  } catch (error) {
                    window.scrollTo({ top: 0 });
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                }}
              >
                {isLoading ? 'Working...' : 'Generate Schedule 1'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="6" lg="12">
              <Label className="card-title m-t-20">
                <span className="order bg-primary">6</span>Preview the Document
              </Label>
              <DocumentPreview documentUrl={pdfBlob} title="Schedule 1" zoom={1.25} />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col sm="2" lg="6">
              <Label className="card-title">
                <span className="order bg-dark">7.a</span>Save the Document (Optional)
              </Label>
              <Button
                color="dark"
                className="m-l-10"
                disabled={isLoading || !pdfBlob}
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  try {
                    setError(false);
                    await downloadSchedule1();
                  } catch (error) {
                    window.scrollTo({ top: 0 });
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                  setIsLoading(false);
                }}
              >
                Save Schedule 1
              </Button>
            </Col>
            <Col sm="2" lg="6">
              <Label className="card-title">
                <span className="order bg-dark">7.b</span>Issue for Signature (Optional)
              </Label>
              <Button
                color="dark"
                className="m-l-10"
                disabled={isLoading || !pdfBlob || !documentRef || previewUrl}
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  try {
                    setError(false);
                    await issueForSignature();
                  } catch (error) {
                    window.scrollTo({ top: 0 });
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                  setIsLoading(false);
                }}
              >
                {userFilter && isUmbrellaWorker(userFilter) ? (
                  <span>Send to umbrella</span>
                ) : (
                  <span>Send for Signature to {profiles[userFilter]?.firstName}</span>
                )}
              </Button>
            </Col>
          </Row>
          {previewUrl && (
            <Row className="m-t-10">
              <Col>
                <Alert color="info">
                  ✅ The document has ID {documentRef} and was sent to the counterparty for review
                  and signing. Preview{' '}
                  <a href={previewUrl} target="_blank" rel="noreferrer">
                    link for email is here.
                  </a>
                </Alert>
              </Col>
            </Row>
          )}
        </CardBody>
      </Card>
      <ReactTooltip ref={reactTooltip} />
    </Container>
  );
};

export default Schedule1;
