import React from 'react';
import { Badge, Progress } from 'reactstrap';

import { formatCurrency, round } from '../../../helpers/numbers';
import SortIndicator from './SortIndicator';

export default [
  {
    Header: ({ column }) => {
      return (
        <>
          When <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'fromTime',
    Cell: ({ cell, value }) => {
      return (
        <div data-tip={cell.row.fromTime} data-effect="solid">
          {value && new Date(value).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
        </div>
      );
    },
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Who <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'userName',
    Cell: ({ cell, value }) => {
      return (
        <div data-tip={cell.row.original.userID} data-effect="solid">
          {value}
        </div>
      );
    },
  },
  {
    Header: 'Where',
    disableSortBy: true,
    accessor: row => row.location.displayName || row.location.city,
    Cell: ({ cell, value }) => {
      if (value) {
        const location = cell.row.original.location;
        const flag = location.countryISO && (
          <img
            src={`https://www.countryflags.io/${location.countryISO.toLowerCase()}/flat/64.png`}
            alt={location.country}
            data-tip={location.country}
            data-effect="solid"
            style={{ width: '24px', marginRight: '5px' }}
          />
        );
        return (
          <>
            {flag}
            {<span className="align-middle">{value}</span>}
          </>
        );
      }
      return null;
    },
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Project <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'task.projectId',
    Cell: ({ cell }) => {
      const task = cell.row.original.task;
      return (
        <Badge pill style={{ backgroundColor: task.projectColor }}>
          {task.projectId}
        </Badge>
      );
    },
  },
  {
    Header: 'Task',
    disableSortBy: true,
    accessor: 'task.name',
    Cell: ({ cell }) => {
      const task = cell.row.original.task;
      return (
        <>
          <Badge
            className="badge-light border align-middle text-secondary"
            style={{ marginRight: '3px' }}
          >
            #{task.id}
          </Badge>
          <span className="align-middle">{task.name}</span>
        </>
      );
    },
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Time Today (hours) <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'timeToday',
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Cost Today <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'cost',
    Cell: ({ value }) => <>{formatCurrency(value, 'GBP', 'symbol')}</>,
  },
  {
    Header: () => <div style={{ minWidth: '100px' }}>Progress</div>,
    id: 'progress',
    disableSortBy: true,
    accessor: row => calculateProgress(row.totalTime, row.allowedEffort),
    Cell: ({ row }) => {
      const { totalTime, allowedEffort } = row.original;
      const progress = calculateProgress(totalTime, allowedEffort);
      const progressColor =
        progress < 50 ? 'success' : progress >= 50 && progress < 75 ? 'info' : 'warning';
      return (
        <>
          <div>{progress}%</div>
          <Progress value={progress} color={progressColor} />
        </>
      );
    },
  },
  {
    Header: 'Source Code Status',
    disableSortBy: true,
    accessor: row => (row.didCommit ? 'YES' : 'NO'),
    Cell: ({ value }) =>
      value === 'YES' ? (
        <div className="text-center">
          <img
            style={{ width: '28px' }}
            src={require('../../../images/code.png')}
            alt="Did commit code"
            data-tip="If the worker is a software developer a green icon in this column means they have sent us source code - the work they have done"
            data-effect="solid"
          />
        </div>
      ) : null,
  },
];

function calculateProgress(totalTime, allowedEffort) {
  if (totalTime && allowedEffort) return round((totalTime / allowedEffort) * 100, 0);
  else return 0;
}
