import Login from './pages/Auth/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Timesheets from './pages/Timesheets/Timesheets';
import AdminTimesheets from './pages/Admin/Timesheets';
import AdminImportZoho from './pages/Admin/ImportZoho/ImportZoho';
import QuoterMaster from './pages/Admin/QuoterMaster/QuoterMaster';
import Projects from './pages/Admin/Projects/Projects';
import Team from './pages/Team/Team';
import Profile from './pages/Team/Profile';
import Timetracker from './pages/Timetracker';
import AdminTimetrackers from './pages/Admin/Timetrackers';
import AdminSettings from './pages/Admin/Settings/Settings';
import Reporting from './pages/Admin/Reporting';
import Companies from './pages/Admin/Companies/Companies';
import Banking from './pages/Admin/Banking/Banking';
import DocumentReview from './pages/DocumentReview/DocumentReview';
import TeamUpdate from './pages/Admin/TeamUpdate';
import Availability from './pages/Availability/Availability';
import AdminAvailability from './pages/Admin/Availability/Availability';

const routes = [
  { path: '/login', component: Login, ispublic: true },
  { path: '/document/:documentKey', component: DocumentReview, ispublic: true },
  { path: '/dashboard', component: Dashboard },
  { path: '/timesheets', component: Timesheets },
  { path: '/availability', component: Availability },
  { path: '/team', component: Team },
  { path: '/profile/:userID', component: Profile },
  { path: '/admin/timesheets', component: AdminTimesheets },
  { path: '/admin/zoho-import', component: AdminImportZoho },
  { path: '/admin/quotermaster', component: QuoterMaster },
  { path: '/admin/projects', component: Projects },
  { path: '/admin/banking', component: Banking },
  { path: '/admin/companies', component: Companies },
  { path: '/admin/timetrackers', component: AdminTimetrackers },
  { path: '/admin/settings/:provider', component: AdminSettings },
  { path: '/admin/reporting', component: Reporting },
  { path: '/admin/teamupdate', component: TeamUpdate },
  { path: '/admin/availability', component: AdminAvailability },
  { path: '/timetracker', component: Timetracker },
  { path: '/', component: Login, ispublic: true },
];

export default routes;
