import {
  FETCH_TIMESHEETS,
  FETCH_TIMESHEETS_SUCCESS,
  FETCH_TIMESHEETS_FAILURE,
  ADD_SELECTED_TIMESHEET,
  REMOVE_SELECTED_TIMESHEET,
  CLEAR_SELECTED_TIMESHEETS,
  FETCH_TIMESHEET_CARDS,
  FETCH_TIMESHEET_CARDS_SUCCESS,
  FETCH_TIMESHEET_CARDS_FAILURE,
} from './actionTypes';
import { SIGN_OUT } from '../auth/actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
  selected: new Set(),
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIMESHEET_CARDS:
    case FETCH_TIMESHEETS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FETCH_TIMESHEETS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.data,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_TIMESHEET_CARDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          cards: action.cards,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_TIMESHEET_CARDS_FAILURE:
    case FETCH_TIMESHEETS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case ADD_SELECTED_TIMESHEET: {
      const selected = new Set(state.selected);
      selected.add(action.item);

      return { ...state, selected };
    }

    case REMOVE_SELECTED_TIMESHEET: {
      const selected = new Set(state.selected);
      selected.delete(action.item);

      return { ...state, selected };
    }

    case CLEAR_SELECTED_TIMESHEETS:
      return { ...state, selected: new Set() };

    case SIGN_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default login;
