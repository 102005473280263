import React, { Component } from 'react';
import { Container, Row, Col, Card, CardBody, Table } from 'reactstrap';
import { activateAuthLayout } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

//Images
import invoice_logo from '../../images/logo_text_150.png';

class Invoice extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    this.props.activateAuthLayout();
  }

  render() {
    return (
      <React.Fragment>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col sm="12">
                <h4 className="page-title">Invoice</h4>
                <p>
                  Please make sure you include all timesheet IDs and payment details as shown on a
                  sample invoice. <br />
                  Questions?{' '}
                  <a href="mailto:accounts@londonappworks.co.uk?subject=`Invoicing Issue`">
                    Emails us!
                  </a>{' '}
                </p>
              </Col>
            </Row>
          </div>
          <Row>
            <Col>
              <Card className="card m-b-20">
                <CardBody>
                  <Row>
                    <Col>
                      <div className="invoice-title">
                        <h4 className="float-right font-16">
                          <strong>Invoice No. INV-0005</strong>
                        </h4>
                        <h3 className="mt-0">
                          <img src={invoice_logo} alt="logo" height="24" />
                        </h3>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6" lg="6">
                          <address>
                            <strong>Billed To:</strong>
                            <br />
                            London App Works LTD
                            <br />
                            5-7 Tanner Street
                            <br />
                            London, SE1 3LE, UK
                            <br />
                            Registered in England – Number 10439602
                            <br />
                            <address>VAT GB256776166</address>
                          </address>
                        </Col>
                        <Col xs="6" lg="6" className="text-right">
                          <address>
                            <strong>Issued By:</strong>
                            <br />
                            John Smith
                            <br />
                            Happy Developer Kft.
                            <br />
                            Main street 4<br />
                            Town
                            <br />
                            Country, POSTCODE
                            <br />
                          </address>
                          <address>EU VAT: HU12345567</address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" lg="6" className="m-t-30">
                          <address>
                            <strong>Payment Method:</strong>
                            <br />
                            Bank Transfer / Transferwise
                            <br />
                            BIC AKKHHUHB IBAN HU42117730161111101800000000
                          </address>
                          <address>
                            <strong>Payment Term:</strong>
                            <br />
                            15 days
                          </address>
                        </Col>
                        <Col xs="6" lg="6" className="m-t-30 text-right">
                          <address>
                            <strong>Issue Date:</strong>
                            <br />
                            January 1, 2029
                            <br />
                            <br />
                          </address>
                          <address>
                            <strong>Due Date:</strong>
                            <br />
                            January 16, 2029
                            <br />
                            <br />
                          </address>
                        </Col>
                      </Row>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div>
                        <div className="p-2">
                          <h3 className="font-16">
                            <strong>Line Items</strong>
                          </h3>
                        </div>
                        <div className="">
                          <Table className="table" responsive>
                            <thead>
                              <tr>
                                <td>
                                  <strong>Item</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Price</strong>
                                </td>
                                <td className="text-center">
                                  <strong>Quantity</strong>
                                </td>
                                <td className="text-right">
                                  <strong>Totals</strong>
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Software Development Services, Timesheet ID cold-lamp-maybe</td>
                                <td className="text-center">£10.99</td>
                                <td className="text-center">1</td>
                                <td className="text-right">£10.99</td>
                              </tr>
                              <tr>
                                <td>Software Development Services, Timesheet ID big-lazy-fish</td>
                                <td className="text-center">£200.00</td>
                                <td className="text-center">1</td>
                                <td className="text-right">£200.00</td>
                              </tr>
                              <tr>
                                <td>
                                  Software Development Services, Timesheet ID very-good-invoice
                                </td>
                                <td className="text-center">£600.00</td>
                                <td className="text-center">1</td>
                                <td className="text-right">£600.00</td>
                              </tr>
                              <tr>
                                <td className="thick-line"></td>
                                <td className="thick-line"></td>
                                <td className="thick-line text-center">
                                  <strong>VAT</strong>
                                </td>
                                <td className="thick-line text-right">£0.00</td>
                              </tr>
                              <tr>
                                <td className="no-line"></td>
                                <td className="no-line"></td>
                                <td className="no-line text-center">
                                  <strong>Total</strong>
                                </td>
                                <td className="no-line text-right">
                                  <h4 className="m-0">£810.99</h4>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(connect(null, { activateAuthLayout })(Invoice));
