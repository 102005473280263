import React from 'react';
import cn from 'classnames';

export const renderField = ({
  input,
  label,
  type,
  disabled,
  meta: { touched, error, warning },
  cols,
  rows,
}) => {
  const fieldProps = {
    ...input,
    placeholder: label,
    type: type,
    className: cn('form-control', { 'border-danger': error }),
    disabled: disabled,
  };
  return (
    <div>
      {type === 'textarea' ? (
        <textarea {...fieldProps} cols={cols} rows={rows} />
      ) : (
        <input {...fieldProps} />
      )}
      {touched &&
        ((error && (
          <small color="danger" className="text-danger font-500">
            <span role="img" aria-label="error">
              ⚠️ {error}
            </span>
          </small>
        )) ||
          (warning && <small>{warning}</small>))}
    </div>
  );
};

export const renderSelectField = ({
  input,
  disabled,
  children,
  meta: { touched, error, warning },
}) => (
  <div>
    <select
      {...input}
      className={cn('form-control', { 'border-danger': error })}
      disabled={disabled}
    >
      {children}
    </select>
    {touched &&
      ((error && (
        <small color="danger" className="text-danger font-500">
          <span role="img" aria-label="error">
            ⚠️ {error}
          </span>
        </small>
      )) ||
        (warning && <small>{warning}</small>))}
  </div>
);

export const required = value => (value ? undefined : 'Required field');

export const optional = value => (value ? undefined : undefined);

export const notEmpty = value =>
  value && !/^[0-9A-Za-z]{5,24}$/.test(value)
    ? 'This field should shorter than 5 and longer than 24 characters and can only contains letters and numbers'
    : undefined;

export const email = value =>
  value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined;

export const phoneValidator = value =>
  value && !/^\+\d{7,16}$/.test(value)
    ? 'Invalid phone (include + and a country code, e.g. +447712345678)'
    : undefined;

export const bicValidation = value =>
  value && !/^[A-Z]{6}[A-Z0-9]{2}([A-Z0-9]{3})?$/.test(value)
    ? 'Invalid BIC/SWIFT for overseas payments (e.g. BUKBGB22)'
    : undefined;

export const sortcodeValidation = value =>
  value && !/^(\d){2}-(\d){2}-(\d){2}$/.test(value)
    ? 'Invalid sortcode for UK payments (e.g. 00-00-00)'
    : undefined;

export const ifscCodeValidation = value =>
  value && !/^([A-Za-z0]{4})(0\d{6})$/.test(value)
    ? 'Invalid IFSC for Indian Rupee payments (e.g. YESB0236041)'
    : undefined;

export const accountIndianValidation = value =>
  value && !/^\d{9,18}$/.test(value)
    ? 'Invalid account number for Indian payments (e.g. 678911234567891)'
    : undefined;

export const accountSouthAfricanValidation = value =>
  value && !/^\d{6,11}$/.test(value)
    ? 'Invalid account number for Indian payments (e.g. 012345678900)'
    : undefined;

export const accountUKValidation = value =>
  value && !/^(\d){8}$/.test(value)
    ? 'Invalid account number for UK payments (e.g. 12345678)'
    : undefined;

export const accountIBANValidation = value =>
  value &&
  !/^([A-Z]{2}[-]?[0-9]{2})(?=(?:[-]?[A-Z0-9]){9,30}$)((?:[-]?[A-Z0-9]{3,5}){2,7})([-]?[A-Z0-9]{1,3})?$/.test(
    value
  )
    ? 'Invalid IBAN for overseas payments (e.g. HU42117730161111101800000000)'
    : undefined;

export const card4DigitsUA = value =>
  value && !/^[0-9]\d{3}/.test(value)
    ? 'For UAH payment last 4 digits of PrivatBank Card (e.g. 2276)'
    : undefined;

export const EUVATwithoutCountryISO = value =>
  value && !/^\d+$/.test(value)
    ? 'Please provide EUVAT without country code (e.g. 12345, instead of GB12345)'
    : undefined;
