import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
  UncontrolledAlert,
} from 'reactstrap';
import Switch from 'react-switch';
import axios from 'axios';

async function isRevolutConnected() {
  try {
    const response = await axios({
      method: 'get',
      url: `/api/revolut/status`,
    });

    const data = response && response.data;
    if (data && !data.error) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

const Revolut = ({ params, search }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [revoluteBusinessEnabled, setRevoluteBusinessEnabled] = useState(false);
  const [isSubmittingRevolut, setIsSubmittingRevolut] = useState(false);

  const onSubmit = useCallback(async () => {
    setIsSubmittingRevolut(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: {
          revoluteBusinessEnabled: revoluteBusinessEnabled,
        },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmittingRevolut(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmittingRevolut(false);
    }
  }, [revoluteBusinessEnabled]);

  useEffect(() => {
    const provider = params && params.provider;

    if (provider === 'revolut') {
      const urlParams = new URLSearchParams(search);
      setRevolutCode(urlParams.get('code') || '');
    }
  }, [params, search]);

  const [revolutCode, setRevolutCode] = useState('');
  const [isRevolutOnline, setIsRevolutOnline] = useState(false);

  useEffect(() => {
    (async function() {
      setIsRevolutOnline(await isRevolutConnected());
    })();
  }, []); // eslint-disable-line

  const handleOnClickLink = useCallback(
    async e => {
      e.preventDefault();
      setIsSubmittingRevolut(true);
      setError(null);
      setSuccess(null);

      try {
        const response = await axios({
          method: 'get',
          url: `/api/revolut/authorize?code=${revolutCode}`,
        });

        const data = response && response.data;
        if (data && !data.error) {
          setSuccess(data.message);
          setRevolutCode('');
        }
        setIsSubmittingRevolut(false);
      } catch (error) {
        setError(
          error.response.data && typeof error.response.data.message === 'string'
            ? error.response.data.message
            : 'Something went wrong :('
        );
        setIsSubmittingRevolut(false);
      }
    },
    [revolutCode]
  );

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/revolut_business2.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Revolut Logo"
                  />
                  Revolut for Business Integration
                </h6>
              </span>
            </div>

            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}

            {!isRevolutOnline ? (
              <>
                <Alert color="warning">Revolut for Business needs to be configured.</Alert>
                <p className="text-muted m-b-30">
                  Please provide authorisation token from Revolut Business administrator console by
                  copying it into the field below. Alternatively go to{' '}
                  <a href="https://business.revolut.com/settings/api">
                    Revolut Business API Console
                  </a>
                  , and click on <em>Enable API access to your account</em> button.
                </p>
                <FormGroup row>
                  <Col sm="8">
                    <div className="d-flex align-items-center">
                      <Switch
                        onColor="#626ed4"
                        onChange={() => {
                          setRevoluteBusinessEnabled(!revoluteBusinessEnabled);
                        }}
                        checked={revoluteBusinessEnabled}
                      />
                      <span className="m-l-10">Enabled integration with Revolut Business.</span>
                    </div>
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="example-text-input" sm="2">
                    Authorisation Code
                  </Label>
                  <Col sm="8">
                    <Input
                      type="text"
                      value={revolutCode}
                      onChange={event => setRevolutCode(event.nativeEvent.target.value)}
                      disabled={isSubmittingRevolut}
                    />
                  </Col>
                  <Col sm="2">
                    <Button
                      type="submit"
                      color="primary"
                      block
                      disabled={!revolutCode || isSubmittingRevolut}
                      onClick={handleOnClickLink}
                    >
                      {!isSubmittingRevolut ? 'Link' : 'Linking...'}
                    </Button>
                  </Col>
                  <FormGroup row>
                    <Col sm="12" className="offset-2">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={isSubmittingRevolut}
                        onClick={onSubmit}
                      >
                        {!isSubmittingRevolut ? 'Submit' : 'Submitting...'}
                      </Button>
                    </Col>
                  </FormGroup>
                </FormGroup>
              </>
            ) : (
              <>
                <Alert color="success">
                  Revolut for Business is connected to Figaro and works fine.
                </Alert>
                <p className="text-muted m-b-30">
                  If you wish to disconnect Figaro from Revolut for Business please go to{' '}
                  <a href="https://business.revolut.com/settings/api">
                    Revolut Business API Console
                  </a>
                  , and click on <em>Revoke API access Token</em> button.
                </p>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Revolut;
