import React, { useState, useRef } from 'react';
import { Alert, Button, Row, Col, Container } from 'reactstrap';
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux';

import Switch from 'react-switch';
import { fetchAdminTimesheets } from '../../store/admin/actions';
import { fetchTimesheets } from '../../store/timesheets/actions';

import './TimesheetUpload.scss';
//import { downloadFile } from '../../helpers/utils';
const noOp = () => {};

function TimesheetUpload({
  timesheetIds,
  onSuccess = noOp,
  onFailure = noOp,
  hasDownloadButton,
  isDraft,
  userID,
  amount,
}) {
  const dispatch = useDispatch();
  const isAdminUser = useSelector(state => state.Admin.isAdminUser);
  const [uploadError, setUploadError] = useState(null);
  const [confirmedInvoice, setConfirmedInvoice] = useState(false);
  const [filename, setFilename] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const formRef = useRef(null);
  const fileRef = useRef(null);

  return (
    <Container fluid className="timesheet-upload border-top">
      {uploadError && (
        <Alert color={confirmedInvoice ? 'info' : 'danger'}>
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            ⛔️
          </span>
          <p dangerouslySetInnerHTML={{ __html: uploadError }}></p>
        </Alert>
      )}
      {uploadError && (
        <Row>
          <Col sm="12" className="d-flex align-items-center m-b-20 p-b-5">
            <Switch
              onColor="#626ed4"
              onChange={() => {
                setConfirmedInvoice(!confirmedInvoice);
              }}
              checked={confirmedInvoice}
            />
            <span className="m-l-10">
              I confirm the invoice is correct and submit it for manual review. <br /> I have tried
              to fix all the issues indicated by Figaro.
            </span>
          </Col>
        </Row>
      )}
      <Row>
        <Col sm={hasDownloadButton ? '6' : '9'} className="m-b-10 m-sm-0">
          <form ref={formRef}>
            <label>Your invoice (PDF only, max. 5 Mb)</label>
            <input
              type="file"
              className="filestyle timesheet-file-input"
              name="invoiceFile"
              ref={fileRef}
              id={`invoiceFile-${timesheetIds[0]}`}
              tabIndex="-1"
              accept="application/pdf"
              onChange={e => {
                const file = e.target.files && e.target.files[0];
                setFilename(file ? file.name : '');
                setUploadError(null);
                setConfirmedInvoice(false);
              }}
            />
            <div className="bootstrap-filestyle input-group">
              <input type="text" className="form-control" placeholder={filename} disabled />
              <span className="group-span-filestyle input-group-append" tabIndex="0">
                <label htmlFor={`invoiceFile-${timesheetIds[0]}`} className="btn btn-secondary ">
                  <span className="icon-span-filestyle fas fa-folder-open"></span>
                  <span className="buttonText">Choose file</span>
                </label>
              </span>
            </div>
            <input
              type="hidden"
              id="invoiceTimesheet"
              value={timesheetIds.join(',')}
              name="invoiceTimesheet"
            />
            <input type="hidden" id="userID" value={userID} name="userID" />
            <input type="hidden" id="amount" value={amount} name="amount" />
          </form>
        </Col>
        <Col sm="3" className="align-self-end">
          <Button
            block
            disabled={isUploading}
            color="success"
            onClick={async () => {
              if (fileRef.current && !fileRef.current.files.length) {
                setUploadError('Please choose a file to upload.');
                return;
              }
              setUploadError(null);
              setIsUploading(true);
              setConfirmedInvoice(false);
              try {
                const data = new FormData(formRef.current);
                const response = await axios({
                  method: 'post',
                  url: isDraft
                    ? `/api/upload/invoice?draft=${isAdminUser ? 2 : 1}` +
                      (userID ? `&userID=${userID}` : '')
                    : `/api/upload/invoice?confirmed=${confirmedInvoice ? 1 : 0}`,
                  data,
                });

                if (response && response.status === 200) {
                  const data = response && response.data;

                  onSuccess((data && data.message) || 'Success!');
                  dispatch(fetchTimesheets());
                  if (isAdminUser) dispatch(fetchAdminTimesheets());
                }
                setIsUploading(false);
              } catch (error) {
                const data = error && error.response && error.response.data;
                setIsUploading(false);
                setConfirmedInvoice(false);
                const code = data?.code;
                const message = data?.message;
                if (code === 400) {
                  setUploadError(
                    'Please check your invoice. Admin team has been notified about this issue.<br/>' +
                      message
                  );
                } else if (code === 422) {
                  setUploadError(
                    "Figaro can't parse your PDF. Please check if your file is corrupted and try again."
                  );
                } else if (code === 401) {
                  setUploadError(message);
                } else {
                  setUploadError('Uh oh, something weird is going on, please try again!');
                }
                onFailure();
              }
            }}
          >
            {isUploading ? 'Uploading...' : 'Upload'}
          </Button>
        </Col>
        {/* {hasDownloadButton && (
          <Col sm="3" className="align-self-end">
            <button
              className="btn btn-block btn-info"
              onClick={() => {
                downloadFile(
                  `/api/bundle/export/pdf?timesheetIds=${timesheetIds}`,
                  `invoice-sample.pdf`
                );
              }}
            >
              Generate Invoice (PDF)
            </button>
          </Col>
        )} */}
      </Row>
    </Container>
  );
}

export default TimesheetUpload;
