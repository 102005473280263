import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button, Row, Col, Alert } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { cloneDeep } from 'lodash';

import './Projects.scss';

import NewProjectModal from './NewProjectModal';
import { fetchProjects } from '../../../store/actions';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import ProjectsTable from './ProjectsTable';

let startDate = new Date().toISOString().substr(0, 10);
let deliveryDate = new Date();
deliveryDate.setMonth(deliveryDate.getMonth() + 1);
deliveryDate = deliveryDate.toISOString().substr(0, 10);

const defaultInitialValues = {
  startDate,
  deliveryDate,
  source: 'azure',
  cost: { currency: 'GBP' },
  budget: { currency: 'GBP' },
  workers: {},
  prices: [],
};

const Projects = () => {
  const { data, isLoading, isAdminUser, error } = useSelector(state => state.Admin);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  useEffect(() => {
    if (!data.projects) dispatch(fetchProjects());
  }, []); // eslint-disable-line

  const [projects, setProjects] = useState([]);

  useEffect(() => {
    if (data.projects) setProjects(Object.values(data.projects));
  }, [data?.projects]);

  const handleOnClickEditAction = useCallback(
    projectId => {
      const project = projects.find(p => p.id === projectId);

      if (project) {
        project.invoices = Array.isArray(project.invoices)
          ? project.invoices.join()
          : project.invoices;
        setInitialValues(project);
        setShowModal(true);
      }
    },
    [projects]
  );

  const handleOnClickCloneAction = useCallback(
    projectId => {
      const project = projects.find(p => p.id === projectId);

      if (project) {
        const values = cloneDeep(project);
        values.startDate = startDate;
        delete values.id;
        delete values.title;
        delete values.epic;
        delete values.deliveryDate;
        delete values.cost.value;
        delete values.budget.value;
        delete values.costToDate;
        delete values.invoices;

        setInitialValues(values);
        setShowModal(true);
      }
    },
    [projects]
  );

  if (!isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <>
      <Container className="projects" fluid>
        <div className="sticky-title">
          <Row className="align-items-center">
            <Col xs={6}>
              <h5 className="page-title">Projects</h5>
            </Col>
            <Col xs={6} className="text-right">
              <Button
                color="primary"
                className="m-r-10"
                onClick={() => {
                  setInitialValues(defaultInitialValues);
                  setShowModal(true);
                }}
              >
                Create Project
              </Button>
              <Button color="secondary" onClick={() => dispatch(fetchProjects())}>
                Refresh List
              </Button>
            </Col>
          </Row>

          {!!error && (
            <Alert color="danger">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                ⛔️
              </span>
              {error}
            </Alert>
          )}
        </div>

        <ProjectsTable
          data={projects}
          isLoading={isLoading}
          onClickEditAction={handleOnClickEditAction}
          onClickCloneAction={handleOnClickCloneAction}
        />
      </Container>

      <NewProjectModal
        isOpen={showModal}
        hide={() => setShowModal(false)}
        initialValues={initialValues}
      />

      <ReactTooltip />
    </>
  );
};

export default Projects;
