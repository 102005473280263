import React, { useState, useEffect, useRef } from 'react';
import { Alert, Container, Card, CardBody, Row, Col, Label, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

const DocumentPreview = ({ documentUrl, title, zoom }) => {
  const reactTooltip = useRef(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [realZoom, setRealZoom] = useState(0.8);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setRealZoom(zoom ?? 0.8);
  }

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  useEffect(() => {
    setPageNumber(1);
  }, [documentUrl, title]);

  return (
    <Container className="d-flex justify-content-center">
      <Card>
        <CardBody>
          <Row>
            <Col className="w-auto">
              <Label className="card-title" sm="12">
                {title ? title : 'Document Preview'}
              </Label>
              <Document file={documentUrl} onLoadSuccess={onDocumentLoadSuccess}>
                <Page pageNumber={pageNumber} scale={realZoom} />
              </Document>
            </Col>
          </Row>
          {documentUrl && (
            <Row className="m-t-20 m-r-10">
              <Col sm="3" className="text-left">
                <Button
                  data-tip="Show Previous Page"
                  data-effect="solid"
                  data-delay-show="200"
                  color="dark"
                  disabled={pageNumber === 1}
                  onClick={() => {
                    setPageNumber(pageNumber - 1);
                  }}
                >
                  Previous
                </Button>
              </Col>
              <Col sm="6" className="text-center">
                <p>
                  Page {pageNumber} of {numPages}
                </p>
              </Col>
              <Col sm="3" className="text-right">
                <Button
                  data-tip="Show Next Page"
                  data-effect="solid"
                  data-delay-show="200"
                  color="dark"
                  disabled={pageNumber >= numPages}
                  onClick={() => {
                    setPageNumber(pageNumber + 1);
                  }}
                >
                  Next
                </Button>
              </Col>
            </Row>
          )}
          <Row>
            <Col sm="12">
              {!documentUrl && <Alert color="dark">Document preview is not available yet.</Alert>}
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ReactTooltip ref={reactTooltip} />
    </Container>
  );
};

export default DocumentPreview;
