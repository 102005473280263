export const columnsDefault = [
  {
    Header: 'ID',
    accessor: 'taskID',
    Footer: 'ID',
  },
  {
    Header: 'Task Name',
    accessor: 'taskName',
    Footer: 'Task Name',
  },
  {
    Header: 'Colleague',
    accessor: 'worker',
    Footer: 'Colleague',
  },
  {
    Header: 'Days Planned',
    accessor: 'daysPlanned',
    Footer: 'Days Planned',
    sortType: 'basic',
  },
  {
    Header: 'Days Worked',
    accessor: 'daysWorked',
    Footer: 'Days Worked',
    sortType: 'basic',
  },
  {
    Header: 'Hours Planned',
    accessor: 'hoursPlanned',
    Footer: 'Hours Planned',
    sortType: 'basic',
  },
  {
    Header: 'Hours Worked',
    accessor: 'hoursWorked',
    Footer: 'Hours Worked',
    sortType: 'basic',
  },
  {
    Header: 'Worker Rate',
    accessor: 'workerRate',
    Footer: 'Worker Rate',
    sortType: 'basic',
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    Footer: 'Cost',
    sortType: 'basic',
  },
  {
    Header: 'Client Rate',
    accessor: 'clientRate',
    Footer: 'Client Rate',
    sortType: 'basic',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Footer: 'Price',
    sortType: 'basic',
  },
  {
    Header: 'Margin',
    accessor: 'margin',
    Footer: 'Margin',
    sortType: 'basic',
  },
];

export const columnsDays = [
  {
    Header: 'ID',
    accessor: 'taskID',
    Footer: 'ID',
  },
  {
    Header: 'Task Name',
    accessor: 'taskName',
    Footer: 'Task Name',
  },
  {
    Header: 'Colleague',
    accessor: 'worker',
    Footer: 'Colleague',
  },
  {
    Header: 'Days Planned',
    accessor: 'daysPlanned',
    Footer: 'Days Planned',
    sortType: 'basic',
  },
  {
    Header: 'Days Worked',
    accessor: 'daysWorked',
    Footer: 'Days Worked',
    sortType: 'basic',
  },
];

export const columnsHours = [
  {
    Header: 'ID',
    accessor: 'taskID',
    Footer: 'ID',
  },
  {
    Header: 'Task Name',
    accessor: 'taskName',
    Footer: 'Task Name',
  },
  {
    Header: 'Colleague',
    accessor: 'worker',
    Footer: 'Colleague',
  },
  {
    Header: 'Hours Planned',
    accessor: 'hoursPlanned',
    Footer: 'Hours Planned',
    sortType: 'basic',
  },
  {
    Header: 'Hours Worked',
    accessor: 'hoursWorked',
    Footer: 'Hours Worked',
    sortType: 'basic',
  },
];

export const columnsFinancials = [
  {
    Header: 'ID',
    accessor: 'taskID',
    Footer: 'ID',
  },
  {
    Header: 'Task Name',
    accessor: 'taskName',
    Footer: 'Task Name',
  },
  {
    Header: 'Colleague',
    accessor: 'worker',
    Footer: 'Colleague',
  },
  {
    Header: 'Days Worked',
    accessor: 'daysWorked',
    Footer: 'Days Worked',
    sortType: 'basic',
  },
  {
    Header: 'Worker Rate',
    accessor: 'workerRate',
    Footer: 'Worker Rate',
    sortType: 'basic',
  },
  {
    Header: 'Cost',
    accessor: 'cost',
    Footer: 'Cost',
    sortType: 'basic',
  },
  {
    Header: 'Client Rate',
    accessor: 'clientRate',
    Footer: 'Client Rate',
    sortType: 'basic',
  },
  {
    Header: 'Price',
    accessor: 'price',
    Footer: 'Price',
    sortType: 'basic',
  },
  {
    Header: 'Margin',
    accessor: 'margin',
    Footer: 'Margin',
    sortType: 'basic',
  },
];
