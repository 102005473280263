import React from 'react';
import { Col, Row, Card, Alert, Spinner } from 'reactstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signIn } from '../../store/actions';
import logo from '../../images/figaro/apple-touch-icon.png';
import { signInWithMicrosoft } from '../../helpers/authUtils';

const pkg = require('../../../package.json');

const consuelaImage = require('../../images/consuela.png');

class Login extends React.Component {
  componentDidMount() {
    this.handleRedirects();
  }

  componentDidUpdate() {
    this.handleRedirects();
  }

  handleRedirects = () => {
    const pathname =
      this.props.location && this.props.location.state && this.props.location.state.redirect;
    const search =
      this.props.location && this.props.location.state && this.props.location.state.search;

    if (this.props.userID) {
      if (pathname && pathname !== '/') {
        this.props.history.push({ pathname: pathname, search: search });
      } else {
        this.props.history.push('/dashboard');
      }
    }
  };

  signInWithMicrosoft = () => {
    signInWithMicrosoft();
  };

  render() {
    const { error, isLoading } = this.props;

    return (
      <React.Fragment>
        <div className="home-btn d-none d-sm-block">
          <Link to="/" className="text-dark">
            <i className="fas fa-home h2"></i>
          </Link>
        </div>

        <div className="wrapper-page">
          <Card className="overflow-hidden account-card mx-10">
            <div className="bg-primary p-4 text-white text-center position-relative">
              <h2 className="font-20 m-b-5">FIGARO</h2>
              {/* <p className="text-white-40 mb-4">Software Development Process Management System</p> */}
              <p className="text-white-40 mb-4">
                Virtual office where the best specialists work together
              </p>
              <div className="logo logo-admin bg-primary">
                <img src={logo} height="45" alt="logo" />
              </div>
            </div>
            <div className="account-card-content">
              <Row className="form-group m-t-20 text-center">
                {this.renderError(error)}

                <Col sm="12" className="text-center m-t-20">
                  <button
                    className="sign-in-microsoft-button"
                    onClick={this.signInWithMicrosoft}
                    disabled={isLoading}
                  >
                    <img
                      src={require('../../images/microsoft-logo.svg')}
                      alt="Microsoft logo"
                      style={{ marginRight: '12px' }}
                    />
                    <span>Sign in with Microsoft</span>
                  </button>
                </Col>

                {isLoading && (
                  <div className="d-flex align-items-center justify-content-center m-t-40 w-100">
                    <Spinner className="m-0" />
                    <div className="m-l-15">Checking your credentials...</div>
                  </div>
                )}
              </Row>
            </div>
            <span className="madeby-text">
              {pkg && `Version ${pkg.version}`}. Made with ❤️
              <a href="https://www.londonappworks.co.uk">in London</a>
            </span>
          </Card>
        </div>
      </React.Fragment>
    );
  }

  renderError(error) {
    if (!error) return null;

    if (error.code || error.message) {
      return (
        <Col>
          {error.code && <h3 className="text-danger">{error.code}</h3>}
          {error.message && <h5>{error.message}</h5>}
          <img
            src={consuelaImage}
            alt={error.code}
            style={{ display: 'block', margin: '0 auto', height: '180px' }}
          />
          <a href={`mailto:support@londonappworks.co.uk`}>Click here to contact Support.</a>
        </Col>
      );
    } else {
      return <Alert color="danger">{error}</Alert>;
    }
  }
}

const mapStatetoProps = state => {
  const { userID, isLoading, error } = state.Auth;
  return { userID, isLoading, error };
};

export default withRouter(connect(mapStatetoProps, { signIn })(Login));
