export const SET_ADMIN_USER = 'SET_ADMIN_USER';

export const FETCH_ADMIN_TIMESHEETS = 'FETCH_ADMIN_TIMESHEETS';
export const FETCH_ADMIN_TIMESHEETS_SUCCESS = 'FETCH_ADMIN_TIMESHEETS_SUCCESS';
export const FETCH_ADMIN_TIMESHEETS_FAILURE = 'FETCH_ADMIN_TIMESHEETS_FAILURE';

export const FETCH_PROJECTS = 'FETCH_PROJECTS';
export const FETCH_PROJECTS_SUCCESS = 'FETCH_PROJECTS_SUCCESS';
export const FETCH_PROJECTS_FAILURE = 'FETCH_PROJECTS_FAILURE';

export const FETCH_ADMIN_TIMETRACKERS = 'FETCH_ADMIN_TIMETRACKERS';
export const FETCH_ADMIN_TIMETRACKERS_SUCCESS = 'FETCH_ADMIN_TIMETRACKERS_SUCCESS';
export const FETCH_ADMIN_TIMETRACKERS_FAILURE = 'FETCH_ADMIN_TIMETRACKERS_FAILURE';

export const FETCH_DAILY_REPORT = 'FETCH_DAILY_REPORT';
export const FETCH_DAILY_REPORT_SUCCESS = 'FETCH_DAILY_REPORT_SUCCESS';
export const FETCH_DAILY_REPORT_FAILURE = 'FETCH_DAILY_REPORT_FAILURE';

export const FETCH_DAILY_REPORT_UPDATE = 'FETCH_DAILY_REPORT_UPDATE';
export const FETCH_DAILY_REPORT_UPDATE_SUCCESS = 'FETCH_DAILY_REPORT_UPDATE_SUCCESS';
export const FETCH_DAILY_REPORT_UPDATE_FAILURE = 'FETCH_DAILY_REPORT_UPDATE_FAILURE';

export const FETCH_COMPANIES = 'FETCH_COMPANIES';
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_FAILURE = 'FETCH_COMPANIES_FAILURE';

export const APPROVE_PAYMENT_REQUEST = 'APPROVE_PAYMENT_REQUEST';
export const REJECT_PAYMENT_REQUEST = 'REJECT_PAYMENT_REQUEST';
export const ACTION_PAYMENT_REQUEST_SUCCESS = 'ACTION_PAYMENT_REQUEST_SUCCESS';
export const ACTION_PAYMENT_REQUEST_FAILURE = 'ACTION_PAYMENT_REQUEST_FAILURE';

export const FETCH_PAYMENTS = 'FETCH_PAYMENTS';
export const FETCH_PAYMENTS_SUCCESS = 'FETCH_PAYMENTS_SUCCESS';
export const FETCH_PAYMENTS_FAILURE = 'FETCH_PAYMENTS_FAILURE';

export const ADD_SCHEDULED_PAYMENT = 'ADD_SCHEDULED_PAYMENT';
export const REMOVE_SCHEDULED_PAYMENT = 'REMOVE_SCHEDULED_PAYMENT';

export const FETCH_PAYMENTS_CARDS = 'FETCH_PAYMENTS_CARDS';
export const FETCH_PAYMENTS_CARDS_FAILURE = 'FETCH_PAYMENTS_CARDS_FAILURE';
export const FETCH_PAYMENTS_CARDS_SUCCESS = 'FETCH_PAYMENTS_CARDS_SUCCESS';
