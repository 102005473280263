import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Container, Row, Col, Button, Label, FormGroup } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import DailyTable from './DailyTable';
import { fetchDailyReport, fetchDailyReportUpdate } from '../../../store/admin/actions';
import { downloadFile } from '../../../helpers/utils';

const DailyReport = () => {
  const dispatch = useDispatch();
  const [date, setDate] = useState(moment(new Date()).format('YYYY-MM-DD'));

  const admin = useSelector(state => state.Admin);
  const { isLoading, error, data } = admin;
  const [isDownloading, setIsDownloading] = useState(false);
  const dailyReport = data.reports.daily;

  if (!admin.isAdminUser) return <AccessDeniedPoster />;

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Generate Daily Report</h4>
          </Col>
        </Row>
      </div>

      {error && (
        <Alert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {(error && error.message) || `Something didn't go very well...`}
        </Alert>
      )}

      <Label className="d-block">
        <span className="order bg-primary">1</span>Select date
      </Label>
      <Row>
        <Col lg={3} md={4} sm={6}>
          <FormGroup>
            <input
              type="date"
              className="form-control"
              value={date}
              onChange={e => setDate(e.target.value)}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row className="m-b-30">
        <Col sm={2}>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={() => {
              dispatch(fetchDailyReport(date));
            }}
          >
            Generate Report
          </Button>
        </Col>
        <Col sm={3}>
          <button
            className="btn btn-primary"
            disabled={isLoading || isDownloading}
            onClick={() => {
              setIsDownloading(true);
              downloadFile(
                `/api/timetracker/daily-report-pdf?date=${date}`,
                `DailyReport-${date}.pdf`,
                () => {
                  setIsDownloading(false);
                }
              );
            }}
          >
            Generate and Download PDF
          </button>
        </Col>
        <Col sm={3}>
          <Button
            color="success"
            disabled={isLoading}
            onClick={() => {
              dispatch(fetchDailyReportUpdate());
            }}
          >
            Request Daily Report Update
          </Button>
        </Col>
      </Row>

      <Label className="d-block m-t-30">
        <span className="order bg-primary">2</span>Review the report
      </Label>

      <DailyTable data={dailyReport} isLoading={isLoading || isDownloading} />

      <ReactTooltip />
    </Container>
  );
};

export default DailyReport;
