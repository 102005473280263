export const statusMapping = {
  NOT_AVAILABLE: { title: 'Unknown Status', description: 'Status not available', color: 'dark' },
  EXPECTING_INVOICE: { title: 'Expecting Invoice', description: '', color: 'warning' },
  HOLDING_INVOICE: { title: 'Pending Review', description: '', color: 'danger' },
  WAITING_FOR_PAYMENT: { title: 'Waiting for Payment', description: '', color: 'danger' },
  SCHEDULED: {
    title: 'Scheduled',
    description: 'Payment will be processed in the next payment run',
    color: 'info',
  },
  INCOMING_PAYMENT_WAITING: { title: 'Incoming Payment Needed', description: '', color: 'danger' },
  WAITING_RECIPIENT_INPUT_TO_PROCEED: {
    title: 'Recipient Missing',
    description: '',
    color: 'danger',
  },
  PENDING: { title: 'Pending...', description: '', color: 'info' },
  PROCESSING: { title: 'Processing...', description: '', color: 'info' },
  FUNDS_CONVERTED: { title: 'Funds Converted', description: '', color: 'info' },
  OUTGOING_PAYMENT_SENT: { title: 'Payment Sent', description: '', color: 'info' },
  CANCELLED: { title: 'Payment Cancelled', description: '', color: 'warning' },
  FUNDS_REFUNDED: { title: 'Payment Refunded', description: '', color: 'danger' },
  BOUNCED_BACK: { title: 'Payment Returned', description: '', color: 'danger' },
  PAID: { title: 'Paid', description: "Payment has reached the payee's account", color: 'success' },
  FREELANCER_PAID: {
    title: 'Paid via Agency',
    description: 'Worker is paid via their agency or third-party system.',
    color: 'success',
  },
  COMPLETED: {
    title: 'Payment Completed',
    description: 'Payment has reached the payee.',
    color: 'success',
  },
};

export const PAYMENT_REQUEST_STATUS = {
  NOT_AVAILABLE: 'not_available',
  EXPECTING_INVOICE: 'expecting_invoice',
  HOLDING_INVOICE: 'holding_invoice',
  WAITING_FOR_PAYMENT: 'waiting_for_payment',
  SCHEDULED: 'scheduled',
  INCOMING_PAYMENT_WAITING: 'incoming_payment_waiting',
  WAITING_RECIPIENT_INPUT_TO_PROCEED: 'waiting_recipient_input_to_proceed',
  PROCESSING: 'processing',
  PENDING: 'pending',
  FUNDS_CONVERTED: 'funds_converted',
  OUTGOING_PAYMENT_SENT: 'outgoing_payment_sent',
  CANCELLED: 'cancelled',
  FUNDS_REFUNDED: 'funds_refunded',
  BOUNCED_BACK: 'bounced_back',
  PAID: 'paid',
  COMPLETED: 'completed',
};
