import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Row, Col, Spinner, Container, Button } from 'reactstrap';
import cn from 'classnames';

import AdminTimesheetsTable from './AdminTimesheetsTable';
import { fetchAdminTimesheets } from '../../../store/actions';

const AdminTimesheets = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState('review');
  const [userFilter, setUserFilter] = useState('');
  const admin = useSelector(state => state.Admin);
  const { profiles } = useSelector(state => state.Team);

  const { data, error, isLoading } = admin;
  const timesheets = data && data.timesheets;
  const cards = data && data.timesheets && data.timesheets.cards;

  useEffect(() => {
    dispatch(fetchAdminTimesheets(filter, userFilter));
  }, [filter, userFilter]); // eslint-disable-line

  return (
    <Container>
      <div className="page-title-box">
        <Row>
          <Col sm="3" className="m-b-15">
            <h4 className="page-title">Timesheets & Payments</h4>
          </Col>
          <Col sm="6" className="m-b-15 text-right">
            <div
              className="btn-group btn-group-toggle m-l-15"
              data-toggle="buttons"
              hidden={isLoading}
            >
              <label className={cn('btn', 'btn-secondary', { active: filter === 'review' })}>
                <input type="radio" name="filter" onChange={() => setFilter('review')} />
                Review
              </label>
              <label className={cn('btn', 'btn-secondary', { active: filter === 'unpaid' })}>
                <input type="radio" name="filter" onChange={() => setFilter('unpaid')} />
                Needs Payment
              </label>
              <label className={cn('btn', 'btn-secondary', { active: filter === 'scheduled' })}>
                <input type="radio" name="filter" onChange={() => setFilter('scheduled')} />
                Scheduled
              </label>
              <label className={cn('btn', 'btn-secondary', { active: filter === 'paid' })}>
                <input type="radio" name="filter" onChange={() => setFilter('paid')} />
                Paid
              </label>
              <label className={cn('btn', 'btn-secondary', { active: filter === '' })}>
                <input type="radio" name="filter" onChange={() => setFilter('')} />
                Show All
              </label>
            </div>
          </Col>
          <Col sm="2" className="text-right">
            <select
              className="form-control d-inline-block w-auto"
              value={userFilter}
              onChange={e => {
                setUserFilter(e.target.value);
              }}
            >
              <option value="">All Workers</option>
              {profiles &&
                Object.values(profiles)
                  .sort((a, b) => (b.firstName < a.firstName ? 1 : -1))
                  .map(profile => (
                    <option key={profile.userID} value={profile.userID}>
                      {profile.firstName} {profile.lastName}
                    </option>
                  ))}
            </select>
          </Col>
          <Col sm="1">
            <Button
              color="outline-secondary"
              className="m-r-10"
              style={{ height: '38px', lineHeight: 'normal', padding: '5px' }}
              data-tip="Reload Timesheets"
              data-effect="solid"
              data-delay-show="200"
              onClick={() => {
                dispatch(fetchAdminTimesheets(filter, userFilter));
              }}
            >
              <i className="mdi mdi-refresh font-24" />
            </Button>
          </Col>
        </Row>

        {error && (
          <Alert color="danger m-t-20" className="wrapped-alert">
            <span role="img" aria-label="see no evil" className="font-18 m-r-5">
              🙈
            </span>
            {error.message}
          </Alert>
        )}
      </div>
      {isLoading && (
        <div className="text-center m-t-40">
          <Spinner color="primary" />
        </div>
      )}

      {!isLoading && !!timesheets && <AdminTimesheetsTable timesheets={timesheets} cards={cards} />}
    </Container>
  );
};

export default AdminTimesheets;
