import Axios from 'axios';
import React from 'react';

//import moment from 'moment';
import moment from 'moment-recur';

export function copyToClipboard(text) {
  const el = document.createElement('input');
  el.value = text;

  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);

  /* Alert the copied text */
  alert('Copied the text: ' + text);
}

/**
 * Group By property from a collection of objects
 *
 * @param {array} list
 * @param {function} keyGetter
 *
 * @returns {Map} A map of the grouped elements
 */
export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach(item => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}

export function isEU(countryISO) {
  const EU = [
    'AT',
    'BE',
    'BG',
    'CY',
    'CZ',
    'DE',
    'DK',
    'EE',
    'ES',
    'FI',
    'FR',
    'GB',
    'GR',
    'HR',
    'HU',
    'IE',
    'IT',
    'LT',
    'LU',
    'LV',
    'MT',
    'NL',
    'PL',
    'PT',
    'RO',
    'SE',
    'SI',
    'SK',
  ];

  if (countryISO) {
    return EU.indexOf(countryISO) > -1;
  }

  return false;
}

export function calculateDuration(times, baseTime = 0) {
  let duration = 0;

  if (baseTime) duration = baseTime;
  else {
    times.forEach(time => {
      if (time.fromTime && time.toTime) {
        const fromTime = new Date(time.fromTime).getTime();
        const toTime = new Date(time.toTime).getTime();
        baseTime += toTime - fromTime;
      }
    });

    duration = baseTime;
  }

  const lastTime = times[times.length - 1];
  duration += Date.now() - new Date(lastTime.fromTime).getTime();

  return { duration, baseTime };
}

export function formatHourDuration(hours) {
  const wholeHours = Math.floor(hours);
  const remainingMinutes = Math.round((hours - wholeHours) * 60);
  const formattedRemainingMinutes = `${
    remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes
  }`;
  return `${wholeHours < 10 ? '0' + wholeHours : wholeHours}:${formattedRemainingMinutes}`;
}

export function formatDuration(milliseconds, excludeSeconds = false) {
  const seconds = milliseconds / 1000;
  const minutes = Math.trunc(seconds / 60);
  const remainingSeconds = Math.round(seconds % 60);
  const hours = Math.trunc(minutes / 60);
  const remainingMinutes = minutes % 60;

  const formattedSeconds = `:${remainingSeconds < 10 ? '0' + remainingSeconds : remainingSeconds}`;

  return `${hours}:${remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes}${
    excludeSeconds ? '' : formattedSeconds
  }`;
}

export function renderDuration(milliseconds, animated = false, className) {
  const seconds = milliseconds / 1000;
  const minutes = Math.trunc(seconds / 60);
  const hours = Math.trunc(minutes / 60);
  const remainingMinutes = minutes % 60;
  const formattedMinutes = remainingMinutes < 10 ? '0' + remainingMinutes : remainingMinutes;

  return (
    <div className={className}>
      <span>{hours || 0}</span>
      <span className={`${animated ? 'seconds-blink' : ''}`}>:</span>
      <span>{formattedMinutes || '00'}</span>
    </div>
  );
}

export function downloadFile(url, filename, optionalCallback) {
  Axios.get(url, { responseType: 'blob' })
    .then(response => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      if (optionalCallback) {
        optionalCallback(true);
      }
    })
    .catch(error => {
      console.log(error);
      alert(`There was a problem downloading this file: \n${(error && error.message) || error}`);
      if (optionalCallback) {
        optionalCallback(false);
      }
    });
}

export function prefetchFile(url, optionalCallback) {
  Axios.get(url, { responseType: 'blob' })
    .then(response => {
      if (optionalCallback) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        optionalCallback(url);
      }
    })
    .catch(error => {
      console.log(error);
      alert(`There was a problem downloading this file: \n${(error && error.message) || error}`);
      if (optionalCallback) {
        optionalCallback(false);
      }
    });
}

export function saveFileFromUrl(url, filename, optionalCallback) {
  if (url) {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    if (optionalCallback) {
      optionalCallback(true);
    }
  } else {
    console.log('Nothing to save');
    if (optionalCallback) {
      optionalCallback(false);
    }
  }
}

export async function sendReminder(paymentType, timesheetId, optionalCallback) {
  try {
    const response = await Axios.get('/api/admin/email/reminder/payment', {
      params: {
        paymentType,
        timesheetId,
      },
    });
    optionalCallback(response);
  } catch (error) {
    if (optionalCallback) {
      optionalCallback(false);
    }
  }
}

export async function getPreviewDocument(documentKey, protectionKey, optionalCallback) {
  try {
    const response = await Axios.get('/api/documents/preview', {
      responseType: 'blob',
      params: {
        documentKey,
        protectionKey,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    optionalCallback(url);
  } catch (error) {
    if (optionalCallback) {
      const rawPayload = await error.response.data.text();
      const errorObject = JSON.parse(rawPayload);
      if (errorObject) {
        optionalCallback(null, errorObject);
      } else {
        optionalCallback(null, error);
      }
    }
  }
}

export async function acceptDocument(documentRef, protectionKey, optionalCallback) {
  try {
    await Axios.get('/api/documents/accept', {
      params: {
        documentRef,
        protectionKey,
      },
    });
    optionalCallback(null);
  } catch (error) {
    if (optionalCallback) {
      optionalCallback(error?.response?.data || new Error('Failed to accept document'));
    }
  }
}

export async function rejectDocument(documentRef, protectionKey, optionalCallback) {
  try {
    await Axios.get('/api/documents/reject', {
      params: {
        documentRef,
        protectionKey,
      },
    });
    optionalCallback(null);
  } catch (error) {
    if (optionalCallback) {
      optionalCallback(error?.response?.data || new Error('Failed to reject document'));
    }
  }
}

export async function shareDocument(documentRef, optionalCallback) {
  try {
    const response = await Axios.get('/api/documents/share', {
      params: {
        documentRef,
      },
    });
    optionalCallback(response);
  } catch (error) {
    if (optionalCallback) {
      optionalCallback(false);
    }
  }
}

export async function getSchedule1(
  projectIDs,
  companyID,
  workerID,
  isDraft,
  shouldIncludeAllTickets,
  shouldSign,
  optionalCallback
) {
  try {
    const response = await Axios.get('/api/projects/schedule1', {
      responseType: 'blob',
      params: {
        projectIDs,
        companyID,
        workerID,
        isDraft,
        shouldSign,
        shouldIncludeAllTickets,
      },
    });
    const documentRef = response?.headers['x-document-ref'];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    optionalCallback({ url, documentRef });
  } catch (error) {
    if (optionalCallback) {
      const rawPayload = await error.response.data.text();
      const errorObject = JSON.parse(rawPayload);
      if (errorObject) {
        optionalCallback(null, errorObject?.message);
      } else {
        optionalCallback(null, error);
      }
    }
  }
}

export async function getWorkerAgreement(
  selectedService,
  companyID,
  fromDate,
  toDate,
  workerID,
  isDraft,
  shouldIncludeTranslations,
  shouldSign,
  optionalCallback
) {
  try {
    const response = await Axios.get('/api/projects/workeragreement', {
      responseType: 'blob',
      params: {
        selectedService,
        companyID,
        fromDate,
        toDate,
        workerID,
        isDraft,
        shouldSign,
        shouldIncludeTranslations,
      },
    });
    const documentRef = response?.headers['x-document-ref'];
    const url = window.URL.createObjectURL(new Blob([response.data]));
    optionalCallback({ url, documentRef });
  } catch (error) {
    if (optionalCallback) {
      const rawPayload = await error.response.data.text();
      const errorObject = JSON.parse(rawPayload);
      if (errorObject) {
        optionalCallback(null, errorObject?.message);
      } else {
        optionalCallback(null, error);
      }
    }
  }
}

export async function getScopeOfWork(
  projectIDs,
  shouldIncludeWorkerEmails,
  shouldIncludeAllTickets,
  isDraft,
  optionalCallback
) {
  try {
    const response = await Axios.get('/api/projects/scopeofwork', {
      responseType: 'blob',
      params: {
        projectIDs,
        iwe: shouldIncludeWorkerEmails,
        iat: shouldIncludeAllTickets,
        draft: isDraft,
      },
    });
    const url = window.URL.createObjectURL(new Blob([response.data]));
    optionalCallback(url);
  } catch (error) {
    if (optionalCallback) {
      const rawPayload = await error.response.data.text();
      const errorObject = JSON.parse(rawPayload);
      if (errorObject) {
        optionalCallback(null, errorObject?.message);
      } else {
        optionalCallback(null, error);
      }
    }
  }
}

function getPreviousWorkday(momentDate) {
  // Get today
  var previousBusinessDay = momentDate.subtract(1, 'days');
  // If today isn't a weekend, continue iterating back until you hit a non-weekend
  while ([0, 6].indexOf(previousBusinessDay.day()) !== -1) {
    previousBusinessDay = previousBusinessDay.subtract(1, 'days').startOf('day');
  }
  // Return the non-weekend day
  return previousBusinessDay;
}

function getNextWorkday(momentDate) {
  // Get today
  var nextWorkDay = momentDate.add(1, 'days');
  // If today isn't a weekend, continue iterating back until you hit a non-weekend
  while ([0, 6].indexOf(nextWorkDay.day()) !== -1) {
    nextWorkDay = nextWorkDay.add(1, 'days').startOf('day');
  }
  // Return the non-weekend day
  return nextWorkDay;
}

export function getNextTimesheetDate(timesheetDay) {
  const shiftDays = 1;
  const baseDate = moment();
  let recurrence = baseDate
    .subtract(shiftDays, 'days')
    .recur()
    .every([4])
    .weeksOfMonth()
    .every(['Friday'])
    .daysOfWeek();

  switch (timesheetDay) {
    // Last business day of the month
    default:
    case '0': {
      const lastDayOfTheMonth = baseDate
        .subtract(shiftDays, 'days')
        .clone()
        .add(1, 'months')
        .startOf('month');
      const previousBusinessDay = getPreviousWorkday(lastDayOfTheMonth);
      recurrence = lastDayOfTheMonth.recur(previousBusinessDay.subtract(1, 'days'));
      break;
    }
    // Last Monday
    case '1':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Monday')
        .daysOfWeek()
        .every([4, 3])
        .weeksOfMonthByDay()
        .monthsOfYear();
      break;
    // Last Tuesday
    case '2':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Tuesday')
        .daysOfWeek()
        .every([4, 3])
        .weeksOfMonthByDay()
        .monthsOfYear();
      break;
    // Last Wednesday
    case '3':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Wednesday')
        .daysOfWeek()
        .every([4, 3])
        .weeksOfMonthByDay()
        .monthsOfYear();
      break;
    // Last Thursday
    case '4':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Thursday')
        .daysOfWeek()
        .every([4, 3])
        .weeksOfMonthByDay()
        .monthsOfYear();
      break;
    // Last Friday
    case '5':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Friday')
        .daysOfWeek()
        .every([4, 3])
        .weeksOfMonthByDay()
        .monthsOfYear();
      break;
    // Every Monday
    case '11':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Monday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Tuesday
    case '12':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Tuesday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Wednesday
    case '13':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Wednesday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Thursday
    case '14':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Thursday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Friday
    case '15':
      recurrence = baseDate
        .subtract(shiftDays, 'days')
        .recur()
        .every('Friday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
  }

  const nextDates = recurrence.next(1, 'dddd, DD MMMM YYYY');
  return nextDates;
}

export function getNextPayDate(payday) {
  let shiftDays = 1;
  let recurrence = moment()
    .subtract(shiftDays, 'days')
    .recur()
    .every([1])
    .weeksOfMonth();

  switch (payday) {
    // Every first day of the month
    default:
    case '0': {
      const fistBusinessDay = getPreviousWorkday(
        moment()
          .subtract(shiftDays, 'days')
          .clone()
          .add(1, 'months')
          .startOf('month')
      );
      const previousBusinessDay = getNextWorkday(fistBusinessDay);
      recurrence = fistBusinessDay.recur(previousBusinessDay.subtract(1, 'days'));
      break;
    }
    // First Monday
    case '1':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Monday')
        .daysOfWeek()
        .every([0])
        .weeksOfMonthByDay();
      break;
    // First Tuesday
    case '2':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Tuesday')
        .daysOfWeek()
        .every([0])
        .weeksOfMonthByDay();
      break;
    // First Wednesday
    case '3':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Wednesday')
        .daysOfWeek()
        .every([0])
        .weeksOfMonthByDay();
      break;
    // First Thursday
    case '4':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Thursday')
        .daysOfWeek()
        .every([0])
        .weeksOfMonthByDay();
      break;
    // First Friday
    case '5':
      recurrence = moment()
        .subtract(shiftDays, 'day')
        .recur()
        .every('Friday')
        .daysOfWeek()
        .every([0])
        .weeksOfMonthByDay();
      break;
    // Every Monday
    case '11':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Monday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Tuesday
    case '12':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Tuesday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Wednesday
    case '13':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Wednesday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Thursday
    case '14':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Thursday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    // Every Friday
    case '15':
      recurrence = moment()
        .subtract(shiftDays, 'days')
        .recur()
        .every('Friday')
        .daysOfWeek()
        .every([0, 1, 2, 3])
        .weeksOfMonthByDay();
      break;
    case '16':
      console.log('Same as timesheet');
      return null;
  }

  const nextDates = recurrence.next(1, 'dddd, DD MMMM YYYY');
  return nextDates;
}
