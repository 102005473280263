import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Container,
  Row,
  Col,
  Label,
  Button,
  ListGroup,
  ListGroupItem,
  UncontrolledAlert,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import Axios from 'axios';
import cn from 'classnames';
import moment from 'moment';

import ProjectTable from './ProjectTable';
import ProjectReportOverview from './ProjectReportOverview';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import { formatCurrency } from '../../../helpers/numbers';
import { fetchProjects } from '../../../store/admin/actions';
import { downloadFile } from '../../../helpers/utils';

async function getReport(fromDate, toDate, projectID, totalReport, regenerate) {
  try {
    const response = await Axios.get('/api/projects/report', {
      params: totalReport
        ? { projectID, totalReport, regenerate: +regenerate }
        : { fromDate, toDate, projectID },
    });
    const data = response && response.data;
    if (data && !data.error) {
      return data.report;
    }
    throw (data && data.error) || 'Unknown error';
  } catch (error) {
    const data = error && error.response && error.response.data;
    throw Error((data && data.message) || error.message);
  }
}

const ProjectReport = () => {
  const reactTooltip = useRef(null);
  const [selectedProject, setSelectedProject] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fromDate, setFromDate] = useState(
    moment()
      .subtract(7, 'days')
      .startOf('week')
      .format('YYYY-MM-DD')
  );
  const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
  const [report, setReport] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [needsTotalReport, setNeedsTotalReport] = useState(0);
  const [reportType, setReportType] = useState('DEFAULT');
  const [needsRegenerate, setNeedsRegenerate] = useState(false);

  const admin = useSelector(state => state.Admin);
  const projects = admin.data && admin.data.projects;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!projects) dispatch(fetchProjects());
  }, [projects, dispatch]);

  useEffect(() => {
    reactTooltip?.current?.globalRebuild?.();
  }, [report]);

  const fetchReport = useCallback(
    async function() {
      if ((!fromDate || !toDate) && !needsTotalReport) {
        throw new Error(
          'From and To Dates are required for the specific period report. Use Total Report to see total numbers for the project instead.'
        );
      }

      if (!selectedProject) {
        throw new Error('Please select a project.');
      }

      const report = await getReport(
        fromDate,
        toDate,
        selectedProject,
        needsTotalReport,
        needsRegenerate
      );
      const data =
        report &&
        report.entries &&
        report.entries.map(entry => ({
          projectID: entry.projectID,
          taskID: entry.taskID,
          taskName: entry.taskName,
          daysPlanned: (entry.hoursPlanned / 8).toFixed(1),
          hoursPlanned: entry.hoursPlanned,
          hoursWorked: entry.hoursWorked,
          daysWorked: (entry.hoursWorked / 8).toFixed(1),
          workerRate: entry.workerRate,
          cost: entry.cost,
          clientRate: entry.clientRate,
          price: entry.price,
          margin: entry.margin,
          worker: entry.workerName || entry.workerID,
        }));

      setTableData(data || []);
      setReport(report);
    },
    [fromDate, toDate, needsTotalReport, selectedProject, needsRegenerate]
  );

  function constructReportUrl(fromDate, toDate, projectID, totalReport, regenerate, includeTotal) {
    const url =
      '/api/projects/client-timesheet' +
      (totalReport
        ? '?projectID=' +
          projectID +
          '&totalReport=' +
          totalReport +
          '&regenerate=' +
          +regenerate +
          '&includeTotal=' +
          (includeTotal ? 1 : 0)
        : '?projectID=' + projectID + '&fromDate=' + fromDate + '&toDate=' + toDate);
    return url;
  }

  const fetchTimesheetPDF = useCallback(
    async function(includeTotal) {
      if ((!fromDate || !toDate) && !needsTotalReport) {
        throw new Error(
          'From and To Dates are required for the specific period report. Use Total Report to see total numbers for the project instead.'
        );
      }

      if (!selectedProject) {
        throw new Error('Please select a project.');
      }

      const url = constructReportUrl(
        fromDate,
        toDate,
        selectedProject,
        needsTotalReport,
        needsRegenerate,
        includeTotal
      );
      setIsLoading(true);
      downloadFile(
        url,
        `${selectedProject}-${includeTotal ? 'Cost-Report' : 'Timesheet'}.pdf`,
        () => {
          setIsLoading(false);
        }
      );
    },
    [fromDate, needsRegenerate, needsTotalReport, selectedProject, toDate]
  );

  if (!admin.isAdminUser) return <AccessDeniedPoster />;

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Generate Project Report</h4>
          </Col>
        </Row>
      </div>
      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          Report generated successfully!
        </UncontrolledAlert>
      )}
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {error}
        </Alert>
      )}
      <Row>
        <Col sm="12">
          <Label for="worker" className="d-block">
            <span className="order bg-primary">1</span>Select a project
          </Label>
          <select
            id="project"
            className="form-control d-inline-block w-auto m-r-10 align-middle"
            value={selectedProject}
            onChange={e => setSelectedProject(e.target.value)}
          >
            <option value=""></option>
            {projects &&
              Object.values(projects)
                .sort((a, b) => (b.title < a.title ? 1 : -1))
                .map(project => {
                  return (
                    <option key={project.title} value={project.id}>
                      {project.id} - {project.title}
                    </option>
                  );
                })}
          </select>
          <Button color="primary" disabled={isLoading} onClick={() => dispatch(fetchProjects())}>
            Refresh List
          </Button>
        </Col>
      </Row>
      <Label className="d-block m-t-30">
        <span className="order bg-primary">2</span>Select Report Type
      </Label>
      <Row>
        <Col sm={12}>
          <ListGroup horizontal>
            <ListGroupItem tag="label" className="d-flex align-items-center">
              <input
                type="radio"
                checked={needsTotalReport}
                onChange={() => {
                  setNeedsTotalReport(1);
                }}
              />
              <span className="m-l-10">Total Report</span>
            </ListGroupItem>
            <ListGroupItem tag="label" className="d-flex align-items-center">
              <input
                type="radio"
                checked={!needsTotalReport}
                onChange={() => {
                  setNeedsTotalReport(0);
                }}
              />
              <span className="m-l-10">Date Range Report</span>
            </ListGroupItem>
            {!needsTotalReport && (
              <>
                <ListGroupItem tag="label">
                  <span className="m-r-5">From Date</span>
                  <input
                    type="date"
                    className="form-control"
                    value={fromDate}
                    onChange={e => {
                      setFromDate(e.target.value);
                      setError(null);
                    }}
                  />
                </ListGroupItem>
                <ListGroupItem tag="label">
                  <span className="m-r-5">To Date</span>
                  <input
                    type="date"
                    className="form-control"
                    value={toDate}
                    onChange={e => {
                      setToDate(e.target.value);
                      setError(null);
                    }}
                  />
                </ListGroupItem>
              </>
            )}

            {!!needsTotalReport && (
              <label className="m-l-15 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={needsRegenerate}
                  onChange={e => setNeedsRegenerate(!needsRegenerate)}
                />
                <span
                  className="align-middle"
                  data-tip="When ON: External planned time is updated from Azure/Zoho/Microsoft Planner."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Regenerate Report
                </span>
              </label>
            )}
          </ListGroup>
        </Col>
      </Row>
      <Row className="m-t-10">
        <Col>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={async e => {
              setIsLoading(true);
              setSuccess(false);
              try {
                setError(false);
                await fetchReport();
              } catch (error) {
                window.scrollTo({ top: 0 });
                setError((error && error.message) || 'An unexpected error occurred');
              }
              setIsLoading(false);
            }}
          >
            Crunch Numbers, Now!
          </Button>
          <Button
            color="primary"
            className="m-l-10"
            disabled={isLoading}
            onClick={async e => {
              setIsLoading(true);
              setSuccess(false);
              try {
                setError(false);
                await fetchTimesheetPDF();
              } catch (error) {
                window.scrollTo({ top: 0 });
                setError((error && error.message) || 'An unexpected error occurred');
              }
              setIsLoading(false);
            }}
          >
            Download Client Timesheet
          </Button>
          <Button
            color="primary"
            className="m-l-10"
            disabled={isLoading}
            onClick={async e => {
              setIsLoading(true);
              setSuccess(false);
              try {
                setError(false);
                await fetchTimesheetPDF(true);
              } catch (error) {
                window.scrollTo({ top: 0 });
                setError((error && error.message) || 'An unexpected error occurred');
              }
              setIsLoading(false);
            }}
          >
            Download Cost Report
          </Button>
        </Col>
      </Row>

      <Label className="d-block m-t-30">
        <span className="order bg-primary">3</span>Review the report
      </Label>

      {!!(report && report.generatedAt) && (
        <>
          <Row>
            <Col md={6}>
              <h6>Generated on {new Date(report.generatedAt).toLocaleString()}</h6>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <h6>
                {report && report.isTotalReport ? 'Total' : 'Partial'} report from{' '}
                {new Date(report.fromDate).toLocaleDateString()} to{' '}
                {new Date(report.toDate).toLocaleDateString()}
              </h6>
            </Col>
          </Row>
        </>
      )}
      {!!(report && report.isTotalReport) && (
        <>
          <Row>
            <Col md={6}>
              <ProjectReportOverview report={report} />
            </Col>
          </Row>
        </>
      )}
      <Row>
        <Col sm={12}>
          <ListGroup horizontal>
            <ListGroupItem tag="label" className="d-flex align-items-center">
              <input
                type="radio"
                checked={reportType === 'DEFAULT'}
                onChange={() => {
                  setReportType('DEFAULT');
                }}
              />
              <span className="m-l-10">Full</span>
            </ListGroupItem>
            <ListGroupItem tag="label" className="d-flex align-items-center">
              <input
                type="radio"
                checked={reportType === 'DAYS'}
                onChange={() => {
                  setReportType('DAYS');
                }}
              />
              <span className="m-l-10">Daily</span>
            </ListGroupItem>
            <ListGroupItem tag="label" className="d-flex align-items-center">
              <input
                type="radio"
                checked={reportType === 'HOURS'}
                onChange={() => {
                  setReportType('HOURS');
                }}
              />
              <span className="m-l-10">Hourly</span>
            </ListGroupItem>
            <ListGroupItem tag="label" className="d-flex align-items-center">
              <input
                type="radio"
                checked={reportType === 'FINANCIAL'}
                onChange={() => {
                  setReportType('FINANCIAL');
                }}
              />
              <span className="m-l-10">Financial</span>
            </ListGroupItem>
          </ListGroup>
        </Col>
      </Row>
      <ProjectTable data={tableData} isLoading={isLoading} type={reportType} />

      <Row className="m-b-10 m-t-30">
        <Col xs={6}>
          <h6>Cost {!(report && report.isTotalReport) && 'For Selected Period'}</h6>
        </Col>
        <Col xs={6} className="text-right">
          <h6>{report && formatCurrency(report.totalCost, 'GBP')}</h6>
        </Col>
      </Row>
      <Row className="m-b-10">
        <Col xs={6}>
          <h6>{report && report.isTotalReport ? 'Budgeted Price' : 'Price For Selected Period'}</h6>
        </Col>
        <Col xs={6} className="text-right">
          <h6>{report && formatCurrency(report.totalPrice, 'GBP')}</h6>
        </Col>
      </Row>
      <Row className="m-b-10">
        <Col xs={6}>
          <h6>Margin {!(report && report.isTotalReport) && 'For Selected Period'}</h6>
        </Col>
        <Col
          xs={6}
          className={cn('text-right', {
            'text-danger': report && report.totalMargin < 0,
            'text-success': report && report.totalMargin > 0,
          })}
        >
          <h6>{report && formatCurrency(report.totalMargin, 'GBP')}</h6>
        </Col>
      </Row>
      <ReactTooltip ref={reactTooltip} />
    </Container>
  );
};

export default ProjectReport;
