import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  Card,
  CardBody,
  Alert,
} from 'reactstrap';

import Invoice from '../../Timesheets/TimesheetInvoice';
import TeamHeader from '../../Team/TeamHeader';
import TimesheetUpload from '../../Timesheets/TimesheetUpload';

const InvoiceTester = () => {
  const [company, setCompany] = useState('');
  const [timesheetIDs, setTimesheetIDs] = useState([]);
  const [userFilter, setUserFilter] = useState('');
  const [amount, setAmount] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const { profiles } = useSelector(state => state.Team);

  const { data } = useSelector(state => state.Admin);
  const companies = (data?.companies && Object.values(data.companies)) || [];

  const [show, setShow] = useState(false);
  const [draftResult, setDraftResult] = useState(null);

  const resetAndCloseModal = () => setShow(false);

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Invoice Tester</h4>
          </Col>
        </Row>
      </div>
      <Row className="align-items-center">
        <Col xs="12">
          <Card>
            <CardBody>
              {draftResult && (
                <Alert color="success">
                  <span role="img" aria-label="no entry" className="font-18 m-r-5">
                    ✅
                  </span>
                  {draftResult}
                </Alert>
              )}
              <TeamHeader
                icon="magnifying_glass"
                title="Invoice Tester"
                subtitle="Use this tool to test your invoice draft before submitting an invoice for timesheet."
              />
              <br />
              <FormGroup>
                <Label for="company" className="d-block">
                  Select a company
                </Label>
                <select
                  id="company"
                  className="form-control d-inline-block w-auto m-r-10"
                  value={company}
                  onChange={async e => {
                    const value = e.target.value;
                    setCompany(value);
                  }}
                >
                  <option value="">Please select</option>
                  {companies &&
                    Object.values(companies)
                      .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                      .map(company => (
                        <option key={company.id} value={company.id}>
                          {company.legalName}
                        </option>
                      ))}
                </select>
              </FormGroup>
              <FormGroup>
                <Label for="worker" className="d-block">
                  Select a worker
                </Label>
                <select
                  id="worker"
                  className="form-control d-inline-block w-auto m-r-10"
                  value={userFilter}
                  disabled={!company}
                  onChange={async e => {
                    const value = e.target.value;
                    setUserFilter(value);
                    setIsLoading(true);
                  }}
                >
                  <option value=""></option>
                  {profiles &&
                    Object.values(profiles)
                      .sort((a, b) => (b.firstName < a.firstName ? 1 : -1))
                      .map(profile => (
                        <option key={profile.userID} value={profile.userID}>
                          {profile.firstName} {profile.lastName}
                        </option>
                      ))}
                </select>
              </FormGroup>
              <FormGroup>
                <Label for="timesheets">
                  Timesheet ID <span className="text-danger">*</span>
                </Label>
                <input
                  id="timesheets"
                  className="form-control"
                  type="text"
                  placeholder="Enter timesheet ID or multiple IDs separated by commas"
                  disabled={!userFilter}
                  onChange={async e => {
                    const value = e.target.value;
                    setTimesheetIDs(value.split(','));
                    //TODO: remove this and add later when company filtering is triggering axios call
                    setIsLoading(false);
                  }}
                ></input>
              </FormGroup>
              <FormGroup>
                <Label for="amount">
                  Amount <span className="text-danger">*</span>
                </Label>
                <input
                  id="amount"
                  className="form-control"
                  type="text"
                  placeholder="Enter amount to test with as an integer or double"
                  disabled={!timesheetIDs.length}
                  onChange={async e => {
                    const value = e.target.value;
                    setAmount(value.trim());
                  }}
                ></input>
              </FormGroup>
              <TimesheetUpload
                timesheetIds={timesheetIDs}
                companyID={company}
                isDraft={true}
                userID={userFilter}
                amount={amount}
                disabled={amount <= 0 || isLoading}
                onSuccess={message => {
                  setDraftResult(message);
                }}
                onFailure={() => setDraftResult(null)}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Modal className="modal-lg" isOpen={show} toggle={resetAndCloseModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Invoice Example</h5>
          <button type="button" onClick={resetAndCloseModal} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Invoice />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default InvoiceTester;
