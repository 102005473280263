import React from 'react';
import { Spinner, Table, Card, CardBody } from 'reactstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';

import { GlobalFilter } from './GlobalFilter';
import columns from './dailyTableColumns';

const DailyTable = ({ data, isLoading }) => {
  const { entries, date, generatedAt } = data;

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
  } = useTable(
    {
      columns,
      data: entries,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <>
      <div className="d-flex align-items-end m-b-15">
        <div className="flex-grow-1">
          <div>{date && `Report Date: ${new Date(date).toLocaleDateString()}`}</div>
          <div>{generatedAt && `Last generated: ${new Date(generatedAt).toLocaleString()}`}</div>
        </div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <Card>
        <CardBody className="p-0">
          <Table striped responsive className="table-sm m-0" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {isLoading && (
                <tr>
                  <td colSpan="12" className="text-center">
                    <Spinner color="primary" />
                  </td>
                </tr>
              )}
              {!isLoading && rows.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-black-50">
                    No entries found
                  </td>
                </tr>
              ) : (
                rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                    </tr>
                  );
                })
              )}
            </tbody>
            <tfoot>
              {footerGroups.map(group => (
                <tr {...group.getFooterGroupProps()}>
                  {group.headers.map(column => (
                    <th {...column.getFooterProps()}>{column.render('Footer')}</th>
                  ))}
                </tr>
              ))}
            </tfoot>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default DailyTable;
