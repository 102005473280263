import axios from 'axios';
import {
  FETCH_TIMESHEETS,
  FETCH_TIMESHEETS_SUCCESS,
  FETCH_TIMESHEETS_FAILURE,
  ADD_SELECTED_TIMESHEET,
  REMOVE_SELECTED_TIMESHEET,
  CLEAR_SELECTED_TIMESHEETS,
  FETCH_TIMESHEET_CARDS,
  FETCH_TIMESHEET_CARDS_SUCCESS,
  FETCH_TIMESHEET_CARDS_FAILURE,
} from './actionTypes';
import { signOut } from '../auth/actions';

export const fetchTimesheets = () => {
  return async dispatch => {
    dispatch(fetchTimesheetsAction());

    try {
      const response = await axios.get('/api/timesheets');

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          dispatch(fetchTimesheetsSuccessAction(data));
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      let err = error;
      const response = error && error.response;
      if (response) {
        err = {
          status: response.status,
          statusText: response.statusText,
        };
        if (response.status === 401) dispatch(signOut());
      }
      dispatch(fetchTimesheetsFailureAction(err));
    }
  };
};

const fetchTimesheetsAction = () => {
  return {
    type: FETCH_TIMESHEETS,
  };
};

const fetchTimesheetsSuccessAction = data => {
  return {
    type: FETCH_TIMESHEETS_SUCCESS,
    data,
  };
};

const fetchTimesheetsFailureAction = error => {
  return {
    type: FETCH_TIMESHEETS_FAILURE,
    error,
  };
};

export const addSelectedTimesheet = item => {
  return {
    type: ADD_SELECTED_TIMESHEET,
    item,
  };
};

export const removeSelectedTimesheet = item => {
  return {
    type: REMOVE_SELECTED_TIMESHEET,
    item,
  };
};

export const clearSelectedTimesheets = () => {
  return {
    type: CLEAR_SELECTED_TIMESHEETS,
  };
};

/**
 * FETCH FRESHDESK CARDS FOR DASHBOARD
 */
export const fetchTimesheetCards = () => {
  return async dispatch => {
    dispatch(fetchTimesheetCardsAction());

    try {
      const response = await axios.get('/api/payments/cards');
      const { data, status } = response;
      if (status === 200 && data && !data.error && data.cards) {
        console.log(data.cards);
        dispatch(fetchTimesheetCardsSuccessAction(data.cards));
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      console.log(error);
      if (data && data.code === 401) dispatch(signOut(data));
      dispatch(fetchTimesheetCardsFailureAction(data));
    }
  };
};

const fetchTimesheetCardsAction = () => ({ type: FETCH_TIMESHEET_CARDS });

const fetchTimesheetCardsSuccessAction = cards => ({ type: FETCH_TIMESHEET_CARDS_SUCCESS, cards });

const fetchTimesheetCardsFailureAction = error => ({ type: FETCH_TIMESHEET_CARDS_FAILURE, error });
