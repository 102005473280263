import React, { useState, useCallback, useEffect } from 'react';
import { Button, Row, Col, Card, CardBody, FormGroup, Label, UncontrolledAlert } from 'reactstrap';
import Switch from 'react-switch';
import axios from 'axios';

const FirewallSettings = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isFirewallEnabled, setIsFirewallEnabled] = useState(false);
  const [alertList, setAlertList] = useState([]);
  const [blackList, setBlackList] = useState([]);

  useEffect(() => {
    const isEnabled = settings?.firewallEnabled || false;
    setIsFirewallEnabled(isEnabled);
    getLists();
  }, [settings]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: {
          firewallEnabled: isFirewallEnabled,
        },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [isFirewallEnabled]);

  const onListReset = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'delete',
        url: '/api/admin/firewall/blacklist',
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
      getLists();
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, []);

  async function getLists() {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'get',
        url: '/api/admin/firewall/blacklist',
      });

      const data = response && response.data;
      if (data && !data.error) {
        setAlertList(data.alertList);
        setBlackList(data.blackList);
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/shield.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Phone System Logo"
                  />
                  Firewall Settings
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Figaro has a built-in DDoS firewall{' '}
              <strong>
                that protects against DDoS attacks on public endpoints, such as document preview or
                radio.
              </strong>
            </p>

            <FormGroup row>
              <Label htmlFor="example-text-input" md="4">
                Enable Firewall
              </Label>
              <Col sm="4">
                <div className="d-flex align-items-center">
                  <Switch
                    onColor="#626ed4"
                    onChange={() => {
                      setIsFirewallEnabled(!isFirewallEnabled);
                    }}
                    checked={isFirewallEnabled}
                    disabled={isSubmitting}
                  />
                  <span className="m-l-10">Enable DDoS endpoint protection.</span>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="4" lg="6">
                <Label htmlFor="Alert-text-input">Alert List</Label>
                <p>
                  Alert list contains IP addresses of clients that triggered warnings, but not yet
                  blacklisted. If such client continues suspicious activity it will be blocked.
                </p>
              </Col>
              <Col sm="4" lg="6">
                <Label htmlFor="Black-text-input">Black List</Label>
                <p>Black List - these clients were permanently blocked (until server restart).</p>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="4" lg="6">
                <select
                  multiple
                  id="alerts"
                  className="form-control d-inline-block w-auto m-r-10 align-middle"
                >
                  {alertList &&
                    alertList.map(alertIP => {
                      return (
                        <option key={alertIP} value={alertIP}>
                          {alertIP}
                        </option>
                      );
                    })}
                </select>
              </Col>
              <Col sm="4" lg="6">
                <select
                  multiple
                  sm="8"
                  id="alerts"
                  className="form-control d-inline-block w-auto m-r-10 align-middle"
                >
                  {blackList &&
                    blackList.map(blackIP => {
                      return (
                        <option key={blackIP} value={blackIP}>
                          {blackIP}
                        </option>
                      );
                    })}
                </select>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="2" className="offset-10">
                <Button type="submit" color="danger" disabled={isSubmitting} onClick={onListReset}>
                  {!isSubmitting ? 'Purge Lists' : 'Purging...'}
                </Button>

                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={onSubmit}
                  className="m-l-10"
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default FirewallSettings;
