import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Container,
  Row,
  Col,
  Label,
  Button,
  UncontrolledAlert,
  Card,
  CardBody,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import DocumentPreview from '../../../components/DocumentPreview/DocumentPreview';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import { fetchProjects } from '../../../store/admin/actions';
import { saveFileFromUrl, getScopeOfWork } from '../../../helpers/utils';

const ScopeOfWork = () => {
  const reactTooltip = useRef(null);
  const [selectedProjects, setselectedProjects] = useState([]);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const [shouldIncludeAllTickets, setShouldIncludeAllTickets] = useState(true);
  const [shouldIncludeWorkerEmails, setShouldIncludeWorkerEmails] = useState(true);
  const [isDraft, setIsDraft] = useState(true);
  const [pdfBlob, setPdfBlob] = useState(null);

  const admin = useSelector(state => state.Admin);
  const projects = admin.data && admin.data.projects;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!projects) dispatch(fetchProjects());
  }, [projects, dispatch]);

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  const generateScopeOfWork = useCallback(
    async function() {
      if (!selectedProjects) {
        throw new Error('Please select a project(s) for Scope of Work document');
      }
      setIsLoading(true);
      getScopeOfWork(
        selectedProjects.join(','),
        shouldIncludeWorkerEmails,
        shouldIncludeAllTickets,
        isDraft,
        (response, error) => {
          if (response) {
            setPdfBlob(response);
          }
          if (error) {
            setError(error && error.message);
          }
          setIsLoading(false);
        }
      );
      setIsLoading(true);
    },
    [selectedProjects, shouldIncludeAllTickets, shouldIncludeWorkerEmails, isDraft] // eslint-disable-line
  );

  const downloadScopeOfWork = useCallback(
    async function() {
      if (!pdfBlob) {
        throw new Error('Please generate a document first');
      }
      setIsLoading(true);
      saveFileFromUrl(pdfBlob, `SoW-${selectedProjects.join('-')}.pdf`, () => {
        setIsLoading(false);
      });
    },
    [pdfBlob, selectedProjects]
  );

  if (!admin.isAdminUser) return <AccessDeniedPoster />;

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Generate Scope of Work</h4>
          </Col>
        </Row>
      </div>
      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          Scope of Work generated successfully!
        </UncontrolledAlert>
      )}
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {error}
        </Alert>
      )}
      <Card>
        <CardBody>
          <Row>
            <Col sm="12">
              <Label for="worker" className="d-block">
                <span className="order bg-primary">1</span>Select project(s)
              </Label>
              <select
                multiple
                id="project"
                className="form-control d-inline-block w-auto m-r-10 align-middle"
                value={selectedProjects}
                onChange={e => {
                  let value = Array.from(e.target.selectedOptions, option => option.value);
                  setselectedProjects(value);
                }}
              >
                {projects &&
                  Object.values(projects)
                    .sort((a, b) => (b.title < a.title ? 1 : -1))
                    .map(project => {
                      return (
                        <option key={project.title} value={project.id}>
                          {project.id} - {project.title}
                        </option>
                      );
                    })}
              </select>
            </Col>
          </Row>
          <Row>
            <Col sm="12">
              <Button
                color="primary"
                disabled={isLoading}
                className="m-t-10"
                onClick={() => dispatch(fetchProjects())}
              >
                Refresh List
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Label className="d-block m-t-30">
            <span className="order bg-primary">2</span>Select Document Options
          </Label>
          <Row>
            <Col sm={4}>
              <label className="m-l-15 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={shouldIncludeAllTickets}
                  onChange={e => setShouldIncludeAllTickets(!shouldIncludeAllTickets)}
                />
                <span
                  className="align-middle"
                  data-tip="When ON: All tickets for projects are listed in the document. When OFF: only project titles and delivery dates are listed."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Include all tickets
                </span>
              </label>
              <Alert color="primary">
                When ON: All tickets for projects are listed in the document. When OFF: only project
                titles and delivery dates are listed.
              </Alert>
            </Col>
            <Col sm={4}>
              <label className="m-l-15 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={shouldIncludeWorkerEmails}
                  onChange={e => setShouldIncludeWorkerEmails(!shouldIncludeWorkerEmails)}
                />
                <span
                  className="align-middle"
                  data-tip="When ON: Pre-Requisites section includes worker contact emails.When OFF: general company email is included instead."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Include worker emails
                </span>
              </label>
              <Alert color="primary">
                When ON: Pre-Requisites section includes worker contact emails. When OFF: general
                company email is included instead.
              </Alert>
            </Col>
            <Col sm={4}>
              <label className="m-l-15 d-flex align-items-center">
                <input
                  type="checkbox"
                  className="align-middle m-r-10"
                  checked={isDraft}
                  onChange={e => setIsDraft(!isDraft)}
                />
                <span
                  className="align-middle"
                  data-tip="Generates document with DRAFT watermark on it."
                  data-effect="solid"
                  data-delay-show="200"
                >
                  Place DRAFT Watermark
                </span>
              </label>
              <Alert color="primary">
                Generates document with beautiful artisanal DRAFT watermark on it.
              </Alert>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <Row>
            <Col sm="6" lg="12">
              <Label className="d-block m-t-30">
                <span className="order bg-primary">3</span>Issue the Document
                <p className="m-t-5 m-l-10">
                  The document will be generated as a PDF and displayed in the preview below.
                  History entry will be made on all projects selected and the file will be sent to
                  S3 storage.
                </p>
              </Label>
              <Button
                color="primary"
                className="m-l-10"
                disabled={isLoading || !selectedProjects.length}
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  setPdfBlob(null);
                  try {
                    setError(false);
                    await generateScopeOfWork();
                  } catch (error) {
                    window.scrollTo({ top: 0 });
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                }}
              >
                {isLoading ? 'Working...' : 'Generate Scope of Work'}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col sm="6" lg="12">
              <Label className="d-block m-t-30">
                <span className="order bg-primary">4</span>Preview the Document
              </Label>
              <DocumentPreview documentUrl={pdfBlob} title="Scope of Work" zoom={1.25} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Row>
            <Col sm="6">
              <Label className="d-block m-t-30">
                <span className="order bg-dark">5</span>Save the Document (Optional)
              </Label>
              <Button
                color="dark"
                className="m-l-10"
                disabled={isLoading || !pdfBlob}
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  try {
                    setError(false);
                    await downloadScopeOfWork();
                  } catch (error) {
                    window.scrollTo({ top: 0 });
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                  setIsLoading(false);
                }}
              >
                Save SoW
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>

      <ReactTooltip ref={reactTooltip} />
    </Container>
  );
};

export default ScopeOfWork;
