import React, { useEffect, useRef, useState } from 'react';
import { Badge, Spinner } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import cn from 'classnames';

import { startTimetracker, stopTimetracker } from '../store/actions';
import { setupTimetracker, clearTimetracker } from '../helpers/timetracker';
import { renderDuration } from '../helpers/utils';

import './TimerBadge.scss';
import SuperBadge from './SuperBadge';

function TimerBadge() {
  const dispatch = useDispatch();
  const timetracker = useSelector(state => state.Timetracker);
  const timeout = useRef(null);
  const [currentTime, setCurrentTime] = useState(0);
  const [activeTimer, setActiveTimer] = useState(null);

  useEffect(() => {
    const timer = timetracker.timers.find(t => t.isCurrentlyRunning);

    if (timer) setActiveTimer(timer);
    else if (activeTimer) {
      const selectedTimer = timetracker.timers.find(t => t.id === activeTimer.id);
      clearInterval(timeout.current);
      timeout.current = null;

      if (activeTimer && selectedTimer) {
        setActiveTimer(selectedTimer);
        setCurrentTime(selectedTimer.totalTimes.totalTimeInSeconds);
      }
    }
  }, [timetracker]); // eslint-disable-line

  useEffect(() => {
    setupTimetracker(activeTimer, setCurrentTime, dispatch, 10000);

    return () => clearTimetracker(activeTimer && activeTimer.id);
  }, [activeTimer]); // eslint-disable-line

  if (activeTimer) {
    let progress = 0;
    let progressBg = 'timer-badge--progress__';
    if (currentTime && activeTimer) {
      const hours = currentTime / 60 / 60;
      const allowedEffort =
        (activeTimer.remainingEffort && parseFloat(activeTimer.remainingEffort)) ||
        (activeTimer.allowedEffort && parseFloat(activeTimer.allowedEffort)) ||
        0;
      progress = (hours * 100) / allowedEffort;
      progressBg = progressBg + (progress < 50 ? 'success' : progress < 80 ? 'warning' : 'danger');
    }

    return (
      <Badge pill color="light" className="timer-badge shadow">
        <div
          className={cn('timer-badge--progress', progressBg, {
            active: activeTimer.isCurrentlyRunning,
          })}
          style={{ width: `${progress}%` }}
        ></div>
        {timetracker.isLoadingTimers ? (
          <div className="timer-badge--spinner-container">
            <Spinner color="danger" className="timer-badge--spinner" />
          </div>
        ) : (
          <button
            className="timer-badge--btn"
            disabled={timetracker.isLoadingTimers}
            title={activeTimer.isCurrentlyRunning ? 'Pause timer' : 'Resume timer'}
            onClick={() => {
              if (activeTimer.isCurrentlyRunning) {
                dispatch(stopTimetracker(activeTimer.id));
              } else {
                dispatch(startTimetracker(activeTimer.id));
              }
            }}
          >
            <i
              className={cn('ion', {
                'ion-md-pause': activeTimer.isCurrentlyRunning,
                'ion-md-play': !activeTimer.isCurrentlyRunning,
              })}
            />
          </button>
        )}

        <span className="timer-badge--time">
          {renderDuration(currentTime * 1000, activeTimer.isCurrentlyRunning)}
        </span>

        <SuperBadge
          color={activeTimer.projectColor}
          leftText={activeTimer.projectId}
          rightText={`#${activeTimer.displayId}`}
          className="m-l-10 m-r-5"
        />

        <div className="timer-badge--task">
          <span className="timer-badge--task__text">{activeTimer.taskName}</span>
        </div>
      </Badge>
    );
  }

  return null;
}

export default TimerBadge;
