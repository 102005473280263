import React, { useState } from 'react';
import { Card, CardHeader, Badge } from 'reactstrap';
import classnames from 'classnames';
import { useSelector } from 'react-redux';

import TimesheetBundleBody from './TimesheetBundleBody';
import TimesheetBundleFooter from './TimesheetBundleFooter';
import AdminTimesheetFooter from '../../Admin/Timesheets/AdminTimesheetFooter';
import DecisionModal from '../../../components/DecisionModal/DecisionModal';
import { copyToClipboard, downloadFile, sendReminder } from '../../../helpers/utils';

import { statusMapping } from '../../TimesheetStatusMapping';
import Axios from 'axios';

import './TimesheetBundle.scss';

const TimesheetBundle = ({ id, timesheets, isAdmin, refreshCallback }) => {
  const paymentRequest = timesheets[0].paymentRequest;
  const mappedStatus = statusMapping[paymentRequest?.status?.toUpperCase() ?? 'NOT_AVAILABLE'];
  const hasPaymentRequest = !!paymentRequest;
  const companyName = timesheets[0].financials?.companyDisplayName;
  const { profiles } = useSelector(state => state.Team);
  const profile = profiles && profiles[timesheets[0].userID];
  const isAgent = profile && profile.type === 'agent';

  const [showArchiveModal, setShowArchiveModal] = useState(false);

  const resetAndCloseModal = () => {
    setShowArchiveModal(false);
  };

  return (
    <Card className={classnames('timesheet-bundle', { 'admin-timesheet-item': isAdmin })}>
      <CardHeader className="d-flex align-items-center">
        <h6>
          <i className="mdi mdi-archive"></i>{' '}
          {isAdmin ? `${timesheets[0].userName}'s Bundle ` : 'Timesheet Bundle'}
        </h6>
        {companyName && (
          <Badge color="success" className="m-r-5 m-l-15 align-text-top" pill>
            {companyName}
          </Badge>
        )}
        {hasPaymentRequest ? (
          <Badge className="font-14" pill color={mappedStatus?.color ?? 'info'}>
            {mappedStatus?.title ?? paymentRequest.status.toUpperCase()}
          </Badge>
        ) : (
          <Badge className="font-14" pill color={statusMapping['EXPECTING_INVOICE'].color}>
            {statusMapping['EXPECTING_INVOICE'].title}
          </Badge>
        )}
        {isAdmin && (
          <button
            title="Copy ID to clipboard"
            className="btn btn-secondary m-l-10 btn-clipboard"
            onClick={() => copyToClipboard(id)}
          >
            <i className="ti-clipboard"></i>
          </button>
        )}
        {isAdmin && hasPaymentRequest ? (
          <button
            title="Download Invoice"
            className="btn btn-secondary m-l-10 btn-clipboard"
            onClick={() => {
              downloadFile(
                `/api/download/invoice?awsPath=${paymentRequest.subcontractorInvoiceURL}&invoiceTimesheet=${id}`,
                `Invoice ${id}.pdf`
              );
            }}
          >
            <i className="mdi mdi-download"></i>
          </button>
        ) : (
          <span></span>
        )}
        {isAdmin && !hasPaymentRequest ? (
          <button
            title="Send Reminder Email"
            className="btn btn-secondary m-l-10 btn-header"
            onClick={() => {
              sendReminder('bundle', paymentRequest.id, result => {
                const resultString =
                  'A reminder was' +
                  (result ? ` sent to the worker.` : ' not sent due to an error.');
                alert(resultString);
              });
            }}
          >
            <i className="mdi mdi-email"></i>
          </button>
        ) : (
          <span></span>
        )}
        {isAdmin && (
          <button
            title="Archive This"
            className="btn btn-secondary m-l-10 btn-header"
            onClick={() => setShowArchiveModal(true)}
          >
            <i className="mdi mdi-eye-off"></i>
          </button>
        )}
      </CardHeader>
      <TimesheetBundleBody id={id} timesheets={timesheets} />

      {isAdmin ? (
        <>
          <AdminTimesheetFooter timesheets={timesheets} isAgent={isAgent} />
          <DecisionModal
            centered
            isOpen={showArchiveModal}
            title={'🗄 Archive Bundle'}
            message={`Do you wish to archive item "${paymentRequest.id}"?`}
            didTapCloseButton={resetAndCloseModal}
            didTapConfirmButton={async reasonMessage => {
              const url = `/api/payment/${paymentRequest.id}/archive`;
              return await Axios(url, { method: 'PUT', data: { reason: reasonMessage } });
            }}
          />
        </>
      ) : (
        <TimesheetBundleFooter id={id} paymentRequest={paymentRequest} />
      )}
    </Card>
  );
};

export default TimesheetBundle;
