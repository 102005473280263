import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';

const XeroSettings = ({ params, search }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [clientId, setCliendId] = useState(null);
  const [clientSecret, setClientSecret] = useState(null);
  const [organisation, setOrganisation] = useState(null);

  async function isXeroConnected() {
    try {
      const response = await axios({
        method: 'get',
        url: `/api/xero/organisation`,
      });

      const data = response && response.data;
      if (data && !data.error) {
        setOrganisation(data.organisationName);
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  const [IsXeroOnline, setIsXeroOnline] = useState(false);

  useEffect(() => {
    (async function() {
      setIsXeroOnline(await isXeroConnected());
    })();
  }, []); // eslint-disable-line

  const openInSameTab = url => {
    const newWindow = window.open(url, '_self', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
  };

  const handleOnClickLink = useCallback(
    async e => {
      e.preventDefault();
      setIsSubmitting(true);
      setError(null);
      setSuccess(null);

      try {
        const response = await axios({
          method: 'get',
          url: `/api/xero/connect`,
          params: {
            clientId,
            clientSecret,
          },
        });

        const data = response && response.data;
        if (data && !data.error) {
          openInSameTab(data.consentUrl);
        }
        setIsSubmitting(false);
      } catch (error) {
        setError(
          error.response.data && typeof error.response.data.message === 'string'
            ? error.response.data.message
            : 'Something went wrong :('
        );
        setIsSubmitting(false);
      }
    },
    [clientId, clientSecret]
  );

  const handleUnlinkClick = useCallback(async e => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'get',
        url: `/api/xero/disconnect`,
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message ?? 'Disconnected');
        setIsXeroOnline(false);
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, []);

  const handleCallback = useCallback(async success => {
    if (success) {
      setError(null);
      setIsXeroOnline(true);
      setIsXeroOnline(await isXeroConnected());
    } else {
      setSuccess(null);
      setError('Xero connection failed');
    }
    window.location.hash = '#xeroSettingsAnchor';
  }, []);

  useEffect(() => {
    const provider = params && params.provider;
    if (provider === 'xero') {
      const results = new URLSearchParams(search);
      handleCallback(results.get('connected'));
    }
  }, [handleCallback, params, search]);

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <div>
              <span id="xeroSettingsAnchor">
                <h6>
                  <img
                    src={require('../../../images/xero.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Xero Logo"
                  />
                  Xero Integration
                </h6>
              </span>
            </div>

            {success && (
              <UncontrolledAlert color="success">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}

            {!IsXeroOnline ? (
              <>
                <Alert color="warning">Xero needs to be configured.</Alert>
                <p className="text-muted m-b-30">
                  Please provide app authorisation details from Xero Developer console by copying it
                  into the fields below. You can find them at{' '}
                  <a href="https://developer.xero.com/app/manage/">Xero Developer</a>, and click on{' '}
                  <em>App Details and then Configuration</em>.
                </p>
                <FormGroup row>
                  <Label htmlFor="example-text-input" sm="2">
                    Client Id
                  </Label>
                  <Col sm="8">
                    <Input
                      type="password"
                      value={clientId}
                      onChange={event => setCliendId(event.nativeEvent.target.value)}
                      disabled={isSubmitting}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="example-text-input" sm="2">
                    Client Secret
                  </Label>
                  <Col sm="8">
                    <Input
                      type="password"
                      value={clientSecret}
                      onChange={event => setClientSecret(event.nativeEvent.target.value)}
                      disabled={isSubmitting}
                    />
                  </Col>
                </FormGroup>
                <FormGroup row>
                  <Label htmlFor="example-text-input" sm="2">
                    Authorisation
                  </Label>
                  <Col sm="8">
                    <p>
                      Click Link to open authorisation window. Please select the organisation you
                      wish to use on Figaro in authorisation dropdown. If you use multiple
                      organisations they will be listed below.
                    </p>
                  </Col>
                  <Col sm="2">
                    <Button
                      type="submit"
                      color="primary"
                      block
                      disabled={isSubmitting}
                      onClick={handleOnClickLink}
                    >
                      Link
                    </Button>
                  </Col>
                </FormGroup>
              </>
            ) : (
              <>
                <Alert color="success">
                  Xero is connected to Figaro and works fine. Your organisation is{' '}
                  <strong>{organisation}</strong>
                </Alert>
                <p className="text-muted m-b-30">
                  If you wish to disconnect Figaro from Xero press this button{' '}
                  <Button
                    type="submit"
                    color="primary"
                    block
                    disabled={isSubmitting}
                    onClick={handleUnlinkClick}
                  >
                    Unlink
                  </Button>
                </p>
              </>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default XeroSettings;
