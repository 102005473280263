import React, { useState, useEffect, useCallback } from 'react';
import { Spinner } from 'reactstrap';
import axios from 'axios';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { toast } from 'react-toastify';

import availabilityStatuses from '../../Availability/AvailabilityStatuses';
import DetailsModal from './DetailsModal';

const localizer = momentLocalizer(moment);

const getEventStartTime = (date, status) => {
  const startTime = availabilityStatuses[status]?.startTime;

  return new Date(`${date}T${startTime || '00:00'}`);
};

const getEventEndTime = (date, status) => {
  const endTime = availabilityStatuses[status]?.endTime;

  return new Date(`${date}T${endTime || '00:00'}`);
};

const getIsAllDayEvent = status => ['SICK', 'HOLIDAY', 'STANDBY'].includes(status);

export const getEventIcon = status => availabilityStatuses[status]?.icon || '';

const getEventColor = status => {
  switch (status) {
    case 'SICK':
    case 'HOLIDAY':
      return '#f7ac0c';
    case 'AVAILABLE':
    case 'AVAILABLE_AM':
    case 'AVAILABLE_PM':
    case 'AVAILABLE_NIGHT':
      return '#028b81';
    case 'STANDBY':
    default:
      return '#4e5ccf';
  }
};

const formats = {
  eventTimeRangeFormat: range =>
    `${moment(range.start).format('HH:mm')} - ${moment(range.end).format('HH:mm')}`,
};

const eventPropGetter = event => ({
  style: { backgroundColor: event.color, color: '#fff' },
});

const calendarStartTime = moment().set({ hours: 8, minutes: 0 });
const calendarEndTime = moment().set({ hours: 23, minutes: 59 });

const AvailabilityCalendar = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async function() {
      try {
        const res = await axios.get('/api/availability/team');
        const items = res.data.availability;
        const _events = [];

        items?.forEach((item, index) => {
          _events.push({
            id: `${item.userID}_${index}`,
            title: `${getEventIcon(item.status)} ${item.employeeFirstName} ${
              item.employeeLastName
            }`,
            start: getEventStartTime(item.day, item.status),
            end: getEventEndTime(item.day, item.status),
            allDay: getIsAllDayEvent(item.status),
            color: getEventColor(item.status),
            data: item,
          });
        });

        setEvents(_events);
        setIsLoading(false);
      } catch (error) {
        process.env.NODE_ENV !== 'production' && console.log(error);
        toast.error('Sorry, there was a problem processing the events.');
        setIsLoading(false);
      }
    })();
  }, []);

  const closeModal = useCallback(() => {
    setSelectedEvent(null);
  }, []);

  const handleOnSelectEvent = useCallback(event => {
    setSelectedEvent(event.data);
  }, []);

  return (
    <div className="position-relative">
      <Calendar
        localizer={localizer}
        dayLayoutAlgorithm="no-overlap"
        events={events}
        startAccessor="start"
        endAccessor="end"
        min={calendarStartTime}
        max={calendarEndTime}
        style={{ height: 800 }}
        eventPropGetter={eventPropGetter}
        formats={formats}
        onSelectEvent={handleOnSelectEvent}
      />

      <DetailsModal selectedEvent={selectedEvent} closeModal={closeModal} />

      {isLoading && (
        <div className="absolute-loader-container full-width">
          <Spinner color="primary" />
        </div>
      )}
    </div>
  );
};

export default AvailabilityCalendar;
