import React from 'react';
import { Field } from 'redux-form';
import { FormGroup, Label, Alert } from 'reactstrap';

import CountryField from '../Subpages/CountryField';
import { renderField, required } from './validation';
import TeamHeader from './TeamHeader';

const Address = ({ field, title, subtitle, iconname, canSelectOnlyCountry, alertText, change }) => {
  return (
    <>
      <FormGroup>
        <TeamHeader icon={iconname} title={title} subtitle={subtitle} />
      </FormGroup>
      {alertText && <Alert color="info">{alertText}</Alert>}
      <FormGroup>
        <Label for={`${field}.line1`}>
          Line 1 <span className="text-danger">*</span>
        </Label>
        <Field
          component={renderField}
          validate={required}
          className="form-control"
          id={`${field}.line1`}
          name={`${field}.line1`}
          type="text"
          disabled={canSelectOnlyCountry}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${field}.line2`}>Line 2</Label>
        <Field
          component="input"
          className="form-control"
          id={`${field}.line2`}
          name={`${field}.line2`}
          type="text"
          disabled={canSelectOnlyCountry}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${field}.line3`}>Line 3</Label>
        <Field
          component="input"
          className="form-control"
          id={`${field}.line3`}
          name={`${field}.line3`}
          type="text"
          disabled={canSelectOnlyCountry}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${field}.postcode`}>Postcode</Label>
        <Field
          component="input"
          className="form-control"
          id={`${field}.postcode`}
          name={`${field}.postcode`}
          type="text"
          disabled={canSelectOnlyCountry}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${field}.city`}>
          City <span className="text-danger">*</span>
        </Label>
        <Field
          component={renderField}
          validate={required}
          className="form-control"
          id={`${field}.city`}
          name={`${field}.city`}
          type="text"
          disabled={canSelectOnlyCountry}
        />
      </FormGroup>
      <FormGroup>
        <Label for={`${field}.countryISO`}>
          Country <span className="text-danger">*</span>
        </Label>
        <CountryField
          name={`${field}.countryISO`}
          validate={required}
          onChange={e => {
            try {
              const select = e.currentTarget;
              const label = select.options[select.selectedIndex].textContent;
              if (label) change(`${field}.country`, label);
            } catch {}
          }}
        />
      </FormGroup>
    </>
  );
};

export default Address;
