import React, { useState, useCallback, useEffect } from 'react';
import Switch from 'react-switch';

import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';

const BillingEmail = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [emailAddress, setEmailAddress] = useState('');
  const [shouldEmailForAvailability, setShouldEmailForAvailability] = useState(null);

  useEffect(() => {
    const email = (settings && settings.billingEmail) || '';
    const shouldEmail = (settings && settings.shouldEmailForAvailability) || false;

    setEmailAddress(email);
    setShouldEmailForAvailability(shouldEmail);
  }, [settings]);

  const handleOnClickLink = useCallback(
    async e => {
      e.preventDefault();
      setIsSubmitting(true);
      setError(null);
      setSuccess(null);

      try {
        const response = await axios({
          method: 'patch',
          url: '/api/settings',
          data: {
            billingEmail: emailAddress,
            shouldEmailForAvailability: shouldEmailForAvailability,
          },
        });

        const data = response && response.data;
        if (data && !data.error) {
          setSuccess(data.message || 'Success!');
        }
        setIsSubmitting(false);
      } catch (error) {
        setError(
          error.response.data && typeof error.response.data.message === 'string'
            ? error.response.data.message
            : 'Something went wrong :('
        );
        setIsSubmitting(false);
      }
    },
    [emailAddress, shouldEmailForAvailability]
  );

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/email.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Email"
                  />
                  Global Email Settings
                </h6>
              </span>
            </div>

            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <>
              <p className="text-muted m-b-30">
                Please provide a billing email you would like to send copies of the invoices
                submitted by workers for <em>all companies</em>.
              </p>
              <FormGroup row>
                <Label htmlFor="example-text-input" sm="2">
                  Global Billing Email
                </Label>
                <Col sm="8">
                  <Input
                    type="text"
                    value={emailAddress}
                    onChange={event => setEmailAddress(event.nativeEvent.target.value)}
                    disabled={isSubmitting}
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label htmlFor="example-text-input" md="2">
                  Availability Reminders
                </Label>
                <Col sm="8">
                  <div className="d-flex align-items-center">
                    <Switch
                      onColor="#626ed4"
                      onChange={() => {
                        setShouldEmailForAvailability(!shouldEmailForAvailability);
                      }}
                      checked={shouldEmailForAvailability}
                    />
                    <span className="m-l-10">
                      System will send reminder emails every night to those who need their
                      availability updated.
                    </span>
                  </div>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Col sm="12" className="offset-2">
                  <Button
                    type="submit"
                    color="primary"
                    disabled={isSubmitting}
                    onClick={handleOnClickLink}
                  >
                    {!isSubmitting ? 'Submit' : 'Submitting...'}
                  </Button>
                </Col>
              </FormGroup>
            </>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default BillingEmail;
