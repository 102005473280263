export const FETCH_TIMESHEETS = 'FETCH_TIMESHEETS';
export const FETCH_TIMESHEETS_SUCCESS = 'FETCH_TIMESHEETS_SUCCESS';
export const FETCH_TIMESHEETS_FAILURE = 'FETCH_TIMESHEETS_FAILURE';

export const ADD_SELECTED_TIMESHEET = 'ADD_SELECTED_TIMESHEET';
export const REMOVE_SELECTED_TIMESHEET = 'REMOVE_SELECTED_TIMESHEET';
export const CLEAR_SELECTED_TIMESHEETS = 'CLEAR_SELECTED_TIMESHEETS';

export const FETCH_TIMESHEET_CARDS = 'FETCH_TIMESHEET_CARDS';
export const FETCH_TIMESHEET_CARDS_FAILURE = 'FETCH_TIMESHEET_CARDS_FAILURE';
export const FETCH_TIMESHEET_CARDS_SUCCESS = 'FETCH_TIMESHEET_CARDS_SUCCESS';
