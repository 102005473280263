import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';
import { clamp } from 'lodash';

const ElasticEstimates = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(() => {
    const timetrackerMaxAllowedEffort = settings && settings.timetrackerMaxAllowedEffort;
    if (timetrackerMaxAllowedEffort !== undefined) {
      setValue(timetrackerMaxAllowedEffort * 100);
      setIsDisabled(false);
    } else setIsDisabled(true);
  }, [settings]);

  const [value, setValue] = useState(100);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: `/api/settings`,
        data: { timetrackerMaxAllowedEffort: value / 100 },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [value]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/logo_150.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Revolut Logo"
                  />
                  Elastic Estimates
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Please set a value between 100% and 500%. This value will be used to automatically
              stop timers when they reach equivalent percentage of the original estimate. Example: a
              ticket was estimated for 4 hours. If this value is set to 200% then workers will be
              able to log up to 200% of the original estimate, i.e. 8 hours.
            </p>

            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Maximum Overtime per timer
              </Label>
              <Col md="3" className="d-flex align-items-center m-b-10">
                <InputGroup>
                  <Input
                    type="number"
                    min="100"
                    max="500"
                    step="10"
                    value={value}
                    onChange={e => setValue(e.target.value)}
                    onBlur={() => setValue(Math.floor(clamp(value, 100, 500)))}
                    disabled={isDisabled}
                  />
                  <InputGroupAddon addonType="append">%</InputGroupAddon>
                </InputGroup>
              </Col>
              <Col md="7" className="d-flex align-items-center m-b-10">
                <input
                  className="w-100"
                  type="range"
                  min="100"
                  max="500"
                  step="10"
                  value={value || 100}
                  onChange={e => setValue(e.target.value)}
                  disabled={isDisabled}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="12" className="offset-2">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isDisabled || isSubmitting || value < 100 || value > 500}
                  onClick={onSubmit}
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ElasticEstimates;
