import React, { useState } from 'react';
import { CardFooter, Row, Col, Button } from 'reactstrap';
import TimesheetUpload from '../TimesheetUpload';
import { statusMapping, PAYMENT_REQUEST_STATUS } from '../../TimesheetStatusMapping';

const TimesheetBundleFooter = ({ id, paymentRequest }) => {
  const [showPayment, setShowPayment] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);

  return (
    <CardFooter>
      <Row>
        <Col sm="9" className="d-flex align-items-center m-b-10 m-sm-0">
          <a href={`mailto:accounts@londonappworks.co.uk?subject=Timesheet Issue - ${id}`}>
            Need help with this timesheet?
          </a>
        </Col>
        <Col sm="3 d-flex align-items-center">
          {paymentRequest && paymentRequest?.status !== PAYMENT_REQUEST_STATUS.EXPECTING_INVOICE ? (
            <Button color="dark" disabled block>
              {statusMapping[paymentRequest?.status.toUpperCase()].title}
            </Button>
          ) : (
            <Button
              block
              color={!uploadSuccess ? 'primary' : 'dark'}
              onClick={() => {
                if (!uploadSuccess) setShowPayment(!showPayment);
              }}
              disabled={!!uploadSuccess}
            >
              {!uploadSuccess ? 'Request Payment' : 'Pending ...'}
            </Button>
          )}
        </Col>
      </Row>
      {showPayment &&
        paymentRequest &&
        paymentRequest?.status === PAYMENT_REQUEST_STATUS.EXPECTING_INVOICE && (
          <Row className="m-t-20">
            <Col sm="12">
              <TimesheetUpload
                hasDownloadButton={false}
                timesheetIds={[paymentRequest.timesheets]}
                onSuccess={message => {
                  setUploadSuccess(message);
                  setShowPayment(false);
                }}
              />
            </Col>
          </Row>
        )}
    </CardFooter>
  );
};

export default TimesheetBundleFooter;
