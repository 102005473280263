import React from 'react';
import { Field } from 'redux-form';
import { Card, CardBody, FormGroup, Label } from 'reactstrap';
import Switch from 'react-switch';

import { renderField, phoneValidator, required, email, renderSelectField } from './validation';
import TeamHeader from './TeamHeader';
import Alert from 'reactstrap/lib/Alert';

const PersonalInfo = ({ isAdmin }) => {
  return (
    <Card>
      <CardBody>
        <FormGroup>
          <TeamHeader
            icon="girl"
            title="Personal Details"
            subtitle="These are personal details of the team member. Some of them can be set by admin only."
          />
        </FormGroup>
        <FormGroup>
          <Label for="firstName">
            First Name <span className="text-danger">*</span>
          </Label>
          <Field
            component={renderField}
            className="form-control"
            id="firstName"
            name="firstName"
            type="text"
            validate={required}
          />
        </FormGroup>
        <FormGroup>
          <Label for="lastName">
            Last Name <span className="text-danger">*</span>
          </Label>
          <Field
            component={renderField}
            className="form-control"
            id="lastName"
            name="lastName"
            type="text"
            validate={required}
          />
        </FormGroup>
        {isAdmin && (
          <>
            <FormGroup>
              <Label for="type">Type</Label>
              <Field
                component={renderSelectField}
                validate={required}
                className="form-control"
                id="type"
                name="type"
              >
                <option></option>
                <option value="candidate">Candidate</option>
                <option value="intern">Intern</option>
                <option value="contractor">Contractor - Self-Employed</option>
                <option value="umbrella">Contractor - Umbrella</option>
                <option value="agent">Agency Freelancer</option>
                <option value="employee">Employee</option>
                <option value="temporary">Temporary</option>
              </Field>
            </FormGroup>
            {/* <FormGroup>
              <Label for="slackID">Slack ID</Label>
              <Field
                component="input"
                className="form-control"
                id="slackID"
                name="slackID"
                type="text"
              />
            </FormGroup> */}
          </>
        )}
        <FormGroup>
          <Label for="DOB">Date of Birth</Label>
          <Field
            component="input"
            className="form-control"
            id="DOB"
            name="DOB"
            type="date"
            parse={value => value && new Date(value).toISOString()}
            format={value => (value ? new Date(value).toISOString().substr(0, 10) : '')}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">
            Email <span className="text-danger">*</span>
          </Label>
          <Field
            component={renderField}
            className="form-control"
            id="email"
            name="email"
            type="email"
            disabled={!isAdmin}
            validate={[required, email]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="personalEmail">Personal Email</Label>
          <Field
            component={renderField}
            className="form-control"
            id="personalEmail"
            name="personalEmail"
            type="email"
            validate={email}
          />
        </FormGroup>
        <FormGroup>
          <Label for="mobile">
            Mobile Phone <span className="text-danger">*</span>
          </Label>
          <Field
            component={renderField}
            validate={[phoneValidator, required]}
            className="form-control"
            id="mobile"
            name="mobile"
            type="tel"
          />
        </FormGroup>
        <FormGroup>
          <Label for="github">Github Profile</Label>
          <Field component="input" className="form-control" id="github" name="github" type="text" />
        </FormGroup>
        <FormGroup>
          <Label for="voip">VoIP</Label>
          <Field
            component="input"
            className="form-control"
            id="voip"
            name="voip"
            type="number"
            disabled={!isAdmin}
          />
        </FormGroup>
        <FormGroup>
          <Label for="job.title">Job Title</Label>
          <Field
            component="input"
            className="form-control"
            id="job.title"
            name="job.title"
            type="text"
            disabled={!isAdmin}
          />
        </FormGroup>
        <FormGroup>
          <Label for="job.description">Job Description</Label>
          <Field
            component="input"
            className="form-control"
            id="job.description"
            name="job.description"
            type="text"
            disabled={!isAdmin}
          />
        </FormGroup>
        <FormGroup>
          <Label for="tShirt">T-Shirt Size</Label>
          <Field component="select" className="form-control" id="tShirt" name="tShirt">
            <option></option>
            <option value="XS">XS</option>
            <option value="S">S</option>
            <option value="M">M</option>
            <option value="L">L</option>
            <option value="XL">XL</option>
            <option value="XXL">XXL</option>
            <option value="3XL">3XL</option>
            <option value="4XL">4XL</option>
          </Field>
        </FormGroup>
        <FormGroup>
          <Label for="hasAllergy" className="m-0 align-middle">
            Allergies
          </Label>
          <Field
            component={({ input: { onChange, checked } }) => (
              <Switch
                className="m-l-15 align-middle"
                onColor="#626ed4"
                onChange={onChange}
                checked={checked}
              />
            )}
            className="form-control"
            id="hasAllergy"
            name="hasAllergy"
            type="checkbox"
          />
        </FormGroup>
        <FormGroup>
          <Field
            placeholder="Please provide some details about your allergies..."
            component="input"
            className="form-control"
            id="allergyComment"
            name="allergyComment"
            type="text"
          />
        </FormGroup>
        <Alert color={'info'}>
          When food is provided during team meetings we need to make sure we don't accidentally kill
          you!
        </Alert>
      </CardBody>
    </Card>
  );
};

export default PersonalInfo;
