import React, { useState, useCallback, useEffect } from 'react';
import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';

async function isFreshdeskConnected() {
  try {
    const response = await axios({
      method: 'get',
      url: `/api/freshdesk/status`,
    });

    const data = response && response.data;
    if (data && !data.error) {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}

const Freshdesk = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isLinking, setIsLinking] = useState(false);
  const [isSavingUrl, setIsSavingUrl] = useState(false);

  const [freshdeskToken, setFreshdeskToken] = useState('');
  const [isFreshdeskOnline, setIsFreshdeskOnline] = useState(false);
  const [freshdeskCardUrl, setFreshdeskCardUrl] = useState('');

  useEffect(() => {
    (async function() {
      setIsFreshdeskOnline(await isFreshdeskConnected());
    })();
  }, []); // eslint-disable-line

  useEffect(() => {
    const freshdeskCardUrl = settings && settings.freshdeskCardUrl;
    if (freshdeskCardUrl !== undefined) setFreshdeskCardUrl(freshdeskCardUrl || '');
  }, [settings]);

  const handleOnClickLink = useCallback(async () => {
    setIsLinking(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'get',
        url: `/api/freshdesk/authorize?code=${freshdeskToken}`,
      });

      const data = response && response.data;

      if (data && !data.error) setSuccess(data.message);
      setIsLinking(false);
      setIsFreshdeskOnline(true);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsLinking(false);
    }
  }, [freshdeskToken]);

  const handleOnClickSave = useCallback(async () => {
    setIsSavingUrl(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: `/api/settings`,
        data: { freshdeskCardUrl },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSavingUrl(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSavingUrl(false);
    }
  }, [freshdeskCardUrl]);

  return (
    <Row>
      <Col sm="12">
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/freshdesk.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Freshdesk Logo"
                  />
                  Freshdesk Integration
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}

            {!isFreshdeskOnline ? (
              <>
                <Alert color="warning">Freshdesk needs to be configured.</Alert>
                <p className="text-muted m-b-30">
                  Please provide API key from Freshdesk administrator console by copying it into the
                  field below. Click on your profile -&gt; "Profile Settings" and copy "Your API
                  Key" in the field below.
                </p>
                <FormGroup row>
                  <Label htmlFor="freshdesk_key" sm="2">
                    API Key
                  </Label>
                  <Col sm="8">
                    <Input
                      id="freshdesk_key"
                      type="password"
                      value={freshdeskToken}
                      onChange={event => setFreshdeskToken(event.nativeEvent.target.value)}
                      disabled={isLinking}
                    />
                  </Col>
                  <Col sm="2">
                    <Button
                      type="submit"
                      color="primary"
                      block
                      disabled={!freshdeskToken || isLinking}
                      onClick={handleOnClickLink}
                    >
                      {!isLinking ? 'Link' : 'Linking...'}
                    </Button>
                  </Col>
                </FormGroup>
              </>
            ) : (
              <>
                <Alert color="success">Freshdesk is connected to Figaro and works fine.</Alert>
                <p className="text-muted m-b-30">
                  If you wish to disconnect Figaro from Freshdesk please go to you profile settings
                  on Freshdesk and press "Reset API key"
                </p>
              </>
            )}

            <FormGroup row>
              <Label htmlFor="freshdesk_card_url" sm="2">
                Freshdesk Card Url
              </Label>
              <Col sm="8">
                <Input
                  id="freshdesk_card_url"
                  type="url"
                  value={freshdeskCardUrl}
                  onChange={event => setFreshdeskCardUrl(event.nativeEvent.target.value)}
                  disabled={isSavingUrl}
                />
              </Col>
              <Col sm="2">
                <Button
                  type="submit"
                  color="primary"
                  block
                  disabled={!freshdeskCardUrl || isSavingUrl}
                  onClick={handleOnClickSave}
                >
                  {!isSavingUrl ? 'Save' : 'Saving...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default Freshdesk;
