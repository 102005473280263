import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Button, Modal, ModalBody, Card, CardBody } from 'reactstrap';

import TimesheetsTable from './TimesheetsTable';
import Invoice from './TimesheetInvoice';
import TeamHeader from '../Team/TeamHeader';
import DashboardCard from '../Dashboard/DashboardCard';
import { fetchTimesheetCards } from '../../store/timesheets/actions';

const Timesheets = () => {
  const dispatch = useDispatch();
  const timesheets = useSelector(state => state.Timesheets);
  const userID = useSelector(state => state.Auth.userID);
  const profile = useSelector(state => (userID ? state.Team.profiles[userID] : null));
  const cards = timesheets.data && timesheets.data.cards;
  const [show, setShow] = useState(false);
  const resetAndCloseModal = () => setShow(false);

  useEffect(() => {
    dispatch(fetchTimesheetCards());
  }, [dispatch]); // eslint-disable-line

  return (
    <Container fluid>
      <div className="page-title-box">
        <Row className="align-items-center">
          <Col xs="9">
            <h4 className="page-title">Timesheets and Payments</h4>
          </Col>
          <Col xs="3" className="d-flex justify-content-end">
            <p></p>
          </Col>
        </Row>
      </div>

      <Row>{cards && cards.map((entry, index) => <DashboardCard item={entry} key={index} />)}</Row>

      <Row className="align-items-center">
        <Col xs="12">
          <Card>
            <Row>
              <Col xs="6">
                <CardBody>
                  <TeamHeader
                    icon="money"
                    title="Invoicing and Payment FAQ"
                    subtitle="How to prepare your invoices?"
                  />
                  <br />
                  <ol id="actionList">
                    <li>See the sample invoice and include same fields in your invoice. </li>
                    <li>
                      Include all timesheet IDs in your invoice{' '}
                      <em>without any linebreaks or spaces</em> (e.g. one-two-three).
                    </li>
                    <li>Check these requirements are met:</li>
                    <ul>
                      <li>Invoice should be in PDF format.</li>
                      <li>
                        Invoice should be in English or includes English as a second language.
                      </li>
                      <li>
                        You are a <em>Supplier</em>. If you get errors on invoice upload about
                        supplier address ➡️ your <em>Business Address</em> in Figaro profile and on
                        your invoice should match.
                      </li>
                    </ul>
                    <li>
                      Upload your PDF invoice by clicking <em>Upload</em> button.
                    </li>
                    <li>
                      Got errors? Don't worry, use checkbox to confirm you have checked the invoice
                      and submit it anyway. Admin team will review it manually.
                    </li>
                  </ol>
                  <Button color="info" size="m" className="m-r-10" onClick={() => setShow(true)}>
                    Show Invoice Example
                  </Button>
                </CardBody>
              </Col>
              <Col xs="6">
                <CardBody>
                  <TeamHeader
                    icon="timesheets2"
                    title="Multiple Timesheets with One Invoice"
                    subtitle="How to submit one invoice for all my work?"
                  />
                  <br />
                  <ol id="actionList">
                    <li>
                      Select timesheets you want to be paid for
                      <ul>
                        <li>
                          Look at the bottom of each timesheet. You should see{' '}
                          <strong>Request Batch Payment</strong> switch on eligible timesheets.
                        </li>
                        <li>
                          Use <strong>Request Batch Payment</strong> switch at the bottom of each
                          timesheet to add them together and upload only one invoice.
                        </li>
                      </ul>
                    </li>
                    <li>
                      When you are done selecting timesheets click on{' '}
                      <strong>Request Payment</strong>
                    </li>
                    <li>
                      A new popup window will display all your items and total amount, company
                      details.
                    </li>
                    <li>Same requirements apply as for single timesheet invoices:</li>
                    <ul>
                      <li>
                        Include all timesheet IDs in your invoice{' '}
                        <em>without any linebreaks or spaces</em> (e.g. one-two-three).
                      </li>
                      <li>Invoice should be in PDF format.</li>
                      <li>
                        Invoice should be in English or includes English as a second language.
                      </li>
                      <li>
                        You are a <em>Supplier</em>. If you get errors on invoice upload about
                        supplier address ➡️ your <em>Business Address</em> in Figaro profile and on
                        your invoice should match.
                      </li>
                    </ul>
                    <li>
                      Upload your PDF invoice by clicking <em>Request Payment</em> button.
                    </li>
                    <li>
                      Got errors? Don't worry, use checkbox to confirm you have checked the invoice
                      and submit it anyway. Admin team will review it manually.
                    </li>
                  </ol>
                </CardBody>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <TimesheetsTable timesheets={timesheets} userType={profile && profile.type} />
      <Modal className="modal-lg" isOpen={show} toggle={resetAndCloseModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Invoice Example</h5>
          <button type="button" onClick={resetAndCloseModal} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <Invoice />
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Timesheets;
