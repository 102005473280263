if ('Notification' in window && Notification.permission === 'default') {
  Notification.requestPermission();
}

/**
 * Send Notification
 *
 * @param {string} message
 * @param {string} id
 * @param {boolean} requireInteraction
 * @param {function} callback
 */
export const sendNotification = (message, id = null, requireInteraction = true, callback) => {
  if (id && getItem(id)) return;

  if (!('Notification' in window)) {
    alert(`FIGARO\n${message}`);

    callback && typeof callback === 'function' && callback();

    if (id) saveItem(id);
  } else if (Notification.permission === 'granted') {
    if (!window.document.hasFocus()) createNotification(message, requireInteraction);

    callback && typeof callback === 'function' && callback();

    if (id) saveItem(id);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then(function(permission) {
      if (permission === 'granted') {
        if (!window.document.hasFocus()) createNotification(message, requireInteraction);

        callback && typeof callback === 'function' && callback();

        if (id) saveItem(id);
      }
    });
  }
};

function createNotification(body, requireInteraction) {
  const notification = new Notification('Figaro', {
    body,
    icon: require('../images/logo_150.png'),
    badge: require('../images/logo_150.png'),
    requireInteraction,
  });

  notification.onclick = () => {
    window.focus();
    notification.close();
  };
}

let log = null;

const getLog = () => {
  if (log) return log;
  const storage = window.localStorage.getItem('figaro_notifications');
  log = JSON.parse(storage) || {};
  return log;
};

const getItem = id => {
  const log = getLog();
  return log[id];
};

const saveItem = id => {
  const log = getLog();
  log[id] = { dateTime: new Date().toISOString() };
  window.localStorage.setItem('figaro_notifications', JSON.stringify(log));
};
