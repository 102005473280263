import React, { useState, useEffect } from 'react';
import Switch from 'react-switch';
import { Spinner, Table, Card, CardBody, Button } from 'reactstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';

import { GlobalFilter } from '../Reporting/GlobalFilter';
import columns from './projectsTableColumns';
import SortIndicator from '../Reporting/SortIndicator';
const completedStatuses = ['COMPLETED', 'ARCHIVED', 'UNKNOWN', 'CANCELLED'];
const ProjectsTable = ({ data, isLoading, onClickEditAction, onClickCloneAction }) => {
  const filterTypes = React.useMemo(
    () => ({
      hideCompleted: rows =>
        rows.filter(
          row =>
            row.original?.status && !completedStatuses.includes(row.original?.status?.toUpperCase())
        ),
    }),
    []
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    state,
    toggleSortBy,
    setFilter,
  } = useTable(
    {
      columns,
      data,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  const [showCompleted, setShowCompleted] = useState(false);

  useEffect(() => {
    toggleSortBy('startDate');
    setFilter('status', 'hideCompleted');
  }, [toggleSortBy, setFilter, data]);

  useEffect(() => {
    setFilter('status', showCompleted ? undefined : 'hideCompleted');
  }, [showCompleted, setFilter]);

  return (
    <>
      <div className="d-flex justify-content-between m-b-15">
        <div className="d-flex align-items-center">
          <Switch
            onColor="#626ed4"
            onChange={() => {
              setShowCompleted(!showCompleted);
            }}
            checked={showCompleted}
          />
          <span className="m-l-10">Show Completed and Unknown</span>
        </div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={state.globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <Card>
        <CardBody className="p-0">
          <Table striped responsive className="table-sm m-0" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => {
                    return (
                      <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                        {column.render('Header')}
                        {column.canSort && <SortIndicator column={column} />}
                      </th>
                    );
                  })}
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {isLoading && (
                <tr>
                  <td colSpan="12" className="text-center">
                    <Spinner color="primary" />
                  </td>
                </tr>
              )}
              {!isLoading && rows.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-black-50">
                    No entries found
                  </td>
                </tr>
              ) : (
                rows.map((row, i) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        const className =
                          cell && cell.column.id === 'margin' && cell.value < 0
                            ? 'text-danger'
                            : '';
                        return (
                          <td
                            {...cell.getCellProps([
                              {
                                className,
                              },
                            ])}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}

                      <td className="text-right">
                        <Button
                          color="link"
                          className="btn-sm"
                          title="Edit Project"
                          onClick={() => onClickEditAction(row.original.id)}
                        >
                          <i className="mdi mdi-pencil-outline font-24 text-success" />
                        </Button>
                      </td>
                      <td className="text-right">
                        <Button
                          color="link"
                          className="btn-sm"
                          title="Clone Project"
                          onClick={() => onClickCloneAction(row.original.id)}
                        >
                          <i className="mdi mdi-content-copy font-20 text-success" />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </>
  );
};

export default ProjectsTable;
