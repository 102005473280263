import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Spinner,
  UncontrolledAlert,
  Table,
  Badge,
} from 'reactstrap';
import Select from 'react-select';
import cn from 'classnames';
import ColorHash from 'color-hash';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { START_TIMER } from '../../store/timetracker/actionTypes';
import { startTimetracker, stopTimetracker, fetchTickets } from '../../store/actions';
import Timer from './Timer';

import './Timetracker.scss';

const colorHash = new ColorHash({ lightness: 0.4, saturation: 0.6 });

const SelectBadge = (id, displayId, projectId, color) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color || colorHash.hex(projectId),
    color: 'white',
    borderRadius: 8,
    content: `"#${displayId ? displayId : id}"`,
    display: 'block',
    textAlign: 'center',
    marginRight: 5,
    height: 20,
    width: 15 + String(displayId ? displayId : id).length * 10,
  },
});

const Timetracker = () => {
  const dispatch = useDispatch();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const selectRef = useRef(null);
  const reactTooltip = useRef(null);

  const { tickets, timers, isLoadingTickets, isLoadingTimers, error, success } = useSelector(
    state => state.Timetracker
  );
  let groupedOptions = [];
  let runningTimer;

  if (tickets) {
    groupedOptions = tickets.map(project => {
      return {
        label: project[0] && (project[0].projectName || project[0].projectId),
        options: project.map(ticket => ({ label: ticket.name, ticket })),
      };
    });
  }

  if (timers && timers.length) {
    runningTimer = timers.find(timer => timer.isCurrentlyRunning);
    timers.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
  }

  const normalTimers = timers?.filter(t => !t.isDayPass);
  const timerCards = normalTimers && normalTimers.slice(0, 4);
  const timerList = normalTimers && normalTimers.slice(4);

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  }, [timers]);

  const startTimer = async timerId => {
    dispatch({ type: START_TIMER });
    window.scrollTo({ top: 0 });
    let location;
    try {
      location = await getLocation();
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.log(error);
    }
    dispatch(startTimetracker(timerId, null, location));
  };

  return (
    <Container fluid className="timetracker">
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            ⛔️
          </span>
          {error}
        </Alert>
      )}

      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          {success}
        </UncontrolledAlert>
      )}

      <Row>
        <Col sm={8} lg={9} className="m-b-15">
          <Select
            ref={selectRef}
            menuPortalTarget={document.querySelector('body')}
            options={groupedOptions}
            styles={{
              groupHeading: provided => ({
                ...provided,
                color: '#9ca8b3',
                fontSize: 13,
                borderBottom: '1px solid #eee',
              }),
              option: (provided, { data }) => {
                const { id, displayId, projectId, color } = data.ticket;
                return {
                  ...provided,
                  ...SelectBadge(id, displayId, projectId, color),
                };
              },
              singleValue: (provided, { data }) => {
                const { id, displayId, projectId, color } = data.ticket;
                return {
                  ...provided,
                  ...SelectBadge(id, displayId, projectId, color),
                };
              },
            }}
            placeholder={
              isLoadingTickets
                ? 'Loading tickets...'
                : tickets && tickets.length
                ? 'Select a task to start a timer...'
                : 'No tasks were found'
            }
            isLoading={isLoadingTickets}
            isOptionSelected={option => selectedTicket && selectedTicket.id === option.ticket.id}
            onChange={item => {
              setSelectedTicket(item && item.ticket);
            }}
            onMenuOpen={() => {
              if (!tickets || !tickets.length) dispatch(fetchTickets());
            }}
          />
        </Col>
        <Col sm={4} lg={3} className="d-flex align-items-start">
          <Button
            color="outline-secondary"
            className="m-r-10"
            style={{ height: '38px', lineHeight: 'normal', padding: '5px' }}
            data-tip="Reload tasks"
            data-effect="solid"
            data-delay-show="200"
            onClick={() => dispatch(fetchTickets())}
          >
            <i className="mdi mdi-refresh font-24" />
          </Button>
          <Button
            style={{ height: '38px' }}
            color="primary"
            className="flex-grow-1"
            disabled={!!runningTimer || !tickets || !tickets.length || !selectedTicket}
            onClick={() => {
              setSelectedTicket(null);
              selectRef.current.select.clearValue();
              dispatch(startTimetracker(null, selectedTicket));
            }}
          >
            Start Timer
          </Button>
        </Col>
      </Row>

      {isLoadingTimers && (
        <div className={cn('text-center', { 'spinner-with-data': !!timers })}>
          <Spinner color="primary" />
        </div>
      )}

      <Row className="m-t-40">
        {timerCards &&
          timerCards.map(timer => (
            <Col key={timer.id} xl="3" lg="6" className="m-b-30">
              <Timer
                data={timer}
                disabled={(runningTimer && runningTimer.id !== timer.id) || isLoadingTimers}
                start={() => startTimer(timer.id)}
                stop={() => dispatch(stopTimetracker())}
              />
            </Col>
          ))}
      </Row>

      <Row>
        {!!(timerList && timerList.length) && (
          <Table>
            <thead>
              <tr>
                <th>Project</th>
                <th>Task ID</th>
                <th>Task</th>
                <th>Worked Time</th>
                <th>Last updated</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {timerList.map(timer => (
                <tr
                  key={timer.id}
                  data-tip={timer.taskDescription}
                  data-type="dark"
                  data-effect="solid"
                  data-delay-show="750"
                  data-class="task-tooltip"
                >
                  <td>
                    <Badge
                      className="font-12"
                      style={{ backgroundColor: `${timer.projectColor || '#02a499'}` }}
                    >
                      {timer.projectId}
                    </Badge>
                  </td>
                  <td>#{timer.displayId ? timer.displayId : timer.taskId}</td>
                  <td>{timer.taskName}</td>
                  <td>{timer.totalTimes.totalDisplayTime}</td>
                  <td>{moment(timer.updatedAt).format('llll')}</td>
                  <td>
                    <Button
                      block
                      disabled={!!runningTimer || isLoadingTimers}
                      color="primary"
                      className="text-uppercase font-500 btn-sm"
                      onClick={() => startTimer(timer.id)}
                    >
                      Start
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Row>

      <ReactTooltip ref={reactTooltip} />
    </Container>
  );
};

const getLocation = () =>
  new Promise((resolve, reject) =>
    navigator.geolocation.getCurrentPosition(
      position => {
        if (position.coords)
          resolve({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        else reject();
      },
      error => reject(error),
      { enableHighAccuracy: true, timeout: 5000 }
    )
  );

export default Timetracker;
