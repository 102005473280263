import React from 'react';
import { Input } from 'reactstrap';
import { useAsyncDebounce } from 'react-table';

import './GlobalFilter.scss';

export function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className="global-filter">
      <Input
        type="text"
        className="form-control w-auto"
        value={value || ''}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`Search ${count} records...`}
      />
      {value && (
        <button
          onClick={() => {
            setValue('');
            onChange('');
          }}
        >
          <i className="mdi mdi-close-circle text-muted" />
        </button>
      )}
    </div>
  );
}
