import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import cn from 'classnames';
import DailyUpdate from './DailyUpdate';

const TeamUpdate = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="p-t-10">
      <h4 className="page-title m-b-30">Team Update</h4>
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 0 })}
            onClick={() => setActiveTab(0)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">Daily Update</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 1 })}
            onClick={() => setActiveTab(1)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Cybersecurity Alert</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            disabled
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 2 })}
            onClick={() => setActiveTab(2)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Admin Update</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <DailyUpdate />
        </TabPane>

        <TabPane tabId={1}></TabPane>

        <TabPane tabId={2}></TabPane>
      </TabContent>
    </div>
  );
};

export default TeamUpdate;
