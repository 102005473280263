import React, { useState, useEffect } from 'react';
import { Card, CardBody, Button, Badge, Progress } from 'reactstrap';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { updateTimetracker } from '../../store/actions';
import { setupTimetracker, clearTimetracker } from '../../helpers/timetracker';
import { formatDuration } from '../../helpers/utils';

const Timer = ({ data, start, stop, disabled }) => {
  const dispatch = useDispatch();
  const [showNotes, setShowNotes] = useState(false);
  const [notes, setNotes] = useState(data.notes);
  const [currentTime, setCurrentTime] = useState(
    data.isCurrentlyRunning ? '--:--:--' : data.totalTimes.totalTimeInSeconds
  );

  useEffect(() => {
    setupTimetracker(data, setCurrentTime, dispatch);

    return () => clearTimetracker(data && data.id);
  }, [data]); // eslint-disable-line

  const { isCurrentlyRunning, projectColor } = data;

  const color = projectColor || '#02a499';

  const hours = currentTime / 60 / 60;
  const allowedEffort =
    (data.remainingEffort && parseFloat(data.remainingEffort)) ||
    (data.allowedEffort && parseFloat(data.allowedEffort)) ||
    0;
  const progress = (hours * 100) / allowedEffort;
  const progressColor = progress < 50 ? 'success' : progress < 80 ? 'warning' : 'danger';

  return (
    <Card className={cn('timer text-white border-0 h-100 m-0')}>
      <CardBody
        className={cn('d-flex flex-column', {
          'bg-muted': !isCurrentlyRunning,
          'bg-dark': isCurrentlyRunning,
          shadow: isCurrentlyRunning,
        })}
      >
        <div className="d-flex">
          <div className={cn('clock flex-shrink-0', { animated: isCurrentlyRunning })}></div>
          <div className="flex-grow-1 m-l-10">
            <div className="mini-label font-500" style={{ backgroundColor: `${color}` }}>
              {data.projectId}
            </div>
            <div className="d-flex flex-wrap justify-content-between align-items-start m-t-10">
              <h4 className="time">{formatDuration(currentTime * 1000, false)}</h4>
              <Button
                size="sm"
                color="light"
                onClick={() => setShowNotes(!showNotes)}
                className={cn({ active: showNotes })}
              >
                Notes
              </Button>
            </div>
            <Progress
              value={progress}
              color={progressColor}
              className={cn('m-t-5 position-relative', {
                'progress-bar-animated': isCurrentlyRunning,
              })}
            />
          </div>
        </div>

        <div className={cn('notes-wrapper', { open: showNotes })}>
          <div className="notes-container">
            <textarea
              className="notes bg-secondary text-white"
              placeholder="Type your notes here..."
              value={notes}
              onChange={e => setNotes(e.target.value)}
              disabled={!showNotes}
            ></textarea>
            <Button
              disabled={!showNotes}
              size="sm"
              color="success"
              className="notes-button"
              onClick={() => {
                dispatch(updateTimetracker(data.id, { notes }));
                setTimeout(() => setShowNotes(false), 200);
              }}
            >
              Save Changes
            </Button>
          </div>
        </div>

        <h5 className="flex-grow-1 m-b-5">
          <Badge pill style={{ backgroundColor: color }} className="m-r-5 font-500">
            <span>#{data.displayId ? data.displayId : data.taskId}</span>
          </Badge>
          <span>{data.taskName}</span>
        </h5>
        <small
          className="d-block m-b-10"
          data-tip={data.taskDescription.length > 120 ? data.taskDescription : undefined}
          data-type="dark"
          data-effect="solid"
          data-event="click"
          data-event-off="mouseleave"
          data-delay-show="250"
          data-class="task-tooltip"
          style={data.taskDescription.length > 120 ? { cursor: 'help' } : {}}
        >
          {`${data.taskDescription.substr(0, 120)}${
            data.taskDescription.length > 120 ? '...' : ''
          }`}
        </small>
        <Button
          block
          disabled={disabled}
          color="light"
          className="text-uppercase font-500"
          onClick={() => {
            if (isCurrentlyRunning) stop();
            else start();
          }}
        >
          {isCurrentlyRunning ? 'Stop' : 'Start'}
        </Button>
      </CardBody>
    </Card>
  );
};

export default Timer;
