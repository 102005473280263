import React, { useState, useRef, useEffect } from 'react';
import { Card, CardHeader, CardBody, Table, Collapse, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { formatCurrency } from '../../../helpers/numbers';

import ReactTooltip from 'react-tooltip';

const TimesheetBundleBody = ({ id, timesheets }) => {
  const [openIndexes, setOpenIndexes] = useState(Array(timesheets.length || 0));

  const reactTooltip = useRef(null);
  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  const paymentRequest = timesheets && timesheets[0] && timesheets[0].paymentRequest;

  let bundleFromDate = paymentRequest.fromDate;
  let bundleToDate = paymentRequest.toDate;
  try {
    bundleFromDate = new Date(bundleFromDate).toLocaleDateString();
    bundleToDate = new Date(bundleToDate).toLocaleDateString();
  } catch {}

  return (
    <CardBody>
      <p>
        This bundle contains {timesheets.length} timesheets, and bundle ID is <strong>{id}</strong>
      </p>

      {timesheets.map((timesheet, index) => {
        let fromDate = timesheet.fromDate;
        let toDate = timesheet.toDate;
        try {
          fromDate = new Date(fromDate).toLocaleDateString();
          toDate = new Date(toDate).toLocaleDateString();
        } catch {}

        return (
          <Card
            className="mb-1 w-100"
            key={index}
            onClick={() => {
              const indexes = Array(timesheets.length);
              indexes[index] = !openIndexes[index];
              setOpenIndexes(indexes);
            }}
          >
            <CardHeader className="p-3">
              <h6 className="m-0 font-14">
                <Link
                  to="#"
                  onClick={() => {
                    const indexes = Array(timesheets.length);
                    indexes[index] = !openIndexes[index];
                    setOpenIndexes(indexes);
                  }}
                  className="text-dark"
                >
                  {timesheet.id}{' '}
                  {timesheet?.useTime && (
                    <span
                      role="img"
                      aria-label="questions mark"
                      className="font-14 m-l-10"
                      data-tip="This timesheet uses human time."
                      data-effect="solid"
                      data-delay-show="200"
                    >
                      ⏱
                    </span>
                  )}
                  {!timesheet?.useTime && (
                    <span
                      role="img"
                      aria-label="questions mark"
                      className="font-14 m-l-10"
                      data-tip="This timesheet uses decimal time. This value is a rounded estimate, without seconds."
                      data-effect="solid"
                      data-delay-show="200"
                    >
                      🤖
                    </span>
                  )}
                </Link>
                {timesheet.financials.companyDisplayName ? (
                  <Badge className="font-12" pill color="success">
                    {timesheet.financials.companyDisplayName}
                  </Badge>
                ) : (
                  <></>
                )}
              </h6>
            </CardHeader>
            <Collapse isOpen={openIndexes[index]}>
              <Table responsive className="mb-0 table-condensed">
                <thead>
                  <tr>
                    <th>Work Item</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Time, HH:mm</th>
                    {!timesheet?.useTime && <th>Decimal, h</th>}
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {timesheet.lineItems.map((lineItem, index) => {
                    let date = lineItem.workDate;
                    try {
                      date = new Date(date).toLocaleDateString();
                    } catch {}

                    return (
                      <tr key={index}>
                        <td>{lineItem.title}</td>
                        <td>{date}</td>
                        <td>{date}</td>
                        <td className={timesheet?.useTime ? '' : 'greyedOut'}>
                          {lineItem.totalDisplayHours}
                        </td>
                        {!timesheet?.useTime && <td>{lineItem.totalHours}</td>}
                        <td className={timesheet?.useTime ? 'greyedOut' : ''}>
                          {lineItem.totalAmount &&
                            formatCurrency(lineItem.totalAmount, lineItem.currency)}
                        </td>
                      </tr>
                    );
                  })}

                  <tr>
                    <td>
                      <h6>Total</h6>
                    </td>
                    <td>{fromDate}</td>
                    <td>{toDate}</td>
                    <td className={timesheet?.useTime ? '' : 'greyedOut'}>
                      {timesheet.financials.totalDisplayHours}
                      {!timesheet?.useTime && (
                        <span
                          role="img"
                          aria-label="questions mark"
                          className="font-14 m-l-10"
                          data-tip="This timesheet uses decimal time. This value is a rounded estimate, without seconds."
                          data-effect="solid"
                          data-delay-show="200"
                        >
                          ❓
                        </span>
                      )}
                    </td>
                    <td className={timesheet?.useTime ? 'greyedOut' : ''}>
                      {' '}
                      {timesheet.financials.totalHours}
                    </td>
                    <td>
                      {formatCurrency(
                        timesheet.financials.totalAmount,
                        timesheet.financials.currency
                      )}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Collapse>
          </Card>
        );
      })}

      <Table className="table-total table-condensed">
        <thead>
          <tr>
            <th style={{ width: '54%' }}></th>
            <th>From</th>
            <th>To</th>
            <th></th>
            <th>Amount</th>
          </tr>
        </thead>
        <tfoot>
          <tr>
            <td>
              <h5>Total</h5>
            </td>
            <td>{bundleFromDate}</td>
            <td>{bundleToDate}</td>
            <td></td>
            <td>{formatCurrency(paymentRequest.totalAmount, paymentRequest.currency)}</td>
          </tr>
        </tfoot>
      </Table>
      <ReactTooltip ref={reactTooltip} />
    </CardBody>
  );
};

export default TimesheetBundleBody;
