import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';

import rootReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middleware = [thunk];

if (process.env.NODE_ENV === 'development') {
  const logger = createLogger({
    collapsed: true,
    // Mute redux-form logs
    predicate: (_, action) => !action.type.includes('@@redux-form'),
  });
  middleware.push(logger);
}

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(...middleware)));

if (module.hot) {
  // Enable Webpack hot module replacement for reducers
  module.hot.accept('./reducers', () => {
    const nextReducer = require('./reducers').default;

    store.replaceReducer(nextReducer);
  });
}

export default store;
