import {
  FETCH_TEAM,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
} from './actionTypes';
import { SIGN_IN_SUCCESS, SIGN_OUT } from '../auth/actionTypes';

const initialState = {
  profiles: null,
  isLoading: false,
  error: null,
};

const team = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TEAM:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FETCH_TEAM_SUCCESS:
      const profiles = { ...state.profiles, ...action.data };

      return {
        ...state,
        profiles,
        isLoading: false,
        error: null,
      };

    case FETCH_TEAM_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case FETCH_USER_PROFILE:
      return { ...state, isLoading: true };

    case FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        profiles: {
          ...state.profiles,
          [action.profile.userID]: action.profile,
        },
      };

    case FETCH_USER_PROFILE_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case SIGN_IN_SUCCESS:
      return {
        ...state,
        profiles: {
          ...state.profiles,
          [action.profile.userID]: action.profile,
        },
      };

    case SIGN_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default team;
