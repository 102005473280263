import axios from 'axios';

import {
  ACTIVATE_AUTH_LAYOUT,
  ACTIVATE_NON_AUTH_LAYOUT,
  TOGGLE_LARGE_LAYOUT,
  SET_GLOBAL_ALERT,
  DISMISS_GLOBAL_ALERT,
  CHECK_AVAILABILITY_SUCCESS,
} from './actionTypes';

export const activateAuthLayout = () => {
  return {
    type: ACTIVATE_AUTH_LAYOUT,
    payload: {
      topbar: true,
      sidebar: true,
      footer: true,
      layoutType: 'Auth',
    },
  };
};

export const activateNonAuthLayout = () => {
  return {
    type: ACTIVATE_NON_AUTH_LAYOUT,
    payload: {
      topbar: false,
      sidebar: false,
      footer: false,
      layoutType: 'NonAuth',
    },
  };
};

export const toggleLargeLayout = () => {
  return {
    type: TOGGLE_LARGE_LAYOUT,
  };
};

export const setGlobalAlert = (text, color, icon) => {
  return {
    type: SET_GLOBAL_ALERT,
    text,
    color,
    icon,
  };
};

export const dismissGlobalAlert = index => {
  return {
    type: DISMISS_GLOBAL_ALERT,
    index,
  };
};

export const checkAvailability = () => {
  return async dispatch => {
    try {
      const response = await axios.get(`/api/availability/check`);

      if (response) {
        dispatch({
          type: CHECK_AVAILABILITY_SUCCESS,
          payload: response.data?.hasPendingAvailability,
        });
      }
    } catch (error) {
      process.env.NODE_ENV !== 'production' && console.log(error);
    }
  };
};
