import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';
import {
  Alert,
  Container,
  Row,
  Col,
  Label,
  Button,
  UncontrolledAlert,
  Card,
  CardBody,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import AccessDeniedPoster from '../../components/AccessDeniedPoster';
import DocumentPreview from '../../components/DocumentPreview/DocumentPreview';
import Footer from '../../components/Layout/Footer';

import {
  //saveFileFromUrl,
  getPreviewDocument,
  acceptDocument,
  rejectDocument,
} from '../../helpers/utils';

const DocumentReview = props => {
  const reactTooltip = useRef(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [thankYou, setThankYou] = useState(false);
  const [enforceConsuela, setEnforceConsuela] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchDocumentForPreview();
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  const fetchDocumentForPreview = useCallback(
    async function() {
      setIsLoading(true);
      const urlParams = new URLSearchParams(props?.location?.search);
      const protectionKey = urlParams.get('protectionKey');
      const documentKey = props?.match?.params?.documentKey;
      if (!protectionKey || !documentKey) {
        setSuccess(false);
        setError(
          'Unable to fetch your document. It was either deleted, expired or revoked. If you copied the URL into the address bar manually please check it and try again. If this error persists please contact your account manager.'
        );
        setIsLoading(false);
      } else {
        getPreviewDocument(documentKey, protectionKey, (response, error) => {
          if (response) {
            setPdfBlob(response);
          }
          if (error) {
            setError(
              error.message ?? 'Error. Could not fetch the document. Please try again a bit later.'
            );
            setEnforceConsuela(error.code === 403 || error.code === 401 || error.code === 429);
          }
          setIsLoading(false);
        });
      }
    },
    [] // eslint-disable-line
  );

  //TODO: allow PDF download for documents that do not require signature / or client documents
  // const downloadDocument = useCallback(
  //   // eslint-disable-line
  //   async function() {
  //     if (!pdfBlob) {
  //       throw new Error('Please fetch the document first');
  //     }
  //     setIsLoading(true);
  //     saveFileFromUrl(pdfBlob, `document.pdf`, () => {
  //       setIsLoading(false);
  //     });
  //   }
  // );

  return (
    <div id="wrapper">
      {thankYou && (
        <div className="fadeMe">
          <UncontrolledAlert color="info">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ✅
            </span>
            Thank you, your response was recorded.
          </UncontrolledAlert>
        </div>
      )}
      <Container className="create-timesheet m-b-20">
        <div className="content-page">
          <div className="content">
            <div className="page-title-box">
              <Row>
                <Col sm="12">
                  <h4 className="page-title">Document Review and Approval</h4>
                </Col>
              </Row>
            </div>
            {success && (
              <UncontrolledAlert color="info">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                Document was processed successfully!
              </UncontrolledAlert>
            )}
            {error && (
              <Alert color="danger">
                <span role="img" aria-label="see no evil" className="font-18 m-r-5">
                  🙈
                </span>
                {error}
              </Alert>
            )}
            {enforceConsuela ? (
              <AccessDeniedPoster />
            ) : (
              <div>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="6" lg="12">
                        <Label className="d-block m-t-30">
                          <span className="order bg-primary">1</span>Get the Document
                          <p className="m-t-5 m-l-10">
                            The document should load automatically. If it does not load within
                            couple of seconds use this button to fetch it.
                          </p>
                        </Label>
                        <Button
                          color="primary"
                          className="m-l-10"
                          disabled={isLoading}
                          onClick={async e => {
                            setIsLoading(true);
                            setSuccess(false);
                            setPdfBlob(null);
                            try {
                              setError(false);
                              await fetchDocumentForPreview();
                            } catch (error) {
                              window.scrollTo({ top: 0 });
                              setError((error && error.message) || 'An unexpected error occurred');
                            }
                          }}
                        >
                          {isLoading ? 'Working...' : 'Fetch Document'}
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="6" lg="12">
                        <Label className="d-block m-t-30">
                          <span className="order bg-primary">2</span>View the Document
                        </Label>
                        <DocumentPreview
                          documentUrl={pdfBlob}
                          title="Your Document to Review"
                          zoom={1.25}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    <Row>
                      <Col sm="6">
                        <Label className="d-block m-t-30">
                          <span className="order bg-success">3.a</span>Accept and sign the Document.
                          Once accepted you will be sent a co-signed copy in email.
                        </Label>
                        <Button
                          color="success"
                          className="m-l-10"
                          disabled={isLoading || !pdfBlob}
                          onClick={async e => {
                            setIsLoading(true);
                            setSuccess(false);
                            try {
                              setError(false);

                              const urlParams = new URLSearchParams(props?.location?.search);
                              const protectionKey = urlParams.get('protectionKey');
                              const documentKey = props?.match?.params?.documentKey;
                              await acceptDocument(documentKey, protectionKey, error => {
                                if (error) {
                                  setError(error?.message || 'Could not accept document');
                                } else {
                                  setSuccess(true);
                                  setThankYou(true);
                                }
                                setIsLoading(false);
                              });
                            } catch (error) {
                              window.scrollTo({ top: 0 });
                              setError((error && error.message) || 'An unexpected error occurred');
                            }
                            setIsLoading(false);
                          }}
                        >
                          Accept
                        </Button>
                      </Col>
                      <Col sm="6">
                        <Label className="d-block m-t-30">
                          <span className="order bg-danger">3.b</span>Reject the Document. Your
                          point of contact will be notified about rejection automatically.
                        </Label>
                        <Button
                          color="danger"
                          className="m-l-10"
                          disabled={isLoading || !pdfBlob}
                          onClick={async e => {
                            setIsLoading(true);
                            setSuccess(false);
                            try {
                              setError(false);

                              const urlParams = new URLSearchParams(props?.location?.search);
                              const protectionKey = urlParams.get('protectionKey');
                              const documentKey = props?.match?.params?.documentKey;
                              await rejectDocument(documentKey, protectionKey, error => {
                                if (error) {
                                  setError(error?.message || 'Could not accept document');
                                } else {
                                  setSuccess(true);
                                  setThankYou(true);
                                }
                                setIsLoading(false);
                              });
                            } catch (error) {
                              window.scrollTo({ top: 0 });
                              setError((error && error.message) || 'An unexpected error occurred');
                            }
                            setIsLoading(false);
                          }}
                        >
                          Reject
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" className="m-t-20">
                        {success && (
                          <UncontrolledAlert color="info">
                            <span role="img" aria-label="no entry" className="font-18 m-r-5">
                              👍
                            </span>
                            Document was processed successfully!
                          </UncontrolledAlert>
                        )}
                        {error && (
                          <Alert color="danger">
                            <span role="img" aria-label="see no evil" className="font-18 m-r-5">
                              🙈
                            </span>
                            {error}
                          </Alert>
                        )}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </div>
            )}
            <ReactTooltip ref={reactTooltip} />
          </div>
          <Footer />
        </div>
      </Container>
    </div>
  );
};

export default DocumentReview;
