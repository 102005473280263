import React, { useState, useCallback, useEffect } from 'react';
import Switch from 'react-switch';

import {
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  UncontrolledAlert,
  Alert,
} from 'reactstrap';
import axios from 'axios';

const GlobalAlertSettings = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertVisible, setAlertVisibility] = useState(false);

  useEffect(() => {
    const globalAlertVisible = (settings && settings.globalAlertVisible) || false;
    const globalAlertMessage = (settings && settings.globalAlertMessage) || '';

    setAlertVisibility(globalAlertVisible);
    setAlertMessage(globalAlertMessage);
  }, [settings]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: { globalAlertMessage: alertMessage, globalAlertVisible: alertVisible },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [alertMessage, alertVisible]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/alertbell.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Azure Logo"
                  />
                  Global Alert
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Global alert message is displayed for loggen in users{' '}
              <strong>directly on the Dashboard</strong>. You can setup the message and control its
              visibility.
              <div style={{ marginTop: '10px' }}>
                <Alert color="danger">
                  This is how your message will appear on users' dashboard.
                </Alert>
              </div>
            </p>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Global alert message
              </Label>
              <Col sm="8">
                <Input
                  id="alertMessageKey"
                  type="text"
                  value={alertMessage}
                  onChange={event => setAlertMessage(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Show this alert to users?
              </Label>
              <Col sm="8">
                <div className="d-flex align-items-center">
                  <Switch
                    onColor="#626ed4"
                    onChange={() => {
                      setAlertVisibility(!alertVisible);
                    }}
                    checked={alertVisible}
                  />
                  <span className="m-l-10">
                    You can control visibility of pre-set alert message with this switch.
                  </span>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="12" className="offset-2">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || !alertMessage.length}
                  onClick={onSubmit}
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default GlobalAlertSettings;
