import React, { useState, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { formatCurrency } from '../../../helpers/numbers';
import { removeSelectedTimesheet, clearSelectedTimesheets } from '../../../store/actions';
import TimesheetsBundleModal from './TimesheetsBundleModal';

import './TimesheetsBundleOverlay.scss';

/**
 * Rendered by TopBarOverlay when there are selectedTimesheets in the store
 */
function TimesheetsBundleOverlay({ timesheets, selectedTimesheets }) {
  const listRef = useRef(null);
  const dispatch = useDispatch();
  const [showBundleModal, setShowBundleModal] = useState(false);

  useEffect(() => {
    // Scroll the list to the bottom when new items are added
    if (listRef && listRef.current) listRef.current.scrollTo(null, listRef.current.clientHeight);
  }, [selectedTimesheets]);

  let total = 0;
  let currency;
  let timesheetIds = [];

  const listItems = Array.from(selectedTimesheets).map(item => {
    total += parseFloat(item.financials.totalAmount || 0);
    currency = item.currency;
    timesheetIds.push(item.id);

    return (
      <li key={item.id} className="list-item text-white">
        <i className="list-item-icon ion ion-ios-paper text-success d-none d-sm-inline" />
        <div className="list-item-title">
          <div>{item.id}</div>
          <div className="amount d-none d-sm-block">
            {formatCurrency(item.financials.totalAmount)}
          </div>
        </div>
        <button
          className="list-item-btn btn"
          onClick={() => dispatch(removeSelectedTimesheet(item))}
        >
          <i className="mdi mdi-close font-20 text-white" />
        </button>
      </li>
    );
  });

  total = formatCurrency(total, currency);

  return (
    <>
      <div className="timesheets-bundle-overlay">
        <div className="left">
          <h6>
            <span>Selected Timesheets</span>{' '}
            <span className="d-none d-sm-inline">Amount: {total}</span>
          </h6>
          <ul className="list" ref={listRef}>
            {listItems}
          </ul>
        </div>
        <div className="right">
          <button
            title={listItems.length < 2 ? 'Please select more than one timesheet' : ''}
            disabled={listItems.length < 2}
            className="btn btn-primary m-b-10"
            onClick={() => {
              setShowBundleModal(true);
            }}
          >
            Request Payment
          </button>
          <button className="btn btn-secondary" onClick={() => dispatch(clearSelectedTimesheets())}>
            Cancel
          </button>
        </div>
      </div>

      <TimesheetsBundleModal
        open={showBundleModal}
        onClickClose={() => setShowBundleModal(false)}
        timesheets={timesheets}
        selectedTimesheets={selectedTimesheets}
      />
    </>
  );
}

export default TimesheetsBundleOverlay;
