import React, { useRef, useEffect, useState, useCallback } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import {
  Col,
  FormGroup,
  Label,
  Row,
  Button,
  Modal,
  ModalBody,
  Alert,
  Table,
  Form,
  Badge,
} from 'reactstrap';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';

import { formatCurrency } from '../../../helpers/numbers';
import { fetchProjects } from '../../../store/actions';
import { fetchCompanies } from '../../../store/actions';
import { renderField } from '../../Team/validation';
import ProjectPrices, { PRICE_ID_PREFIX } from './ProjectPrices';
import { projectStatusMapping } from './ProjectStatusMapping';

function required(value) {
  if (!value) return 'Required';
  else return undefined;
}

function number(value) {
  if (value && isNaN(value)) return 'Should be a valid number';
  else if (value && value % 1 !== 0) return 'Should be an integer';
  else return undefined;
}

const NewProjectModal = ({
  isOpen,
  hide,
  handleSubmit,
  submitting,
  change,
  pristine,
  invalid,
  destroy,
  initialValues,
}) => {
  const firstInput = useRef(null);
  const [error, setError] = useState(null);
  const [workers, setWorkers] = useState(null);
  const [showActiveOnly, setShowActiveOnly] = useState(true);
  const dispatch = useDispatch();
  const form = useSelector(state => state.form.new_project);
  const isAzure = form?.values?.source === 'azure';
  const { data } = useSelector(state => state.Admin);

  const onSubmit = useCallback(
    async values => {
      try {
        if (!values || !Object.keys(values).length) return;
        values.invoices = Array.isArray(values.invoices)
          ? values.invoices
          : values.invoices
              ?.split?.(',')
              .map(i => i?.trim?.() || '')
              .filter(Boolean) || values.invoices;
        setError(null);
        const response = await axios('/api/projects', {
          method: initialValues.id ? 'PUT' : 'POST',
          data: values,
        });

        if (response && !response.data.error) {
          dispatch(fetchProjects());
          hide();
          destroy();
        }
      } catch (error) {
        const data = error && error.response && error.response.data;
        setError(data ? data.message : error ? error.message : 'Something went wrong...');
      }
    },
    [destroy, dispatch, hide, initialValues.id]
  );

  const updateBudget = useCallback(() => {
    const workersForm = form?.values?.workers;

    if (workersForm) {
      const budget = Object.keys(workersForm).reduce((acc, workerId) => {
        const workerFormValues = workersForm[workerId];

        if (workerFormValues) {
          return acc + workersForm[workerId].price * workersForm[workerId].maximumEffort;
        }

        return acc;
      }, 0);

      change('budget.value', budget);
    }
  }, [change, form?.values?.workers]);

  const updateCost = useCallback(() => {
    const workersForm = form?.values?.workers;

    if (workers && workersForm) {
      const cost = Object.keys(workersForm).reduce((acc, workerId) => {
        const workerFormValues = workersForm[workerId];

        if (workerFormValues) {
          const worker = workers.find(w => w.userID === workerId);
          const rate = worker?.rates?.find(r => r.rateID === workerFormValues.rateID)?.BHR || 0;

          return acc + rate * workerFormValues.maximumEffort;
        }

        return acc;
      }, 0);

      change('cost.value', cost);
    }
  }, [change, form?.values?.workers, workers]);

  // Focus first input when the modal opens
  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        firstInput.current?.focus?.();
      }, 100);
    }
  }, [isOpen]);

  // Fetch workers on mount
  useEffect(() => {
    (async function() {
      try {
        const response = await axios('/api/projects/workers');

        if (response && !response.data.error) {
          setWorkers(response.data.workers);
        }
      } catch (error) {
        const data = error?.response?.data;
        setError(data ? data.message : "Couldn't get worker profiles...");
      }
    })();
  }, []);

  // Update epic value
  useEffect(() => {
    if (!isAzure) change('epic', '');
  }, [isAzure]); // eslint-disable-line

  useEffect(() => {
    if (!data.companies) dispatch(fetchCompanies());
  }, []); // eslint-disable-line

  const companies = (data?.companies && Object.values(data.companies)) || [];

  return (
    <Modal centered isOpen={isOpen} toggle={hide} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {initialValues.id ? 'Edit Project' : 'Create New Project'}
        </h5>
        <button type="button" onClick={hide} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <Form onSubmit={handleSubmit(onSubmit)}>
          {error && (
            <Alert color="danger">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                ⛔️
              </span>
              {error}
            </Alert>
          )}
          <FormGroup row>
            <Label sm={3}>Project ID</Label>
            <Col sm={4}>
              <Field
                component={renderField}
                type="text"
                name="id"
                validate={[required]}
                disabled={!!initialValues.id}
              />
            </Col>
            <Col sm={2}>
              <Field component={renderField} type="color" name="uiColor" />
            </Col>
            <Col sm={3}>
              <Field
                component="select"
                className="form-control"
                id="status"
                name="status"
                validate={[required]}
              >
                <option>Please select</option>
                {projectStatusMapping &&
                  Object.values(projectStatusMapping).map((status, i) => (
                    <option key={i} value={status.title} disabled={status.hidden}>
                      {status.title}
                    </option>
                  ))}
              </Field>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Project Title</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="title" validate={[required]} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Client ID</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="client.id" validate={[required]} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Client Name</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="client.name" validate={[required]} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Client Contact Name</Label>
            <Col sm={9}>
              <Field
                component={renderField}
                label="Name of client's employee responsible for this project"
                type="text"
                name="client.accountManagerName"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Client Contact Email</Label>
            <Col sm={9}>
              <Field
                component={renderField}
                type="email"
                label="Email of client's point of contact for this project"
                name="client.accountManagerEmail"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Billing Company</Label>
            <Col sm={9}>
              <Field
                component="select"
                className="form-control"
                id="billingCompanyID"
                name="billingCompanyID"
                validate={[required]}
              >
                <option>Please select</option>
                {companies &&
                  Object.values(companies)
                    .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                    .map(company => (
                      <option key={company.id} value={company.id}>
                        {company.legalName}
                      </option>
                    ))}
              </Field>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Start Date</Label>
            <Col sm={3}>
              <Field
                component={renderField}
                type="date"
                name="startDate"
                parse={value => value && new Date(value).toISOString()}
                format={value => (value ? new Date(value).toISOString().substr(0, 10) : '')}
                validate={[required]}
              />
            </Col>
            <Label sm={3}>Delivery Date</Label>
            <Col sm={3}>
              <Field
                component={renderField}
                type="date"
                name="deliveryDate"
                parse={value => value && new Date(value).toISOString()}
                format={value => (value ? new Date(value).toISOString().substr(0, 10) : '')}
                validate={[required]}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Cost</Label>
            <Col sm={3}>
              <Field
                component={renderField}
                type="number"
                name="cost.value"
                parse={value => parseFloat(value) || undefined}
                validate={[required]}
              />
            </Col>
            <Col sm={3}>
              <Field
                component={renderField}
                type="text"
                className="form-control"
                name="cost.currency"
                validate={[required]}
              />
            </Col>
            <Col sm={3}>
              <Button
                block
                data-tip="Cost is calculated by aggregating each worker's rate multiplied by their maximum effort for this project."
                data-type="info"
                data-effect="solid"
                data-delay-show="250"
                className="btn-dark"
                onClick={updateCost}
              >
                Update
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Budgeted Price</Label>
            <Col sm={3}>
              <Field
                component={renderField}
                type="number"
                className="form-control"
                name="budget.value"
                parse={value => parseFloat(value) || undefined}
                validate={[required]}
              />
            </Col>
            <Col sm={3}>
              <Field
                component={renderField}
                type="text"
                className="form-control"
                name="budget.currency"
                validate={[required]}
              />
            </Col>
            <Col sm={3}>
              <Button
                block
                data-tip="Budget is calculated by aggregating each worker's price multiplied by their maximum effort for this project."
                data-type="info"
                data-effect="solid"
                data-delay-show="250"
                className="btn-dark"
                onClick={updateBudget}
              >
                Update
              </Button>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Purchase Order / Quote No.</Label>
            <Col sm={9}>
              <Field
                component={renderField}
                type="text"
                name="purchaseOrder"
                label="Purchase Order Number or Quote Number"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Invoices</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="invoices" label="Invoice(s)" />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Ticket Source</Label>
            <Col sm={9}>
              <Field component="select" className="form-control" id="source" name="source">
                <option value="azure">Microsoft Azure DevOps</option>
                <option value="zohoprojects">Zoho Projects</option>
                <option value="msplanner">Microsoft Planner</option>
                <option value="freshdesk" disabled>
                  Freshdesk
                </option>
                <option value="asana" disabled>
                  Asana
                </option>
              </Field>
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>External ID</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="externalId" validate={[required]} />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Epic</Label>
            <Col sm={9}>
              <Field
                component={renderField}
                type="text"
                name="epic"
                disabled={!isAzure}
                validate={[number]}
                parse={value => (/\d/.test(value) ? parseInt(value) : null)}
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3}>Day Pass: Full-time time tracking only</Label>
            <Col sm={9} className="d-flex align-items-center">
              <Field
                component={({ input: { onChange, checked } }) => (
                  <Switch onColor="#626ed4" onChange={onChange} checked={checked} />
                )}
                className="form-control"
                name="dayPassAllowed"
                type="checkbox"
              />
            </Col>
          </FormGroup>

          <ProjectPrices />

          <FormGroup>
            <FormGroup row>
              <Label sm={8}>
                <h4>Colleagues</h4>
              </Label>
              <Label sm={2} className="label-class">
                Assigned Only
              </Label>
              <Col sm={2} className="d-flex align-items-center">
                <Switch
                  onColor="#626ed4"
                  onChange={() => {
                    setShowActiveOnly(!showActiveOnly);
                  }}
                  checked={showActiveOnly}
                />
              </Col>
            </FormGroup>
            <Table responsive striped className="table-sm">
              <thead>
                <tr>
                  <th></th>
                  <th>Name</th>
                  <th>Job Title</th>
                  <th style={{ minWidth: '125px' }}>Rate</th>
                  <th style={{ minWidth: '125px', width: '140px' }}>Price</th>
                  <th style={{ minWidth: '80px', width: '80px' }}>Max Effort</th>
                  <th>Cost</th>
                </tr>
              </thead>
              <tbody>
                {workers &&
                  workers.map((worker, index) => (
                    <WorkerItem
                      worker={worker}
                      index={index}
                      key={worker.userID}
                      change={change}
                      initialData={initialValues?.workers?.[worker.userID]}
                      prices={form?.values?.prices}
                      showActiveOnly={showActiveOnly}
                    />
                  ))}
              </tbody>
            </Table>
          </FormGroup>

          <Row className="m-t-20">
            <Col sm={12} className="text-right">
              <Button color="primary" type="submit" disabled={submitting || pristine || invalid}>
                {submitting ? 'Submitting...' : initialValues.id ? 'Update' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>

      <ReactTooltip />
    </Modal>
  );
};

const WorkerItem = ({ worker, index, change, initialData, prices = [], showActiveOnly }) => {
  const [isSelected, setIsSelected] = useState(false);
  const defaultRate = worker && worker.rates && worker.rates[0];
  const [rateID, setRateID] = useState(defaultRate && defaultRate.rateID);
  const [maxDailyHours, setMaxDailyHours] = useState(defaultRate && defaultRate.maxDailyHours);
  const [price, setPrice] = useState(0);
  const [priceID, setPriceID] = useState();
  const [maximumEffort, setMaximumEffort] = useState(0);
  const [cost, setCost] = useState(defaultRate && formatCurrency(0, defaultRate.currency));
  const disabled = !worker || !worker.rates || !worker.rates.length;

  useEffect(() => {
    if (initialData) {
      setIsSelected(true);
      setRateID(initialData.rateID);
      setMaxDailyHours(initialData.maxDailyHours);
      setMaximumEffort(initialData.maximumEffort);
      setPriceID(initialData.priceID);
      setPrice(
        initialData.priceID
          ? prices.find(p => p.id === initialData.priceID)?.amount || 0
          : initialData.price
      );

      const value = calculateCost(worker, initialData.rateID, initialData.maximumEffort);
      value && setCost(value);
    }
  }, [initialData, worker, prices]);

  const updateValues = useCallback(
    force => {
      if (isSelected || force) {
        const values = {
          rateID,
          maxDailyHours: parseFloat(maxDailyHours) || 0,
          maximumEffort: parseFloat(maximumEffort) || 0,
          price,
          priceID,
        };
        change(`workers[${worker.userID}]`, values);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSelected, maxDailyHours, maximumEffort, price, priceID, rateID, worker.userID]
  );

  const toggleWorker = useCallback(() => {
    if (!isSelected) {
      updateValues(true);
    } else {
      change(`workers[${worker.userID}]`, undefined);
    }
    setIsSelected(!isSelected);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSelected, updateValues, worker.userID]);

  useEffect(() => {
    updateValues();
  }, [rateID, maximumEffort, price, priceID]); // eslint-disable-line

  return (
    <tr key={index} hidden={!isSelected && showActiveOnly}>
      <td>
        <input
          type="checkbox"
          checked={isSelected}
          onChange={toggleWorker}
          disabled={disabled || (!isSelected && !worker.isActive)}
        />
      </td>
      <td>
        {worker.name}{' '}
        <Badge
          tag="div"
          color={
            worker.isActive
              ? isSelected
                ? 'success'
                : 'secondary'
              : isSelected
              ? 'danger'
              : 'warning'
          }
          pill
          className="align-items-center"
        >
          {worker.userID}
        </Badge>
      </td>
      <td disabled={!worker.isActive}>{worker.jobTitle}</td>
      <td>
        {disabled ? (
          <Badge color="danger">No rates</Badge>
        ) : (
          <select
            className="form-control"
            value={rateID}
            disabled={!isSelected || !worker.isActive}
            onChange={e => {
              const rateID = e.target.value;
              setRateID(rateID);
              const matchingRates = worker.rates.filter(rate => rate.rateID === rateID);
              const selectedRate = matchingRates && matchingRates.length && matchingRates[0];
              setMaxDailyHours(selectedRate?.maxDailyHours || 0);

              const value = calculateCost(worker, rateID, maximumEffort);
              value && setCost(value);
            }}
          >
            {worker.rates &&
              worker.rates.map(rate => (
                <option key={rate.rateID} value={rate.rateID}>
                  {formatCurrency(rate.BHR, rate.currency)}, daily max {rate.maxDailyHours} h
                </option>
              ))}
          </select>
        )}
      </td>
      <td>
        <select
          value={priceID || price}
          className="form-control"
          disabled={!isSelected}
          onChange={e => {
            const price = e.target.value;
            const isPriceID = price.startsWith(PRICE_ID_PREFIX);

            if (isPriceID) {
              setPriceID(price);
              setPrice(prices.find(p => p.id === price)?.amount || 0);
            } else {
              setPriceID(undefined);
              setPrice(parseFloat(price));
            }
          }}
        >
          <option key="zero" value={0}>
            0
          </option>
          {prices
            .filter(p => p.amount)
            .map((p, i) => (
              <option key={i} value={p.id || p.amount}>
                {formatCurrency(p.amount, p.currency)} ({p.title})
              </option>
            ))}
        </select>
      </td>
      <td>
        <input
          className="form-control"
          type="number"
          step={1}
          value={maximumEffort}
          disabled={disabled || !isSelected}
          onChange={e => {
            const maximumEffort = e.target.value;
            setMaximumEffort(maximumEffort);

            const value = calculateCost(worker, rateID, maximumEffort);
            value && setCost(value);
          }}
        />
      </td>
      <td>{cost}</td>
    </tr>
  );
};

function calculateCost(worker, rateID, maximumEffort) {
  const rate = worker && worker.rates.find(r => r.rateID === rateID);

  if (rate) return formatCurrency((rate.BHR || 0) * maximumEffort, rate.currency);

  return formatCurrency(0);
}

export default reduxForm({
  form: 'new_project',
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
})(NewProjectModal);
