import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const SideNav = () => {
  const timesheets = useSelector(state => state.Timesheets);
  const timesheetEntries = timesheets && timesheets.data && timesheets.data.entries;
  const unsubmittedEntries =
    timesheetEntries && timesheetEntries.filter(entry => !entry.financials.subcontractorInvoiceURL);

  const userID = useSelector(state => state.Auth.userID);
  const profiles = useSelector(state => state.Team.profiles);
  const profile = profiles && profiles[userID];

  const admin = useSelector(state => state.Admin);
  let unpaidTimesheets = 0;
  if (admin.data && admin.data.timesheets && admin.data.timesheets.paymentRequests) {
    unpaidTimesheets = admin.data.timesheets.paymentRequests.length;
  }

  const hasPendingAvailability = useSelector(state => state.Layout.hasPendingAvailability);

  return (
    <div id="sidebar-menu">
      <ul className="metismenu" id="menu">
        <li className="menu-title">Main</li>
        <li>
          <Link to="/dashboard" className="waves-effect">
            <i className="ti-home"></i>
            <span>Dashboard</span>
          </Link>
        </li>
        <li>
          <Link to="/team" className="waves-effect">
            <i className="ti-user"></i>
            <span>Team</span>
          </Link>
        </li>
        {!!(profile && profile.type !== 'intern') && (
          <li>
            <Link to="/timesheets" className="waves-effect">
              <i className="ti-receipt"></i>
              {unsubmittedEntries && unsubmittedEntries.length > 0 && (
                <span className="badge badge-danger badge-pill float-right">
                  {unsubmittedEntries.length}
                </span>
              )}
              <span>Timesheets</span>
            </Link>
          </li>
        )}
        <li>
          <Link to="/timetracker" className="waves-effect d-flex align-items-center">
            <i className="ti-timer"></i>
            <span className="flex-grow-1">Timetracker</span>
          </Link>
        </li>
        <li>
          <Link to="/availability" className="waves-effect">
            <i className="ti-calendar"></i>
            {hasPendingAvailability && (
              <span className="badge badge-danger badge-pill float-right">!</span>
            )}
            <span>Availability</span>
          </Link>
        </li>
        {admin.isAdminUser && (
          <>
            <li className="menu-title">Management</li>
            <li>
              <Link to="/admin/availability" className="waves-effect">
                <i className="ti-calendar"></i>
                <span>Team Availability</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/teamupdate" className="waves-effect d-flex align-items-center">
                <i className="ti-announcement"></i>
                <span className="flex-grow-1">Team Update</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/timetrackers" className="waves-effect d-flex align-items-center">
                <i className="ti-time"></i>
                <span className="flex-grow-1">Timetrackers</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/timesheets" className="waves-effect">
                <i className="ti-layout-width-default"></i>
                {unpaidTimesheets > 0 && (
                  <span
                    className="badge badge-info badge-pill float-right"
                    title={`${unpaidTimesheets} unpaid payment requests`}
                  >
                    {unpaidTimesheets}
                  </span>
                )}
                <span>Timesheets</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/banking" className="waves-effect">
                <i className="ti-money"></i>
                <span>Banking</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/projects" className="waves-effect">
                <i className="ti-rocket"></i>
                <span>Projects</span>
              </Link>
            </li>
            <li className="menu-title">Sales and Paperwork</li>
            <li>
              <Link to="/admin/quotermaster" className="waves-effect" enabled="false">
                <i className="ti-ruler-pencil"></i>
                <span>Quote Master CPQ</span>
              </Link>
            </li>
            <li className="menu-title">Analytics</li>
            <li>
              <Link to="/admin/reporting" className="waves-effect d-flex align-items-center">
                <i className="ti-pie-chart"></i>
                <span className="flex-grow-1">Reports</span>
              </Link>
            </li>
            {/* <li>
              <Link to="/admin/zoho-import" className="waves-effect">
                <i className="ti-import"></i>
                <span>Import Zoho Timesheets</span>
              </Link>
            </li> */}
            <li className="menu-title">Settings</li>
            <li>
              <Link to="/admin/companies" className="waves-effect">
                <i className="ti-briefcase"></i>
                <span>Companies</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/settings/general" className="waves-effect d-flex align-items-center">
                <i className="ti-settings"></i>
                <span className="flex-grow-1">Settings</span>
              </Link>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default SideNav;
