import React, { useState } from 'react';
import { Alert, Modal, ModalBody, Button, Row, Col, Label } from 'reactstrap';

const DecisionModal = ({
  title,
  message,
  destructive,
  isOpen,
  didTapCloseButton,
  didTapConfirmButton,
}) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [reason, setReason] = useState('');

  async function action(reasonMessage) {
    try {
      const response = await didTapConfirmButton(reasonMessage);
      setSuccess((response && response.data && response.data.message) || '✅ Success!');
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;
      setError((data && data.message) || '🙈 There was a problem with your request');
    }
  }

  return (
    <Modal centered isOpen={isOpen} toggle={didTapCloseButton}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">{title}</h5>
        <button type="button" onClick={didTapCloseButton} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        {success && (
          <Alert color="info">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              👍
            </span>
            {success}
          </Alert>
        )}
        {error && (
          <Alert color="danger">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ⛔️
            </span>
            {error}
          </Alert>
        )}
        {!success && !error && (
          <>
            <p>{message}</p>
            <Row>
              <Col xs="3">
                <Label for="reason">Enter Reason (optional)</Label>
              </Col>
              <Col xs="9">
                <input
                  id="reason"
                  type="text"
                  placeholder="e.g. Operational Reasons"
                  className="form-control"
                  onChange={event => setReason(event.target.value)}
                ></input>
              </Col>
            </Row>
            <Row>
              <Col xs="6">
                <Button
                  block
                  color={destructive ? 'danger' : 'info'}
                  disabled={updating}
                  onClick={async () => {
                    setSuccess(null);
                    setError(null);
                    setUpdating(true);
                    await action(reason);
                    setUpdating(false);
                  }}
                >
                  {updating ? 'Wait...' : 'Confirm'}
                </Button>
              </Col>
              <Col xs="6">
                <Button block color="secondary" onClick={didTapCloseButton} disabled={updating}>
                  Cancel
                </Button>
              </Col>
            </Row>
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default DecisionModal;
