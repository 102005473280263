import React, { useCallback, useState, useRef, useEffect } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardFooter,
  CardBody,
  Table,
  Alert,
  Button,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import Switch from 'react-switch';

import ReactTooltip from 'react-tooltip';
import { useSelector, useDispatch } from 'react-redux';
import { removeSelectedTimesheet, addSelectedTimesheet } from '../../store/actions';
import { formatCurrency } from '../../helpers/numbers';
import TimesheetUpload from './TimesheetUpload';

import { statusMapping, PAYMENT_REQUEST_STATUS } from '../TimesheetStatusMapping';

import './TimesheetItem.scss';

const TimesheetItem = ({ timesheet, hasBatchPayment, isAgent }) => {
  const [showPayment, setShowPayment] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const dispatch = useDispatch();

  const reactTooltip = useRef(null);

  let selectedItem;
  let isSelected;
  const id = timesheet.id;
  const hasUploadedInvoice = timesheet.financials.subcontractorInvoiceURL;
  const selectedTimesheets = useSelector(state => state.Timesheets.selected);
  const companyName = timesheet.financials?.companyDisplayName;

  if (!hasUploadedInvoice) {
    selectedItem = Array.from(selectedTimesheets).find(i => i.id === id);
    isSelected = !!selectedItem;
  }

  let fromDate = timesheet.fromDate;
  let toDate = timesheet.toDate;
  try {
    fromDate = new Date(fromDate).toLocaleDateString();
    toDate = new Date(toDate).toLocaleDateString();
  } catch {}

  const [conflict, setConflict] = useState(null);

  const handleSwitchOnChange = useCallback(() => {
    // Check whether companies for different timesheets are the same
    // as the one that the user wants to add now.
    const selectedTimesheetsByCompany = {
      [timesheet.financials?.companyID]: timesheet,
    };
    let preSelectedCompanyName;
    for (let t of selectedTimesheets) {
      preSelectedCompanyName = t.financials?.companyDisplayName;
      selectedTimesheetsByCompany[t.financials.companyID] = t;
    }

    if (Object.keys(selectedTimesheetsByCompany).length > 1) {
      const name1 = timesheet.financials.companyDisplayName;
      const name2 = preSelectedCompanyName;

      setConflict(
        <div>
          <p>
            This timesheet was issued by {name1 ? <b>{name1}</b> : 'a different company'}, while the
            rest of the timesheets you're trying to bundle are issued by{' '}
            {name2 ? <b>{name2}</b> : 'a different company'}. You can only bundle together
            timesheets for the same company.
          </p>
          <p>
            Please create a separate bundle for{' '}
            <b>
              <em>{timesheet.id}</em>
            </b>
            .
          </p>
        </div>
      );

      return;
    }

    setShowPayment(false);
    dispatch(isSelected ? removeSelectedTimesheet(timesheet) : addSelectedTimesheet(timesheet));
  }, [dispatch, isSelected, selectedTimesheets, timesheet]);

  const closeModal = useCallback(() => {
    setConflict(null);
  }, []);

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  return (
    <>
      <Card className={`timesheet-item${isSelected ? ' selected' : ''}`} key={id}>
        <CardHeader className="d-flex align-items-center">
          <h6>
            Timesheet ID: <em>{id}</em>
          </h6>
          {companyName && (
            <Badge color="success" className="m-r-5 m-l-15 align-text-top" pill>
              {companyName}
            </Badge>
          )}
        </CardHeader>

        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr>
                <th>Work Item</th>
                <th>From</th>
                <th>To</th>
                <th>Time, HH:mm</th>
                {!timesheet?.useTime && <th>Decimal, h</th>}
                {!isAgent && <th>Amount</th>}
              </tr>
            </thead>
            <tbody>
              {timesheet.lineItems.map((lineItem, index) => {
                let date = lineItem.workDate;
                try {
                  date = new Date(date).toLocaleDateString();
                } catch {}

                return (
                  <tr key={index}>
                    <td>
                      {lineItem.taskId && (
                        <Badge color="primary" className="m-r-5 align-text-top" pill>
                          #{lineItem.taskId}
                        </Badge>
                      )}
                      {lineItem.title}
                    </td>
                    <td>{date}</td>
                    <td>{date}</td>
                    <td className={timesheet?.useTime ? '' : 'greyedOut'}>
                      {lineItem.totalDisplayHours}
                    </td>
                    {!timesheet?.useTime && <td>{lineItem.totalHours}</td>}
                    {!isAgent && (
                      <td>
                        {lineItem.totalAmount &&
                          formatCurrency(lineItem.totalAmount, lineItem.currency)}
                      </td>
                    )}
                  </tr>
                );
              })}

              <tr>
                <td>
                  <h5>Total</h5>
                </td>
                <td>{fromDate}</td>
                <td>{toDate}</td>
                <td className={timesheet?.useTime ? '' : 'greyedOut'}>
                  {timesheet.financials.totalDisplayHours}
                  {!timesheet?.useTime && (
                    <span
                      role="img"
                      aria-label="questions mark"
                      className="font-14 m-l-10"
                      data-tip="This timesheet uses decimal time. This value is a rounded estimate, without seconds."
                      data-effect="solid"
                      data-delay-show="200"
                    >
                      ❓
                    </span>
                  )}
                </td>
                {!timesheet?.useTime && <td>{timesheet.financials.totalHours}</td>}
                {!isAgent && (
                  <td>
                    {formatCurrency(
                      timesheet.financials.totalAmount,
                      timesheet.financials.currency
                    )}
                  </td>
                )}
              </tr>
            </tbody>
          </Table>
        </CardBody>

        {!isAgent && (
          <CardFooter className="p-0">
            <Container fluid className="request-payment-row">
              <Row>
                <Col sm="12">
                  {uploadSuccess && (
                    <Alert color="primary">
                      <span role="img" aria-label="no entry" className="font-18 m-r-5">
                        👍
                      </span>
                      {uploadSuccess}
                    </Alert>
                  )}
                </Col>
              </Row>
              <Row>
                <Col
                  sm={!hasUploadedInvoice && hasBatchPayment ? '6' : '9'}
                  className="d-flex align-items-center m-b-10 m-sm-0"
                >
                  <a href={`mailto:accounts@londonappworks.co.uk?subject=Timesheet Issue - ${id}`}>
                    Need help with this timesheet?
                  </a>
                </Col>

                {!hasUploadedInvoice && hasBatchPayment && (
                  <Col sm="3" className="d-flex align-items-center m-b-10 m-sm-0">
                    <Switch
                      onColor="#626ed4"
                      onChange={handleSwitchOnChange}
                      checked={isSelected}
                    />
                    <span className="m-l-10">Request Batch Payment</span>
                  </Col>
                )}

                <Col sm="3 d-flex align-items-center">
                  {timesheet.paymentRequest &&
                  timesheet.paymentRequest.status !== PAYMENT_REQUEST_STATUS.EXPECTING_INVOICE ? (
                    <Button color="dark" disabled block>
                      {statusMapping[timesheet.paymentRequest.status.toUpperCase()]?.title ?? 'N/A'}
                    </Button>
                  ) : (
                    <Button
                      block
                      color={!uploadSuccess ? 'primary' : 'dark'}
                      onClick={() => {
                        if (!uploadSuccess) setShowPayment(!showPayment);
                      }}
                      disabled={!!uploadSuccess || isSelected}
                    >
                      {!uploadSuccess ? 'Request Payment' : 'Pending ...'}
                    </Button>
                  )}
                </Col>
              </Row>
            </Container>

            {showPayment && (
              <TimesheetUpload
                hasDownloadButton
                timesheetIds={[timesheet.id]}
                onSuccess={message => {
                  setUploadSuccess(message);
                  setShowPayment(false);
                }}
              />
            )}
          </CardFooter>
        )}
      </Card>

      <Modal isOpen={!!conflict} centered toggle={closeModal}>
        <ModalHeader toggle={closeModal}>Timesheet can't be added</ModalHeader>
        <ModalBody>{conflict}</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      <ReactTooltip ref={reactTooltip} />
    </>
  );
};

export default TimesheetItem;
