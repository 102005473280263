import React, { useEffect } from 'react';
import { Container, Row, Col, Alert } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchVirtualOffice, fetchDashboardCards } from '../../store/actions';
import VirtualOfficeTable from './VirtualOfficeTable';
import DashboardCard from './DashboardCard';

const Dashboard = () => {
  const dashboard = useSelector(state => state.Dashboard);
  const team = useSelector(state => state.Team);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchVirtualOffice());
    dispatch(fetchDashboardCards());

    const timeout = window.setInterval(() => {
      dispatch(fetchVirtualOffice());
      dispatch(fetchDashboardCards());
    }, 60000);

    return () => window.clearInterval(timeout);
  }, [dispatch]);

  const cards = dashboard.data && dashboard.data.freshdeskCards;

  return (
    <Container fluid>
      <div className="sticky-title">
        <Row className="align-items-center">
          <Col xs={12}>
            <h5 className="page-title">Dashboard</h5>
            {dashboard?.data?.alert && (
              <Alert color="danger">
                <span role="img" aria-label="alert">
                  ⚠️
                </span>{' '}
                {dashboard.data.alert}
              </Alert>
            )}
          </Col>
        </Row>
      </div>

      <Row>{cards && cards.map((entry, index) => <DashboardCard item={entry} key={index} />)}</Row>

      <VirtualOfficeTable dashboard={dashboard} profiles={team.profiles} />
    </Container>
  );
};

export default Dashboard;
