import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Container, Row, Col, Label, Button, UncontrolledAlert } from 'reactstrap';
import Axios from 'axios';

import BurndownTable from './BurndownTable';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import { fetchProjects } from '../../../store/admin/actions';

async function getReport(projectID) {
  try {
    const response = await Axios.get('/api/projects/burndown', {
      params: { projectID },
    });
    const data = response && response.data;
    if (data && !data.error) {
      return data.report;
    }
    throw (data && data.error) || 'Unknown error';
  } catch (error) {
    const data = error && error.response && error.response.data;
    throw Error((data && data.message) || error.message);
  }
}

const BurndownReport = () => {
  const [selectedProject, setSelectedProject] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [report, setReport] = useState([]);

  const admin = useSelector(state => state.Admin);
  const projects = admin.data && admin.data.projects;
  const dispatch = useDispatch();

  const fetchReport = useCallback(
    async function() {
      if (!selectedProject) {
        throw new Error('Please select a project.');
      }

      const report = await getReport(selectedProject);
      setReport(report);
    },
    [selectedProject]
  );

  if (!admin.isAdminUser) return <AccessDeniedPoster />;

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Generate Burndown Report</h4>
          </Col>
        </Row>
      </div>

      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          Report generated successfully!
        </UncontrolledAlert>
      )}

      {error && (
        <Alert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {error}
        </Alert>
      )}

      <Row>
        <Col sm="12">
          <Label for="worker" className="d-block">
            <span className="order bg-primary">1</span>Select a project
          </Label>
          <select
            id="project"
            className="form-control d-inline-block w-auto m-r-10 align-middle"
            value={selectedProject}
            onChange={e => setSelectedProject(e.target.value)}
          >
            <option value=""></option>
            {projects &&
              Object.values(projects)
                .sort((a, b) => (b.title < a.title ? 1 : -1))
                .map(project => {
                  return (
                    <option key={project.title} value={project.id}>
                      {project.id} - {project.title}
                    </option>
                  );
                })}
          </select>
          <Button color="primary" disabled={isLoading} onClick={() => dispatch(fetchProjects())}>
            Refresh List
          </Button>
        </Col>
      </Row>

      <Row className="m-t-30">
        <Col sm={12}>
          <Button
            color="primary"
            disabled={isLoading}
            onClick={async e => {
              setIsLoading(true);
              setSuccess(false);
              try {
                setError(false);
                await fetchReport();
              } catch (error) {
                window.scrollTo({ top: 0 });
                setError((error && error.message) || 'An unexpected error occurred');
              }
              setIsLoading(false);
            }}
          >
            Crunch Numbers, Now!
          </Button>
        </Col>
      </Row>

      <Label className="d-block m-t-30">
        <span className="order bg-primary">2</span>Review the report
      </Label>

      <BurndownTable data={report} isLoading={isLoading} />
    </Container>
  );
};

export default BurndownReport;
