import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Container,
  Button,
  Row,
  Col,
  Form,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Alert,
} from 'reactstrap';
import axios from 'axios';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';

const ImportZoho = () => {
  const admin = useSelector(state => state.Admin);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [recordId, setRecordId] = useState('');

  if (!admin.isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <Container fluid>
      <div className="page-title-box">
        <Row className="align-items-center m-b-30">
          <Col sm="12">
            <h4 className="page-title">Import Timesheets From Zoho</h4>
          </Col>
        </Row>
        {success && (
          <Alert color="primary">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              👍
            </span>
            {success}
          </Alert>
        )}

        {error && (
          <Alert color="danger">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ⛔️
            </span>
            {error}
          </Alert>
        )}

        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <p className="text-muted m-b-30">
                  Please open the relevant Zoho timesheet in your browser, and copy the last long
                  number following the word <em>recordId</em> from the address bar. Insert it in the
                  field below.
                </p>
                <Form>
                  <FormGroup row>
                    <Label htmlFor="example-text-input" sm="2">
                      Zoho RecordId
                    </Label>
                    <Col sm="10">
                      <Input
                        type="text"
                        value={recordId}
                        onChange={event => setRecordId(event.nativeEvent.target.value)}
                        disabled={isSubmitting}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Col sm="12" className="offset-2">
                      <Button
                        type="submit"
                        color="primary"
                        disabled={!recordId || isSubmitting}
                        onClick={async e => {
                          e.preventDefault();
                          setIsSubmitting(true);
                          setError(null);
                          setSuccess(null);

                          try {
                            const response = await axios({
                              method: 'get',
                              url: `/api/zoho/import-timesheet?timesheetID=${recordId}`,
                            });

                            const data = response && response.data;
                            if (data && !data.error) {
                              setSuccess(data.message);
                              setRecordId('');
                            }
                            setIsSubmitting(false);
                          } catch (error) {
                            setError(
                              error.response.data && typeof error.response.data.message === 'string'
                                ? error.response.data.message
                                : 'Something went wrong :('
                            );
                            setIsSubmitting(false);
                          }
                        }}
                      >
                        {!isSubmitting ? 'Submit' : 'Submitting...'}
                      </Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </Container>
  );
};

export default ImportZoho;
