import Axios from 'axios';
import firebase from 'firebase/app';

import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
} from './actionTypes';
import { fetchTimesheets } from '../timesheets/actions';
import { fetchAdminTimesheets, setAdminUser } from '../admin/actions';
import { fetchTeam } from '../team/actions';
import { fetchTimetrackers } from '../timetracker/actions';
import { checkAvailability } from '../layout/actions';

const signInAction = () => ({
  type: SIGN_IN,
});

const signInSuccessAction = profile => ({
  type: SIGN_IN_SUCCESS,
  profile,
});

export const signInFailureAction = error => ({
  type: SIGN_IN_FAILURE,
  error,
});

/**
 * Sign in
 *
 * @param {string} accessToken The provider access token. Not to be confused with Firebase's id token.
 */
export const signIn = accessToken => {
  return async dispatch => {
    dispatch(signInAction());

    try {
      const response = await Axios.post('/api/signin', { accessToken });

      if (response.status === 200) {
        const profile = response.data && response.data.profile;

        dispatch(signInSuccessAction(profile));
        dispatch(fetchTimesheets());
        dispatch(fetchTeam());
        dispatch(fetchTimetrackers());
        dispatch(checkAvailability());

        if (profile.isAdmin) {
          dispatch(setAdminUser(profile.isAdmin));
          dispatch(fetchAdminTimesheets());
        }
      } else throw Error(response);
    } catch (error) {
      const data = error && error.response && error.response.data;

      dispatch(signInFailureAction(data));
    }
  };
};

const signOutAction = () => ({
  type: SIGN_OUT,
});

const signOutSuccessAction = error => ({
  type: SIGN_OUT_SUCCESS,
  error,
});

const signOutFailureAction = error => ({
  type: SIGN_OUT_FAILURE,
  error,
});

export const signOut = originatingError => {
  return async dispatch => {
    dispatch(signOutAction());

    try {
      await firebase.auth().signOut();

      await Axios.post('/api/signout');

      dispatch(signOutSuccessAction(originatingError));
    } catch (error) {
      console.warn(error);
      dispatch(signOutFailureAction(originatingError || error));
    }
  };
};

export const appSignOut = () => {
  return async dispatch => {
    dispatch(signOutAction());
    dispatch(signOutSuccessAction());
  };
};
