import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import cn from 'classnames';

import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import Bookings from './Bookings';
import AvailabilityCalendar from './Calendar';

const Availability = () => {
  const admin = useSelector(state => state.Admin);
  const [activeTab, setActiveTab] = useState(0);

  if (!admin.isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <Container fluid className="create-timesheet">
      <div className="page-title-box">
        <Row className="align-items-center m-b-30">
          <Col sm="12">
            <h4 className="page-title">Team Availability</h4>
          </Col>
        </Row>
        <Nav tabs className="nav-tabs-custom">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 0 })}
              onClick={() => setActiveTab(0)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Calendar</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 1 })}
              onClick={() => setActiveTab(1)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Bookings</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab} style={{ marginTop: '15px' }}>
          <TabPane tabId={0}>
            <AvailabilityCalendar />
          </TabPane>

          <TabPane tabId={1}>
            <Bookings />
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};

export default Availability;
