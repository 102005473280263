import React from 'react';
import { Row, Col, Spinner, Alert } from 'reactstrap';
import TimesheetItem from './TimesheetItem';
import TimesheetBundle from './Bundle/TimesheetBundle';

import './TimesheetsTable.scss';

const TimesheetsTable = ({ timesheets, userType }) => {
  const { data, isLoading, error } = timesheets;

  const bundleItems = {};
  const timesheetItems = [];
  let invoiceNotUploadedCount = 0;

  if (data && data.entries) {
    data.entries.forEach(entry => {
      if (
        entry.paymentRequest &&
        entry.paymentRequest.timesheets &&
        entry.paymentRequest.timesheets.length > 1
      ) {
        if (!bundleItems[entry.paymentRequest.id]) bundleItems[entry.paymentRequest.id] = [];
        bundleItems[entry.paymentRequest.id].push(entry);
      } else {
        if (!entry.financials.subcontractorInvoiceURL) invoiceNotUploadedCount += 1;
        timesheetItems.push(entry);
      }
    });
  }

  return (
    <Row className="timesheets-table">
      <Col lg="12">
        {isLoading && (
          <div className={`text-center ${data ? 'spinner-with-data' : ''}`}>
            <Spinner color="primary" />
          </div>
        )}

        {!isLoading && error && (
          <Alert color="danger m-t-20">
            <span role="img" aria-label="see no evil" className="font-18 m-r-5">
              🙈
            </span>
            There was a problem trying to fetch this!
          </Alert>
        )}

        {!isLoading && (!data || !data.entries || !data.entries.length) && (
          <div>
            <p>You have no timesheets available. Come back later!</p>
            <img
              src={require('../../images/stewie.gif')}
              style={{ height: '300px' }}
              alt="Stewie Griffin"
            />
          </div>
        )}

        {Object.keys(bundleItems).map(key => {
          const bundleTimesheets = bundleItems[key];
          return <TimesheetBundle key={key} timesheets={bundleTimesheets} id={key} />;
        })}

        {timesheetItems.map(timesheet => (
          <TimesheetItem
            key={timesheet.id}
            timesheet={timesheet}
            hasBatchPayment={invoiceNotUploadedCount > 1}
            isAgent={userType === 'agent'}
          />
        ))}
      </Col>
    </Row>
  );
};

export default TimesheetsTable;
