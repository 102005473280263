import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import NotificationMenu from './Menus/notificationMenu';
import ProfileMenu from './Menus/profileMenu';
import SelectionTopBar from '../TopBarOverlay/TopBarOverlay';

import { toggleLargeLayout } from '../../store/actions';

import logoLight from '../../images/logo_text_150_dark.png';
import logoSmall from '../../images/logo_150.png';
import TimerBadge from '../TimerBadge';

import axios from 'axios';
import PhoneMenu from './Menus/phoneMenu';

const Topbar = ({ toggleLargeLayout, path }) => {
  const [radioSettings, setRadioSettings] = useState(null);
  const [phone, setPhone] = useState(null);

  const userID = useSelector(state => state.Auth.userID);
  const profiles = useSelector(state => state.Team.profiles);
  const profile = profiles && profiles[userID];

  useEffect(() => {
    (async function() {
      if (profile) {
        try {
          const res = await axios.get(`/api/phones/ext/${profile.voip}`);
          const phone = res && res.data && res.data;
          setPhone(phone);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, [profile]); // eslint-disable-line

  useEffect(() => {
    (async function() {
      if (profile) {
        try {
          const res = await axios.get('/api/radio');
          const settings = res && res.data && res.data.radioSettings;
          setRadioSettings(settings);
        } catch (error) {
          console.log(error);
        }
      }
    })();
  }, []); // eslint-disable-line

  return (
    <React.Fragment>
      <div className="topbar">
        <div className="topbar-left">
          <Link to="/" className="logo">
            <span>
              <img src={logoLight} alt="" height="50" />
            </span>
            <i>
              <img src={logoSmall} alt="" height="50" />
            </i>
          </Link>
          {process.env.REACT_APP_ENVIRONMENT === 'development' && (
            <div className="dev-badge">DEV</div>
          )}
        </div>

        <nav className="navbar-custom">
          <ul className="navbar-right list-inline float-right mb-0">
            <li className="list-inline-item d-none d-md-inline-block mr-1">
              {/* {(radioSettings && radioSettings.radioVisibility) && ( */}
              <div className="audioWrapper">
                <div
                  className="audioHolder"
                  hidden={!(radioSettings && radioSettings.radioVisibility)}
                >
                  {radioSettings && radioSettings.url && (
                    <audio controls autoPlay={radioSettings.autoplay}>
                      <source src={radioSettings.url} />
                      Your browser does not support the audio element.
                    </audio>
                  )}
                </div>
              </div>
              {/* )} */}
            </li>

            <li className="dropdown notification-list list-inline-item d-none d-md-inline-block mr-1">
              <Link
                onClick={toggleFullscreen}
                className="nav-link waves-effect"
                to="#"
                id="btn-fullscreen"
              >
                <i className="mdi mdi-fullscreen noti-icon"></i>
              </Link>
            </li>
            {profile?.voip && <PhoneMenu phone={phone} />}
            <NotificationMenu />
            <ProfileMenu />
          </ul>

          <ul className="list-inline menu-left mb-0">
            <li className="float-left">
              <button
                onClick={() => {
                  document.body.classList.toggle('enlarged');
                  toggleLargeLayout();
                }}
                className="button-menu-mobile open-left waves-effect"
              >
                <i className="mdi mdi-menu"></i>
              </button>
            </li>

            <li className="float-left position-relative">
              {!path.startsWith('/timetracker') && <TimerBadge />}
            </li>
          </ul>
        </nav>
        <SelectionTopBar />
      </div>
    </React.Fragment>
  );
};

const toggleFullscreen = () => {
  if (
    !document.fullscreenElement &&
    /* alternative standard method */ !document.mozFullScreenElement &&
    !document.webkitFullscreenElement
  ) {
    // current working methods
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen();
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen();
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
    }
  } else {
    if (document.cancelFullScreen) {
      document.cancelFullScreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.webkitCancelFullScreen) {
      document.webkitCancelFullScreen();
    }
  }
};

const mapDispatchToProps = { toggleLargeLayout };

export default connect(null, mapDispatchToProps)(Topbar);
