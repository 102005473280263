import React, { useRef, useEffect, useState } from 'react';
import { reduxForm, Field } from 'redux-form';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { Col, FormGroup, Label, Row, Button, Modal, ModalBody, Alert, Form } from 'reactstrap';
import Switch from 'react-switch';

import Address from './Address';
import { fetchCompanies } from '../../../store/actions';
import { renderField } from '../../Team/validation';

function required(value) {
  if (!value) return 'Required';
  else return undefined;
}

const NewCompanyModal = ({
  isOpen,
  hide,
  handleSubmit,
  submitting,
  change,
  pristine,
  invalid,
  destroy,
  initialValues,
}) => {
  const firstInput = useRef(null);
  const [error, setError] = useState(null);

  const [isLogoUploadVisible, setIsLogoUploadVisible] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [filename, setFilename] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const formRef = useRef(null);
  const fileRef = useRef(null);

  const dispatch = useDispatch();

  const onSubmit = async values => {
    try {
      if (!values || !Object.keys(values).length) return;

      setError(null);
      const response = await axios(
        `/api/companies${initialValues.id ? '/' + initialValues.id : ''}`,
        {
          method: initialValues.id ? 'PUT' : 'POST',
          data: values,
        }
      );

      if (response && !response.data.error) {
        dispatch(fetchCompanies());
        hide();
        destroy();
      }
    } catch (error) {
      const data = error?.response?.data;
      setError(data ? data.message : error ? error.message : 'Something went wrong...');
    }
  };

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        firstInput.current && firstInput.current.focus();
      }, 100);
    }
  }, [isOpen]);

  return (
    <Modal centered isOpen={isOpen} toggle={hide} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          {initialValues.id ? 'Edit Company' : 'Create New Company'}
        </h5>
        <button type="button" onClick={hide} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div>
          <Label for="logo">Logo</Label>
          <div className="text-center" lg={3}>
            <div
              className="logo-container bg-light"
              style={
                initialValues?.design?.logo && {
                  backgroundImage: `url(${initialValues?.design?.logo})`,
                }
              }
            >
              {!initialValues?.design?.logo && (
                <img
                  className="logo-placeholder"
                  src={require('../../../../src/images/business_logo.png')}
                  alt="Logo"
                />
              )}
            </div>
          </div>
          <Button color="primary" onClick={() => setIsLogoUploadVisible(true)}>
            Change
          </Button>
          <div hidden={!isLogoUploadVisible}>
            <p>Please choose a jpeg file to upload.</p>
            {uploadError && (
              <Alert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {uploadError}
              </Alert>
            )}
            <div className="bootstrap-filestyle input-group">
              <form ref={formRef} className="w-100">
                <input
                  type="file"
                  className="filestyle photo-file-input"
                  name="logo"
                  ref={fileRef}
                  id="logo"
                  tabIndex="-1"
                  accept="image/png, image/jpeg"
                  onChange={e => {
                    const file = e.target.files && e.target.files[0];
                    setFilename(file ? file.name : '');
                    setUploadError(null);
                  }}
                />
                <div className="bootstrap-filestyle input-group">
                  <input type="text" className="form-control" placeholder={filename} disabled />
                  <span className="group-span-filestyle input-group-append" tabIndex="0">
                    <label htmlFor="logo" className="btn btn-secondary ">
                      <span className="icon-span-filestyle fas fa-folder-open"></span>
                      <span className="buttonText">Choose file</span>
                    </label>
                  </span>
                </div>
              </form>
            </div>
            <div className="m-t-30 text-right">
              <Button
                disabled={isUploading || (fileRef.current && !fileRef.current.files.length)}
                color="success"
                onClick={async () => {
                  if (fileRef.current && !fileRef.current.files.length) {
                    setUploadError('Please choose a file to upload.');
                    return;
                  }
                  setUploadError(null);
                  setIsUploading(true);

                  try {
                    const data = new FormData(formRef.current);

                    await axios({
                      method: 'put',
                      url: `/api/companies/${initialValues.id}/logo`,
                      data,
                    });

                    setIsLogoUploadVisible(false);
                    setIsUploading(false);
                    dispatch(fetchCompanies());
                    hide();
                    destroy();
                  } catch (error) {
                    const data = error && error.response && error.response.data;

                    setIsUploading(false);
                    setUploadError(
                      (data && data.message) || 'There was a problem with your upload :('
                    );
                  }
                }}
              >
                {isUploading ? 'Uploading...' : 'Upload'}
              </Button>
            </div>
          </div>
        </div>

        <Form onSubmit={handleSubmit(onSubmit)}>
          {error && (
            <Alert color="danger">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                ⛔️
              </span>
              {error}
            </Alert>
          )}

          <FormGroup row>
            <Label sm={3}>ID</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="id" disabled />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Legal Name</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="legalName" validate={[required]} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Director's Full Name</Label>
            <Col sm={9}>
              <Field
                component={renderField}
                type="text"
                name="signatoryName"
                validate={[required]}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Email</Label>
            <Col sm={9}>
              <Field component={renderField} type="email" name="email" validate={[required]} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} alt="Email for support tickets">
              Support Email
            </Label>
            <Col sm={9}>
              <Field
                component={renderField}
                type="email"
                name="supportEmail"
                validate={[required]}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} alt="Email for billing tickets and automatic invoice processing">
              Billing Email
            </Label>
            <Col sm={9}>
              <Field
                component={renderField}
                type="email"
                name="billingEmail"
                validate={[required]}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Website</Label>
            <Col sm={9}>
              <Field component={renderField} type="url" name="website" validate={[required]} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Phone</Label>
            <Col sm={9}>
              <Field component={renderField} type="phone" name="phone" validate={[required]} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Primary Branding Colour</Label>
            <Col sm={3}>
              <Field component={renderField} type="color" name="design.primaryColour" />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>Secondary Branding Colour</Label>
            <Col sm={3}>
              <Field component={renderField} type="color" name="design.secondaryColour" />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3}>TIN</Label>
            <Col sm={9}>
              <Field component={renderField} type="text" name="tin" validate={[required]} />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="isDefault" className="m-0 align-middle">
              Is Default Company
            </Label>
            <Col sm={9}>
              <Field
                component={({ input: { onChange, checked } }) => (
                  <Switch onColor="#626ed4" onChange={onChange} checked={checked} />
                )}
                className="form-control"
                id="isDefault"
                name="isDefault"
                type="checkbox"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="isVATVerified" className="m-0 align-middle">
              VAT Verified
            </Label>
            <Col sm={9}>
              <Field
                component={({ input: { onChange, checked } }) => (
                  <Switch onColor="#626ed4" onChange={onChange} checked={checked} />
                )}
                className="form-control"
                id="isVATVerified"
                name="isVATVerified"
                type="checkbox"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="isEU" className="m-0 align-middle">
              EU Business
            </Label>
            <Col sm={9}>
              <Field
                component={({ input: { onChange, checked } }) => (
                  <Switch onColor="#626ed4" onChange={onChange} checked={checked} />
                )}
                className="form-control"
                id="isEU"
                name="isEU"
                type="checkbox"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="isBusiness" className="m-0 align-middle">
              Business
            </Label>
            <Col sm={9}>
              <Field
                component={({ input: { onChange, checked } }) => (
                  <Switch onColor="#626ed4" onChange={onChange} checked={checked} />
                )}
                className="form-control"
                id="isBusiness"
                name="isBusiness"
                type="checkbox"
              />
            </Col>
          </FormGroup>

          <hr />

          <FormGroup row>
            <Label sm={3} for="bankingDetails.bicSwiftSortCode">
              BIC / SWIFT / Sort Code / IFSC
            </Label>
            <Col sm={9}>
              <Field
                component={renderField}
                className="form-control"
                style={{ backgroundColor: 'red' }}
                id="bankingDetails.bicSwiftSortCode"
                name="bankingDetails.bicSwiftSortCode"
                type="text"
                required
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="bankingDetails.ibanAccountNumber">
              IBAN / Account Number / PrivatBank Card 4 Digits
            </Label>
            <Col sm={9}>
              <Field
                component={renderField}
                className="form-control"
                id="bankingDetails.ibanAccountNumber"
                name="bankingDetails.ibanAccountNumber"
                type="text"
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Label sm={3} for="bankingDetails.currency">
              Currency
            </Label>
            <Col sm={9}>
              <Field
                component="select"
                className="form-control"
                id="bankingDetails.currency"
                name="bankingDetails.currency"
              >
                <option>Please Select</option>
                <option value="GBP">GBP - Pound Sterling</option>
                <option value="HUF">HUF - Hungarian Forint</option>
                <option value="EUR">EUR - Euro</option>
                <option value="USD">USD - US Dollars</option>
                <option value="UAH">UAH - Ukrainian Hryvnia</option>
                <option value="RON">RON - Romanian Leu</option>
                <option value="ZAR">ZAR - South African Rand</option>
                <option value="INR">INR - Indian rupee</option>
              </Field>
            </Col>
          </FormGroup>

          <hr />

          <Address title="Registered Address" field="registeredAddress" change={change} />

          <Address title="Office Address" field="officeAddress" change={change} />

          <Address title="Postal Address" field="postalAddress" change={change} />

          <hr />
          <FormGroup row>
            <Label sm={9} for="paymentMethod">
              <h4>Payment Providers</h4>
            </Label>
            <Col sm={9}></Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} for="paymentMethod">
              WISE
            </Label>
            <Col sm={9}></Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} for="paymentMethods.transferwise.apiKey">
              Wise API Key
            </Label>
            <Col sm={9}>
              <Field
                component={renderField}
                className="form-control"
                style={{ backgroundColor: 'red' }}
                id="paymentMethods.transferwise.apiKey"
                name="paymentMethods.transferwise.apiKey"
                type="text"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} for="paymentMethods.transferwise.privateKey">
              Wise Private Key (BASE64)
            </Label>
            <Col sm={9}>
              <Field
                component={renderField}
                className="form-control"
                id="paymentMethods.transferwise.privateKey"
                name="paymentMethods.transferwise.privateKey"
                type="textarea"
                rows="4"
              />
            </Col>
          </FormGroup>
          <FormGroup row>
            <Label sm={3} for="paymentMethods.transferwise.sandbox">
              Sandbox Environment
            </Label>
            <Col sm={9}>
              <Field
                component={({ input: { onChange, checked } }) => (
                  <Switch onColor="#626ed4" onChange={onChange} checked={checked} />
                )}
                className="form-control"
                id="paymentMethods.transferwise.sandbox"
                name="paymentMethods.transferwise.sandbox"
                type="checkbox"
              />
            </Col>
          </FormGroup>
          <Row className="m-t-20">
            <Col sm={12} className="text-right">
              <Button color="primary" type="submit" disabled={submitting || pristine || invalid}>
                {submitting ? 'Submitting...' : initialValues.id ? 'Update' : 'Create'}
              </Button>
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default reduxForm({
  form: 'new_company',
  enableReinitialize: true,
  forceUnregisterOnUnmount: true,
})(NewCompanyModal);
