import React, { useState } from 'react';
import { Card, CardHeader, CardBody, Table, Collapse, Badge } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';

import AdminTimesheetFooter from './AdminTimesheetFooter';
import DecisionModal from '../../../components/DecisionModal/DecisionModal';
import { formatCurrency } from '../../../helpers/numbers';
import { copyToClipboard, downloadFile, sendReminder } from '../../../helpers/utils';
import { fetchAdminTimesheets } from '../../../store/admin/actions';
import { fetchTimesheets } from '../../../store/timesheets/actions';

import { statusMapping } from '../../TimesheetStatusMapping';

import './AdminTimesheetItem.scss';

const AdminTimesheetItem = ({ timesheet, refreshCallback }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showArchiveModal, setShowArchiveModal] = useState(false);
  const hasPaymentRequest = timesheet.paymentRequest !== undefined;
  const paymentRequest = timesheet.paymentRequest;
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [success, setSuccess] = useState();
  const { profiles } = useSelector(state => state.Team);
  const profile = profiles && profiles[timesheet.userID];
  const isAgent = profile && profile.type === 'agent';

  const dispatch = useDispatch();
  const resetAndCloseModal = () => {
    setSuccess(null);
    setShowDeleteModal(false);
    setShowArchiveModal(false);

    if (success) {
      dispatch(fetchTimesheets());
      dispatch(fetchAdminTimesheets());
    }
  };

  let fromDate = timesheet.fromDate;
  let toDate = timesheet.toDate;
  try {
    fromDate = new Date(fromDate).toLocaleDateString();
    toDate = new Date(toDate).toLocaleDateString();
  } catch {}

  return (
    <Card className="timesheet-bundle admin-timesheet-item" key={timesheet.id}>
      <CardHeader className="d-flex align-items-center">
        <h6>
          <i className="mdi mdi-account-clock"></i> {timesheet.userName}'s Timesheet
          {timesheet?.useTime && (
            <span
              role="img"
              aria-label="questions mark"
              className="font-14 m-l-10"
              data-tip="This timesheet uses human time."
              data-effect="solid"
              data-delay-show="200"
            >
              ⏱
            </span>
          )}
          {!timesheet?.useTime && (
            <span
              role="img"
              aria-label="questions mark"
              className="font-14 m-l-10"
              data-tip="This timesheet uses decimal time. This value is a rounded estimate, without seconds."
              data-effect="solid"
              data-delay-show="200"
            >
              🤖
            </span>
          )}
        </h6>

        <HeaderBadges timesheet={timesheet} paymentRequest={paymentRequest} isAgent={isAgent} />

        <button
          title="Copy ID to clipboard"
          className="btn btn-secondary m-l-10 btn-clipboard"
          onClick={() => copyToClipboard(timesheet.id)}
        >
          <i className="ti-clipboard"></i>
        </button>
        {hasPaymentRequest ? (
          <button
            title="Download Invoice"
            className="btn btn-secondary m-l-10 btn-clipboard"
            onClick={() => {
              downloadFile(
                `/api/download/invoice?awsPath=${timesheet.paymentRequest.subcontractorInvoiceURL}&invoiceTimesheet=${timesheet.paymentRequest.id}`,
                `Invoice ${timesheet.id}.pdf`
              );
            }}
          >
            <i className="mdi mdi-download"></i>
          </button>
        ) : (
          <div>
            {timesheet.isBillable && !timesheet.isPaid && (
              <button
                title="Send Reminder Email"
                className="btn btn-secondary m-l-10 btn-header"
                onClick={() => {
                  sendReminder('timesheet', timesheet.id, result => {
                    const resultString =
                      'A reminder was' +
                      (result ? ` sent to the worker.` : ' not sent due to an error.');
                    alert(resultString);
                  });
                }}
              >
                <i className="mdi mdi-email"></i>
              </button>
            )}
            {timesheet.isBillable && !timesheet.isPaid && (
              <button
                title="Delete Payment Request"
                className="btn btn-secondary m-l-10 btn-header"
                onClick={() => setShowDeleteModal(true)}
              >
                <i className="mdi mdi-delete"></i>
              </button>
            )}
          </div>
        )}
        <button
          title="Archive This"
          className="btn btn-secondary m-l-10 btn-header"
          onClick={() => setShowArchiveModal(true)}
        >
          <i className="mdi mdi-eye-off"></i>
        </button>
      </CardHeader>

      <CardBody>
        <Card className="mb-1 w-100" onClick={toggle}>
          <CardHeader className="p-3">
            <h6 className="m-0 font-14">
              <Link to="#" onClick={toggle} className="text-dark">
                {timesheet.id}{' '}
              </Link>
              {timesheet.financials.companyDisplayName ? (
                <Badge className="font-12" pill color="success">
                  {timesheet.financials.companyDisplayName}
                </Badge>
              ) : (
                <></>
              )}
            </h6>
          </CardHeader>
          <Collapse isOpen={isOpen}>
            <Table responsive className="mb-0">
              <thead>
                <tr>
                  <th>Work Item</th>
                  <th>From</th>
                  <th>To</th>
                  <th>Hours</th>
                  <th>Decimal</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {timesheet.lineItems.map((lineItem, index) => {
                  let date = lineItem.workDate;
                  try {
                    date = new Date(date).toLocaleDateString();
                  } catch {}

                  return (
                    <tr key={index}>
                      <td>
                        {lineItem.taskId && (
                          <Badge color="primary" className="m-r-5 align-text-top" pill>
                            #{lineItem.taskId}
                          </Badge>
                        )}
                        {lineItem.title}
                      </td>
                      <td>{date}</td>
                      <td>{date}</td>
                      <td className={timesheet?.useTime ? '' : 'greyedOut'}>
                        {lineItem.totalDisplayHours}
                      </td>
                      <td className={timesheet?.useTime ? 'greyedOut' : ''}>
                        {lineItem.totalHours}
                      </td>
                      <td>
                        {lineItem.totalAmount &&
                          formatCurrency(lineItem.totalAmount, lineItem.currency)}
                      </td>
                    </tr>
                  );
                })}
                <tr>
                  <td>
                    <h5>Total</h5>
                  </td>
                  <td>{fromDate}</td>
                  <td>{toDate}</td>
                  <td className={timesheet?.useTime ? '' : 'greyedOut'}>
                    {timesheet.financials.totalDisplayHours}
                  </td>
                  <td className={timesheet?.useTime ? 'greyedOut' : ''}>
                    {timesheet.financials.totalHours}
                  </td>
                  <td>
                    {formatCurrency(
                      timesheet.financials.totalAmount,
                      timesheet.financials.currency
                    )}
                  </td>
                </tr>
              </tbody>
            </Table>
          </Collapse>
        </Card>
        <Table>
          <thead>
            <tr>
              <th style={{ width: '54%' }}></th>
              <th>From</th>
              <th>To</th>
              <th></th>
              <th>Amount</th>
            </tr>
          </thead>
          <tfoot>
            <tr>
              <td>
                <h5>Total</h5>
              </td>
              <td>{fromDate}</td>
              <td>{toDate}</td>
              <td></td>
              <td>
                {formatCurrency(timesheet.financials.totalAmount, timesheet.financials.currency)}
              </td>
            </tr>
          </tfoot>
        </Table>
      </CardBody>

      <AdminTimesheetFooter timesheets={[timesheet]} isAgent={isAgent} />

      <DecisionModal
        centered
        destructive
        isOpen={showDeleteModal}
        title={'🗑 Delete Timesheet'}
        message={`Do you wish to delete item "${timesheet.id}"?`}
        didTapCloseButton={resetAndCloseModal}
        didTapConfirmButton={async reasonMessage => {
          const url = `/api/timesheet/${timesheet.id}`;
          return await Axios(url, { method: 'DELETE', data: { reason: reasonMessage } });
        }}
      />

      <DecisionModal
        centered
        isOpen={showArchiveModal}
        title={'🗄 Archive Timesheet'}
        message={`Do you wish to archive item "${timesheet.id}"?`}
        didTapCloseButton={resetAndCloseModal}
        didTapConfirmButton={async reasonMessage => {
          const url = `/api/timesheet/${timesheet.id}/archive`;
          return await Axios(url, { method: 'PUT', data: { reason: reasonMessage } });
        }}
      />
    </Card>
  );
};

const HeaderBadges = ({ isAgent, timesheet, paymentRequest }) => {
  const mappedStatus = statusMapping[paymentRequest?.status?.toUpperCase() ?? 'NOT_AVAILABLE'];

  if (timesheet?.isBillable === false || isAgent) {
    return (
      <Badge pill className="font-14" color="info">
        Non Billable
      </Badge>
    );
  } else {
    if (paymentRequest) {
      return (
        <Badge pill className="font-14" color={mappedStatus.color}>
          {mappedStatus.title}
        </Badge>
      );
    } else {
      if (timesheet.isPaid) {
        return (
          <Badge pill className="font-14" color={statusMapping['PAID'].color}>
            {statusMapping['PAID'].title}
          </Badge>
        );
      } else {
        return (
          <Badge pill className="font-14" color={statusMapping['EXPECTING_INVOICE'].color}>
            {statusMapping['EXPECTING_INVOICE'].title}
          </Badge>
        );
      }
    }
  }
};

export default AdminTimesheetItem;
