export const projectStatusMapping = {
  NEW: { title: 'New', color: 'info', description: 'New Project' },
  'IN PROGRESS': { title: 'In Progress', color: 'info', description: 'Project is in development' },
  QUOTED: {
    title: 'Quoted',
    color: 'info',
    description: 'The client has ordered the project, but no PO / quote was confirmed yet.',
  },
  OVERDUE: {
    title: 'Overdue',
    color: 'danger',
    description: 'Project is overrunning its delivery date',
  },
  DELIVERED: {
    title: 'Delivered',
    color: 'info',
    description: 'Project was delivered, pending acceptance',
  },
  INVOICED: {
    title: 'Invoiced',
    color: 'success',
    description: 'Invoice was generated for the project',
  },
  PAID: { title: 'Paid', color: 'success', description: 'Payment received' },
  COMPLETED: {
    title: 'Completed',
    color: 'success',
    description: 'Project was completed',
    completed: true,
  },
  ARCHIVED: {
    title: 'Archived',
    color: 'dark',
    description: 'Project was archived',
    completed: true,
  },
  UNKNOWN: { title: 'Unknown', color: 'dark', description: 'Unknown State', hidden: true },
  CANCELLED: {
    title: 'Cancelled',
    color: 'dark',
    descriptions: 'Project was cancelled and never started',
    completed: true,
  },
};
