import React from 'react';
import moment from 'moment';
import { Badge, Button, Col, Label, Modal, ModalBody, Row } from 'reactstrap';

import { getEventIcon } from './Calendar';

const DetailsModal = ({ selectedEvent, closeModal }) => {
  return (
    <Modal centered isOpen={!!selectedEvent} toggle={closeModal} style={{ width: '100%' }}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Availability Detail</h5>
        <button type="button" onClick={closeModal} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {selectedEvent && (
        <ModalBody>
          <Row>
            <Col xs="12">
              <strong>{moment(selectedEvent.day).format('dddd, LL')}</strong>
            </Col>
          </Row>
          <Row>
            <Col xs="12" className="d-flex align-items-baseline">
              <span className="flex-grow-1">
                {selectedEvent?.employeeFirstName} {selectedEvent?.employeeLastName}
              </span>
              <div>
                <span>{selectedEvent.status}</span>
                <span className="font-30 m-l-10">{getEventIcon(selectedEvent.status)}</span>
              </div>
            </Col>
          </Row>
          <Row className="m-t-15">
            <Col xs="12">
              <Label>Projects</Label>
              <div>
                {selectedEvent.projectIds?.length ? (
                  selectedEvent.projectIds?.map(id => (
                    <Badge key={id} color="info" pill className="m-r-5 m-b-5">
                      {id}
                    </Badge>
                  ))
                ) : (
                  <span className="text-muted">None</span>
                )}
              </div>
            </Col>
          </Row>
          <Row className="m-t-15">
            <Col xs="12">
              <Label>Bookings</Label>
              <div>
                {selectedEvent.bookedForIds?.length ? (
                  selectedEvent.bookedForIds?.map(id => (
                    <Badge key={id} color="warning" pill className="m-r-5 m-b-5">
                      {id}
                    </Badge>
                  ))
                ) : (
                  <span className="text-muted">None</span>
                )}
              </div>
            </Col>
          </Row>

          <Row className="m-t-20">
            <Col xs="6">
              <Button block color="secondary" onClick={closeModal}>
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      )}
    </Modal>
  );
};

export default DetailsModal;
