import React, { useState } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';

import cn from 'classnames';
import ScopeOfWork from '../Reporting/ScopeOfWork';
import Schedule1 from './Schedule1';
import WorkAgreement from './WorkAgreement';

const QuoterMaster = () => {
  const [activeTab, setActiveTab] = useState(0);
  return (
    <Container fluid>
      <div className="p-t-10">
        <h4 className="page-title m-b-30">Quote Master CPQ</h4>
        <Nav tabs className="nav-tabs-custom">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 0 })}
              onClick={() => setActiveTab(0)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Offers</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 1 })}
              onClick={() => setActiveTab(1)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Scope of Work</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 2 })}
              onClick={() => setActiveTab(2)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Client Work Agreement</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 3 })}
              onClick={() => setActiveTab(3)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Worker Agreement</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 4 })}
              onClick={() => setActiveTab(4)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Worker Schedule 1</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            <img
              className="m-t-20"
              src={require('../../../images/comingsoon.png')}
              style={{ height: '350px', display: 'block', margin: 'auto' }}
              alt="coming soon"
            />
          </TabPane>

          <TabPane tabId={1}>
            <ScopeOfWork />
          </TabPane>

          <TabPane tabId={2}>
            <img
              className="m-t-20"
              src={require('../../../images/comingsoon.png')}
              style={{ height: '350px', display: 'block', margin: 'auto' }}
              alt="coming soon"
            />
          </TabPane>

          <TabPane tabId={3}>
            <WorkAgreement />
          </TabPane>

          <TabPane tabId={4}>
            <Schedule1 />
          </TabPane>
        </TabContent>
      </div>
    </Container>
  );
};

export default QuoterMaster;
