import React from 'react';
import {
  Table,
  Card,
  CardBody,
  Button,
  Pagination,
  PaginationItem,
  PaginationLink,
} from 'reactstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter, usePagination } from 'react-table';

import { GlobalFilter } from '../Reporting/GlobalFilter';
import columns from './paymentsTableColumns';

const PaymentsTable = ({ data, isLoading, onClickView, type, onChangeType }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    rows,
    prepareRow,
    preGlobalFilteredRows,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    toggleSortBy,
    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: 20,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  // Show running timers first by default when data loads
  React.useEffect(() => {
    toggleSortBy('timestamp');
  }, [toggleSortBy, data]);

  const fromItem = pageSize * pageIndex + 1;
  let toItem = pageSize * pageIndex + pageSize;
  toItem = toItem > rows.length ? rows.length : toItem;

  return (
    <>
      <div className="d-flex flex-row justify-content-between m-b-15 m-t-20">
        <div className="d-flex flex-row">
          <select
            className="form-control w-auto text-secondary m-r-10"
            value={type}
            onChange={e => onChangeType(e.target.value === 'true')}
          >
            <option value="true">Paid</option>
            <option value="false">Unpaid</option>
          </select>
          <select
            className="form-control w-auto text-secondary"
            value={pageSize}
            onChange={e => setPageSize(Number(e.target.value))}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize} entries
              </option>
            ))}
          </select>
        </div>
        <GlobalFilter
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      </div>
      <Card>
        <CardBody className="p-0">
          <Table striped responsive className="table-sm m-0" {...getTableProps()}>
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                    </th>
                  ))}
                  <th></th>
                  <th></th>
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {!isLoading && page.length === 0 ? (
                <tr>
                  <td colSpan="12" className="text-center text-black-50">
                    No entries found
                  </td>
                </tr>
              ) : (
                page.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                      })}
                      <td>
                        <Button
                          color="link"
                          className="btn-sm p-0"
                          data-tip="Edit Timetracker"
                          data-effect="solid"
                          data-delay-show="500"
                          onClick={() => onClickView(row.original)}
                        >
                          <i className="mdi mdi-magnify font-24 text-success" />
                        </Button>
                      </td>
                    </tr>
                  );
                })
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>

      <div className="d-flex align-items-center">
        <div data-test="datatable-info" className="flex-grow-1">
          <div className="dataTables_info" role="status" aria-live="polite">
            Showing {fromItem} to {toItem} of {rows.length} entries
          </div>
        </div>

        <Pagination>
          <PaginationItem>
            <PaginationLink first onClick={() => gotoPage(0)} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink previous disabled={!canPreviousPage} onClick={previousPage} />
          </PaginationItem>
          {pageOptions.map(i => (
            <PaginationItem key={i} active={pageIndex === i}>
              <PaginationLink onClick={() => gotoPage(i)}>{i + 1}</PaginationLink>
            </PaginationItem>
          ))}
          <PaginationItem>
            <PaginationLink next disabled={!canNextPage} onClick={nextPage} />
          </PaginationItem>
          <PaginationItem>
            <PaginationLink last onClick={() => gotoPage(pageCount - 1)} />
          </PaginationItem>
        </Pagination>
      </div>
    </>
  );
};

export default PaymentsTable;
