import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'reactstrap';

import Topbar from './Topbar';
import Sidebar from './Sidebar';
import Footer from './Footer';

import { dismissGlobalAlert } from '../../store/actions';
import ErrorBoundary from '../../ErrorBoundary';

const GlobalAlerts = () => {
  const alerts = useSelector(state => state.Layout.alerts);
  const dispatch = useDispatch();

  return alerts.map((alert, index) => (
    <Alert
      key={index}
      color={alert.color || 'primary'}
      className="d-flex align-items-center"
      style={{ marginTop: index === 0 ? '90px' : '15px' }}
      isOpen={!!alert}
      toggle={() => dispatch(dismissGlobalAlert(index))}
    >
      {alert.icon && (
        <span role="img" aria-label="emoji" className="font-18 m-r-5">
          {alert.icon}
        </span>
      )}
      {alert.text || ''}
    </Alert>
  ));
};

const Layout = ({ children, path }) => {
  const selectedTimesheets = useSelector(state => state.Timesheets.selected);
  const isSpacerActive = selectedTimesheets.size > 0;

  return (
    <div id="wrapper">
      <Topbar path={path} />
      <Sidebar />
      <div className="content-page">
        <div className={`spacer ${isSpacerActive ? 'active' : ''}`}></div>
        <div className="content">
          <ErrorBoundary>
            <GlobalAlerts />
            {children}
          </ErrorBoundary>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default Layout;
