import React from 'react';
import { useSelector } from 'react-redux';
import classnames from 'classnames';

import TimesheetsBundleOverlay from '../../pages/Timesheets/Bundle/TimesheetsBundleOverlay';

import './TopBarOverlay.scss';

function TopBarOverlay() {
  const timesheetsState = useSelector(state => state.Timesheets);
  const timesheets = timesheetsState.data && timesheetsState.data.entries;
  const selectedTimesheets = useSelector(state => state.Timesheets.selected);
  const isVisible = selectedTimesheets.size > 0;

  return (
    <div className="top-bar-overlay">
      <div
        className={classnames('top-bar-overlay-wrapper', 'bg-light', { 'bar-visible': isVisible })}>
        <TimesheetsBundleOverlay timesheets={timesheets} selectedTimesheets={selectedTimesheets} />
      </div>
    </div>
  );
}

export default TopBarOverlay;
