import React from 'react';

function TeamHeader({ icon, title, subtitle }) {
  return (
    <div className="d-flex flex-row align-items-center">
      <img
        src={require(`../../images/${icon}.png`)}
        alt={icon}
        style={{
          height: '40px',
          margin: '8px',
          marginRight: '12px',
        }}
      />
      <div className="flex-grow-1">
        <h5>{title}</h5>
        <div>{subtitle}</div>
      </div>
    </div>
  );
}

export default TeamHeader;
