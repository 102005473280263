import {
  ACTIVATE_AUTH_LAYOUT,
  ACTIVATE_NON_AUTH_LAYOUT,
  TOGGLE_LARGE_LAYOUT,
  SET_GLOBAL_ALERT,
  DISMISS_GLOBAL_ALERT,
  CHECK_AVAILABILITY_SUCCESS,
} from './actionTypes';

const initialState = {
  topbar: true,
  sidebar: true,
  footer: true,

  is_large_state: false,

  alerts: [],

  hasPendingAvailability: false,
};

const layout = (state = initialState, action) => {
  switch (action.type) {
    case ACTIVATE_AUTH_LAYOUT:
      state = {
        ...state,
        ...action.payload,
      };
      break;

    case ACTIVATE_NON_AUTH_LAYOUT:
      state = {
        ...state,
        ...action.payload,
      };
      break;

    case TOGGLE_LARGE_LAYOUT:
      state = {
        ...state,
        is_large_state: !state.is_large_state,
      };
      break;

    case SET_GLOBAL_ALERT:
      const alerts = [
        ...state.alerts,
        { text: action.text, color: action.color || 'primary', icon: action.icon },
      ];

      state = {
        ...state,
        alerts,
      };
      break;

    case DISMISS_GLOBAL_ALERT: {
      const alerts = [...state.alerts];
      alerts.splice(action.index, 1);

      state = {
        ...state,
        alerts,
      };
      break;
    }

    case CHECK_AVAILABILITY_SUCCESS: {
      state = {
        ...state,
        hasPendingAvailability: !!action.payload,
      };
      break;
    }

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default layout;
