import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
  SIGN_OUT_SUCCESS,
  SIGN_OUT_FAILURE,
} from './actionTypes';

const initialState = {
  userID: null,
  isLoading: false,
  error: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case SIGN_IN:
      return { ...state, isLoading: true, error: null };
    case SIGN_IN_SUCCESS:
      return {
        ...state,
        userID: action.profile.userID,
        isLoading: false,
        error: null,
      };
    case SIGN_IN_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    case SIGN_OUT:
      return { ...state, userID: null, isLoading: true };
    case SIGN_OUT_SUCCESS:
      return { ...state, isLoading: false, error: action.error };
    case SIGN_OUT_FAILURE:
      return { ...state, isLoading: false, error: action.error };

    default:
      return { ...state };
  }
};

export default login;
