import React from 'react';
import moment from 'moment';
import { Badge, Progress } from 'reactstrap';
import { useSelector } from 'react-redux';
import { formatCurrency } from '../../../helpers/numbers';
import { projectStatusMapping } from './ProjectStatusMapping';

export default [
  {
    Header: '#',
    accessor: 'id',
  },
  {
    Header: 'Project',
    accessor: 'title',
    Cell: ({ cell: { row }, value }) => {
      const project = row && row.original;
      const externalId = project && project.externalId;
      const source = project && project.source;
      return (
        <>
          <span className="align-middle m-r-5">{value}</span>
          {!!(externalId && externalId.length) && (
            <Badge
              pill
              color="info"
              className="align-middle"
              data-html="true"
              data-tip={`<div class="text-capitalize">Source: ${source}</div>`}
              data-effect="solid"
              data-delay-show="200"
            >
              {source.toUpperCase().substr(0, 1)}
            </Badge>
          )}
        </>
      );
    },
  },
  {
    Header: 'Client',
    accessor: 'client.name',
  },
  {
    Header: 'Start Date',
    id: 'startDate',
    sortDescFirst: true,
    accessor: row => (row.startDate ? new Date(row.startDate).getTime() : 0),
    Cell: ({ value }) => value && new Date(value).toLocaleDateString(),
  },
  {
    Header: 'Status',
    id: 'status',
    filter: 'hideCompleted',
    accessor: 'status',
    Cell: ({ cell: { row }, value }) => {
      const statusMapped =
        projectStatusMapping[value?.toUpperCase()] ?? projectStatusMapping['UNKNOWN'];
      return (
        <div
          className={`text-${statusMapped?.color ?? 'danger'}`}
          data-effect="solid"
          data-delay-show="200"
          data-tip={`Delivery Date: ${new Date(row.original.deliveryDate).toLocaleDateString()}`}
        >
          <span className="fas fa-circle m-r-5" />
          <span>{statusMapped?.title ?? 'Not Specified'}</span>
        </div>
      );
    },
  },
  {
    Header: 'Team',
    accessor: 'workers',
    disableGlobalFilter: true,
    disableSortBy: true,
    Cell: ({ cell: { row } }) => {
      const workersIds = Object.keys(row.original.workers) || [];

      return <Workers workersIds={workersIds} />;
    },
  },
  {
    Header: 'Cost',
    accessor: 'cost.value',
    Cell: ({ cell: { row } }) => {
      const project = row && row.original;
      const costToDate = project.costToDate && project.costToDate.value;
      const costToDateTip = createCostTip(project);
      const progress = ((costToDate || 0) / project.cost.value) * 100;
      const progressColor =
        progress < 50 ? 'success' : progress >= 50 && progress < 75 ? 'info' : 'warning';

      return (
        <div data-tip={costToDateTip} data-html="true" data-effect="solid">
          <div className="d-flex justify-content-between">
            <div>{formatCurrency(project.cost.value, project.cost.currency, 'symbol')}</div>
            <div>{Math.round(progress)}%</div>
          </div>
          <Progress value={progress} color={progressColor} />
        </div>
      );
    },
  },
];

const Workers = ({ workersIds }) => {
  const { profiles } = useSelector(state => state.Team);

  return (
    <div className="d-flex flex-wrap">
      {workersIds.map(id => {
        const profile = profiles[id];
        const name = profile && `${profiles[id].firstName} ${profiles[id].lastName} (${id})`;
        const photo = profile && profile.photo;

        return (
          <div
            key={id}
            className="photo rounded-circle text-muted"
            data-tip={name || id}
            data-effect="solid"
            style={
              photo && {
                backgroundImage: `url(${photo})`,
              }
            }
          >
            {!photo && id}
          </div>
        );
      })}
    </div>
  );
};

function createCostTip(project) {
  const costToDate = project.costToDate && project.costToDate.value;
  const costToDateTimestamp = project.costToDate && moment(project.costToDate.datetime);
  const costToDateTip = costToDateTimestamp
    ? `<div>Cost to date: <b>${formatCurrency(
        costToDate,
        project.cost.currency,
        'symbol'
      )}</b></div><div>Last updated: ${costToDateTimestamp.calendar()}</div>`
    : '<div><b>No cost to date generated yet!</b></div><div>Please go to reporting and generate a total project report.</div>';

  return costToDateTip;
}
