import React from 'react';
import { useSelector } from 'react-redux';
import Layout from './components/Layout/';
import { Route, Switch, BrowserRouter as Router, Redirect } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, Slide } from 'react-toastify';

import ErrorBoundary from './ErrorBoundary';
import routes from './routes';
import './custom.css';
import './App.scss';
import 'react-toastify/dist/ReactToastify.css';

let lastSearchRequest = {};

moment.updateLocale('en', { week: { dow: 1 } });

const PrivateRoute = ({ component: Component, hasUser, ...rest }) => {
  if (rest.location.search) {
    lastSearchRequest[rest.location.pathname] = rest.location.search;
  }

  return (
    <Layout path={rest.path}>
      <Route
        {...rest}
        render={props =>
          hasUser ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: '/',
                state: {
                  redirect: rest.location.pathname,
                  search: rest.location.search,
                  lastSearchRequest: lastSearchRequest,
                },
              }}
            />
          )
        }
      />
    </Layout>
  );
};

const App = () => {
  const { userID } = useSelector(state => state.Auth);

  return (
    <ErrorBoundary>
      <Router>
        <Switch>
          {routes.map((route, idx) => {
            return route.ispublic ? (
              <Route path={route.path} component={route.component} key={idx} />
            ) : (
              <PrivateRoute
                path={route.path}
                component={route.component}
                hasUser={!!userID}
                key={idx}
              />
            );
          })}
        </Switch>
      </Router>
      <ToastContainer position="top-right" transition={Slide} />
    </ErrorBoundary>
  );
};

export default App;
