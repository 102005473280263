import React, { useEffect, useState } from 'react';
import { Button, Table, Card, CardBody, Input, Badge } from 'reactstrap';
import useSound from 'use-sound';
import moment from 'moment';

import availabilityStatuses from './AvailabilityStatuses';

const RosterTable = ({ data, updateAvailability }) => {
  const [localData, setLocalData] = useState([]);

  const [playToggle] = useSound('click.mp3', { volume: 0.25 });

  useEffect(() => {
    const _localData = data?.map(item => ({ ...item })) || [];
    setLocalData(_localData);
  }, [data]);

  return (
    <Card>
      <CardBody className="p-0">
        <Table responsive className="table-sm m-0">
          <thead>
            <tr>
              <th>Weekday</th>
              <th>Date</th>
              <th className="d-none d-sm-table-cell">Projects</th>
              <th>Booked</th>
              <th style={{ minWidth: '145px' }}>Status</th>
              <th className="d-none d-md-table-cell" style={{ minWidth: '200px' }}>
                Notes
              </th>
            </tr>
          </thead>
          <tbody>
            {localData.length === 0 ? (
              <tr>
                <td colSpan="12" className="text-center text-black-50">
                  No entries found
                </td>
              </tr>
            ) : (
              localData?.map((item, index) => {
                return (
                  <tr key={index} className={item.isWeekend ? 'weekend-row' : ''}>
                    <td>
                      <span color="danger">{moment(item.day).format('dddd')}</span>
                    </td>
                    <td>{moment(item.day).format('LL')}</td>
                    <td className="d-none d-sm-table-cell">
                      {item.projectIds?.map(projectId => {
                        return (
                          <Badge key={projectId} className="m-r-5">
                            {projectId}
                          </Badge>
                        );
                      })}
                    </td>
                    <td>
                      {item.bookedForIds?.map(projectId => {
                        return (
                          <Badge key={projectId} className="m-r-5" color="success">
                            {projectId}
                          </Badge>
                        );
                      })}
                    </td>
                    <td>
                      <div
                        className="d-none d-md-inline-flex"
                        style={{ flexWrap: 'wrap', gap: '3px' }}
                      >
                        {availabilityStatuses &&
                          Object.keys(availabilityStatuses).map(statusKey => {
                            const status = availabilityStatuses[statusKey];
                            return (
                              <Button
                                key={statusKey}
                                color={status.tint}
                                outline
                                size="sm"
                                style={{ whiteSpace: 'nowrap' }}
                                onClick={() => {
                                  playToggle();
                                  const _localData = [...localData];
                                  _localData[index].status = statusKey;
                                  setLocalData(_localData);
                                  updateAvailability(localData[index]);
                                }}
                                active={item.status === statusKey}
                              >
                                {status.buttonTitle}
                              </Button>
                            );
                          })}
                      </div>

                      <select
                        className="form-control d-block d-md-none"
                        value={item.status}
                        onChange={async e => {
                          const value = e.target.value;
                          playToggle();
                          const _localData = [...localData];
                          _localData[index].status = value;
                          setLocalData(_localData);
                          updateAvailability(item);
                        }}
                      >
                        {!item.status && <option value="">Please select...</option>}
                        {availabilityStatuses &&
                          Object.keys(availabilityStatuses).map(statusKey => {
                            const status = availabilityStatuses[statusKey];
                            return (
                              <option key={statusKey} value={statusKey} color={status.tint}>
                                {status.icon} {status.buttonTitle}
                              </option>
                            );
                          })}
                      </select>
                    </td>
                    <td className="d-none d-md-table-cell">
                      <div className="d-flex">
                        <Input
                          type="text"
                          placeholder="Type private note here"
                          value={item.note}
                          onChange={async e => {
                            const value = e.target.value;
                            const _localData = [...localData];
                            _localData[index].note = value;
                            setLocalData(_localData);
                          }}
                          onKeyPress={e => {
                            if (e.nativeEvent.keyCode === 13) {
                              e.nativeEvent.target?.blur();
                            }
                          }}
                          onBlur={() => {
                            if (item.note !== data[index].note) updateAvailability(item);
                          }}
                        />
                        <Button
                          color="link"
                          className="btn-sm btn-save-availability p-0 m-l-10 m-r-5"
                          data-tip="Save Notes"
                          data-effect="solid"
                          data-delay-show="500"
                          disabled={item?.note === data[index]?.note}
                          onClick={() => {
                            updateAvailability(item);
                          }}
                        >
                          <i className="mdi mdi-content-save font-20 text-primary" />
                        </Button>
                      </div>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default RosterTable;
