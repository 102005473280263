const availabilityStatuses = {
  SICK: { icon: '😷', title: 'Sick Day', buttonTitle: 'Sick', tint: 'warning' },
  HOLIDAY: { icon: '🏝', title: 'Day Off', buttonTitle: 'OFF', tint: 'warning' },
  AVAILABLE: {
    icon: '🙋‍♂️',
    title: 'Available All Day',
    buttonTitle: 'All Day',
    tint: 'success',
    startTime: '09:00',
    endTime: '18:00',
  },
  AVAILABLE_AM: {
    icon: '🕖',
    title: 'Available Morning Only',
    buttonTitle: 'Morning',
    tint: 'success',
    startTime: '09:00',
    endTime: '13:00',
  },
  AVAILABLE_PM: {
    icon: '🕑',
    title: 'Available Afternoon Only',
    buttonTitle: 'Afternoon',
    tint: 'success',
    startTime: '14:00',
    endTime: '18:00',
  },
  AVAILABLE_NIGHT: {
    icon: '🌙',
    title: 'Available Night Shift',
    buttonTitle: 'Night',
    tint: 'success',
    startTime: '18:00',
    endTime: '23:00',
  },
  STANDBY: { icon: '👀', title: 'On Standby', buttonTitle: 'Standby', tint: 'primary' },
};

export default availabilityStatuses;
