export default [
  {
    Header: 'Legal Name',
    accessor: 'legalName',
  },
  {
    Header: 'TIN',
    accessor: 'tin',
  },
  {
    Header: 'VAT Verified',
    accessor: 'isVATVerified',
    Cell: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    Header: 'EU Business',
    accessor: 'isEU',
    Cell: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    Header: 'Default',
    accessor: 'isDefault',
    Cell: ({ value }) => (value ? '✅' : '☑️'),
  },
  {
    Header: 'WISE Payments',
    accessor: 'paymentMethods.transferwise.apiKey',
    Cell: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    Header: 'Revolut Payments',
    accessor: 'paymentMethods.revolut.client_id',
    Cell: ({ value }) => (value ? '✅' : '❌'),
  },
];
