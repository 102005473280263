import React from 'react';
import { Row, Col } from 'reactstrap';

import AdminTimesheetItem from './AdminTimesheetItem';
import TimesheetBundle from '../../Timesheets/Bundle/TimesheetBundle';
import { formatCurrency } from '../../../helpers/numbers';
import DashboardCard from '../../Dashboard/DashboardCard';

const AdminTimesheetsTable = ({ timesheets, cards }) => {
  if (timesheets) {
    const { timesheets: items } = timesheets;

    const bundleItems = {};
    const timesheetItems = [];
    const paymentRequests = [];
    var total = 0;

    if (items) {
      items.forEach(item => {
        if (
          item.paymentRequest &&
          item.paymentRequest.timesheets &&
          item.paymentRequest.timesheets.length > 1
        ) {
          if (!bundleItems[item.paymentRequest.id]) bundleItems[item.paymentRequest.id] = [];
          bundleItems[item.paymentRequest.id].push(item);
          paymentRequests.push(item.paymentRequest.id);
          total = total + item.financials.totalAmount;
        } else {
          timesheetItems.push(item);
          total = total + item.financials.totalAmount;
        }
      });
    }

    const totalCard = {
      title: 'Total',
      subtitle: '',
      iconname: 'pound',
      background: '#6c5ce7',
      value: formatCurrency(total, 'GBP') || 'N/A',
    };

    const timesheetCard = {
      title: 'Timesheets',
      subtitle: '',
      iconname: 'clock1',
      background: '#6c5ce7',
      value: timesheetItems.length + paymentRequests.length || 'N/A',
    };

    return (
      <Row className="timesheets-table-admin">
        {timesheetCard && <DashboardCard item={timesheetCard} />}
        {totalCard && <DashboardCard item={totalCard} />}
        {cards &&
          cards.map((entry, index) => {
            return <DashboardCard item={entry} key={index} />;
          })}
        <Col lg="12">
          {(!items || !items.length) && (
            <p>There are no timesheets or timesheet bundles available.</p>
          )}

          {Object.keys(bundleItems).map(key => {
            const bundleTimesheets = bundleItems[key];
            return <TimesheetBundle key={key} id={key} timesheets={bundleTimesheets} isAdmin />;
          })}

          {timesheetItems.map(timesheet => (
            <AdminTimesheetItem key={timesheet.id} timesheet={timesheet} />
          ))}
        </Col>
      </Row>
    );
  }

  return null;
};

export default AdminTimesheetsTable;
