import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Container, Alert, Spinner, Button } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import TimesModal from './TimesModal';
import TimetrackersTable from './TimetrackersTable';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import { fetchAdminTimetrackers } from '../../../store/actions';
import HistoryModal from './HistoryModal';

const Timetrackers = () => {
  const dispatch = useDispatch();
  const [selectedTracker, setSelectedTracker] = useState(null);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [type, setType] = useState('active');

  useEffect(() => {
    dispatch(fetchAdminTimetrackers(type));
  }, [type]); // eslint-disable-line

  const {
    data: { timetrackers },
    isLoading,
    error,
  } = useSelector(state => state.Admin);

  const admin = useSelector(state => state.Admin);
  if (!admin.isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <Container fluid className="timetracker">
      <div className="sticky-title">
        <Row className="align-items-center">
          <Col xs="6">
            <h5 className="page-title">Timetrackers</h5>
          </Col>
          <Col xs={6} className="text-right">
            <Button color="secondary" onClick={() => dispatch(fetchAdminTimetrackers(type))}>
              Refresh List
            </Button>
          </Col>
        </Row>
      </div>
      <Alert color="info">
        <span role="img" aria-label="no entry" className="font-18 m-r-5">
          👋
        </span>
        Lookup timetrackers by worker name or display ID of the tracker. <strong>Active</strong>{' '}
        timetracker is visible and available for the worker it belongs to. <strong>Locked</strong>{' '}
        timetracker has been added to a timesheet and cannot be seen by the worker anymore.
      </Alert>
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            ⛔️
          </span>
          {error}
        </Alert>
      )}

      {isLoading && (
        <div className="text-center m-b-20">
          <Spinner color="primary" />
        </div>
      )}

      <p className="text-left text-muted">
        Times are{' '}
        <a
          href="https://www.timeanddate.com/worldclock/timezone/utc"
          target="_blank"
          rel="noopener noreferrer"
        >
          UTC
        </a>
        .
      </p>

      <TimetrackersTable
        data={timetrackers || []}
        isLoading={isLoading}
        onClickEdit={tracker => setSelectedTracker(tracker)}
        onClickHistory={tracker => setSelectedHistory(tracker)}
        onChangeType={setType}
        type={type}
      />

      <TimesModal
        hide={(shouldReload = false) => {
          setSelectedTracker(null);
          shouldReload && dispatch(fetchAdminTimetrackers());
        }}
        tracker={selectedTracker}
      />

      <HistoryModal hide={() => setSelectedHistory(null)} tracker={selectedHistory} />

      <ReactTooltip />
    </Container>
  );
};

export default Timetrackers;
