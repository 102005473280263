export const FETCH_TIMER = 'FETCH_TIMER';
export const FETCH_TIMER_SUCCESS = 'FETCH_TIMER_SUCCESS';
export const FETCH_TIMER_FAILURE = 'FETCH_TIMER_FAILURE';

export const FETCH_TICKETS = 'FETCH_TICKETS';
export const FETCH_DAYPASS_TICKETS = 'FETCH_DAYPASS_TICKETS';
export const FETCH_TICKETS_SUCCESS = 'FETCH_TICKETS_SUCCESS';
export const FETCH_TICKETS_FAILURE = 'FETCH_TICKETS_FAILURE';

export const START_TIMER = 'START_TIMER';
export const START_TIMER_DONE = 'START_TIMER_DONE';

export const STOP_TIMER = 'STOP_TIMER';
export const STOP_TIMER_DONE = 'STOP_TIMER_DONE';

export const CLOCKIN_TIMER = 'CLOCKIN_TIMER';
export const CLOCKIN_TIMER_SUCCESS = 'CLOCKIN_TIMER_SUCCESS';
export const CLOCKIN_TIMER_FAILURE = 'CLOCKIN_TIMER_FAILURE';

export const UPDATE_TIMER = 'UPDATE_TIMER';
export const UPDATE_TIMER_SUCCESS = 'UPDATE_TIMER_SUCCESS';
export const UPDATE_TIMER_FAILURE = 'UPDATE_TIMER_FAILURE';

export const FETCH_DAYPASS_PROJECTS = 'FETCH_DAYPASS_PROJECTS';
export const FETCH_DAYPASS_PROJECTS_SUCCESS = 'FETCH_DAYPASS_PROJECTS_SUCCESS';
export const FETCH_DAYPASS_PROJECTS_FAILURE = 'FETCH_DAYPASS_PROJECTS_FAILURE';
