import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Container,
  Row,
  Col,
  Spinner,
  Label,
  Button,
  Table,
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  UncontrolledAlert,
} from 'reactstrap';
import Axios from 'axios';
import Switch from 'react-switch';

import { formatCurrency, round } from '../../../helpers/numbers';
import { groupBy, formatDuration } from '../../../helpers/utils';
import { fetchCompanies, fetchProjects } from '../../../store/actions';

async function getTimetrackers(userID) {
  const response = await Axios.get(`/api/timetracker/admin`, { params: { userID } });

  if (response && response.data && !response.data.error) {
    return { timers: response.data.timers, excludedIds: response.data.excludedIds };
  }
}

async function sendReminder(userID, projectID) {
  try {
    const response = await Axios.get(`/api/timetracker/send-task-reminder`, {
      params: { userID, projectID },
    });

    return response?.data?.error === false;
  } catch (error) {
    return false;
  }
}

async function closeTickets(userID, projectID) {
  try {
    const response = await Axios.get(`/api/timetracker/close-open-tasks`, {
      params: { userID, projectID },
    });

    return response?.data?.error === false;
  } catch (error) {
    return false;
  }
}

const CreateTimesheet = () => {
  const [company, setCompany] = useState('');
  const [userFilter, setUserFilter] = useState('');
  const [timers, setTimers] = useState(new Map());
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedTimers, setSelectedTimers] = useState(new Set());
  const [excludedTimers, setExcludedTimers] = useState(new Set());
  const [error, setError] = useState(null);
  const [companyWarning, setCompanyWarning] = useState(null);
  const [rateWarning, setRateWarning] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isNonBillable, setIsNonBillable] = useState(false);
  const [shouldEmail, setShouldEmail] = useState(false);
  const [useTime, setUseTime] = useState(false);

  const { profiles } = useSelector(state => state.Team);
  const dispatch = useDispatch();
  const { data } = useSelector(state => state.Admin);
  const projects = data && data.projects;

  useEffect(() => {
    if (!data.companies) dispatch(fetchCompanies());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!projects) dispatch(fetchProjects());
  }, [projects, dispatch]);

  const companies = (data?.companies && Object.values(data.companies)) || [];

  const currentTimers = timers.get(selectedProject);
  const currentTimersWithoutExclusions =
    currentTimers && currentTimers.filter(timer => !excludedTimers.has(timer));

  const total = isNonBillable
    ? 0
    : Array.from(selectedTimers).reduce(
        (accumulator, currentValue) => accumulator + currentValue.financials.estimatedCharge,
        0
      );
  const totalTime = Array.from(selectedTimers).reduce(
    (accumulator, currentValue) => accumulator + currentValue.totalTimes.totalTimeInSeconds,
    0
  );

  const totalUsingTime = isNonBillable
    ? 0
    : Array.from(selectedTimers).reduce(
        (accumulator, currentValue) =>
          accumulator +
          round(
            (Math.floor(currentValue.totalTimes.totalTimeInSeconds / 60) / 60) *
              currentValue.financials.hourlyRate
          ),
        0
      );

  const handleRowClick = useCallback(
    timer => {
      const timers = new Set(selectedTimers);
      timers.has(timer) ? timers.delete(timer) : timers.add(timer);
      setSelectedTimers(timers);
    },
    [selectedTimers]
  );

  function getStyleForTimesheetRow(timer) {
    let styleName = '';

    if (excludedTimers.has(timer)) {
      styleName = 'timesheet-blocked';
    }

    if (timer.notes) {
      styleName = 'timesheet-notes';
    }

    return styleName;
  }

  function checkCompanyMismatch(company, projectID) {
    let project = projects[projectID];
    if (company && project && project.billingCompanyID && project.billingCompanyID !== company) {
      const warningMessage = `✋ Project ${project.title} should be billed via ${
        companies.find(entry => entry.id === project.billingCompanyID)?.legalName
      }`;
      setCompanyWarning(warningMessage);
    } else {
      setCompanyWarning(null);
    }
  }

  function checkWorkerRates(workerID, projectID) {
    let project = projects[projectID];
    let worker = profiles[workerID];
    let projectWorker = project?.workers[workerID];
    if (worker && project && worker.financials?.rates && projectWorker) {
      const allRatesSorted = worker.financials?.rates?.sort((rate1, rate2) => {
        return new Date(rate2.validFrom) - new Date(rate1.validFrom);
      });
      const latestRate = allRatesSorted[0];
      const assignedRate = allRatesSorted.find(rate => rate.rateID === projectWorker.rateID);

      if (latestRate.rateID !== projectWorker.rateID) {
        var warningMessage = `❌ Worker ${worker.firstName} (${worker.userID}) is not on their latest rate for project ${project.title}`;

        if (assignedRate) {
          if (assignedRate.BHR === latestRate.BHR) {
            warningMessage = `⚠️ Worker ${worker.firstName} (${worker.userID}) rate is not using their latest rate for project ${project.title}, but hourly rate is the same.`;
          }
        }
        setRateWarning(warningMessage);
      } else {
        setRateWarning(null);
      }
    } else if (project) {
      setRateWarning(
        `❌ Project ${project.title} has no rates setup for ${worker.firstName} (${worker.userID})`
      );
    }
  }

  async function listTimersForWorker(workerID) {
    setUserFilter(workerID);
    setIsLoading(true);
    setSuccess(false);
    setSelectedProject(null);
    setSelectedTimers(new Set());

    try {
      const timersAndExclusions = await getTimetrackers(workerID);
      const timers = timersAndExclusions.timers;
      const exclusionIds = timersAndExclusions.excludedIds;
      const exclusionTimers = timers.filter(
        timer => exclusionIds.includes(timer.displayId) || timer.notes
      );
      setTimers(groupBy(timers, timer => timer.projectId));
      setExcludedTimers(new Set(exclusionTimers));
    } catch (error) {
      setError((error && error.message) || 'An unexpected error occurred');
    }
    setIsLoading(false);
  }

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Create Timesheet</h4>
          </Col>
        </Row>
      </div>

      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          <strong className="font-16">{success}</strong>.
        </UncontrolledAlert>
      )}

      {error && (
        <UncontrolledAlert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {error}
        </UncontrolledAlert>
      )}

      {(rateWarning || companyWarning) && (
        <UncontrolledAlert color="warning">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🤨
          </span>
          {rateWarning ?? ''}
          <span>
            <br />
          </span>
          {companyWarning ?? ''}
        </UncontrolledAlert>
      )}

      <Row>
        <Col sm="4">
          <Label for="company" className="d-block">
            <span className="order bg-primary">1</span>Select a company
          </Label>
          <select
            id="company"
            className="form-control d-inline-block w-auto m-r-10"
            value={company}
            onChange={async e => {
              const value = e.target.value;
              setError(null);
              setCompany(value);
              checkCompanyMismatch(value, selectedProject);
            }}
          >
            <option>Please select</option>
            {companies &&
              Object.values(companies)
                .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                .map(company => (
                  <option key={company.id} value={company.id}>
                    {company.legalName}
                  </option>
                ))}
          </select>
        </Col>
        <Col sm="4">
          <Label for="worker" className="d-block">
            <span className="order bg-primary">2</span>Select a worker
          </Label>
          <select
            id="worker"
            className="form-control d-inline-block w-auto m-r-10"
            value={userFilter}
            onChange={async e => {
              const value = e.target.value;
              await listTimersForWorker(value);
            }}
          >
            <option value=""></option>
            {profiles &&
              Object.values(profiles)
                .sort((a, b) => (b.firstName < a.firstName ? 1 : -1))
                .map(profile => (
                  <option key={profile.userID} value={profile.userID}>
                    {profile.firstName} {profile.lastName}
                  </option>
                ))}
          </select>
        </Col>
      </Row>

      <Label className="d-block m-t-30">
        <span className="order bg-primary">2</span>Select the project
      </Label>
      <Row>
        <Col sm={12}>
          <ListGroup vertical="true">
            {timers.size > 0 ? (
              Array.from(timers.keys()).map((project, index) => (
                <ListGroupItem key={index} tag="label">
                  <input
                    type="radio"
                    checked={selectedProject === project}
                    onChange={() => {
                      setError(null);
                      setSelectedTimers(new Set());
                      setSelectedProject(project);
                      checkCompanyMismatch(company, project);
                      checkWorkerRates(userFilter, project);
                    }}
                  />
                  <span className="m-l-10">{project}</span>
                </ListGroupItem>
              ))
            ) : (
              <div className="text-black-50">No projects</div>
            )}
          </ListGroup>
        </Col>
      </Row>

      <Label className="d-block m-t-30">
        <span className="order bg-primary">3</span>Select the items to be included in the timesheet
      </Label>

      <Button
        className="btn-info btn-sm m-b-10"
        disabled={!currentTimers || !currentTimers.length}
        onClick={() => {
          if (currentTimersWithoutExclusions) {
            if (currentTimersWithoutExclusions.length !== selectedTimers.size)
              setSelectedTimers(new Set(currentTimersWithoutExclusions));
            else setSelectedTimers(new Set());
          }
        }}
      >
        {(currentTimersWithoutExclusions &&
          currentTimersWithoutExclusions.length !== selectedTimers.size) ||
        !currentTimersWithoutExclusions
          ? 'Select All'
          : 'Deselect All'}
      </Button>
      <Button
        className="btn-info btn-sm m-l-10 m-b-10"
        disabled={!userFilter}
        onClick={async () => {
          setError(null);
          setSuccess(null);
          setIsLoading(true);
          try {
            const response = await sendReminder(userFilter, selectedProject);
            if (response) {
              setSuccess('Reminder has been sent');
            } else {
              setError('Sorry, could not send a reminder');
            }
          } catch (error) {
            setError(error);
          }

          setIsLoading(false);
        }}
      >
        Send Reminder To Close Tickets
      </Button>
      <Button
        className="btn-info btn-sm m-l-10 m-b-10"
        disabled={!userFilter}
        onClick={async () => {
          setError(null);
          setSuccess(null);
          setIsLoading(true);
          try {
            const response = await closeTickets(userFilter, selectedProject);
            if (response) {
              setSuccess('Tickets were closed');
              await listTimersForWorker(userFilter);
              setSelectedProject(selectedProject);
            } else {
              setError('Sorry, could not close the tickets');
            }
          } catch (error) {
            setError(error);
          }

          setIsLoading(false);
        }}
      >
        Close All Open Tickets
      </Button>
      <Card className="m-0">
        <CardBody className="p-0">
          <Table striped responsive className="table-sm m-0">
            <thead>
              <tr>
                <th>Selected</th>
                <th>Task ID</th>
                <th>Task Name</th>
                <th>From</th>
                <th>To</th>
                <th>Time Worked</th>
                <th>Estimated Charge</th>
                <th>Notes</th>
              </tr>
            </thead>
            <tbody>
              {!!(selectedProject && currentTimers)
                ? currentTimers.map(timer => (
                    <tr
                      className={getStyleForTimesheetRow(timer)}
                      key={timer.id}
                      onClick={() => {
                        if (!excludedTimers.has(timer)) {
                          handleRowClick(timer);
                        }
                      }}
                    >
                      <td className="text-center">
                        <input
                          type="checkbox"
                          checked={selectedTimers.has(timer)}
                          onChange={() => {
                            if (!excludedTimers.has(timer)) {
                              handleRowClick(timer);
                            }
                          }}
                          hidden={excludedTimers.has(timer)}
                        />
                      </td>
                      <td>{timer.displayId}</td>
                      <td>{timer.taskName}</td>
                      <td>
                        {timer.times[0] && new Date(timer.times[0].fromTime).toLocaleDateString()}
                      </td>
                      <td>
                        {timer.times[timer.times.length - 1] &&
                          new Date(timer.times[timer.times.length - 1].toTime).toLocaleDateString()}
                      </td>
                      <td>{timer.totalTimes.totalDisplayTime}</td>
                      <td>{formatCurrency(timer.financials.estimatedCharge)}</td>
                      <td>{timer.notes}</td>
                    </tr>
                  ))
                : !isLoading && (
                    <tr>
                      <td colSpan="8" className="text-center text-black-50">
                        No timetrackers
                      </td>
                    </tr>
                  )}
            </tbody>
          </Table>

          {isLoading && (
            <div className="text-center w-100">
              <Spinner color="primary" />
            </div>
          )}
        </CardBody>
      </Card>

      <Row>
        <Col xs={6}>
          <h6>Total Worked Time</h6>
        </Col>
        <Col xs={6} className="text-right">
          <h5>{formatDuration(totalTime * 1000)}</h5>
        </Col>
      </Row>
      <Row className="m-b-30">
        <Col xs={6}>
          <h6>Total Estimated Charge</h6>
        </Col>
        <Col xs={6} className="text-right">
          <h5>{useTime ? formatCurrency(totalUsingTime, 'GBP') : formatCurrency(total, 'GBP')}</h5>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-flex align-items-center m-b-15">
          <Switch
            onColor="#02a499"
            disabled={!selectedTimers.size}
            onChange={() => setIsNonBillable(!isNonBillable)}
            checked={isNonBillable}
          />
          <span className="m-l-10">Create a non-billable timesheet</span>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-flex align-items-center m-b-15">
          <Switch
            onColor="#02a499"
            disabled={!selectedTimers.size}
            onChange={() => setShouldEmail(!shouldEmail)}
            checked={shouldEmail}
          />
          <span className="m-l-10">Notify worker in email</span>
        </Col>
      </Row>

      <Row>
        <Col xs={12} className="d-flex align-items-center m-b-15">
          <Switch
            onColor="#02a499"
            disabled={!selectedTimers.size}
            onChange={() => setUseTime(!useTime)}
            checked={useTime}
          />
          <span className="m-l-10">Use time instead of decimals</span>
        </Col>
      </Row>

      <Button
        disabled={selectedTimers.size === 0 || isSubmitting}
        color="success"
        block
        className="m-b-30"
        onClick={async () => {
          try {
            setError(null);
            setIsSubmitting(true);
            const isSilent = !shouldEmail;
            const timetrackersIds = Array.from(selectedTimers).map(timer => timer.id);
            const response = await Axios.post('/api/admin/timesheets', {
              timetrackersIds,
              userID: userFilter,
              isBillable: !isNonBillable,
              billingCompanyID: company,
              isSilent: isSilent,
              useTime,
            });

            if (!response?.data?.error) {
              const timesheetID = response.data.timesheet?.id;
              const selectedCompany = companies.find(entry => entry.id === company);
              setSuccess(
                `Timesheet ${timesheetID} payable via ${
                  selectedCompany.legalName
                } has been created successfully. ${
                  isSilent ? 'No email was sent' : 'We emailed the user'
                }`
              );
              setUserFilter('');
              setSelectedProject(null);
              setSelectedTimers(new Set());
              setCompany('');
              setShouldEmail(false);
              setUseTime(false);
              setTimers(new Map());
            }
          } catch (error) {
            const data = (error && error.response && error.response.data) || error;
            setError((data && data.message) || 'An unexpected error occurred');
          }
          window.scrollTo({ top: 0 });
          setIsSubmitting(false);
          setIsNonBillable(false);
        }}
      >
        {isSubmitting ? 'Please wait...' : 'Create Timesheet'}
      </Button>
    </Container>
  );
};

export default CreateTimesheet;
