import React, { useState } from 'react';
import {
  CardFooter,
  Row,
  Col,
  ButtonDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import {
  approvePaymentRequest,
  fetchAdminTimesheets,
  rejectPaymentRequest,
} from '../../../store/admin/actions';
import { fetchTimesheets } from '../../../store/timesheets/actions';
import { PAYMENT_REQUEST_STATUS } from '../../TimesheetStatusMapping';

import PaymentModal from '../../../components/PaymentModal/PaymentModal.js';
import PaymentManualModal from '../../../components/PaymentManualModal/PaymentManualModal';

const disallowedStatuses = [
  PAYMENT_REQUEST_STATUS.EXPECTING_INVOICE,
  PAYMENT_REQUEST_STATUS.HOLDING_INVOICE,
  PAYMENT_REQUEST_STATUS.WAITING_RECIPIENT_INPUT_TO_PROCEED,
  PAYMENT_REQUEST_STATUS.PROCESSING,
  PAYMENT_REQUEST_STATUS.CANCELLED,
  PAYMENT_REQUEST_STATUS.FUNDS_REFUNDED,
];

const AdminTimesheetFooter = ({ timesheets, isAgent }) => {
  const [showPaidModal, setShowPaidModal] = useState(false);
  const [showMakePaymentModal, setShowMakePaymentModal] = useState(false);
  const [showSchedulePayment, setShowSchedulePayment] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [success, setSuccess] = useState();

  const [dropdownOpen, setOpen] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const profiles = useSelector(state => state.Team.profiles);
  const dispatch = useDispatch();

  const isBundle = timesheets.length > 1;
  const timesheet = timesheets[0];
  const paymentRequest = timesheet.paymentRequest;
  const isPaid = isBundle ? paymentRequest.isPaid : timesheet.isPaid;
  const billingCompanyID = isBundle
    ? paymentRequest.billingCompanyID
    : timesheet.financials.companyID;

  const { data } = useSelector(state => state.Admin);
  const companies = (data?.companies && Object.values(data.companies)) || [];

  const status = paymentRequest && paymentRequest.status;
  const canMarkAsPaid = !disallowedStatuses.includes(status);
  const isPayableBundle =
    status &&
    status === PAYMENT_REQUEST_STATUS.WAITING_FOR_PAYMENT &&
    paymentRequest.subcontractorInvoiceURL;
  const isPayableTimesheet =
    timesheet &&
    timesheet.isPaid === false &&
    timesheet.financials?.subcontractorInvoiceURL &&
    !paymentRequest;
  const isInvoicePendingReview =
    status &&
    status === PAYMENT_REQUEST_STATUS.HOLDING_INVOICE &&
    paymentRequest.subcontractorInvoiceURL;

  const resetAndCloseModal = () => {
    setSuccess(null);
    setUpdating(false);
    setShowPaidModal(false);
    setShowMakePaymentModal(false);
    setShowPaidModal(false);
    setShowSchedulePayment(false);

    if (success) {
      dispatch(fetchAdminTimesheets());
      dispatch(fetchTimesheets());
    }
  };

  const zohoIds = timesheets.map(t => t.financials.zohoTimesheetId).filter(t => t);
  const paymentProvider = paymentRequest && paymentRequest.paymentProvider;

  return (
    <>
      <CardFooter>
        <Row>
          <Col sm="9" className="d-flex align-items-center flex-wrap">
            {zohoIds.length > 0 && (
              <>
                <span className="m-r-5">Zoho ID{zohoIds.length > 1 ? 's' : ''}:</span>
                {zohoIds.map((id, i) => (
                  <React.Fragment key={id}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://people.zoho.eu/londonappworksltd/zp#timetracker/timesheet/edittimesheet-formId:5660000000014971/recordId:${id}`}
                    >
                      {id}
                    </a>
                    {i < zohoIds.length - 1 ? ',' : ''}{' '}
                  </React.Fragment>
                ))}
              </>
            )}
          </Col>
        </Row>
        {(isPayableBundle || isPayableTimesheet) && !isAgent && (
          <Row>
            <Col sm="9" className="d-flex align-items-center flex-wrap">
              This payment request has passed invoice validation and now is ready to be paid.
            </Col>
            <Col sm="3" className="m-b-10 m-sm-0 text-right">
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle}>
                <DropdownToggle caret color="primary">
                  Payment Options
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    disabled={!paymentProvider && !(!canMarkAsPaid || isPaid)}
                    onClick={() => setShowMakePaymentModal(true)}
                  >
                    Pay Now
                  </DropdownItem>
                  <DropdownItem
                    disabled={!paymentProvider && !(!canMarkAsPaid || isPaid)}
                    onClick={() => setShowSchedulePayment(true)}
                  >
                    Schedule Payment
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    disabled={!canMarkAsPaid || isPaid}
                    onClick={() => setShowPaidModal(true)}
                  >
                    Mark as Paid
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        )}
        {isInvoicePendingReview && (
          <Row>
            <Col sm="9" className="d-flex align-items-center flex-wrap">
              This payment request has failed automatic invoice validation and requires a review.
            </Col>
            <Col sm="3" className="m-b-10 m-sm-0 text-right">
              <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} disabled={updating}>
                <DropdownToggle caret color="primary">
                  Invoice Review
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    onClick={async () => {
                      setUpdating(true);
                      await dispatch(approvePaymentRequest(paymentRequest.id));

                      dispatch(fetchAdminTimesheets());
                      dispatch(fetchTimesheets());
                      setUpdating(false);
                    }}
                  >
                    Approve
                  </DropdownItem>
                  <DropdownItem
                    onClick={async () => {
                      setUpdating(true);
                      await dispatch(rejectPaymentRequest(paymentRequest.id));

                      dispatch(fetchAdminTimesheets());
                      dispatch(fetchTimesheets());
                      setUpdating(false);
                    }}
                  >
                    Reject
                  </DropdownItem>
                </DropdownMenu>
              </ButtonDropdown>
            </Col>
          </Row>
        )}
      </CardFooter>

      {/* Make Payment Now */}
      <PaymentModal
        centered
        isOpen={showMakePaymentModal}
        didTapCloseButton={resetAndCloseModal}
        didTapPayButton={resetAndCloseModal}
        timesheet={timesheet}
        isBundle={isBundle}
        paymentRequest={paymentRequest}
        profiles={profiles}
        companyBillingID={billingCompanyID}
        companies={companies}
      />

      {/* Schedule Payment Later */}
      <PaymentModal
        centered
        isOpen={showSchedulePayment}
        didTapCloseButton={resetAndCloseModal}
        didTapPayButton={resetAndCloseModal}
        timesheet={timesheet}
        isBundle={isBundle}
        paymentRequest={paymentRequest}
        profiles={profiles}
        nextPaymentDate={new Date()}
        companyBillingID={billingCompanyID}
        companies={companies}
      />

      {/* Mark Payment as Paid */}
      <PaymentManualModal
        centered
        paymentRequest={paymentRequest}
        isBundle={isBundle}
        timesheet={timesheet}
        companies={companies}
        profiles={profiles}
        isOpen={showPaidModal}
        didTapCloseButton={resetAndCloseModal}
        didTapMarkAsPaidButton={resetAndCloseModal}
        companyBillingID={billingCompanyID}
      ></PaymentManualModal>
    </>
  );
};

export default AdminTimesheetFooter;
