import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Spinner, Alert } from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';

import axios from 'axios';
import RosterTable from './RosterTable';

const Availability = () => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isIncomplete, setIsIncomplete] = useState(false);

  useEffect(() => {
    if (data) setIsIncomplete(data.some(i => !i.status));
  }, [data]);

  const fetchUserAvailability = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/api/availability/updatable`);
      setIsLoading(false);

      setData(response.data.availability);
    } catch (error) {
      const errorData = error?.response?.data;
      setIsLoading(false);
      toast.error(
        errorData?.message || 'Sorry, something went wrong trying to fetch your availability.'
      );
    }
  }, []);

  const updateAvailability = useCallback(
    async availabilityItem => {
      try {
        setIsLoading(true);

        const response = await axios.put(`/api/availability/day`, availabilityItem);
        setIsLoading(false);

        // Update the element
        const updatedElement = response.data.availability;
        let updatedData = [...data];
        const updatedIndex = updatedData.findIndex(item => {
          return item.day === updatedElement.day;
        });
        updatedData[updatedIndex] = updatedElement;
        console.log(updatedElement, updatedIndex, updatedData);
        setData(updatedData);
      } catch (error) {
        setIsLoading(false);
        const errorData = error?.response?.data;
        toast.error(errorData?.message || 'Sorry, something went wrong.');

        // Reset the data to the original
        setData([...data]);
      }
    },
    [data]
  );

  useEffect(() => {
    fetchUserAvailability();
  }, [fetchUserAvailability]);

  return (
    <Container fluid className="team position-relative">
      <div className="sticky-title">
        <Row className="align-items-center">
          <Col xs={6}>
            <h5 className="page-title">Availability</h5>
          </Col>
        </Row>
      </div>

      {isIncomplete && (
        <Alert color="warning" className="wrapped-alert m-t-20">
          <span role="img" aria-label="check" className="font-18 m-r-5">
            ☝️
          </span>
          Please select a status for every day shown below.
        </Alert>
      )}

      <Row>
        <Col className="position-relative">
          <RosterTable data={data} updateAvailability={updateAvailability} />

          {isLoading && (
            <div className="absolute-loader-container">
              <Spinner color="primary" />
            </div>
          )}
        </Col>
      </Row>
      <ReactTooltip />
    </Container>
  );
};

export default Availability;
