import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import MetisMenu from 'metismenujs';

import Sidenav from './SideNav';

import 'react-perfect-scrollbar/dist/css/styles.css';

class Sidebar extends Component {
  componentDidMount() {
    new MetisMenu('#menu', {});

    var matchingMenuItem = null;
    var ul = document.getElementById('menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }

    if (window.innerWidth < 992) {
      document.body.classList.toggle('enlarged');
    }
  }

  activateParentDropdown = item => {
    item.classList.add('mm-active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active'); // li
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');
        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('active');
          }
        }
      }
      return false;
    }
  };

  componentDidUpdate() {
    if (this.props.is_large_state === true) {
      new MetisMenu('#menu', {});
    }
  }

  render() {
    return (
      <div className="left side-menu">
        {this.props.is_large_state ? (
          <Sidenav />
        ) : (
          <PerfectScrollbar>
            <Sidenav />
          </PerfectScrollbar>
        )}

        <div className="clearfix"></div>
      </div>
    );
  }
}

const mapStatetoProps = state => {
  const {
    Layout: { is_large_state },
  } = state;

  return { is_large_state };
};

export default withRouter(connect(mapStatetoProps)(Sidebar));
