import React from 'react';
import { Field } from 'redux-form';
import { Card, CardBody, FormGroup, Label, Button, Alert } from 'reactstrap';
import { isEU } from '../../helpers/utils';

import CountryField from '../Subpages/CountryField';
import {
  renderField,
  bicValidation,
  phoneValidator,
  sortcodeValidation,
  required,
  optional,
  accountIBANValidation,
  accountUKValidation,
  card4DigitsUA,
  EUVATwithoutCountryISO,
  notEmpty,
  ifscCodeValidation,
  accountIndianValidation,
  accountSouthAfricanValidation,
  email,
} from './validation';
import TeamHeader from './TeamHeader';

const BusinessInfo = ({
  isAdmin,
  getVIES,
  getPayee,
  currencyCode,
  officeCountryISO,
  isPaymentApproved,
  workerType,
}) => {
  const isEUcountry = isEU(officeCountryISO);
  const isUmbrella = workerType === 'umbrella';

  return (
    <Card>
      <CardBody>
        <FormGroup>
          <TeamHeader
            icon="briefcase"
            title="Business Details"
            subtitle="Bank account and registration details of the business."
          />
        </FormGroup>
        <FormGroup>
          <Label for="businessName">Business Name</Label>
          {isEUcountry && (
            <Alert color="info">
              {isAdmin ? (
                <span>
                  For EU: This is set by 1. Selecting country in Office Adress, 2. Entering EUVAT
                  without country code (e.g. GB1234566 should be 1234566) 3. Pressing Verify. <br />
                  For non-EU: edit this field directly.
                </span>
              ) : (
                <span>
                  Your Business name is set by your EU VAT number. Make sure country is set in{' '}
                  <strong>Office Address</strong>, then Press Verify.
                </span>
              )}
            </Alert>
          )}
          <Field
            component="input"
            className="form-control"
            id="businessName"
            name="businessName"
            type="text"
            disabled={isEUcountry && !isAdmin}
          />
        </FormGroup>
        {isUmbrella && (
          <>
            <FormGroup>
              <Label for="businessDirectorName">
                Business Authorised Director <span className="text-danger">*</span>
              </Label>
              <Alert color="info">
                <span>Name of the business director, who is authorised to sign agreements.</span>
              </Alert>
              <Field
                component={renderField}
                className="form-control"
                id="businessDirectorName"
                name="businessDirectorName"
                type="text"
                validate={[required]}
              />
            </FormGroup>
            <FormGroup>
              <Label for="businessEmail">
                Business Accounts Email <span className="text-danger">*</span>
              </Label>
              <Alert color="info">
                <span>
                  Email address of the business director. They will receive paperwork to sign from
                  Figaro.
                </span>
              </Alert>
              <Field
                component={renderField}
                className="form-control"
                id="businessEmail"
                name="businessEmail"
                type="email"
                validate={[required, email]}
              />
            </FormGroup>
          </>
        )}
        <FormGroup>
          <Label for="financials.taxID">
            Tax ID / EU VAT / TIN{' '}
            {isEUcountry && !isAdmin && <span className="text-danger">*</span>}
          </Label>
          <Field
            component={renderField}
            className="form-control"
            id="financials.taxID"
            name="financials.taxID"
            type="text"
            validate={[
              isEUcountry && !isAdmin ? required : optional,
              isEUcountry ? EUVATwithoutCountryISO : notEmpty,
            ]}
          />
          {isEUcountry && (
            <div className="d-flex" style={{ marginTop: '10px', marginBottom: '5px' }}>
              <Button className="btn-primary" onClick={getVIES}>
                Verify EUVAT
              </Button>
            </div>
          )}
        </FormGroup>
        {!isPaymentApproved && (
          <FormGroup>
            <Label>
              <div style={{ display: 'inline' }}>
                <span role="img" aria-label="checkbox" style={{ padding: '5px' }}>
                  ❌
                </span>
                <span> Account verification failure. Check bank account details.</span>
              </div>
            </Label>
          </FormGroup>
        )}
        {isPaymentApproved && (
          <FormGroup>
            <Label>
              <div style={{ display: 'inline' }}>
                <span role="img" aria-label="checkbox" style={{ padding: '5px' }}>
                  ✅
                </span>
                <span>
                  {' '}
                  Account Verified. Payments <strong>will be</strong> processed.
                </span>
              </div>
            </Label>
          </FormGroup>
        )}
        <FormGroup>
          <div className="d-flex" style={{ marginTop: '10px', marginBottom: '5px' }}>
            <Button
              className={`btn-${isPaymentApproved ? 'success' : 'danger'}`}
              onClick={getPayee}
            >
              {isPaymentApproved ? 'Re-' : ''}Verify Bank Account
            </Button>
          </div>
        </FormGroup>
        <FormGroup>
          <Label for="financials.bicSwiftSortCode">
            BIC / SWIFT / Sort Code / IFSC{' '}
            {currencyCode !== 'UAH' && <span className="text-danger">*</span>}
          </Label>
          <Field
            component={renderField}
            className="form-control"
            style={{ backgroundColor: 'red' }}
            id="financials.bicSwiftSortCode"
            name="financials.bicSwiftSortCode"
            type="text"
            validate={[
              currencyCode === 'GBP'
                ? sortcodeValidation
                : currencyCode === 'INR'
                ? ifscCodeValidation
                : bicValidation,
              currencyCode === 'UAH' ? optional : required,
            ]}
            required
          />
        </FormGroup>
        <FormGroup>
          <Label for="financials.ibanAccountNumber">
            IBAN / Account Number / PrivatBank Card 4 Digits <span className="text-danger">*</span>
          </Label>
          <Field
            component={renderField}
            className="form-control"
            id="financials.ibanAccountNumber"
            name="financials.ibanAccountNumber"
            type="text"
            validate={[
              currencyCode === 'GBP'
                ? accountUKValidation
                : currencyCode === 'UAH'
                ? card4DigitsUA
                : currencyCode === 'INR'
                ? accountIndianValidation
                : currencyCode === 'ZAR'
                ? accountSouthAfricanValidation
                : accountIBANValidation,
              required,
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="financials.accountPhoneNumber">
            Account Phone Number{' '}
            {(currencyCode === 'ZAR' || currencyCode === 'UAH') && (
              <span className="text-danger">*</span>
            )}
          </Label>
          <div>Phone number that bank has on file associated with business bank account.</div>
          <Field
            component={renderField}
            className="form-control"
            id="financials.accountPhoneNumber"
            name="financials.accountPhoneNumber"
            type="text"
            validate={[
              phoneValidator,
              currencyCode === 'ZAR' || currencyCode === 'UAH' ? required : phoneValidator,
            ]}
          />
        </FormGroup>
        <FormGroup>
          <Label for="financials.currency">
            Currency <span className="text-danger">*</span>
          </Label>
          <div>Currency of business bank account. Invoices are always in Pound Sterling.</div>
          <Field
            component="select"
            className="form-control"
            id="financials.currency"
            name="financials.currency"
            validate={[required]}
          >
            <option>Please Select</option>
            <option value="GBP">GBP - Pound Sterling</option>
            <option value="HUF">HUF - Hungarian Forint</option>
            <option value="EUR">EUR - Euro</option>
            <option value="USD">USD - US Dollars</option>
            <option value="UAH">UAH - Ukrainian Hryvnia</option>
            <option value="RON">RON - Romanian Leu</option>
            <option value="ZAR">ZAR - South African Rand</option>
            <option value="INR">INR - Indian rupee</option>
            <option value="XAU" disabled={!isAdmin}>
              Gold
            </option>
            <option value="XBT" disabled={!isAdmin}>
              Bitcoin
            </option>
            <option value="LTC" disabled={!isAdmin}>
              Litecoin
            </option>
            <option value="XLM" disabled={!isAdmin}>
              Stellar Lumen
            </option>
          </Field>
        </FormGroup>
        <FormGroup>
          <Label for="financials.countryISO">
            Country of Account <span className="text-danger">*</span>
          </Label>
          <div>
            Country of business account, make sure it is set correctly or the payment will fail.
          </div>
          <CountryField name="financials.countryISO" validate={required} />
        </FormGroup>
      </CardBody>
    </Card>
  );
};

export default BusinessInfo;
