import React, { useState, useCallback, useEffect } from 'react';
import Switch from 'react-switch';

import {
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';

const RadioSettings = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [radioUrl, setRadioUrl] = useState('');
  const [autoplay, setAutoplay] = useState(false);
  const [visibility, setVisibility] = useState(false);

  useEffect(() => {
    const autoplaySettings = (settings && settings.radioAutoplay) || false;
    const radioUrlSettings = (settings && settings.radioUrl) || '';
    const radioVisibility = (settings && settings.radioVisibility) || false;
    console.log(radioVisibility);
    setAutoplay(autoplaySettings);
    setRadioUrl(radioUrlSettings);
    setVisibility(radioVisibility);
  }, [settings]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: {
          radioUrl: radioUrl,
          radioAutoplay: autoplay,
          radioVisibility: visibility,
        },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [radioUrl, autoplay, visibility]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/radio1.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Azure Logo"
                  />
                  Office Radio
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Figaro allows you to share music with your co-workers{' '}
              <strong>directly on the Dashboard</strong>. Provide supported stream URL compatible
              with HTML5 Audio tag and it will become available to the whole team.
            </p>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Audio Streaming URL
              </Label>
              <Col sm="8">
                <Input
                  id="radioUrlKey"
                  type="text"
                  value={radioUrl}
                  onChange={event => setRadioUrl(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Auto Play
              </Label>
              <Col sm="8">
                <div className="d-flex align-items-center">
                  <Switch
                    onColor="#626ed4"
                    onChange={() => {
                      setAutoplay(!autoplay);
                    }}
                    checked={autoplay}
                  />
                  <span className="m-l-10">
                    Start playback automatically as soon as music is buffered.
                  </span>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Visibility
              </Label>
              <Col sm="8">
                <div className="d-flex align-items-center">
                  <Switch
                    onColor="#626ed4"
                    onChange={() => {
                      setVisibility(!visibility);
                    }}
                    checked={visibility}
                  />
                  <span className="m-l-10">Show or hide the player on user's dashboard.</span>
                </div>
              </Col>
            </FormGroup>
            {radioUrl && radioUrl.length && (!visibility || autoplay) && (
              <UncontrolledAlert color="warning">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⚠️
                </span>
                Take care not to annoy people. You have enabled some settings that might be very
                annoying and should be used carefully!
                <ul>
                  {autoplay && (
                    <li>
                      <strong>AUTOPLAY IS ON</strong> - The stream will start playing automatically
                      for every logged in user.
                    </li>
                  )}
                  {!visibility && (
                    <li>
                      <strong>VISIBILITY IS OFF</strong> - The player controls are hidden! Users
                      won't be able to stop the playback. This can be very distracting.
                    </li>
                  )}
                </ul>
              </UncontrolledAlert>
            )}
            <FormGroup row>
              <Col sm="12" className="offset-2">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || (!radioUrl.length && (visibility || autoplay))}
                  onClick={onSubmit}
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default RadioSettings;
