import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { signOut } from '../../../store/auth/actions';

const ProfileMenu = ({ profile, signOut, history }) => {
  const [isOpen, setIsOpen] = useState(false);

  if (!profile) return null;

  return (
    <Dropdown
      isOpen={isOpen}
      toggle={() => setIsOpen(!isOpen)}
      className="notification-list list-inline-item nav-pro-img"
      tag="li"
    >
      <DropdownToggle className="nav-link arrow-none nav-user waves-effect" tag="a">
        {!profile.photo ? (
          <i className="fas fa-user font-18" />
        ) : (
          <div
            className="photo rounded-circle"
            style={{ backgroundImage: profile.photo && `url(${profile.photo})` }}
          />
        )}
      </DropdownToggle>
      <DropdownMenu className="profile-dropdown" right>
        <DropdownItem onClick={() => history.push(`/profile/${profile.userID}`)}>
          <i className="mdi mdi-account-circle"></i> Profile
        </DropdownItem>
        <div className="dropdown-divider"></div>
        <DropdownItem className="text-danger" onClick={() => signOut()}>
          <i className="mdi mdi-power text-danger"></i> Logout
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

const mapStateToProps = state => ({
  profile: state.Team.profiles && state.Team.profiles[state.Auth.userID],
});

export default withRouter(connect(mapStateToProps, { signOut })(ProfileMenu));
