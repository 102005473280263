import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Modal,
  ModalBody,
  Button,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Badge,
  Alert,
} from 'reactstrap';

import Axios from 'axios';
import Switch from 'react-switch';

import { formatCurrency } from '../../helpers/numbers';
import { statusMapping, PAYMENT_REQUEST_STATUS } from '../../../src/pages/TimesheetStatusMapping';
import { getNextPayDate } from '../../../src/helpers/utils';
import { toast } from 'react-toastify';

const PaymentModal = ({
  paymentRequest,
  timesheet,
  companies,
  profiles,
  isOpen,
  isBundle,
  didTapCloseButton,
  didTapPayButton,
  nextPaymentDate,
  companyBillingID,
}) => {
  const itemId = isBundle ? paymentRequest.id : timesheet.id;
  const isPaid = isBundle ? paymentRequest.isPaid : timesheet.isPaid;
  const totalAmount = isBundle ? paymentRequest.totalAmount : timesheet.financials.totalAmount;
  const currency = isBundle ? paymentRequest.currency : timesheet.financials.currency;
  const status = isBundle
    ? paymentRequest?.status.toUpperCase()
    : timesheet?.paymentRequest?.status.toUpperCase();
  const mappedStatus = statusMapping[status ?? PAYMENT_REQUEST_STATUS.NOT_AVAILABLE.toUpperCase()];
  const payee = isBundle ? profiles[paymentRequest.requester] : profiles[timesheet.userID];
  const shouldSchedule = nextPaymentDate !== undefined;

  const [updating, setUpdating] = useState(false);

  const [shouldPayVAT, setShouldPayVAT] = useState(false);
  const [shouldPayNow, setShouldPayNow] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState('');
  const [billingCompany, setBillingCompany] = useState(companyBillingID ?? '');
  const [reference, setReference] = useState(itemId);
  const [description, setDescription] = useState(itemId);
  const [payday, setPayday] = useState(-1);

  const paymentCompany =
    companies && billingCompany && companies.find(item => item.id === billingCompany);
  const paymentProviders = paymentCompany && paymentCompany?.bankingDetails?.providers;

  async function payNow() {
    setUpdating(true);
    const url = isBundle ? `/api/bundle/pay/${itemId}` : `/api/timesheet/pay/${itemId}`;

    try {
      const response = await Axios(url, {
        method: 'POST',
        data: {
          payVAT: shouldPayVAT,
          provider: selectedProvider,
          companyID: billingCompany,
          shouldPayNow: shouldPayNow,
        },
      });
      const payment = response && response.data;
      toast.success(
        `Payment ${payment.isPaid ? 'was successful' : 'has not been paid'} and has ${
          payment.payment.id ? payment.payment.id : 'no'
        } transfer ID. ${
          payment.isPaid
            ? 'Email was sent to the worker. Payment will reach their account around ' +
              moment(payment.estimatedDeliveryDate).format('dddd DD MMM YYYY, HH:mm')
            : ''
        } `
      );
      didTapPayButton();
    } catch (error) {
      const data = error && error.response && error.response.data;
      toast.error((data && data.message) || 'There was a problem with your request');
    }
    setUpdating(false);
  }

  async function schedulePayment() {
    setUpdating(true);
    const url = isBundle
      ? `/api/bundle/schedule/${itemId}/1`
      : `/api/timesheet/schedule/${itemId}/1`;

    try {
      const response = await Axios(url, {
        method: 'POST',
        data: {
          paymentProvider: selectedProvider,
          reference: reference,
          description: description,
          shouldEmail: true,
          shouldPayVAT: shouldPayVAT,
        },
      });
      const isScheduled = response?.data?.error === false;
      if (isScheduled) {
        toast.success(
          `Payment was scheduled for ${
            isBundle ? 'bundle' : 'timesheet'
          } ${itemId} and will be executed on the next payment run on. Email has been sent to the user.`
        );
        didTapPayButton();
      } else {
        toast.error(
          `Payment scheduling failed for ${
            isBundle ? 'bundle' : 'timesheet'
          } ${itemId} and will NOT be executed on the next payment run.`
        );
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      toast.error((data && data.message) || 'There was a problem with your request');
    }
    setUpdating(false);
  }

  useEffect(() => {
    //TODO: replace with fetch for payment day based on Settings
    setPayday(-1);
  }, []); // eslint-disable-line

  return (
    <Modal centered isOpen={isOpen} toggle={didTapCloseButton} style={{ width: '100%' }}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Payment Details</h5>
        <button type="button" onClick={didTapCloseButton} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="invoice-title">
                      <img src={require('../../images/transfer.png')} alt="logo" height="24" />
                      <span className="m-l-10 p-t-5">
                        {isBundle ? 'Bundle' : 'Single Timesheet'} Payment
                      </span>
                    </div>
                    <hr />
                    <Row>
                      <Col xs="6" lg="6">
                        <p>
                          {new Date().toLocaleString(undefined, {
                            timeZone: 'UTC',
                            dateStyle: 'short',
                          })}
                        </p>
                      </Col>
                      <Col xs="6" lg="6" className="text-right">
                        <Badge
                          color={mappedStatus.color}
                          pill
                          className="d-flex align-items-center font-14"
                        >
                          {mappedStatus.title}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" lg="6">
                        <address>
                          <Label for="company" className="card-title">
                            Billing Company
                          </Label>
                          <select
                            id="company"
                            className="form-control d-inline-block m-r-10"
                            value={billingCompany}
                            onChange={async e => {
                              const value = e.target.value;
                              setBillingCompany(value);
                            }}
                            disabled={updating || shouldSchedule}
                          >
                            <option value="">Please select</option>
                            {companies &&
                              Object.values(companies)
                                .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                                .map(company => (
                                  <option key={company.id} value={company.id}>
                                    {company.legalName}
                                  </option>
                                ))}
                          </select>
                        </address>
                      </Col>
                      <Col xs="6" lg="6" className="text-right">
                        <address>
                          <Label for="provider" className="card-title">
                            Payment Method
                          </Label>
                          <select
                            id="provider"
                            className="form-control d-inline-block m-r-10"
                            value={selectedProvider}
                            onChange={async e => {
                              const value = e.target.value;
                              setSelectedProvider(value);
                            }}
                          >
                            <option value="">Please select</option>
                            {paymentProviders &&
                              paymentProviders.map(provider => (
                                <option key={provider} value={provider}>
                                  {provider.charAt(0).toUpperCase() + provider.slice(1)}
                                </option>
                              ))}
                          </select>
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" lg="3" className="m-t-5">
                        <address>
                          <strong>Payee</strong>
                        </address>
                      </Col>
                      <Col xs="9" lg="9" className="text-right">
                        <address>
                          <p>
                            {payee?.firstName} {payee?.lastName}
                          </p>
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" lg="3">
                        <address>
                          <p className="m-t-5">
                            <strong>Reference</strong>
                          </p>
                        </address>
                      </Col>
                      <Col xs="9" lg="9" className="text-right">
                        <address>
                          <input
                            type="text"
                            value={reference ?? 'No Ref.'}
                            className="form-control"
                            onChange={e => {
                              setReference(e.target.value);
                            }}
                          />
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" lg="3">
                        <address>
                          <p className="m-t-5">
                            <strong>Description</strong>
                          </p>
                        </address>
                      </Col>
                      <Col xs="9" lg="9" className="text-right">
                        <address>
                          <input
                            type="text"
                            value={description}
                            className="form-control"
                            onChange={e => {
                              setDescription(e.target.value);
                            }}
                          />
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" lg="6">
                        <address>
                          <strong>Source Amount</strong>
                        </address>
                        <address>
                          <strong>VAT Amount</strong>
                        </address>
                      </Col>
                      <Col xs="6" lg="6" className="text-right">
                        <address>{formatCurrency(totalAmount, currency)}</address>
                        <address>
                          {formatCurrency(totalAmount * (shouldPayVAT ? 0.2 : 0), currency)}
                        </address>
                      </Col>
                    </Row>
                    <Row className="m-t-5">
                      <Col xs="6" lg="6" className="total">
                        <address>
                          <strong>Total Amount</strong>
                        </address>
                      </Col>
                      <Col xs="6" lg="6" className="text-right total">
                        {formatCurrency(shouldPayVAT ? totalAmount * 1.2 : totalAmount, currency)}
                        <br />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="2" lg="2" className="m-t-10">
                        <Switch
                          onColor="#626ed4"
                          className="m-t-5"
                          onChange={() => {
                            setShouldPayVAT(!shouldPayVAT);
                          }}
                          checked={shouldPayVAT}
                        />
                      </Col>
                      <Col xs="10" lg="10" className="m-t-10 text-right">
                        <span className="p-b-10">
                          Add UK VAT of 20%. This is automatically applied for any supplier based in
                          the UK.
                        </span>
                      </Col>
                    </Row>

                    {!shouldSchedule && (
                      <Row>
                        <Col xs="2" lg="2" className="m-t-10">
                          <Switch
                            onColor="#626ed4"
                            className="m-t-5"
                            onChange={() => {
                              setShouldPayNow(!shouldPayNow);
                            }}
                            checked={shouldPayNow}
                          />
                        </Col>
                        <Col xs="10" lg="10" className="m-t-10 text-right">
                          <span className="p-b-10">
                            Create and fund the transaction now. If disabled then transaction needs
                            to be funded manually via Wise.
                          </span>
                        </Col>
                      </Row>
                    )}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {shouldSchedule && (
          <Row>
            <Col>
              {payday && (
                <Alert className="total text-center" color="info">
                  Next Payment Run {getNextPayDate(payday)}
                </Alert>
              )}
            </Col>
          </Row>
        )}
        <Row>
          <Col xs={isPaid ? 12 : 6}>
            <Button block color="secondary" onClick={didTapCloseButton} disabled={updating}>
              Cancel
            </Button>
          </Col>
          {!isPaid && (
            <Col xs="6">
              <Button
                block
                color={shouldSchedule ? 'dark' : 'primary'}
                onClick={() => {
                  if (shouldSchedule) {
                    schedulePayment();
                  } else {
                    payNow();
                  }
                }}
                disabled={updating || !selectedProvider || !billingCompany || !reference}
              >
                {shouldSchedule ? 'Schedule Payment' : 'Pay Now'}
              </Button>
            </Col>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PaymentModal;
