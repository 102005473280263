import React from 'react';

export default ({ column }) => (
  <span style={{ marginLeft: '3px' }}>
    {column.isSorted ? (
      column.isSortedDesc ? (
        <i className="fas fa-sort-down" />
      ) : (
        <i className="fas fa-sort-up" />
      )
    ) : (
      <i className="fas fa-sort" />
    )}
  </span>
);
