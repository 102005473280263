import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_CARDS,
  FETCH_DASHBOARD_CARDS_FAILURE,
  FETCH_DASHBOARD_CARDS_SUCCESS,
} from './actionTypes';
import { SIGN_OUT } from '../auth/actionTypes';

const initialState = {
  data: null,
  isLoading: false,
  error: null,
};

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD:
    case FETCH_DASHBOARD_CARDS:
      return {
        ...state,
        isLoading: true,
        error: null,
      };

    case FETCH_DASHBOARD_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          virtualOffice: action.virtualOffice,
          outOfOffice: action.outOfOffice,
          alert: action.alert,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_DASHBOARD_CARDS_SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          freshdeskCards: action.cards,
        },
        isLoading: false,
        error: null,
      };

    case FETCH_DASHBOARD_FAILURE:
    case FETCH_DASHBOARD_CARDS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    case SIGN_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default dashboard;
