import React from 'react';
import { Badge } from 'reactstrap';

import { calculateDuration } from './utils';
import { sendNotification } from './notifications';
import { setGlobalAlert, fetchTimetrackers } from '../store/actions';

let timeouts = {};
let pollTimeouts = {};

export const clearTimetracker = id => {
  clearTimeout(timeouts[id]);
  delete timeouts[id];

  clearTimeout(pollTimeouts[id]);
  delete pollTimeouts[id];
};

/**
 * Setup a Timetracker
 *
 * @param {object} timer The timetracker object
 * @param {function} setCurrentTime A React set state method to update the current time
 * @param {function} dispatch A Redux dispatcher
 */
export const setupTimetracker = (timer, setCurrentTime, dispatch, interval = 1000) => {
  if (!timer) return;

  if (timer.isCurrentlyRunning && !timeouts[timer.id]) {
    const { duration, baseTime } = calculateDuration(timer.times, 0);
    const color = timer.projectColor || '#02a499';
    setCurrentTime(duration / 1000);

    timeouts[timer.id] = setInterval(() => {
      const { duration } = calculateDuration(timer.times, baseTime);
      setCurrentTime(duration / 1000);

      const allowedEffort = getAllowedEffort(timer);

      if (allowedEffort > 0) {
        const hours = duration / 1000 / 60 / 60;

        if (hours >= allowedEffort) {
          // Send 100% notification
          sendNotification('Timer at 100%!', `${timer.id}_100`, true, () => {
            dispatch(
              setGlobalAlert(
                <>
                  <span className="m-r-5">You've reached the maximum planned time for task</span>
                  {renderTaskBadge(timer, color)}
                </>,
                'warning',
                '⏰'
              )
            );
          });
        } else if (hours >= allowedEffort * 0.8) {
          // Send 80% notification
          sendNotification('Timer at 80%!', `${timer.id}_80`, false, () => {
            dispatch(
              setGlobalAlert(
                <>
                  <span className="m-r-5">
                    You have reached 80% of the maximum allowed time for task
                  </span>
                  {renderTaskBadge(timer, color)}
                </>,
                'primary',
                '⏰'
              )
            );
          });
        }
      }
    }, interval);

    pollTimetrackers(timer.id, dispatch);
  } else {
    clearTimetracker(timer.id);

    if (timer.totalTimes) setCurrentTime(timer.totalTimes.totalTimeInSeconds);
  }
};

function pollTimetrackers(id, dispatch) {
  if (!pollTimeouts[id])
    pollTimeouts[id] = setInterval(() => {
      dispatch(fetchTimetrackers());
    }, 60000);
}

function renderTaskBadge(timer, color) {
  return (
    <>
      <Badge pill style={{ backgroundColor: color }} className="m-r-5 font-500">
        <span>#{timer.displayId ? timer.displayId : timer.taskId}</span>
      </Badge>
      <span className="text-secondary">{timer.taskName}</span>
    </>
  );
}

/**
 * Get the appropriate allowed effort for a task.
 *
 * @param {object} tracker
 */
function getAllowedEffort(tracker) {
  if (tracker)
    return tracker.remainingEffort !== undefined && tracker.remainingEffort !== null
      ? tracker.remainingEffort && parseFloat(tracker.remainingEffort)
      : tracker.allowedEffort && parseFloat(tracker.allowedEffort);
}
