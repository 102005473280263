import React from 'react';
import { Row, Col, Card, CardBody, Table, Spinner, Alert, Badge } from 'reactstrap';
import moment from 'moment';
import './VirtualOfficeTable.scss';

const VirtualOfficeTable = ({ dashboard, profiles }) => {
  const { data, isLoading, error } = dashboard;
  const entries = data && data.virtualOffice;
  const outOfOffice = data && data.outOfOffice;

  return (
    <Row className="virtual-office-table">
      <Col lg="12">
        <Card>
          <CardBody>
            <h4 className="mt-0 header-title">Today in our Office</h4>
            {isLoading && (
              <div className={`text-center ${data ? 'spinner-with-data' : ''}`}>
                <Spinner color="primary" />
              </div>
            )}
            {!isLoading && error && (
              <Alert color="danger m-t-20">
                <span role="img" aria-label="see no evil" className="font-18 m-r-5">
                  🙈
                </span>
                There was a problem trying to fetch this!
              </Alert>
            )}
            {entries && (
              <Table responsive striped className="mb-0 m-t-30">
                <thead>
                  <tr>
                    <th>VoIP</th>
                    <th>Team Member</th>
                    <th>Check-In</th>
                    <th>Location</th>
                    <th>Project</th>
                    <th>Task</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {entries.map((entry, index) => {
                    let voIP;
                    let jobDescription;
                    let address;
                    let countryISO;
                    let flagSrc;
                    let photo;
                    let isAgent;

                    if (profiles && profiles[entry.userID]) {
                      voIP = profiles[entry.userID].voip;
                      jobDescription = profiles[entry.userID].job.description;
                      photo = profiles[entry.userID].photo;
                      isAgent = profiles[entry.userID].type === 'agent';
                      const officeAddress = profiles[entry.userID].officeAddress;

                      if (officeAddress) {
                        address = `${officeAddress.city}${
                          officeAddress.country ? `, ${officeAddress.country}` : ''
                        }`;
                        countryISO = officeAddress.countryISO;
                      }
                    }

                    const when = entry.isCurrentlyRunning
                      ? 'Now'
                      : entry.updatedAt
                      ? new Date(entry.updatedAt).toLocaleTimeString().substr(0, 5)
                      : '';

                    if (entry.location && entry.location.countryISO) {
                      countryISO = entry.location.countryISO;
                    }

                    if (countryISO)
                      flagSrc = `https://github.com/hampusborgos/country-flags/raw/main/png100px/${countryISO
                        .toLowerCase()
                        .replace('-cn', '')}.png`;

                    return (
                      <tr key={index}>
                        <td>
                          <span>{voIP && <PhoneBadge number={voIP} active />}</span>
                        </td>
                        <td className="who-row">
                          {!photo ? (
                            <div>
                              <i className="fas fa-user font-18" />
                              <span title={jobDescription}>{entry.employeeFirstName}</span>
                              {isAgent && (
                                <Badge
                                  pill
                                  style={{ backgroundColor: entry.projectColor }}
                                  className="m-l-10"
                                >
                                  Freelancer
                                </Badge>
                              )}
                            </div>
                          ) : (
                            <div>
                              <img
                                src={photo}
                                alt={entry.employeeFirstName}
                                className="thumb-md rounded-circle mr-2"
                                style={{
                                  height: photo ? '68px' : '35px',
                                  width: photo ? '68px' : '35px',
                                  display: 'block',
                                  float: 'none',
                                  objectFit: 'cover',
                                  overflow: 'hidden',
                                  border: `1px solid`,
                                  color: entry.projectColor,
                                }}
                                // style={{
                                //   height: photo ? '100px' : '35px',
                                //   width: photo ? 'auto' : '35px',
                                //   display: 'block',
                                //   float: 'none',
                                //   'object-fit': 'cover',
                                //   overflow: 'hidden',
                                //   border: `1px solid`,
                                //   color: entry.projectColor,
                                // }}
                              />

                              <span title={jobDescription}>
                                {entry.employeeFirstName} {entry.employeeLastName}
                                {isAgent && (
                                  <Badge
                                    pill
                                    style={{ backgroundColor: entry.projectColor }}
                                    className="m-l-10"
                                  >
                                    Freelancer
                                  </Badge>
                                )}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>{when}</td>
                        <td className="where-row">
                          {flagSrc && (
                            <img
                              src={flagSrc}
                              alt={countryISO}
                              title={countryISO}
                              className="flag"
                            />
                          )}
                          {entry.location ? (
                            <>
                              {entry.location.displayName ? (
                                <span>{entry.location.displayName}</span>
                              ) : (
                                <span>
                                  {entry.location.city}, {entry.location.country}
                                </span>
                              )}
                              {entry.location.officeBookingUrl && (
                                <a
                                  href={entry.location.officeBookingUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Click here to book the nearest office or meeting"
                                >
                                  <Badge color="secondary">{entry.location.nearestOffice}</Badge>
                                </a>
                              )}
                            </>
                          ) : (
                            <span>{address && address.toLowerCase()}</span>
                          )}
                        </td>
                        <td>
                          <div>
                            <Badge pill style={{ backgroundColor: entry.projectColor }}>
                              {entry.projectId}
                            </Badge>
                          </div>
                        </td>
                        <td>
                          <Badge
                            className="badge-light border align-middle text-secondary"
                            style={{ marginRight: '3px' }}
                          >
                            #{entry.displayId}
                          </Badge>
                          <span className="align-middle">{entry.taskName}</span>
                        </td>
                        <td>
                          <WorkingIndicator entry={entry} />
                        </td>
                      </tr>
                    );
                  })}
                  {data &&
                    data.leaves &&
                    data.leaves.map((leave, index) => {
                      const voIP =
                        data.profiles &&
                        data.profiles[leave.userID] &&
                        data.profiles[leave.userID]['Internal VoIP'];
                      const days = parseInt(leave['Days Taken']);
                      const isAgent = data.profiles[leave.userID].type !== 'agent';
                      return (
                        <tr key={index}>
                          <td className="who-row">
                            <span>{leave.ownerName}</span>
                            {voIP && <PhoneBadge number={voIP} active />}
                            {isAgent && (
                              <Badge pill color="warning" className="m-l-10">
                                Freelancer
                              </Badge>
                            )}
                          </td>
                          <td>Until {leave.To}</td>
                          <td>Out of Office</td>
                          <td>{leave['Leave Type']}</td>
                          <td>
                            🏝 Away for {days} day{!days || days > 1 ? 's' : ''}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <h4 className="mt-0 header-title">Out of Office</h4>
            {outOfOffice && (
              <Table responsive striped className="mb-0 m-t-30">
                <thead>
                  <tr>
                    <th>VoIP</th>
                    <th>Team Member</th>
                    <th>Last Seen</th>
                    <th>Location</th>
                    <th>Return Time</th>
                    <th>Reason</th>
                  </tr>
                </thead>
                <tbody>
                  {outOfOffice.map((entry, index) => {
                    let voIP;
                    let jobDescription;
                    let address;
                    let countryISO;
                    let flagSrc;
                    let photo;
                    let lastSeen;
                    let isAgent;

                    if (profiles && profiles[entry.userID]) {
                      voIP = profiles[entry.userID].voip;
                      jobDescription = profiles[entry.userID].job.title;
                      photo = profiles[entry.userID].photo;
                      lastSeen = profiles[entry.userID].lastSeen;
                      isAgent = profiles[entry.userID].type === 'agent';

                      const officeAddress = profiles[entry.userID].officeAddress;

                      if (officeAddress) {
                        address = `${officeAddress.city}${
                          officeAddress.country ? `, ${officeAddress.country}` : ''
                        }`;
                        countryISO = officeAddress.countryISO;
                      }
                    }

                    if (countryISO)
                      flagSrc = `https://github.com/hampusborgos/country-flags/raw/main/png100px/${countryISO
                        .toLowerCase()
                        .replace('-cn', '')}.png`;
                    return (
                      <tr key={index}>
                        <td>
                          <span>
                            {voIP ? (
                              <PhoneBadge number={voIP} active />
                            ) : (
                              <PhoneBadge number={'N/A'} />
                            )}
                          </span>
                        </td>
                        <td className="who-row">
                          {!photo ? (
                            <div>
                              <i className="fas fa-user font-18" />
                              <span title={jobDescription}>{entry.firstName}</span>
                            </div>
                          ) : (
                            <div>
                              <img
                                src={photo}
                                alt={entry.firstName}
                                className={'thumb-md rounded-circle mr-2'}
                                style={{
                                  height: photo ? '48px' : '35px',
                                  width: photo ? '48px' : '35px',
                                  border: `1px solid`,
                                  color: entry.projectColor,
                                }}
                              />

                              <span title={jobDescription}>
                                {entry.firstName} {entry.lastName}
                                {isAgent && (
                                  <Badge pill color="warning" className="m-l-10">
                                    Freelancer
                                  </Badge>
                                )}
                              </span>
                            </div>
                          )}
                        </td>
                        <td>{lastSeen ? moment(lastSeen).fromNow() : 'N/A'}</td>
                        <td className="where-row">
                          {flagSrc && (
                            <img
                              src={flagSrc}
                              alt={countryISO}
                              title={countryISO}
                              className="flag"
                            />
                          )}
                          {entry.location ? (
                            <>
                              {entry.location.displayName ? (
                                <span>{entry.location.displayName}</span>
                              ) : (
                                <span>
                                  {entry.location.city}, {entry.location.country}
                                </span>
                              )}
                              {entry.location.officeBookingUrl && (
                                <a
                                  href={entry.location.officeBookingUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title="Click here to book the nearest office or meeting"
                                >
                                  <Badge color="secondary">{entry.location.nearestOffice}</Badge>
                                </a>
                              )}
                            </>
                          ) : (
                            <span>{address && address.toLowerCase()}</span>
                          )}
                        </td>
                        <td>
                          <div>
                            <Badge pill style={{ backgroundColor: '#2B2D2F' }}>
                              N/A
                            </Badge>
                          </div>
                        </td>
                        <td>
                          <WorkingIndicator comment={'Private Reason'} />
                        </td>
                      </tr>
                    );
                  })}
                  {data &&
                    data.leaves &&
                    data.leaves.map((leave, index) => {
                      const voIP =
                        data.profiles &&
                        data.profiles[leave.userID] &&
                        data.profiles[leave.userID]['Internal VoIP'];
                      const days = parseInt(leave['Days Taken']);

                      return (
                        <tr key={index}>
                          <td className="who-row">
                            <span>{leave.ownerName}</span>
                            {voIP && <PhoneBadge number={voIP} />}
                          </td>
                          <td>Until {leave.To}</td>
                          <td>Out of Office</td>
                          <td>{leave['Leave Type']}</td>
                          <td>
                            🏝 Away for {days} day{!days || days > 1 ? 's' : ''}
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </Table>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

const PhoneBadge = ({ number, active = false }) => (
  <Badge
    pill
    className="phone"
    color={active ? 'warning' : 'secondary'}
    title="Internal phone number"
    style={{ marginTop: '5px' }}
    {...(active && {
      href: `https://londonappworks.3cx.co.uk:5001/webclient/#/call?phone=${number}`,
      target: '_blank',
    })}
  >
    <span role="img" aria-label="Internal phone number">
      📞
    </span>
    <span>{number}</span>
  </Badge>
);

const WorkingIndicator = ({ entry, comment }) => (
  <div className="working-indicator">
    {entry && !entry.isDayPass && (
      <>
        <i className={entry.isCurrentlyRunning ? 'green-indicator' : 'yellow-indicator'} />
        <span style={{ verticalAlign: 'middle' }}>
          {entry.isCurrentlyRunning ? 'Working now' : 'Worked today'}
        </span>
      </>
    )}
    {entry && entry.isDayPass && (
      <>
        <i className="blue-indicator" />
        <span style={{ verticalAlign: 'middle' }}>Working all day</span>
      </>
    )}
    {!entry && (
      <>
        <i className="grey-indicator" />
        <span style={{ verticalAlign: 'middle' }}>{comment}</span>
      </>
    )}
  </div>
);

export default VirtualOfficeTable;
