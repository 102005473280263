import axios from 'axios';
import {
  FETCH_TIMER,
  FETCH_TIMER_SUCCESS,
  FETCH_TIMER_FAILURE,
  FETCH_TICKETS,
  FETCH_DAYPASS_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_FAILURE,
  START_TIMER,
  START_TIMER_DONE,
  STOP_TIMER,
  STOP_TIMER_DONE,
  CLOCKIN_TIMER,
  CLOCKIN_TIMER_SUCCESS,
  CLOCKIN_TIMER_FAILURE,
  UPDATE_TIMER,
  UPDATE_TIMER_SUCCESS,
  UPDATE_TIMER_FAILURE,
  FETCH_DAYPASS_PROJECTS,
  FETCH_DAYPASS_PROJECTS_SUCCESS,
  FETCH_DAYPASS_PROJECTS_FAILURE,
} from './actionTypes';
import { signOut } from '../auth/actions';
import { setGlobalAlert } from '../layout/actions';

export const fetchTimetrackers = () => {
  return async (dispatch, getState) => {
    dispatch(fetchTimetrackerAction());
    const currentTimers = getState().Timetracker.timers;

    try {
      const response = await axios.get('/api/timetracker');

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error && data.timers) {
          const currentRunningTimer = currentTimers.find(t => t.isCurrentlyRunning);
          const updatedTimer =
            currentRunningTimer && data.timers.find(t => t.id === currentRunningTimer.id);

          if (
            currentRunningTimer &&
            currentRunningTimer.isCurrentlyRunning &&
            !updatedTimer.isCurrentlyRunning
          ) {
            dispatch(setGlobalAlert('The timer was stopped!', 'warning', '⏰'));
          }
          dispatch(fetchTimetrackerSuccessAction(data.timers));
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch(fetchTimetrackerFailureAction(errorMessage));
    }
  };
};

const fetchTimetrackerAction = () => {
  return {
    type: FETCH_TIMER,
  };
};

const fetchTimetrackerSuccessAction = data => {
  return {
    type: FETCH_TIMER_SUCCESS,
    data,
  };
};

const fetchTimetrackerFailureAction = error => {
  return {
    type: FETCH_TIMER_FAILURE,
    error,
  };
};

export const startTimetracker = (trackerId, ticket, location) => {
  return async dispatch => {
    dispatch({ type: START_TIMER });

    try {
      const response = await axios.post('/api/timetracker/start', {
        id: trackerId,
        ticket,
        location,
      });

      if (response) {
        const { data } = response;
        if (data && !data.error) {
          dispatch(fetchTimetrackerSuccessAction(data.timers));

          if (ticket) dispatch(fetchTickets());
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch(fetchTimetrackerFailureAction(errorMessage));
    }

    dispatch({ type: START_TIMER_DONE });
  };
};

export const clockInTimetracker = (trackerId, ticket, location, halfday) => {
  return async dispatch => {
    dispatch({ type: CLOCKIN_TIMER });
    try {
      const response = await axios.post('/api/timetracker/clockin', {
        id: trackerId,
        ticket,
        location,
        isDayPass: true,
        halfday,
      });

      if (response) {
        const { data } = response;
        if (data && !data.error) {
          dispatch(fetchTimetrackerSuccessAction(data.timers));
          if (ticket) dispatch(fetchTickets());
          dispatch({
            type: CLOCKIN_TIMER_SUCCESS,
            message: 'Thank you for reporting your work today!',
          });
        } else {
          dispatch({ type: CLOCKIN_TIMER_FAILURE, error: data.error });
        }
      }
    } catch (error) {
      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch(fetchTimetrackerFailureAction(errorMessage));
    }
  };
};

export const stopTimetracker = () => {
  return async dispatch => {
    dispatch({ type: STOP_TIMER });

    try {
      const response = await axios.put('/api/timetracker/stop');

      if (response) {
        const { data } = response;
        if (data && !data.error) {
          dispatch(fetchTimetrackerSuccessAction(data.timers));
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      dispatch({ type: STOP_TIMER_DONE });

      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) {
        dispatch(signOut());
        return;
      }

      setTimeout(() => {
        dispatch(setGlobalAlert(errorMessage, 'danger', '⚠️'));
      }, 1000);
      dispatch(fetchTimetrackers());
    }
  };
};

export const updateTimetracker = (id, props) => {
  return async dispatch => {
    dispatch({ type: UPDATE_TIMER });

    try {
      const response = await axios.put('/api/timetracker', { id, ...props });

      if (response) {
        const { data } = response;
        if (data && !data.error) {
          dispatch({
            type: UPDATE_TIMER_SUCCESS,
            message: 'All good! The timetracker was updated successfully.',
          });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      dispatch({ type: STOP_TIMER_DONE });

      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch({ type: UPDATE_TIMER_FAILURE, error: errorMessage });
    }
  };
};

const fetchTicketsAction = () => {
  return {
    type: FETCH_TICKETS,
  };
};

const fetchDaypassTicketsAction = () => {
  return {
    type: FETCH_DAYPASS_TICKETS,
  };
};

const fetchTicketsSuccessAction = data => {
  return {
    type: FETCH_TICKETS_SUCCESS,
    data,
  };
};

const fetchTicketsFailureAction = error => {
  return {
    type: FETCH_TICKETS_FAILURE,
    error,
  };
};

export const fetchTickets = () => {
  return async dispatch => {
    dispatch(fetchTicketsAction());

    try {
      const response = await axios.get('/api/timetracker/tickets');

      if (response) {
        const { data } = response;

        if (data && data.message) {
          dispatch(setGlobalAlert(data.message, 'warning', '🤦‍♂️'));
        }

        if (data && !data.error) {
          dispatch(fetchTicketsSuccessAction(data.tickets));
        } else {
          throw Error(data.error);
        }
      }
    } catch (error) {
      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch(fetchTicketsFailureAction(errorMessage));
    }
  };
};

export const fetchDaypassTickets = () => {
  return async dispatch => {
    dispatch(fetchDaypassTicketsAction());

    try {
      const response = await axios.get('/api/timetracker/tickets?daypass=1');
      if (response) {
        const { data } = response;

        if (data && data.message) {
          dispatch(setGlobalAlert(data.message, 'warning', '🤦‍♂️'));
        }

        if (data && !data.error) {
          dispatch(fetchTicketsSuccessAction(data.tickets));
        } else {
          throw Error(data.error);
        }
      }
    } catch (error) {
      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch(fetchTicketsFailureAction(errorMessage));
    }
  };
};

const fetchDaypassProjectsAction = () => {
  return {
    type: FETCH_DAYPASS_PROJECTS,
  };
};

const fetchDaypassProjectSuccess = data => {
  return {
    type: FETCH_DAYPASS_PROJECTS_SUCCESS,
    data,
  };
};

const fetchDaypassProjectFailure = error => {
  return {
    type: FETCH_DAYPASS_PROJECTS_FAILURE,
    error,
  };
};

export const fetchDaypassProjects = () => {
  return async dispatch => {
    dispatch(fetchDaypassProjectsAction());

    try {
      const response = await axios.get('/api/projects/daypass');
      if (response) {
        const { data } = response;

        if (data && data.message) {
          dispatch(setGlobalAlert(data.message, 'warning', '🤦‍♂️'));
        }

        if (data && !data.error) {
          dispatch(fetchDaypassProjectSuccess(data.projects));
        } else {
          throw Error(data.error);
        }
      }
    } catch (error) {
      const response = error && error.response;
      const data = response && error.response.data;
      const errorMessage =
        (data && data.message) || (response && response.statusText) || 'Something went wrong...';

      if (response && response.status === 401) dispatch(signOut());
      dispatch(fetchDaypassProjectFailure(errorMessage));
    }
  };
};
