import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col, Card, Spinner, Alert, Button, Badge } from 'reactstrap';
import Switch from 'react-switch';
import ReactTooltip from 'react-tooltip';
import { fetchTeam } from '../../store/team/actions';

import axios from 'axios';
import './Team.scss';

const Team = ({ history }) => {
  const dispatch = useDispatch();
  const { profiles: teamProfiles, isLoading, error } = useSelector(state => state.Team);
  const { userID } = useSelector(state => state.Auth);
  const ownProfile = teamProfiles[userID];

  const [submissionSuccess, setSubmissionSuccess] = useState(null);
  const [submissionError, setSubmissionError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (teamProfiles && Object.keys(teamProfiles).length === 1) {
      setSubmissionError(null);
      setSubmissionSuccess(null);
      dispatch(fetchTeam());
    }
  }, []); // eslint-disable-line

  const changeActiveState = async (userID, shouldBeActive) => {
    setSubmissionError(null);
    setSubmissionSuccess(null);
    setIsSubmitting(true);
    try {
      const response = await axios(`/api/profiles/${userID}`, {
        method: 'PUT',
        data: { isActive: shouldBeActive },
      });
      setIsSubmitting(false);
      setSubmissionSuccess(true);
      if (response.data.profile) {
        dispatch(fetchTeam());
      }
      if (response.data.message) {
        setSubmissionError(response.data.message);
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      setIsSubmitting(false);
      console.log(error);
      setSubmissionError(data ? data.message : true);
    }
  };

  const setAvailabilityRequired = async userID => {
    setSubmissionError(null);
    setSubmissionSuccess(null);
    setIsSubmitting(true);
    try {
      const response = await axios(`/api/profiles/${userID}`, {
        method: 'PUT',
        data: { availabilityRequired: true },
      });
      setIsSubmitting(false);
      setSubmissionSuccess(true);
      if (response.data.profile) {
        dispatch(fetchTeam());
      }
      if (response.data.message) {
        setSubmissionError(response.data.message);
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      setIsSubmitting(false);
      console.log(error);
      setSubmissionError(data ? data.message : true);
    }
  };

  return (
    <Container fluid className="team position-relative">
      <div className="sticky-title">
        <Row className="align-items-center">
          <Col xs={6}>
            <h5 className="page-title">Team</h5>
          </Col>
          {ownProfile.isAdmin && (
            <Col xs={6} className="text-right">
              <Button color="primary" onClick={() => history.push(`/profile/new`)}>
                Create Profile
              </Button>
            </Col>
          )}
        </Row>
        {!isLoading && error && (
          <Alert color="danger m-t-20">
            <span role="img" aria-label="see no evil" className="font-18 m-r-5">
              🙈
            </span>
            There was a problem trying to fetch this!
          </Alert>
        )}
      </div>

      {isLoading && (
        <div className={`text-center ${teamProfiles ? 'spinner-with-data' : ''}`}>
          <Spinner color="primary" />
        </div>
      )}
      <Row>
        <Col key="submission_result">
          <Alert
            color="danger"
            className="m-t-20"
            isOpen={!isSubmitting && !!submissionError}
            toggle={() => setSubmissionError(null)}
          >
            <span role="img" aria-label="see no evil" className="font-18 m-r-5">
              🙈
            </span>
            {submissionError || 'Sorry, something went wrong'}
          </Alert>

          <Alert
            color="info"
            className="m-t-20"
            isOpen={!isSubmitting && !!submissionSuccess}
            toggle={() => setSubmissionSuccess(null)}
          >
            <span role="img" aria-label="sucess" className="font-18 m-r-5">
              👍
            </span>
            {typeof submissionSuccess === 'string'
              ? submissionSuccess
              : 'The profile was updated successfully'}
          </Alert>
        </Col>
      </Row>
      <Row>
        {teamProfiles &&
          Object.keys(teamProfiles).map(key => {
            const profile = teamProfiles[key];
            const name = `${profile.firstName || ''} ${profile.lastName || ''}`;
            var location = '';
            if (profile.officeAddress) {
              var locationArray = [];
              if (profile.officeAddress.city) locationArray.push(profile.officeAddress.city);
              if (profile.officeAddress.country) locationArray.push(profile.officeAddress.country);
              location = locationArray.join(', ');
            }
            /* eslint-disable jsx-a11y/anchor-is-valid */
            return (
              <Col key={key} xl="4" md="6" className="m-b-30">
                <Card className="directory-card h-100 m-0">
                  <div className="d-flex">
                    <div>
                      <div
                        className="photo-bg img-thumbnail rounded-circle thumb-lg d-flex align-items-center justify-content-center"
                        style={{ backgroundImage: `url(${require('../../images/logo_150.png')})` }}
                      >
                        <div
                          className="photo rounded-circle p-0"
                          style={{
                            backgroundImage: profile.photo && `url(${profile.photo})`,
                            height: profile.photo ? '80px' : '70px',
                            width: profile.photo ? '80px' : '70px',
                          }}
                        ></div>
                      </div>
                      {/* <Badge
                        pill
                        color={profile.isInToday ? 'success' : 'danger'}
                        className="font-14 d-block p-1 m-t-5"
                      >
                        {profile.isInToday ? 'Available' : 'Out of Office'}
                      </Badge> */}
                      {ownProfile.isAdmin && (
                        <Badge
                          pill
                          color={
                            new Date() < Date.parse(profile.workExpiry) &&
                            new Date() < Date.parse(profile.schedule1Expiry)
                              ? 'success'
                              : 'danger'
                          }
                          className="font-14 d-block p-1 m-t-5"
                        >
                          {new Date() < Date.parse(profile.workExpiry) &&
                          new Date() < Date.parse(profile.schedule1Expiry)
                            ? 'Valid'
                            : 'Warning'}
                        </Badge>
                      )}
                    </div>

                    <div className="contents">
                      <h5 className="text-primary font-18 mt-0">{name}</h5>
                      <h6 className="font-12">{(profile.job && profile.job.title) || ''}</h6>
                      <div className="font-12 mb-1">
                        {(profile.job && profile.job.description) || ''}
                      </div>
                      <div className="font-12">{(!!location.length && location) || ''}</div>
                    </div>

                    <ul className="list-unstyled social-links float-right">
                      {profile.voip && (
                        <li>
                          <a
                            href={`https://londonappworks.3cx.co.uk:5001/webclient/#/call?phone=${profile.voip}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            alt="Click to start a call via 3CX"
                            data-tip="Start a call on 3CX"
                            className="btn-primary"
                          >
                            <i className="mdi mdi-phone"></i>
                          </a>
                        </li>
                      )}

                      {ownProfile.isAdmin &&
                        profile.availabilityRequired !== undefined &&
                        profile.isActive && (
                          <li>
                            <a
                              onClick={() => {
                                setAvailabilityRequired(profile.userID);
                              }}
                              disabled={isSubmitting || !profile.isActive}
                              href="#"
                              className={
                                profile?.availabilityRequired ? 'btn-danger' : 'btn-primary'
                              }
                              rel="noopener noreferrer"
                              alt={
                                profile?.availabilityRequired
                                  ? 'Request missing availability'
                                  : 'Send extraordinary reminder'
                              }
                              data-tip={
                                profile?.availabilityRequired
                                  ? 'Request missing availability'
                                  : 'Send extraordinary reminder'
                              }
                            >
                              <i className="mdi mdi-calendar"></i>
                            </a>
                          </li>
                        )}

                      {profile.email && (
                        <li>
                          <a
                            href={`mailto:${profile.email}?subject=Message from your colleague`}
                            className="btn-primary"
                            target="_blank"
                            rel="noopener noreferrer"
                            alt="Send an email now"
                            data-tip="Send an email now"
                          >
                            <i className="mdi mdi-email"></i>
                          </a>
                        </li>
                      )}
                    </ul>
                  </div>

                  {ownProfile && (ownProfile.isAdmin || ownProfile.userID === profile.userID) && (
                    <>
                      <hr />
                      <Row>
                        <Col>
                          <button
                            onClick={() => history.push(`/profile/${profile.userID}`)}
                            className="btn btn-outline-primary"
                            type="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <i className="mdi mdi-pencil mr-2"></i>Edit Profile
                          </button>
                        </Col>
                        <Col className="text-right">
                          {ownProfile && ownProfile.isAdmin && profile && (
                            <div>
                              <label className="switch-label">
                                {profile.isActive ? 'Ready for Work' : 'Currently Unavailable'}
                              </label>
                              <Switch
                                disabled={isSubmitting}
                                id={'profileActive_' + profile.userID}
                                onColor="#626ed4"
                                onChange={checked => {
                                  changeActiveState(profile.userID, checked);
                                }}
                                checked={profile.isActive}
                                alt={
                                  profile.isActive
                                    ? 'Set user as inactive (will disappear from team list and dashboard for all, but admins)'
                                    : 'Set user active and able to work.'
                                }
                                data-tip={
                                  profile.isActive
                                    ? 'Set user as inactive (will disappear from team list and dashboard for all, but admins)'
                                    : 'Set user active and able to work.'
                                }
                              />
                            </div>
                          )}
                        </Col>
                      </Row>
                    </>
                  )}
                </Card>
              </Col>
            );
          })}
      </Row>
      <ReactTooltip />
    </Container>
  );
};

//export default Team;
export default Team;
