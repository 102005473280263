import React, { useState } from 'react';
import moment from 'moment';
import Axios from 'axios';
import { Modal, ModalBody, Button, Row, Col, Label, Card, CardBody, Badge } from 'reactstrap';
import Switch from 'react-switch';
import { toast } from 'react-toastify';
import { formatCurrency } from '../../helpers/numbers';
import { statusMapping, PAYMENT_REQUEST_STATUS } from '../../../src/pages/TimesheetStatusMapping';

const PaymentManualModal = ({
  paymentRequest,
  isBundle,
  timesheet,
  companies,
  profiles,
  isOpen,
  didTapCloseButton,
  didTapMarkAsPaidButton,
  companyBillingID,
}) => {
  const itemId = isBundle ? paymentRequest.id : timesheet.id;
  const isPaid = isBundle ? paymentRequest.isPaid : timesheet.isPaid;
  const totalAmount = isBundle ? paymentRequest.totalAmount : timesheet.financials.totalAmount;
  const currency = isBundle ? paymentRequest.currency : timesheet.financials.currency;
  const status = isBundle
    ? paymentRequest?.status.toUpperCase()
    : timesheet?.paymentRequest?.status.toUpperCase();
  const mappedStatus = statusMapping[status ?? PAYMENT_REQUEST_STATUS.NOT_AVAILABLE.toUpperCase()];
  const payee = isBundle ? profiles[paymentRequest.requester] : profiles[timesheet.userID];
  const [shouldPayVAT, setShouldPayVAT] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState('none');
  const [billingCompany, setBillingCompany] = useState(companyBillingID ?? '');
  const [reference, setReference] = useState(itemId);
  const [description, setDescription] = useState(itemId);

  const [updating, setUpdating] = useState(false);

  const [shouldEmail, setShouldEmail] = useState(false);

  const paymentCompany =
    companies && billingCompany && companies.find(item => item.id === billingCompany);
  const paymentProviders = paymentCompany && ['none', ...paymentCompany?.bankingDetails?.providers];

  async function doMarkPaid() {
    setUpdating(true);
    const url = isBundle ? `/api/bundle/${itemId}/paid/1` : `/api/timesheet/${itemId}/paid/1`;

    try {
      const response = await Axios(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        data: {
          shouldPayVAT: shouldPayVAT,
          transactionID: reference,
          paymentProvider: selectedProvider,
          paymentDate: moment()
            .add('18', 'hours')
            .toDate(),
          description: description,
          reference: reference,
          shouldEmail: shouldEmail,
        },
      });
      const payment = response && response.data;

      toast.success(
        `Payment ${payment.isPaid ? 'was successful' : 'has not been paid'} and has ${
          payment.payment.id ? payment.payment.id : 'no'
        } transfer ID. ${
          payment.isPaid
            ? 'Email was sent to the worker. Payment will reach their account around ' +
              moment(payment.estimatedDeliveryDate).format('dddd DD MMM YYYY, HH:mm')
            : ''
        } `
      );
      closeAndReset();
      didTapMarkAsPaidButton();
    } catch (error) {
      const data = error && error.response && error.response.data;
      toast.error((data && data.message) || 'There was a problem with your request');
    }
    setUpdating(false);
  }

  function closeAndReset() {
    setUpdating(null);
    setShouldEmail(false);
    setShouldPayVAT(false);
    setBillingCompany('');
    setReference('');
    setDescription('');
  }

  return (
    <Modal centered isOpen={isOpen} toggle={didTapCloseButton} style={{ width: '100%' }}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Payment Details</h5>
        <button type="button" onClick={didTapCloseButton} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <Row>
          <Col xs="12">
            <Card>
              <CardBody>
                <Row>
                  <Col>
                    <div className="invoice-title">
                      <img src={require('../../images/payday.png')} alt="logo" height="24" />
                      <span className="m-l-10 p-t-5">
                        {isBundle ? 'Bundle' : 'Single Timesheet'} Payment
                      </span>
                    </div>
                    <hr />
                    <Row>
                      <Col xs="6" lg="6">
                        <p>
                          {new Date().toLocaleString(undefined, {
                            timeZone: 'UTC',
                            dateStyle: 'short',
                          })}
                        </p>
                      </Col>
                      <Col xs="6" lg="6" className="text-right">
                        <Badge
                          color={mappedStatus.color}
                          pill
                          className="d-flex align-items-center font-14"
                        >
                          {mappedStatus.title}
                        </Badge>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" lg="6">
                        <address>
                          <Label for="company" className="card-title">
                            Billing Company
                          </Label>
                          <select
                            id="company"
                            className="form-control d-inline-block m-r-10"
                            value={billingCompany}
                            onChange={async e => {
                              const value = e.target.value;
                              setBillingCompany(value);
                            }}
                          >
                            <option value="">Please select</option>
                            {companies &&
                              Object.values(companies)
                                .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                                .map(company => (
                                  <option key={company.id} value={company.id}>
                                    {company.legalName}
                                  </option>
                                ))}
                          </select>
                        </address>
                      </Col>
                      <Col xs="6" lg="6" className="text-right">
                        <address>
                          <Label for="provider" className="card-title">
                            Payment Method
                          </Label>
                          <select
                            id="provider"
                            className="form-control d-inline-block m-r-10"
                            value={selectedProvider}
                            onChange={async e => {
                              const value = e.target.value;
                              setSelectedProvider(value);
                            }}
                          >
                            <option value="">Please select</option>
                            {paymentProviders &&
                              paymentProviders.map(provider => (
                                <option key={provider} value={provider}>
                                  {provider.charAt(0).toUpperCase() + provider.slice(1)}
                                </option>
                              ))}
                          </select>
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" lg="3" className="m-t-5">
                        <address>
                          <strong>Payee</strong>
                        </address>
                      </Col>
                      <Col xs="9" lg="9" className="text-right">
                        <address>
                          <p>
                            {payee?.firstName} {payee?.lastName}
                          </p>
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" lg="3">
                        <address>
                          <p className="m-t-5">
                            <strong>Reference</strong>
                          </p>
                        </address>
                      </Col>
                      <Col xs="9" lg="9" className="text-right">
                        <address>
                          <input
                            type="text"
                            value={reference ?? 'No Ref.'}
                            className="form-control"
                            onChange={e => {
                              setReference(e.target.value);
                            }}
                          />
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="3" lg="3">
                        <address>
                          <p className="m-t-5">
                            <strong>Description</strong>
                          </p>
                        </address>
                      </Col>
                      <Col xs="9" lg="9" className="text-right">
                        <address>
                          <input
                            type="text"
                            value={description}
                            className="form-control"
                            onChange={e => {
                              setDescription(e.target.value);
                            }}
                          />
                        </address>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs="6" lg="6">
                        <address>
                          <strong>Source Amount</strong>
                        </address>
                        <address>
                          <strong>VAT Amount</strong>
                        </address>
                      </Col>
                      <Col xs="6" lg="6" className="text-right">
                        <address>{formatCurrency(totalAmount, currency)}</address>
                        <address>
                          {formatCurrency(totalAmount * (shouldPayVAT ? 0.2 : 0), currency)}
                        </address>
                      </Col>
                    </Row>
                    <Row className="m-t-5">
                      <Col xs="6" lg="6" className="total">
                        <address>
                          <strong>Total Amount</strong>
                        </address>
                      </Col>
                      <Col xs="6" lg="6" className="text-right total">
                        {formatCurrency(shouldPayVAT ? totalAmount * 1.2 : totalAmount, currency)}
                        <br />
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="2" lg="2" className="m-t-10">
                        <Switch
                          onColor="#626ed4"
                          className="m-t-5"
                          onChange={() => {
                            setShouldPayVAT(!shouldPayVAT);
                          }}
                          checked={shouldPayVAT}
                        />
                      </Col>
                      <Col xs="10" lg="10" className="m-t-10 text-right">
                        <span className="p-b-10">
                          Add UK VAT of 20%. This is automatically applied for any supplier based in
                          the UK.
                        </span>
                      </Col>
                    </Row>

                    <Row>
                      <Col xs="2" lg="2" className="m-t-10">
                        <Switch
                          onColor="#626ed4"
                          className="m-t-5"
                          onChange={() => {
                            setShouldEmail(!shouldEmail);
                          }}
                          checked={shouldEmail}
                        />
                      </Col>
                      <Col xs="10" lg="10" className="m-t-10 text-right">
                        <span className="p-b-10">
                          Email worker about this payment being marked as paid.
                        </span>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col xs={isPaid ? 12 : 6}>
            <Button block color="secondary" onClick={didTapCloseButton}>
              Cancel
            </Button>
          </Col>
          {!isPaid && (
            <Col xs="6">
              <Button
                block
                color={'primary'}
                onClick={() => {
                  doMarkPaid();
                }}
                disabled={updating || !selectedProvider || !billingCompany || !reference}
              >
                Mark as Paid
              </Button>
            </Col>
          )}
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default PaymentManualModal;
