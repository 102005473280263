import React, { useReducer, useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { Col, FormGroup, Label, Row, Button, Modal, ModalBody, Alert } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { fetchUserProfile } from '../../store/team/actions';

const NewRateModal = ({ isOpen, hide, userID }) => {
  const firstInput = useRef(null);
  const [error, setError] = useState();
  const [submitting, setSubmitting] = useState(false);
  const reduxDispatch = useDispatch();

  const onSubmit = async event => {
    try {
      event.preventDefault();
      setSubmitting(true);
      const response = await axios(`/api/profiles/${userID}/rate`, {
        method: 'POST',
        data: state,
      });

      if (response && !response.data.error) {
        reduxDispatch(fetchUserProfile(userID));
      }
    } catch (error) {
      setSubmitting(false);
      const data = error && error.response && error.response.data;
      setError(data ? data.message : `Something went wrong...`);
    }
  };

  const defaultState = {
    BHR: '',
    currency: 'GBP',
    OT: 1,
    maxDailyHours: '',
    validFrom: new Date().toISOString(),
    comment: '',
  };

  function reducer(state, action) {
    switch (action.field) {
      case 'BHR':
        return { ...state, BHR: action.value };
      case 'currency':
        return { ...state, currency: action.value };
      case 'OT':
        return { ...state, OT: action.value };
      case 'maxDailyHours':
        return { ...state, maxDailyHours: action.value };
      case 'validFrom':
        return { ...state, validFrom: new Date(action.value).toISOString() };
      case 'comment':
        return { ...state, comment: action.value };

      default:
        break;
    }
  }

  const [state, dispatch] = useReducer(reducer, defaultState);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        firstInput.current && firstInput.current.focus();
      }, 100);
    }
  }, [isOpen]);

  return (
    <Modal centered isOpen={isOpen} toggle={hide}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Add New Rate</h5>
        <button type="button" onClick={hide} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        {error && (
          <Alert color="danger">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ⛔️
            </span>
            {error}
          </Alert>
        )}
        <Row>
          <Col sm={4}>
            <FormGroup>
              <Label>Basic Hourly Rate</Label>
              <input
                ref={firstInput}
                type="number"
                className="form-control"
                value={state.BHR}
                onChange={event => dispatch({ field: 'BHR', value: event.target.value })}
              />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label>Currency</Label>
              <input disabled type="text" className="form-control" value={state.currency} />
            </FormGroup>
          </Col>
          <Col sm={4}>
            <FormGroup>
              <Label>OT Coefficient</Label>
              <input
                type="number"
                className="form-control"
                value={state.OT}
                onChange={event => dispatch({ field: 'OT', value: event.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label>Maximum Daily Hours</Label>
              <input
                type="number"
                className="form-control"
                value={state.maxDailyHours}
                onChange={event => dispatch({ field: 'maxDailyHours', value: event.target.value })}
              />
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label>Valid From</Label>
              <input
                type="date"
                className="form-control"
                value={state.validFrom.substr(0, 10)}
                onChange={event => dispatch({ field: 'validFrom', value: event.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <FormGroup>
              <Label>Comment</Label>
              <input
                type="text"
                className="form-control"
                value={state.comment}
                onChange={event => dispatch({ field: 'comment', value: event.target.value })}
              />
            </FormGroup>
          </Col>
        </Row>

        <Row className="m-t-20">
          <Col sm={12} className="text-right">
            <Button color="primary" onClick={onSubmit} disabled={submitting}>
              {submitting ? 'Submitting...' : 'Submit Rate'}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default NewRateModal;
