import moment from 'moment';
import React from 'react';

import { formatCurrency } from '../../../helpers/numbers';
import SortIndicator from '../Reporting/SortIndicator';
import { statusMapping } from './../../TimesheetStatusMapping';

export default [
  {
    Header: ({ column }) => {
      return (
        <>
          Date <SortIndicator column={column} />
        </>
      );
    },
    id: 'timestamp',
    sortDescFirst: true,
    accessor: row => row.timestamp,
    Cell: ({ value }) => <>{new moment(value).format('DD MMM YYYY')}</>,
    disableGlobalFilter: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Reference <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'id',
  },
  {
    Header: 'Amount',
    accessor: 'totalAmount',
    Cell: ({ value }) => <>{formatCurrency(value, undefined, 'symbol')}</>,
    sortDescFirst: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Payee <SortIndicator column={column} />
        </>
      );
    },
    accessor: row => (row ? `${row.employeeFirstName} ${row.employeeLastName}` : ''),
    id: 'requester',
    Cell: ({ row }) => {
      const { employeeFirstName, employeeLastName, requester } = row.original;
      return employeeFirstName ? (
        <div data-tip={requester} data-effect="solid">
          {employeeFirstName} {employeeLastName}
        </div>
      ) : (
        <div>{requester}</div>
      );
    },
    disableGlobalFilter: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Status <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'status',
    Cell: ({ row }) => {
      const { status } = row.original;
      return <div>{statusMapping[status.toUpperCase()]?.title ?? 'N/A'}</div>;
    },
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Paid <SortIndicator column={column} />
        </>
      );
    },
    id: 'isPaid',
    sortDescFirst: true,
    accessor: row => (row.isPaid ? 1 : 0),
    Cell: ({ value }) =>
      value === 1 ? (
        <span className="text-success font-weight-bold">Paid</span>
      ) : (
        <span className="text-danger">Unpaid</span>
      ),
    disableGlobalFilter: true,
  },
];
