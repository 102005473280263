/**
 * Format a number into a currency for the user locale
 * @param {number} quantity
 * @param {string} currency
 * @param {'symbol'|'narrowSymbol'|'code'|'name'} currencyDisplay
 */
export const formatCurrency = (quantity = 0, currency = 'GBP', currencyDisplay = 'code') => {
  try {
    const locale = navigator.language || 'en-GB';

    return parseFloat(quantity).toLocaleString(locale, {
      style: 'currency',
      currency,
      currencyDisplay,
    });
  } catch (error) {
    return quantity;
  }
};

/**
 *
 * @param {number} minutes
 */
export const minutesToTime = minutes => {
  const sign = minutes < 0 ? '-' : '';
  const min = Math.floor(Math.abs(minutes));
  const sec = Math.floor((Math.abs(minutes) * 60) % 60);
  return sign + (min < 10 ? '0' : '') + min + ':' + (sec < 10 ? '0' : '') + sec;
};

/**
 * Round quantity to a specific number of decimals (defaults to 2)
 *
 * @param {number} quantity
 * @param {number} decimals
 */
export function round(quantity, decimals = 2) {
  quantity = parseFloat(quantity);
  if (isNaN(quantity)) return;

  const precision = Math.pow(10, decimals);
  return Math.round(quantity * precision) / precision;
}
