import React, { useState } from 'react';
import { Dropdown, DropdownToggle /*DropdownMenu, DropdownItem*/ } from 'reactstrap';
// import { Scrollbars } from 'react-custom-scrollbars';

const NotificationMenu = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Dropdown
      isOpen={isMenuOpen}
      toggle={() => setIsMenuOpen(!isMenuOpen)}
      className="notification-list list-inline-item mr-1"
      tag="li"
    >
      <DropdownToggle className="nav-link arrow-none waves-effect" tag="a">
        <i className="mdi mdi-bell-outline noti-icon"></i>
        {/* <span className="badge badge-pill badge-danger noti-icon-badge">3</span> */}
      </DropdownToggle>
      {/* <DropdownMenu className="dropdown-menu-lg" right>
          <h6 className="dropdown-item-text"> Notifications (258) </h6>
          <Scrollbars style={{ height: '230px' }}>
            <DropdownItem tag="a" htef="#" className="notify-item active">
              <div className="notify-icon bg-success">
                <i className="mdi mdi-cart-outline"></i>
              </div>
              <p className="notify-details">
                Your order is placed
                <span className="text-muted">
                  Dummy text of the printing and typesetting industry.
                </span>
              </p>
            </DropdownItem>
          </Scrollbars>
          <DropdownItem tag="a" htef="#" className="text-center text-primary notify-all">
            View all <i className="fi-arrow-right"></i>
          </DropdownItem>
        </DropdownMenu> */}
    </Dropdown>
  );
};

export default NotificationMenu;
