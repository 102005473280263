import React, { useEffect, useState } from 'react';
import { Container, Nav, NavItem, NavLink, TabContent, TabPane, Row } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import DashboardCard from '../../Dashboard/DashboardCard';
import { fetchPaymentCards, fetchPayments, fetchCompanies } from '../../../store/admin/actions';

import cn from 'classnames';
import PaymentsTable from './PaymentsTable';
import PaymentDetailsModal from '../../../components/PaymentDetailsModal/PaymentDetailsModal';

const Banking = () => {
  const dispatch = useDispatch();
  // const userID = useSelector(state => state.Auth.userID);
  const profiles = useSelector(state => state.Team.profiles);
  // const profile = userID ? profiles[userID] : null;
  // const { data, isLoading, isAdminUser, error } = useSelector(state => state.Admin);
  const { data, isLoading } = useSelector(state => state.Admin);
  const payments = data && data.payments;
  const paymentCards = data && data.paymentCards;
  const [show, setShow] = useState(false);
  const [selectedPayment, setSelectedPayment] = useState(null);
  const resetAndCloseModal = () => setShow(false);
  const [type, setType] = useState(true);

  useEffect(() => {
    dispatch(fetchPaymentCards());
  }, [dispatch]); // eslint-disable-line

  useEffect(() => {
    dispatch(fetchPayments(type));
  }, [type]); // eslint-disable-line

  useEffect(() => {
    if (!data.companies) dispatch(fetchCompanies());
  }, []); // eslint-disable-line

  const [activeTab, setActiveTab] = useState(0);
  return (
    <Container fluid>
      <div className="p-t-10">
        <h4 className="page-title m-b-30">
          <span role="img" aria-label="bank" className="font-18 m-r-5">
            🏦{' '}
          </span>{' '}
          Banking
        </h4>

        <Row>
          {paymentCards &&
            paymentCards.map((entry, index) => <DashboardCard item={entry} key={index} />)}
        </Row>
        <Nav tabs className="nav-tabs-custom">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 0 })}
              onClick={() => setActiveTab(0)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-home"></i>
              </span>
              <span className="d-none d-sm-block">Payments</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 2 })}
              onClick={() => setActiveTab(2)}
            >
              <span className="d-block d-sm-none">
                <i className="fas fa-user"></i>
              </span>
              <span className="d-none d-sm-block">Scheduling</span>
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId={0}>
            {payments && (
              <PaymentsTable
                data={payments || []}
                isLoading={isLoading}
                onClickView={payment => {
                  setSelectedPayment(payment);
                  setShow(true);
                }}
                onChangeType={setType}
                type={type}
              />
            )}
          </TabPane>
          <TabPane tabId={2}>
            {/* <img
              className="m-t-20"
              src={require('../../../images/comingsoon.png')}
              style={{ height: '350px', display: 'block', margin: 'auto' }}
              alt="coming soon"
            /> */}
          </TabPane>
        </TabContent>
      </div>
      <PaymentDetailsModal
        isOpen={show}
        payment={selectedPayment}
        profiles={profiles}
        didTapCloseButton={resetAndCloseModal}
      ></PaymentDetailsModal>
    </Container>
  );
};

export default Banking;
