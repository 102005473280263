import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button, Row, Col, Alert } from 'reactstrap';
import ReactTooltip from 'react-tooltip';

import './Companies.scss';

import NewCompanyModal from './NewCompanyModal';
import { fetchCompanies } from '../../../store/actions';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import CompaniesTable from './CompaniesTable';

const defaultInitialValues = {};

const Companies = () => {
  const { data, isLoading, isAdminUser, error } = useSelector(state => state.Admin);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const [initialValues, setInitialValues] = useState(defaultInitialValues);

  useEffect(() => {
    if (!data.companies) dispatch(fetchCompanies());
  }, []); // eslint-disable-line

  const companies = (data?.companies && Object.values(data.companies)) || [];

  if (!isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <>
      <Container className="companies" fluid>
        <div className="sticky-title">
          <Row className="align-items-center">
            <Col xs={6}>
              <h5 className="page-title">Companies</h5>
            </Col>
            <Col xs={6} className="text-right">
              <Button
                color="primary"
                className="m-r-10"
                onClick={() => {
                  setInitialValues(defaultInitialValues);
                  setShowModal(true);
                }}
              >
                Create Company
              </Button>
              <Button color="secondary" onClick={() => dispatch(fetchCompanies())}>
                Refresh List
              </Button>
            </Col>
          </Row>

          {!!error && (
            <Alert color="danger">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                ⛔️
              </span>
              {error}
            </Alert>
          )}
        </div>

        <CompaniesTable
          data={companies}
          isLoading={isLoading}
          onClickAction={id => {
            const company = companies.find(c => c.id === id);
            if (company) {
              setInitialValues(company);
              setShowModal(true);
            }
          }}
        />
      </Container>

      <NewCompanyModal
        isOpen={showModal}
        hide={() => setShowModal(false)}
        initialValues={initialValues}
      />

      <ReactTooltip />
    </>
  );
};

export default Companies;
