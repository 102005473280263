import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Container,
  Button,
  Alert,
  Spinner,
  UncontrolledAlert,
  Table,
  Badge,
  Collapse,
  Card,
  CardHeader,
  Modal,
  ModalBody,
  Label,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import cn from 'classnames';
import ColorHash from 'color-hash';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';
import axios from 'axios';
import { groupBy } from '../../helpers/utils';

import { clockInTimetracker, fetchDaypassTickets, fetchDaypassProjects } from '../../store/actions';

import './Timetracker.scss';
import CardBody from 'reactstrap/lib/CardBody';
import Switch from 'react-switch';

const colorHash = new ColorHash({ lightness: 0.4, saturation: 0.6 });

const SelectBadge = (id, displayId, projectId, color) => ({
  alignItems: 'center',
  display: 'flex',

  ':before': {
    backgroundColor: color || colorHash.hex(projectId),
    color: 'white',
    borderRadius: 8,
    content: `"#${displayId ? displayId : id}"`,
    display: 'block',
    textAlign: 'center',
    marginRight: 5,
    height: 20,
    width: 15 + String(displayId ? displayId : id).length * 10,
  },
});

const Daypasses = () => {
  const dispatch = useDispatch();
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [selectedTimer, setSelectedTimer] = useState(null);
  const selectRef = useRef(null);
  const reactTooltip = useRef(null);
  const [showClockInModal, setShowClockInModal] = useState(false);
  const [confirmedCanWork, setConfirmedCanWork] = useState(false);
  const [wantsHalfDay, setWantsHalfDay] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [phone, setPhone] = useState(null);
  const [phoneWarning, setPhoneWarning] = useState(null);

  const userID = useSelector(state => state.Auth.userID);
  const profiles = useSelector(state => state.Team.profiles);
  const profile = profiles && profiles[userID];
  const hasPhoneReady =
    (phone && phone.registered && phone.status === 'Available') || !profile.voip;

  useEffect(() => {
    dispatch(fetchDaypassProjects());
  }, []); // eslint-disable-line

  async function refreshPhoneStatus() {
    if (profile && showClockInModal) {
      setUpdating(true);
      try {
        const res = await axios.get(`/api/phones/ext/${profile.voip}/refresh`);
        const phone = res && res.data && res.data;
        setPhone(phone);
        if (phone.registered) {
          if (phone.status !== 'Available') {
            setPhoneWarning('Please change your phone status to Available.');
          } else {
            setPhoneWarning(null);
          }
        } else {
          setPhoneWarning(
            'Your VOIP phone is offline. Please re-connect your app / weblcient / desktop phone now. If you are having issues use Helpdesk.'
          );
        }
      } catch (error) {
        console.log(error);
        setPhoneWarning(
          'Could not fetch your phone status. Please use Helpdesk to resolve this issue.'
        );
        setPhone(null);
      }
      setUpdating(false);
    }
  }

  useEffect(() => {
    refreshPhoneStatus();
  }, [profile, showClockInModal]); // eslint-disable-line

  let {
    tickets,
    timers,
    daypassProjects,
    isLoadingTickets,
    isLoadingTimers,
    error,
    success,
  } = useSelector(state => state.Timetracker);
  let groupedOptions = [];
  let runningTimer;

  const resetAndCloseModal = () => {
    setUpdating(false);
    setShowClockInModal(false);
    setConfirmedCanWork(false);
    setWantsHalfDay(false);
  };

  if (tickets) {
    groupedOptions = tickets.map(project => {
      return {
        label: project[0] && (project[0].projectName || project[0].projectId),
        options: project.map(ticket => ({ label: ticket.name, ticket })),
      };
    });
  }

  if (timers && timers.length) {
    runningTimer = timers.find(timer => timer.isCurrentlyRunning);
    timers.sort((a, b) => (a.updatedAt < b.updatedAt ? 1 : -1));
  }

  const availableProjectIDs = daypassProjects && daypassProjects.map(project => project.id);
  const timerList =
    timers.length > 0 &&
    availableProjectIDs &&
    timers.filter(timer => availableProjectIDs.includes(timer.projectId));

  const [openIndexes, setOpenIndexes] = useState(Array(timerList?.length || 0));

  // For Day Passes we only want to display the most recent ticket/timer per project and hide the rest
  const minimisedTimerList = timerList && groupBy(timerList, timer => timer.projectId);

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  }, [timers]);

  function getMaximumDayHours(projectId) {
    if (daypassProjects) {
      let matchingProject = daypassProjects.find(project => project.id === projectId);
      if (matchingProject?.maxDailyHours) {
        return matchingProject?.maxDailyHours;
      } else {
        return 0;
      }
    } else {
      return 0;
    }
  }

  function getHalfDayHours(projectId) {
    let halfday = getMaximumDayHours(projectId) * 0.5;
    return halfday;
  }

  function getClockInOptions(projectId) {
    let options = [];

    let minHours = getHalfDayHours(projectId);
    let maxHours = getMaximumDayHours(projectId);

    if (minHours === 0 || maxHours === 0) {
      options.push({ hours: 0, title: `DP-01 - Out of time`, minimal: false, disabled: true });
      console.log('No hours are available');
    } else {
      options.push({
        hours: maxHours,
        title: `${maxHours} hours (Normal Workday)`,
        minimal: false,
        disabled: false,
      });
      options.push({
        hours: minHours,
        title: `${minHours} hours (Minimal Workday)`,
        minimal: true,
        disabled: false,
      });
    }
    return options;
  }

  return (
    <Container fluid className="timetracker">
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            ⛔️
          </span>
          {error}
        </Alert>
      )}

      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          {success}
        </UncontrolledAlert>
      )}

      <Row>
        <Col sm={8} lg={9} className="m-b-15">
          <Select
            ref={selectRef}
            menuPortalTarget={document.querySelector('body')}
            options={groupedOptions}
            styles={{
              groupHeading: provided => ({
                ...provided,
                color: '#9ca8b3',
                fontSize: 13,
                borderBottom: '1px solid #eee',
              }),
              option: (provided, { data }) => {
                const { id, displayId, projectId, color } = data.ticket;
                return {
                  ...provided,
                  ...SelectBadge(id, displayId, projectId, color),
                };
              },
              singleValue: (provided, { data }) => {
                const { id, displayId, projectId, color } = data.ticket;
                return {
                  ...provided,
                  ...SelectBadge(id, displayId, projectId, color),
                };
              },
            }}
            placeholder={
              isLoadingTickets
                ? 'Loading daily tickets...'
                : tickets && tickets.length
                ? 'Select a task to clock in for a day...'
                : 'No daily tasks were found'
            }
            isLoading={isLoadingTickets}
            isOptionSelected={option => selectedTicket && selectedTicket.id === option.ticket.id}
            onChange={item => {
              setSelectedTicket(item && item.ticket);
            }}
            onMenuOpen={() => {
              if (!tickets || !tickets.length) dispatch(fetchDaypassTickets());
            }}
          />
        </Col>
        <Col sm={4} lg={3} className="d-flex align-items-start">
          <Button
            color="outline-secondary"
            className="m-r-10"
            style={{ height: '38px', lineHeight: 'normal', padding: '5px' }}
            data-tip="Reload tasks"
            data-effect="solid"
            data-delay-show="200"
            onClick={() => dispatch(fetchDaypassTickets())}
          >
            <i className="mdi mdi-refresh font-24" />
          </Button>
          <Button
            style={{ height: '38px' }}
            color="primary"
            className="flex-grow-1"
            disabled={!!runningTimer || !tickets || !tickets.length || !selectedTicket}
            onClick={() => {
              setShowClockInModal(true);
            }}
          >
            Clock In Today
          </Button>
        </Col>
      </Row>

      {isLoadingTimers && (
        <div className={cn('text-center', { 'spinner-with-data': !!timers })}>
          <Spinner color="primary" />
        </div>
      )}

      <Row>
        {!!(minimisedTimerList && daypassProjects && daypassProjects.length) &&
          daypassProjects.map((project, index) => (
            <Card
              className="mb-1 w-100"
              key={index}
              onClick={() => {
                const indexes = Array(timerList.length);
                indexes[index] = !openIndexes[index];
                setOpenIndexes(indexes);
              }}
            >
              <CardHeader className="p-3">
                <h6 className="m-0 font-14">
                  <Link
                    to="#"
                    onClick={() => {
                      const indexes = Array(timerList.length);
                      indexes[index] = !openIndexes[index];
                      setOpenIndexes(indexes);
                    }}
                    className="text-dark"
                  >
                    <Container fluid>
                      <Row sm="12">
                        <Col sm="6">
                          <Badge
                            className="font-12"
                            style={{ backgroundColor: `${project.uiColor || '#02a499'}` }}
                          >
                            {project.id}
                          </Badge>
                          <span style={{ paddingLeft: '10px' }}>{project.title}</span>
                        </Col>
                        <Col sm="6" className="text-right">
                          {minimisedTimerList.get(project.id) ? (
                            <Button
                              disabled={isLoadingTimers || isLoadingTickets}
                              color="primary"
                              className="text-uppercase font-500 btn-sm"
                              onClick={() => {
                                setSelectedTimer(minimisedTimerList.get(project.id)[0]);
                                setShowClockInModal(true);
                              }}
                              data-tip={
                                minimisedTimerList.get(project.id)
                                  ? 'Click here to indicate work today.'
                                  : 'Please select a day task from the dropdown above.'
                              }
                              data-type="dark"
                              data-effect="solid"
                              data-delay-show="750"
                              data-class="task-tooltip"
                            >
                              Clock In Today
                            </Button>
                          ) : (
                            <Badge
                              variant="secondary"
                              data-tip="Please select a day task from the dropdown above and start it"
                            >
                              Not started
                            </Badge>
                          )}
                        </Col>
                      </Row>
                    </Container>
                  </Link>
                </h6>
              </CardHeader>
              <Collapse isOpen={openIndexes[index]}>
                {minimisedTimerList.get(project.id) && minimisedTimerList.get(project.id)[0] ? (
                  <Table>
                    <thead>
                      <tr>
                        <th>Task ID</th>
                        <th>Workday</th>
                        <th>Task</th>
                        <th>Time Worked</th>
                        <th>Clocked In</th>
                        <th>Clocked Out</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {minimisedTimerList.get(project.id)[0].times &&
                        minimisedTimerList.get(project.id)[0].times.map(timeEntry => (
                          <tr
                            key={index + timeEntry.fromTime}
                            data-tip={minimisedTimerList.get(project.id)[0].taskDescription}
                            data-type="dark"
                            data-effect="solid"
                            data-delay-show="750"
                            data-class="task-tooltip"
                          >
                            <td>
                              #
                              {minimisedTimerList.get(project.id)[0].displayId
                                ? minimisedTimerList.get(project.id)[0].displayId
                                : minimisedTimerList.get(project.id)[0].taskId}
                            </td>
                            <td>{moment(timeEntry.toTime).format('ddd, DD.MM.YY')}</td>
                            <td>{minimisedTimerList.get(project.id)[0].taskName}</td>
                            <td>
                              {moment
                                .utc(moment(timeEntry.toTime).diff(moment(timeEntry.fromTime)))
                                .format('HH:mm')}
                            </td>
                            <td>{moment(timeEntry.fromTime).format('HH:mm')}</td>
                            <td>{moment(timeEntry.toTime).format('HH:mm')}</td>
                            <td></td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                ) : (
                  <Alert color="info">
                    <h6>Hey, nice to see you!</h6>
                    <p>
                      Okay, so to start work on "{project.title}" you need to have at least one task
                      assigned to you on this project. You can check for your assigned tasks by
                      using the dropdown at the top of this page. If the task has been assigned to
                      you less than five minutes ago use the refresh button too!
                    </p>
                    <hr />
                    <p className="mb-0">
                      If you are still having problem checking in for the day, please use Helpdesk.
                    </p>
                  </Alert>
                )}
              </Collapse>
            </Card>
          ))}
      </Row>

      <ReactTooltip ref={reactTooltip} />
      <Modal centered isOpen={showClockInModal} toggle={resetAndCloseModal}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Day Clock In</h5>
          <button type="button" onClick={resetAndCloseModal} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {success && (
            <Alert color="info">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                👍
              </span>
              {success}
            </Alert>
          )}
          {error && (
            <Alert color="danger">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                ⛔️
              </span>
              {error}
            </Alert>
          )}
          {!success && !error && (
            <>
              <div>
                <div className={'p-b-10'}> Hey! Thanks for clocking in!</div>
                <Card>
                  <CardBody>
                    <span role="img" aria-label="waving hand">
                      👋 Shall we run through the cheklist for the day?
                    </span>{' '}
                    <ul>
                      <li>I will be available on team chat</li>
                      <li>
                        I am working on{' '}
                        <strong>{selectedTimer?.projectId || selectedTicket?.projectId}</strong>{' '}
                        project
                      </li>
                      <li>
                        and my timekeeping ticket is{' '}
                        <strong>{selectedTimer?.taskId || selectedTicket?.displayId}</strong>
                      </li>
                      <li>
                        {' '}
                        I will work {''}
                        <select
                          onChange={e => {
                            if (!updating) {
                              setWantsHalfDay(e.target.value === 'true');
                            }
                          }}
                          disabled={updating}
                        >
                          {getClockInOptions(
                            selectedTimer?.projectId || selectedTicket?.projectId
                          ).map(hoursItem => (
                            <option
                              key={hoursItem.hours}
                              value={hoursItem.minimal}
                              disabled={hoursItem.disabled}
                            >
                              {hoursItem.title}
                            </option>
                          ))}
                        </select>
                      </li>
                    </ul>
                    {!!profile.voip && !updating && (
                      <Alert color={phoneWarning ? 'danger' : 'success'}>
                        Your VoIP phone number is <strong>{phone && phone.number}</strong> and it is{' '}
                        <strong>{phone && phone.registered ? 'online' : 'offline'}</strong>. Your
                        phone is <strong>{phone && phone.status}</strong>.{' '}
                        {!!phoneWarning && (
                          <span>
                            {phoneWarning}
                            <br />
                            <Button
                              onClick={() => {
                                refreshPhoneStatus();
                              }}
                              className="m-t-5"
                            >
                              Check Phone Again
                            </Button>
                          </span>
                        )}
                      </Alert>
                    )}
                  </CardBody>
                </Card>
                <Row hidden={!hasPhoneReady}>
                  <Col xs="2">
                    <Switch
                      onColor="#626ed4"
                      onChange={(checked, event, id) => {
                        setConfirmedCanWork(checked);
                      }}
                      checked={confirmedCanWork}
                      disabled={!hasPhoneReady || updating}
                    />
                  </Col>
                  <Col>
                    <Label className={'label-class'}>All checked! I'm ready to clock in!</Label>
                  </Col>
                </Row>
                <label className={'p-b-10'}></label>
              </div>
              <Row hidden={!hasPhoneReady && !updating}>
                <Col xs="6" sm="12">
                  <Button
                    block
                    color="info"
                    disabled={updating || !confirmedCanWork || !hasPhoneReady}
                    onClick={async () => {
                      setUpdating(true);
                      if (selectedTimer) {
                        dispatch(clockInTimetracker(selectedTimer.id, null, null, wantsHalfDay));
                      } else if (selectedTicket) {
                        dispatch(clockInTimetracker(null, selectedTicket, null, wantsHalfDay));
                      }
                      setSelectedTimer(null);
                      setSelectedTicket(null);
                      selectRef.current.select.clearValue();
                      setUpdating(false);
                      setShowClockInModal(false);
                      setConfirmedCanWork(false);
                      setWantsHalfDay(false);
                    }}
                  >
                    {updating ? 'Please Wait...' : 'Clock In'}
                  </Button>
                </Col>
              </Row>
            </>
          )}
        </ModalBody>
      </Modal>
    </Container>
  );
};

export default Daypasses;
