import React from 'react';
import { Field, FieldArray } from 'redux-form';
import { Col, FormGroup, Label, Button } from 'reactstrap';

import { renderField } from '../../Team/validation';

function required(value) {
  if (!value) return 'Required';
  else return undefined;
}

function number(value) {
  if (value && isNaN(value)) return 'Should be a valid number';
  else if (value && value < 0) return 'Should be a positive number';
  else return undefined;
}

const ProjectPrices = () => {
  return <FieldArray name="prices" component={renderMembers} />;
};

const renderMembers = ({ fields }) => (
  <>
    <FormGroup row>
      <Label sm={10}>Prices for Services</Label>
      <Col sm={2}>
        <Button
          block
          className="btn-sm btn-dark"
          onClick={() => {
            const id = `${PRICE_ID_PREFIX}${Date.now()}-${Math.trunc(Math.random() * 100000)}`;
            fields.push({ id, currency: 'GBP' });
          }}
        >
          Add Price
        </Button>
      </Col>
    </FormGroup>

    {fields.map((member, index) => (
      <FormGroup row key={index}>
        <Col sm={4} className="offset-sm-3">
          <Field
            name={`${member}.title`}
            type="text"
            component={renderField}
            label="Title"
            validate={[required]}
          />
        </Col>
        <Col sm={2}>
          <Field
            name={`${member}.amount`}
            type="number"
            component={renderField}
            parse={value => parseFloat(value) || undefined}
            validate={[required, number]}
            label="Amount"
          />
        </Col>
        <Col sm={2}>
          <Field
            name={`${member}.currency`}
            type="text"
            component={renderField}
            validate={[required]}
            label="Currency"
          />
        </Col>
        <Col sm={1}>
          <Button
            block
            color="danger"
            className="btn-sm"
            title="Remove price"
            style={{ height: '35px' }}
            onClick={() => fields.remove(index)}
          >
            <span className="mdi mdi-close font-18"></span>
          </Button>
        </Col>
      </FormGroup>
    ))}
  </>
);

export const PRICE_ID_PREFIX = 'price-';

export default ProjectPrices;
