import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane, Row, Col, Container } from 'reactstrap';
import cn from 'classnames';

import Timetracker from './Timetracker';
import Daypasses from './Daypasses';
import { fetchTimetrackers } from '../../store/actions';

const TimetrackerPage = () => {
  const [activeTab, setActiveTab] = useState(0);
  const userID = useSelector(state => state.Auth.userID);
  const profile = useSelector(state => state.Team.profiles[userID]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchTimetrackers());
  }, []); // eslint-disable-line

  return (
    <div className="p-t-10">
      {profile?.dayPassAllowed && (
        <Nav tabs className="nav-tabs-custom">
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 0 })}
              onClick={() => setActiveTab(0)}
            >
              <span className="d-none d-sm-block">Timetracker</span>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              style={{ cursor: 'pointer' }}
              className={cn({ active: activeTab === 1 })}
              onClick={() => setActiveTab(1)}
            >
              <span className="d-none d-sm-block">Day Pass</span>
            </NavLink>
          </NavItem>
        </Nav>
      )}

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          {!profile?.dayPassAllowed ? (
            <Container>
              <div className="page-title-box">
                <Row className="align-items-center">
                  <Col sm="9">
                    <h4 className="page-title">Timetracker</h4>
                  </Col>
                </Row>
              </div>
            </Container>
          ) : (
            <div className="m-t-40" />
          )}
          <Timetracker />
        </TabPane>
        <TabPane tabId={1}>
          <Container>
            <div className="m-t-40" />
          </Container>
          <Daypasses />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default TimetrackerPage;
