import React from 'react';
import { Card, CardBody, Col, Button } from 'reactstrap';
import { downloadFile } from '../../helpers/utils';

const DashboardCard = ({ item }) => {
  return (
    <>
      <Col xl="3" md="4" className="m-b-30">
        <Card className="directory-card h-100 m-0" style={{ background: item.background }}>
          <CardBody>
            <div className="d-flex">
              <div className="align-items-center justify-content-center">
                <img
                  src={
                    item.iconname
                      ? require(`../../images/${item.iconname}.png`)
                      : item.gifname
                      ? require(`../../images/${item.gifname}.gif`)
                      : ''
                  }
                  alt=""
                  style={{ height: '38px' }}
                />
              </div>
              <div className="contents m-l-15 flex-grow-1">
                <h5 className="font-14 text-uppercase mt-0 text-white-50">{item.title}</h5>
                <div className="d-block">
                  <span className="font-800 font-16 mt-0 text-white">{item.value}</span>
                  &nbsp;
                  <span className="text-white text-uppercase font-14 mt-0">{item.subtitle}</span>
                  {!!item.url && !item.tooltip && (
                    <div className="m-t-5 text-right">
                      {item.isDownloadable ? (
                        <Button
                          title="Download"
                          className="btn btn-dark text-white btn-sm"
                          onClick={() => {
                            downloadFile(item.url, item.filename);
                          }}
                        >
                          Download
                        </Button>
                      ) : (
                        <a
                          href={item.url}
                          className="btn btn-dark text-white btn-sm"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          View
                        </a>
                      )}
                    </div>
                  )}
                  {!!item.tooltip && (
                    <div className="m-t-5 text-left">
                      <span className="card-tip">{item.tooltip}</span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};
export default DashboardCard;
