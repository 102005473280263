import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Alert, Button, Card, CardBody, Col, Label, Row, UncontrolledAlert } from 'reactstrap';

import { fetchProjects } from '../../../store/actions';

function filterProjectsByUser(userID, projects) {
  const filtered = Object.values(projects)?.filter(project => {
    return (
      Object.keys(project.workers)?.includes(userID) &&
      moment(project.deliveryDate).isAfter(moment().startOf('day'))
    );
  });
  return filtered;
}

const Bookings = () => {
  const { profiles } = useSelector(state => state.Team);
  const [userFilter, setUserFilter] = useState('');
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const { data } = useSelector(state => state.Admin);
  const projects = data && data.projects;

  const dispatch = useDispatch();

  const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(
    moment()
      .add(7, 'days')
      .format('YYYY-MM-DD')
  );

  useEffect(() => {
    if (projects) {
      setAvailableProjects(filterProjectsByUser(userFilter, projects));
    }
  }, [userFilter]); // eslint-disable-line

  useEffect(() => {
    if (!projects) dispatch(fetchProjects());
  }, []); // eslint-disable-line

  return (
    <>
      <Row>
        <Col sm="4" lg="4">
          <div className="page-title-box">
            <Row>
              <Col sm="12">
                <h4 className="page-title">Offer a Booking</h4>
              </Col>
            </Row>
          </div>
          {success && (
            <UncontrolledAlert color="info">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                👍
              </span>
              Booking was created and sent to the worker for their approval!
            </UncontrolledAlert>
          )}
          {error && (
            <Alert color="danger">
              <span role="img" aria-label="see no evil" className="font-18 m-r-5">
                🙈
              </span>
              {error}
            </Alert>
          )}
        </Col>
      </Row>
      <Card>
        <CardBody>
          <Row>
            <Col sm="1" lg="3">
              <Label for="worker" className="card-title">
                <span className="order bg-primary">1</span>Select a worker
              </Label>
              <select
                id="worker"
                className="form-control d-inline-block m-r-10"
                value={userFilter}
                onChange={async e => {
                  const value = e.target.value;
                  setSelectedProject(null);
                  setUserFilter(value);
                  setIsLoading(true);
                  setSuccess(false);
                  try {
                  } catch (error) {
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                  setIsLoading(false);
                }}
              >
                <option>Please select</option>
                {profiles &&
                  Object.values(profiles)
                    .sort((a, b) => (b.firstName < a.firstName ? 1 : -1))
                    .map(profile => (
                      <option key={profile.userID} value={profile.userID}>
                        {profile.firstName} {profile.lastName}
                      </option>
                    ))}
              </select>
            </Col>
            <Col sm="1" lg="3">
              <Label for="project" className="card-title">
                <span className="order bg-primary">2</span>Select project
              </Label>
              {availableProjects?.length > 0 && (
                <select
                  id="project"
                  className="form-control d-inline-block m-r-10 align-middle"
                  value={selectedProject}
                  onChange={e => {
                    let value = e.target.value;
                    setSelectedProject(value);
                  }}
                >
                  <option key={'default'} value={''}>
                    Please select
                  </option>
                  {availableProjects &&
                    Object.values(availableProjects)
                      .sort((a, b) => (b.title < a.title ? 1 : -1))
                      .map(project => {
                        return (
                          <option key={project.title} value={project.id}>
                            {project.id} - {project.title}
                          </option>
                        );
                      })}
                </select>
              )}
              {availableProjects?.length === 0 && (
                <Alert color="dark">Please select a worker</Alert>
              )}
            </Col>
            <Col sm="1" lg="2">
              <Label for="project" className="card-title">
                <span className="order bg-primary">3</span>From Date
              </Label>
              <input
                disabled={!selectedProject}
                type="date"
                className="form-control"
                value={fromDate}
                onChange={e => {
                  setFromDate(e.target.value);
                  setError(null);
                }}
              />
            </Col>
            <Col sm="1" lg="2">
              <Label for="project" className="card-title">
                <span className="order bg-primary">4</span>To Date
              </Label>
              <input
                disabled={!selectedProject}
                type="date"
                className="form-control"
                value={toDate}
                onChange={e => {
                  setToDate(e.target.value);
                  setError(null);
                }}
              />
            </Col>
            <Col sm="1" lg="2">
              <Label for="project" className="card-title">
                <span className="order bg-primary">5</span>
              </Label>
              <Button disabled={isLoading} color={'primary'}>
                Make a Booking
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default Bookings;
