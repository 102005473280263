import React, { useState, useCallback, useEffect } from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  UncontrolledAlert,
  Alert,
} from 'reactstrap';
import Switch from 'react-switch';
import axios from 'axios';

const ThreeCXSettings = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [baseUrl, setBaseUrl] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState(null);
  const [enable3CX, setEnable3CX] = useState(false);

  useEffect(() => {
    const urlSettings = settings?.phoneUrl || '';
    const username = settings?.phoneUsername || '';
    const password = settings?.phonePassword || '';
    const isEnabled = settings?.phoneEnabled || false;

    setBaseUrl(urlSettings);
    setUsername(username);
    setPassword(password);
    setEnable3CX(isEnabled);
  }, [settings]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: {
          phoneUrl: baseUrl,
          phoneUsername: username,
          phonePassword: password,
          phoneEnabled: enable3CX,
        },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [baseUrl, username, password, enable3CX]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/support2.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Phone System Logo"
                  />
                  3CX PBX Settings
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Figaro integrates with 3CX PBX to fetch{' '}
              <strong>system state, extension details and to set user availability.</strong>
            </p>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                3CX FQDN (URL)
              </Label>
              <Col sm="8">
                <Input
                  id="azuredevops_key"
                  type="url"
                  value={baseUrl}
                  onChange={event => setBaseUrl(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                3CX Admin User
              </Label>
              <Col sm="8">
                <Input
                  id="orgname_key"
                  type="text"
                  value={username}
                  onChange={event => setUsername(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                3CX Admin Password
              </Label>
              <Col sm="8">
                <Input
                  id="defaultpool_id"
                  type="password"
                  value={password}
                  onChange={event => setPassword(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="8" className="offset-2">
                {password && (
                  <Alert color="info">
                    Please enable admin user IP whitelisting to prevent these credentials being used
                    from outside Figaro.
                  </Alert>
                )}
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Enable Synchronisation
              </Label>
              <Col sm="8">
                <div className="d-flex align-items-center">
                  <Switch
                    onColor="#626ed4"
                    onChange={() => {
                      setEnable3CX(!enable3CX);
                    }}
                    checked={enable3CX}
                    disabled={
                      isSubmitting || !baseUrl.length || !username.length || !password.length
                    }
                  />
                  <span className="m-l-10">
                    Update user availability in Figaro based on 3CX status every three minutes.
                  </span>
                </div>
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="12" className="offset-2">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || !baseUrl.length || !username.length || !password.length}
                  onClick={onSubmit}
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ThreeCXSettings;
