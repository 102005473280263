import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Container, Row, Col, Alert } from 'reactstrap';
import axios from 'axios';

import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import ElasticEstimates from './ElasticEstimates';
import Freshdesk from './Freshdesk';
import Revolut from './Revolut';
import PaymentDates from './PaymentDates';
import AzureSettings from './AzureSettings';
import BillingEmail from './BillingEmail';
import RadioSettings from './RadioSettings';
import GlobalAlertSettings from './GlobalAlertSettings';
import ThreeCXSettings from './ThreeCXSettings';
import XeroSettings from './XeroSettings';
import FirewallSettings from './FirewallSettings';
import MSTeamsSettings from './MSTeamsSettings';

const Settings = props => {
  const admin = useSelector(state => state.Admin);

  const [error, setError] = useState(null);
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    (async function() {
      try {
        const res = await axios.get('/api/settings');
        const settings = res && res.data && res.data.settings;
        setSettings(settings);
      } catch (error) {
        console.log(error);
        setError('There was a problem fetching the settings. Please try reloading the page.');
      }
    })();
  }, []); // eslint-disable-line

  if (!admin.isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <Container fluid>
      <div className="page-title-box">
        <Row className="align-items-center m-b-30">
          <Col sm="12">
            <h4 className="page-title">System Settings</h4>
          </Col>
        </Row>

        {error && (
          <Alert color="danger">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ⛔️
            </span>
            {error}
          </Alert>
        )}

        <AzureSettings settings={settings} />

        <MSTeamsSettings settings={settings} />

        <Revolut params={props.match.params} search={props.location && props.location.search} />

        <Freshdesk settings={settings} />

        <ElasticEstimates settings={settings} />

        <PaymentDates settings={settings} />

        <BillingEmail settings={settings} />

        <GlobalAlertSettings settings={settings} />

        <RadioSettings settings={settings} />

        <ThreeCXSettings settings={settings} />

        <FirewallSettings settings={settings} />

        <XeroSettings
          params={props.match.params}
          search={props.location && props.location.search}
        />
      </div>
    </Container>
  );
};

export default Settings;
