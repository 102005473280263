import React, { useState } from 'react';
import axios from 'axios';
import { Card, CardBody, FormGroup, Row, Button, Table } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { fetchUserProfile } from '../../store/team/actions';
import NewRateModal from './NewRateModal';
import TeamHeader from './TeamHeader';

const Rates = ({ profile }) => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();

  const rates = profile.financials ? profile.financials.rates || [] : [];

  const deleteRate = async rateID => {
    try {
      const response = await axios(`/api/profiles/${profile.userID}/rate/${rateID}`, {
        method: 'DELETE',
      });

      if (response && !response.data.error) {
        dispatch(fetchUserProfile(profile.userID));
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      alert(data ? data.message : `Something went wrong...`);
    }
  };

  return (
    <Card>
      <CardBody>
        <FormGroup>
          <TeamHeader
            icon="pound"
            title="Worker Rates"
            subtitle="Hourly rates the worker receives for their services."
          />
        </FormGroup>
        {!!rates.length && (
          <Row form>
            <Table className="table-sm">
              <thead>
                <tr>
                  <th>BHR</th>
                  <th>Currency</th>
                  <th>Max Daily Hours</th>
                  <th>Valid From</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {rates.map((rate, index) => {
                  return (
                    <tr
                      key={index}
                      className={rate.isCurrent ? 'bg-success text-white font-weight-bold' : ''}
                    >
                      <td>{rate.BHR}</td>
                      <td>{rate.currency}</td>
                      <td>{rate.maxDailyHours}</td>
                      <td>{new Date(rate.validFrom).toLocaleDateString()}</td>
                      <td className="text-right">
                        <DeleteButton
                          color={rate.isCurrent ? 'white' : 'black'}
                          onClick={() => {
                            const res = window.confirm(
                              rate.isCurrent
                                ? 'This is the current rate!\nAre you sure you want to delete it?'
                                : 'Are you sure you want to delete this rate?'
                            );
                            if (res) deleteRate(rate.rateID);
                          }}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        )}
        <Button
          color="primary"
          onClick={e => {
            e.preventDefault();
            setShowModal(true);
          }}
        >
          Add New Rate
        </Button>

        <NewRateModal isOpen={showModal} hide={() => setShowModal(false)} userID={profile.userID} />
      </CardBody>
    </Card>
  );
};

const DeleteButton = ({ onClick, color = 'black' }) => (
  <button
    title="Delete rate"
    style={{
      background: 'none',
      border: 'none',
      padding: '0 4px',
      fontSize: '18px',
      color,
    }}
    onClick={e => {
      e.preventDefault();
      onClick();
    }}
  >
    ✕
  </button>
);

export default Rates;
