import {
  FETCH_TIMER,
  FETCH_TIMER_SUCCESS,
  FETCH_TIMER_FAILURE,
  FETCH_TICKETS,
  FETCH_TICKETS_SUCCESS,
  FETCH_TICKETS_FAILURE,
  START_TIMER,
  START_TIMER_DONE,
  STOP_TIMER,
  STOP_TIMER_DONE,
  UPDATE_TIMER,
  UPDATE_TIMER_SUCCESS,
  UPDATE_TIMER_FAILURE,
  FETCH_DAYPASS_TICKETS,
  FETCH_DAYPASS_PROJECTS,
  FETCH_DAYPASS_PROJECTS_FAILURE,
  FETCH_DAYPASS_PROJECTS_SUCCESS,
  CLOCKIN_TIMER_SUCCESS,
  CLOCKIN_TIMER_FAILURE,
} from './actionTypes';
import { SIGN_OUT } from '../auth/actionTypes';

const initialState = {
  tickets: [],
  timers: [],
  isLoadingTickets: false,
  isLoadingTimers: false,
  error: null,
  success: null,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TIMER:
      return {
        ...state,
        isLoadingTimers: true,
        error: null,
        success: null,
      };

    case FETCH_TIMER_SUCCESS:
      return {
        ...state,
        timers: action.data,
        isLoadingTimers: false,
        error: null,
      };

    case FETCH_TIMER_FAILURE:
      return {
        ...state,
        isLoadingTimers: false,
        error: action.error,
      };

    case FETCH_TICKETS:
      return {
        ...state,
        isLoadingTickets: true,
        isLoadingTimers: true,
        error: null,
        success: null,
      };

    case FETCH_DAYPASS_TICKETS:
      return {
        ...state,
        isLoadingTickets: true,
        isLoadingTimers: true,
        error: null,
        success: null,
      };

    case FETCH_TICKETS_SUCCESS:
      return {
        ...state,
        tickets: action.data,
        isLoadingTickets: false,
        isLoadingTimers: false,
        error: null,
      };

    case FETCH_TICKETS_FAILURE:
      return {
        ...state,
        isLoadingTickets: false,
        isLoadingTimers: false,
        error: action.error,
      };

    /* Daypass Projects */
    case FETCH_DAYPASS_PROJECTS:
      return {
        ...state,
        isLoadingTickets: false,
        isLoadingTimers: false,
        error: null,
        success: null,
      };

    case FETCH_DAYPASS_PROJECTS_SUCCESS:
      return {
        ...state,
        daypassProjects: action.data,
        isLoadingTickets: false,
        isLoadingTimers: false,
        error: null,
      };

    case FETCH_DAYPASS_PROJECTS_FAILURE:
      return {
        ...state,
        isLoadingTickets: false,
        isLoadingTimers: false,
        error: action.error,
      };

    case START_TIMER:
    case STOP_TIMER:
      return { ...state, isLoadingTimers: true, success: null, error: null };

    case START_TIMER_DONE:
    case STOP_TIMER_DONE:
      return { ...state, isLoadingTimers: false };

    case CLOCKIN_TIMER_SUCCESS:
      return { ...state, isLoadingTimers: false, success: action.message, error: null };

    case CLOCKIN_TIMER_FAILURE:
      return { ...state, isLoadingTimers: false, success: null, error: action.error };

    case UPDATE_TIMER:
      return { ...state, isLoadingTimers: true, error: false, success: null };
    case UPDATE_TIMER_SUCCESS:
      return { ...state, isLoadingTimers: false, error: false, success: action.message };
    case UPDATE_TIMER_FAILURE:
      return { ...state, isLoadingTimers: false, error: action.error };

    case SIGN_OUT:
      return initialState;

    default:
      return { ...state };
  }
};

export default login;
