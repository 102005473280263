import React, { useState, useCallback, useEffect } from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';

const AzureSettings = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [azureToken, setAzureToken] = useState('');
  const [orgName, setOrgName] = useState('');
  const [defaultPool, setDefaultPool] = useState(null);

  useEffect(() => {
    const azureTokenSettings = (settings && settings.azureToken) || '';
    const orgNameSettings = (settings && settings.orgName) || '';
    const defaultPoolSettings = (settings && settings.defaultPool) || '';

    setAzureToken(azureTokenSettings);
    setOrgName(orgNameSettings);
    setDefaultPool(defaultPoolSettings);
  }, [settings]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: { azureToken: azureToken, orgName: orgName, defaultPool: defaultPool },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [azureToken, orgName, defaultPool]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/azure.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Azure Logo"
                  />
                  AzureDevOps Settings
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Figaro integrates with Azure DevOps to fetch{' '}
              <strong>epic, tasks and bugs and their estimates</strong> as well as list of build
              agents for the main dashboard.
            </p>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Azure Access Token
              </Label>
              <Col sm="8">
                <Input
                  id="azuredevops_key"
                  type="password"
                  value={azureToken}
                  onChange={event => setAzureToken(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Azure Organisation Name
              </Label>
              <Col sm="8">
                <Input
                  id="orgname_key"
                  type="text"
                  value={orgName}
                  onChange={event => setOrgName(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Azure Default Build Agent Pool ID
              </Label>
              <Col sm="8">
                <Input
                  id="defaultpool_id"
                  type="text"
                  value={defaultPool}
                  onChange={event => setDefaultPool(event.nativeEvent.target.value)}
                  disabled={isSubmitting}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="12" className="offset-2">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || !azureToken.length || !orgName.length}
                  onClick={onSubmit}
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default AzureSettings;
