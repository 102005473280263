import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';

import { signIn } from '../store/actions';

let store;
let firebaseUser;
let accessToken;

// Embed the bearer token on each axios request to the api
axios.interceptors.request.use(async config => {
  if (config && config.url && config.url.startsWith('/api/') && firebaseUser) {
    const idToken = await firebaseUser.getIdToken();
    config.headers['Authorization'] = `Bearer ${idToken}`;
  }
  return config;
});

export const initAuth = reduxStore => {
  store = reduxStore;

  firebase.auth().onAuthStateChanged(async user => {
    if (user) {
      firebaseUser = user;

      try {
        const idToken = await user.getIdToken();

        if (idToken) {
          const { userID } = store.getState().Auth;

          if (!userID) store.dispatch(signIn(accessToken));
        }
      } catch (error) {
        console.log(error);
      }
    }
  });
};

export const signInWithMicrosoft = () => {
  const provider = new firebase.auth.OAuthProvider('microsoft.com');

  provider.setCustomParameters({
    tenant: 'londonappworks.onmicrosoft.com',
  });

  logIn(provider);
};

const logIn = async provider => {
  try {
    const result = await firebase.auth().signInWithPopup(provider);

    accessToken = result && result.credential && result.credential.accessToken;
    if (!accessToken) {
      console.error('LOGIN ERROR', result);
    }
  } catch (error) {
    console.log('LOGIN ERROR', error);
  }
};
