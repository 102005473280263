import React from 'react';
import { Card, CardBody } from 'reactstrap';
import cn from 'classnames';

import { round } from '../../../helpers/numbers';
import Alert from 'reactstrap/lib/Alert';

const ProjectReportOverview = ({ report }) => {
  if (report) {
    const totalHours = report.maximumEffort || 0;
    let plannedHours = report.totalPlannedHours;
    let warnings = report.warnings;
    let suggestions = report.suggestions;
    let hoursWorked = 0;

    report.entries.forEach(entry => {
      hoursWorked += entry.hoursWorked;
    });

    return (
      <Card className="border-0 shadow m-t-20">
        <CardBody>
          <h6 className="card-title">Project Overview</h6>
          <Item
            title="Figaro Maximum Work Capacity"
            hours={totalHours}
            tip="Sum of all maximum efforts of all workers assigned to the project on Figaro."
          />
          <Item
            title="Capacity Available For Planning"
            hours={totalHours - plannedHours}
            tip="Available work capacity to be planned for on external system (JIRA, Azure, etc.)"
          />
          <Item
            title="Planned Work"
            hours={plannedHours}
            tip="Sum of all planned and estimated work, assigned to tickets on external system (JIRA, Azure, etc.)"
          />
          <Item
            title="Planned Work Done"
            hours={hoursWorked}
            tip="Time worked by all workers on this project to date"
          />
          <Item
            title="Planned Work Left"
            hours={plannedHours - hoursWorked}
            tip="How much already planned work is left to be done?"
          />
          {warnings &&
            warnings.map(warning => {
              return (
                <Alert color="danger">
                  <span role="img" aria-label="alert">
                    🚨
                  </span>
                  {warning}
                </Alert>
              );
            })}
          {suggestions &&
            suggestions.map(suggestion => {
              return (
                <Alert color="warning">
                  <span role="img" aria-label="suggestion">
                    🧐
                  </span>
                  {suggestion}
                </Alert>
              );
            })}
        </CardBody>
      </Card>
    );
  }

  return null;
};

const Item = ({ title, hours, tip }) => (
  <div
    className="d-flex justify-content-between"
    data-tip={tip}
    data-effect="solid"
    data-delay-show="200"
  >
    <div>{title}</div>
    <div className={cn('font-16 font-weight-bold', { 'text-danger': hours <= 0 })}>
      {round(hours)} h
    </div>
  </div>
);

export default ProjectReportOverview;
