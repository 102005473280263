import React from 'react';
import { Field } from 'redux-form';
import { FormGroup, Label, Col } from 'reactstrap';

import CountryField from '../../Subpages/CountryField';
import { renderField, required } from '../../Team/validation';

const Address = ({ field, title, canSelectOnlyCountry, change }) => {
  return (
    <>
      <FormGroup row>
        <Label sm={12}>{title}</Label>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="offset-sm-3" for={`${field}.line1`}>
          Line 1 <span className="text-danger">*</span>
        </Label>
        <Col sm={7}>
          <Field
            component={renderField}
            validate={required}
            className="form-control"
            id={`${field}.line1`}
            name={`${field}.line1`}
            type="text"
            disabled={canSelectOnlyCountry}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="offset-sm-3" for={`${field}.line2`}>
          Line 2
        </Label>
        <Col sm={7}>
          <Field
            component="input"
            className="form-control"
            id={`${field}.line2`}
            name={`${field}.line2`}
            type="text"
            disabled={canSelectOnlyCountry}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="offset-sm-3" for={`${field}.line3`}>
          Line 3
        </Label>
        <Col sm={7}>
          <Field
            component="input"
            className="form-control"
            id={`${field}.line3`}
            name={`${field}.line3`}
            type="text"
            disabled={canSelectOnlyCountry}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="offset-sm-3" for={`${field}.postcode`}>
          Postcode
        </Label>
        <Col sm={7}>
          <Field
            component="input"
            className="form-control"
            id={`${field}.postcode`}
            name={`${field}.postcode`}
            type="text"
            disabled={canSelectOnlyCountry}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="offset-sm-3" for={`${field}.city`}>
          City <span className="text-danger">*</span>
        </Label>
        <Col sm={7}>
          <Field
            component={renderField}
            validate={required}
            className="form-control"
            id={`${field}.city`}
            name={`${field}.city`}
            type="text"
            disabled={canSelectOnlyCountry}
          />
        </Col>
      </FormGroup>
      <FormGroup row>
        <Label sm={2} className="offset-sm-3" for={`${field}.countryISO`}>
          Country <span className="text-danger">*</span>
        </Label>
        <Col sm={7}>
          <CountryField
            name={`${field}.countryISO`}
            validate={required}
            onChange={e => {
              try {
                const select = e.currentTarget;
                const label = select.options[select.selectedIndex].textContent;
                if (label) change(`${field}.country`, label);
              } catch {}
            }}
          />
        </Col>
      </FormGroup>
    </>
  );
};

export default Address;
