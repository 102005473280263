import React from 'react';
import { Container, Row, Col } from 'reactstrap';

const AccessDeniedPoster = () => (
  <Container fluid>
    <div className="page-title-box">
      <Row className="align-items-center m-b-30">
        <Col sm="12">
          <h4 className="page-title">Access Denied</h4>
          <p>
            It seems that you got lost and tried to access a page you should not. Worry not, press
            here to <a href="/dashboard">go home</a>. Just in case we took a note of the page
            address and the time you tried to access it, so out IT team can check this for you.
          </p>
          <img
            src={require('../images/consuelaposter.png')}
            style={{ height: '350px', display: 'block', margin: 'auto' }}
            alt="no acccess"
          />
        </Col>
      </Row>
    </div>
  </Container>
);

export default AccessDeniedPoster;
