import React, { useState, useEffect } from 'react';
import { Modal, ModalBody, Table, Row, Col, Alert, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency, minutesToTime } from '../../../helpers/numbers';
import TimesheetUpload from '../TimesheetUpload';
import { clearSelectedTimesheets } from '../../../store/actions';
import { copyToClipboard } from '../../../helpers/utils';
import axios from 'axios';

function getCompanyAddressString(currentCompany) {
  const registeredAddress = currentCompany?.registeredAddress;
  const address =
    currentCompany?.legalName +
    '\nVAT/TAX ID' +
    currentCompany?.tin +
    '\n' +
    registeredAddress?.line1 +
    '\n' +
    registeredAddress?.line2 +
    '\n' +
    registeredAddress?.line3 +
    '\n' +
    registeredAddress?.city +
    '\n' +
    registeredAddress?.country +
    '\n' +
    registeredAddress?.postcode;
  return address;
}

function TimesheetsBundleModal({ open, onClickClose, timesheets, selectedTimesheets }) {
  const [uploadSuccess, setUploadSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [companies, setCompanies] = useState(null);
  const dispatch = useDispatch();
  const userID = useSelector(state => state.Auth.userID);

  let totalHours = 0;
  let totalAmount = 0;
  let ids = [];

  const closeModal = () => {
    if (uploadSuccess) dispatch(clearSelectedTimesheets());
    onClickClose();
    setUploadSuccess(null);
    currentCompany = null;
  };

  const lines = Array.from(selectedTimesheets).map(item => {
    // Get the full timesheet
    const timesheet = timesheets.find(i => i.id === item.id);
    totalAmount += parseFloat(item.financials.totalAmount || 0);
    totalHours += parseFloat(timesheet.financials.totalHours || 0);
    ids.push(item.id);

    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{timesheet.financials.totalDisplayHours}</td>
        <td>{formatCurrency(item.financials.totalAmount, item.currency)}</td>
      </tr>
    );
  });

  let currentCompany =
    selectedTimesheets?.size &&
    companies?.find(
      company => company.id === Array.from(selectedTimesheets)[0].financials.companyID
    );

  useEffect(() => {
    if (!userID) return;

    async function listCompanies() {
      try {
        const response = await axios.get('/api/companies');

        if (response) {
          const { data, status } = response;

          if (status === 200 && data && !data.error) {
            setError(null);
            setCompanies(data.companies);
          } else {
            setError('Unable to fetch list of companies');
          }
        }
      } catch (error) {
        console.log(error);
        setError(error?.response?.data);
      }
    }

    listCompanies();
    // Needs to run only once to load companies
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal centered isOpen={open} toggle={closeModal}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Batch Payment</h5>
        <button type="button" onClick={closeModal} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        {error && <Alert color="warning">{error}</Alert>}
        <p>Multiple timesheets payment with one invoice.</p>
        <ol>
          <li>Include these timesheet IDs in your invoice.</li>
          <li>
            State your business details on your invoice. If you need to update them do this in your
            profile.
          </li>
        </ol>
        {currentCompany && (
          <>
            You are invoicing the following client
            <br />
            <table className="client-background" id="client-details">
              <thead>
                <tr>
                  <th>
                    {' '}
                    <button
                      title="Copy ID to clipboard"
                      className="btn btn-secondary m-l-10 btn-clipboard"
                      onClick={() => copyToClipboard(getCompanyAddressString(currentCompany))}
                    >
                      <i className="ti-clipboard"></i>
                    </button>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{currentCompany?.legalName}</td>
                </tr>
                <tr>
                  <td>
                    <strong>VAT/TAX ID {currentCompany?.tin}</strong>
                  </td>
                </tr>
                <tr>
                  <td>{currentCompany?.registeredAddress?.line1}</td>
                </tr>
                <tr>
                  <td>{currentCompany?.registeredAddress?.line2}</td>
                </tr>
                <tr>
                  <td>{currentCompany?.registeredAddress?.line3}</td>
                </tr>
                <tr>
                  <td>{currentCompany?.registeredAddress?.city}</td>
                </tr>
                <tr>
                  <td>
                    {currentCompany?.registeredAddress?.country},{' '}
                    {currentCompany?.registeredAddress?.postcode}
                  </td>
                </tr>
              </tbody>
            </table>
          </>
        )}
        <Table striped responsive className="table-condensed border-bottom">
          <thead>
            <tr>
              <th>Timesheet</th>
              <th>Hours</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>{lines}</tbody>
          <tfoot>
            <tr className="font-16">
              <th>TOTAL</th>
              <th>{minutesToTime(totalHours)}</th>
              <th>{formatCurrency(totalAmount)}</th>
            </tr>
          </tfoot>
        </Table>
        {uploadSuccess ? (
          <>
            <Row>
              <Col xs="12">
                <Alert color="primary">
                  <span role="img" aria-label="no entry" className="font-18 m-r-5">
                    👍
                  </span>
                  {uploadSuccess}
                </Alert>
              </Col>
            </Row>
            <Row className="border-top">
              <Col sm="3" className="offset-9 m-t-20">
                <Button block color="primary" onClick={closeModal}>
                  Done
                </Button>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row className="m-t-20 m-b-20">
              {/* <Col xs="12">
                <button
                  className="btn btn-block btn-info"
                  onClick={() => {
                    downloadFile(
                      `/api/bundle/export/csv?timesheetIds=${ids.join(',')}`,
                      `bundle.csv`
                    );
                  }}
                >
                  Download CSV
                </button>
                <p className="m-t-5 font-10">
                  Download line items in Excel format. Compatible with e-Számla.hu
                </p>
              </Col> */}
            </Row>
            <TimesheetUpload timesheetIds={ids} onSuccess={message => setUploadSuccess(message)} />
          </>
        )}
      </ModalBody>
    </Modal>
  );
}

export default TimesheetsBundleModal;
