import React from 'react';
import { Spinner, Table, Card, CardBody } from 'reactstrap';
import { useTable, useSortBy, useFilters, useGlobalFilter } from 'react-table';

import columns from './burndownTableColumns';

const BurndownTable = ({ data, isLoading }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );

  return (
    <Card>
      <CardBody className="p-0">
        <Table striped responsive className="table-sm m-0" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading && (
              <tr>
                <td colSpan="12" className="text-center">
                  <Spinner color="primary" />
                </td>
              </tr>
            )}
            {!isLoading && rows.length === 0 ? (
              <tr>
                <td colSpan="12" className="text-center text-black-50">
                  No entries found
                </td>
              </tr>
            ) : (
              rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => {
                      return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>;
                    })}
                  </tr>
                );
              })
            )}
          </tbody>
          <tfoot>
            {footerGroups.map(group => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map(column => (
                  <th {...column.getFooterProps()}>{column.render('Footer')}</th>
                ))}
              </tr>
            ))}
          </tfoot>
        </Table>
      </CardBody>
    </Card>
  );
};

export default BurndownTable;
