import axios from 'axios';
import {
  FETCH_DASHBOARD,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  FETCH_DASHBOARD_CARDS,
  FETCH_DASHBOARD_CARDS_FAILURE,
  FETCH_DASHBOARD_CARDS_SUCCESS,
} from './actionTypes';
import { signOut } from '../auth/actions';

/**
 * FETCH VIRTUAL OFFICE ENTRIES
 */
export const fetchVirtualOffice = () => {
  return async dispatch => {
    dispatch(fetchVirtualOfficeAction());

    try {
      const response = await axios.get('/api/virtual-office');

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          // Sort entries by working now first
          if (data.virtualOffice)
            data.virtualOffice.sort((a, b) => b.isCurrentlyRunning - a.isCurrentlyRunning);
          dispatch(
            fetchVirtualOfficeSuccessAction(data.virtualOffice, data.outOfOffice, data.alert)
          );
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut(data));
      dispatch(fetchDashboardFailureAction(data));
    }
  };
};

const fetchVirtualOfficeAction = () => ({ type: FETCH_DASHBOARD });

const fetchVirtualOfficeSuccessAction = (virtualOffice, outOfOffice, alert) => ({
  type: FETCH_DASHBOARD_SUCCESS,
  virtualOffice,
  outOfOffice,
  alert,
});

const fetchDashboardFailureAction = error => ({ type: FETCH_DASHBOARD_FAILURE, error });

/**
 * FETCH FRESHDESK CARDS FOR DASHBOARD
 */
export const fetchDashboardCards = () => {
  return async dispatch => {
    dispatch(fetchDashboardCardsAction());

    try {
      const response = await axios.get('/api/dashboard/cards');
      const { data, status } = response;
      if (status === 200 && data && !data.error && data.cards) {
        dispatch(fetchDashboardCardsSuccessAction(data.cards));
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      console.log(error);
      if (data && data.code === 401) dispatch(signOut(data));
      dispatch(fetchDashboardCardsFailureAction(data));
    }
  };
};

const fetchDashboardCardsAction = () => ({ type: FETCH_DASHBOARD_CARDS });

const fetchDashboardCardsSuccessAction = cards => ({ type: FETCH_DASHBOARD_CARDS_SUCCESS, cards });

const fetchDashboardCardsFailureAction = error => ({ type: FETCH_DASHBOARD_CARDS_FAILURE, error });
