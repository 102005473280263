import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import cn from 'classnames';
import AdminTimesheets from './AdminTimesheets';
import CreateTimesheet from './CreateTimesheet';

import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import InvoiceTester from './InvoiceTester';

const Timesheets = () => {
  const [activeTab, setActiveTab] = useState(0);

  const admin = useSelector(state => state.Admin);
  if (!admin.isAdminUser) {
    return <AccessDeniedPoster />;
  }

  return (
    <div className="p-t-10">
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 0 })}
            onClick={() => setActiveTab(0)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">View Timesheets</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 1 })}
            onClick={() => setActiveTab(1)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Process Timesheets</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 2 })}
            onClick={() => setActiveTab(2)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Invoice Tester</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <AdminTimesheets />
        </TabPane>
        <TabPane tabId={1}>
          <CreateTimesheet />
        </TabPane>
        <TabPane tabId={2}>
          <InvoiceTester />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Timesheets;
