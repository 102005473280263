import React from 'react';

export default class ErrorBoundary extends React.Component {
  state = { error: null, errorInfo: null };

  static getDerivedStateFromError(error, errorInfo) {
    return { error, errorInfo };
  }

  render() {
    if (this.state.error) {
      console.error(this.state.error);

      return (
        <div
          style={{
            padding: '20px 10%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div>
            <img
              src={require('./images/consuela.png')}
              alt="Consuela no like"
              style={{ display: 'block', height: '240px', margin: '30px auto' }}
            />
            <h1>Sorry something went wrong...</h1>
          </div>
          <pre style={{ maxWidth: '100%', minHeight: '100px' }}>
            {this.state.error.toString()}
            {process.env.REACT_APP_ENVIRONMENT !== 'production' &&
              this.state.errorInfo &&
              this.state.errorInfo.componentStack}
          </pre>
        </div>
      );
    }

    return this.props.children;
  }
}
