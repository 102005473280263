import React from 'react';
import { Modal, ModalBody, Table } from 'reactstrap';

const HistoryModal = ({ tracker, hide }) => {
  if (!tracker) return null;

  const { updates, updatedBy, updatedAt } = tracker;

  return (
    <Modal centered isOpen toggle={() => hide()} size={updates ? 'xl' : 'md'}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">
          Timetracker History for <em>{tracker.id}</em>
        </h5>
        <button type="button" onClick={() => hide()} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody className={updates ? 'p-0' : ''}>
        {!updates && (
          <div>
            {updatedAt && (
              <div>
                Last update: {new Date(updatedAt).toLocaleString()}
                {updatedBy && <> by {updatedBy}</>}.
              </div>
            )}
            <div className="text-muted m-t-20">
              Detailed history is not available for this item.
            </div>
          </div>
        )}

        {updates && (
          <Table responsive bordered striped className="table-sm m-0">
            <thead>
              <tr>
                <th>Date</th>
                <th>User ID</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              {updates.map((update, i) => (
                <tr key={i}>
                  <td>
                    {new Date(update.timestamp).toLocaleString(undefined, { timeZone: 'UTC' })}
                  </td>
                  <td>{update.userID}</td>
                  <td>
                    {update.userName} {update.description}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </ModalBody>
    </Modal>
  );
};

export default HistoryModal;
