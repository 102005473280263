import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import axios from 'axios';
import ReactTooltip from 'react-tooltip';
import { Col, Label, Row, Button, Modal, ModalBody, Alert, FormGroup } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { formatDuration, formatHourDuration, } from '../../../helpers/utils';
import { fetchAdminTimetrackers, setGlobalAlert, fetchProjects, fetchUserProfile } from '../../../store/actions';

const TimesModal = ({ tracker, hide }) => {
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [times, setTimes] = useState(null);
  const [projectId, setProjectId] = useState(tracker && tracker.projectId);
  const [workerId, setWorkerId] = useState(tracker && tracker.userID);
  const [rate, setRate] = useState(null);
  const [selectedRate, setSelectedRate] = useState(null);
  const [allowedEffort, setAllowedEffort] = useState(tracker && tracker.allowedEffort);
  const [notes, setNotes] = useState((tracker && tracker.notes) ?? '');
  const [newRemainingEffort, setNewRemainingEffort] = useState(0);
  const [errors, setErrors] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [stopping, setStopping] = useState(false);
  const {
    data: { projects },
  } = useSelector(state => state.Admin);

  const {
    profiles,
  } = useSelector(state => state.Team);

  useEffect(() => {
    if (!projects) dispatch(fetchProjects());
  }, []); // eslint-disable-line

  let total = 0;
  const hasErrors = errors && errors.some(item => item.fromTime || item.toTime);
  
  useEffect(() => {
    const rates = profiles && profiles[workerId] && profiles[workerId].financials?.rates;
    setSelectedRate(rates?.find(rate => rate.rateID === tracker?.financials?.rateID));
    setRate(tracker?.financials?.rateID); 
  }, [profiles, tracker, workerId]);

  useEffect(() => {
    const rates = profiles && profiles[workerId] && profiles[workerId].financials?.rates;
    setSelectedRate(rates?.find(userRate => userRate.rateID === rate));
  }, [rate, profiles, workerId]);

  useEffect(() => {
    if (tracker) {
      if (tracker.times) {
        setTimes([...tracker.times]);
        setErrors(tracker.times.map(() => ({ fromTime: null, toTime: null })));
      }
      if (tracker.projectId) setProjectId(tracker.projectId);
      if (tracker.allowedEffort) setAllowedEffort(tracker.allowedEffort);
      if (tracker.userID) setWorkerId(tracker.userID);
      if (tracker.notes) setNotes(tracker.notes);

      dispatch(fetchUserProfile(tracker.userID));
    }
  }, [tracker, dispatch]);

  useEffect(() => {
    const completedHours = tracker?.totalTimes?.totalTimeInSeconds / 3600;
    if (completedHours) {
      const remainingHours = Math.max(allowedEffort - completedHours, 0);
      setNewRemainingEffort(remainingHours);
    }
  }, [tracker, allowedEffort]);

  if (!tracker) return null;

  return (
    <Modal centered isOpen={!!times} toggle={() => hide()} size="lg">
      <div className="modal-header">
        <h5 className="modal-title mt-0">Edit Timetracker - ID #{tracker.displayId}</h5>
        <button type="button" onClick={() => hide()} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        {tracker.isDayPass && (
          <Alert color="info" className="text-left d-flex align-items-center">
            <span role="img" aria-label="day pass" className="font-18 m-r-5">
              ☀️
            </span>
            <span className="flex-grow-1">This is a daypass timer.</span>
          </Alert>
        )}
        {tracker.isCurrentlyRunning && (
          <Alert color="info" className="text-left d-flex align-items-center">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ⏰
            </span>
            <span className="flex-grow-1">This timer is currently running!</span>
            <Button
              color="danger"
              disabled={stopping}
              onClick={async () => {
                try {
                  setStopping(true);
                  const response = await axios.put(`/api/timetracker/stop/${tracker.userID}`);

                  if (response) {
                    const { data } = response;
                    if (data && data.error) throw new Error(data.error);
                    else {
                      hide();
                      dispatch(fetchAdminTimetrackers());
                    }
                  }
                } catch (error) {
                  const response = error && error.response;
                  const data = response && error.response.data;
                  const errorMessage =
                    (data && data.message) ||
                    (response && response.statusText) ||
                    'Something went wrong...';

                  hide();
                  dispatch(fetchAdminTimetrackers());
                  dispatch(setGlobalAlert(errorMessage, 'danger', '⚠️'));
                }
                setStopping(false);
              }}
            >
              {stopping ? 'STOPPING...' : 'STOP'}
            </Button>
          </Alert>
        )}
        {error && (
          <Alert color="danger">
            <span role="img" aria-label="no entry" className="font-18 m-r-5">
              ⛔️
            </span>
            {error}
          </Alert>
        )}

        <FormGroup row>
          <Col xs={12}>
            <Label>Worker</Label>
            <select
              className="form-control"
              value={workerId}
              onChange={e => setWorkerId(e.target.value)}
            >
              {profiles &&
                Object.keys(profiles).map(key => (
                  <option key={key} value={key}>
                    {profiles[key].userID} - {profiles[key].firstName} {profiles[key].lastName}
                  </option>
                ))}
            </select>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col xs={12}>
            <Label>Associated Project</Label>
            <select
              className="form-control"
              value={projectId}
              onChange={e => setProjectId(e.target.value)}
            >
              {projects &&
                Object.keys(projects).map(key => (
                  <option key={key} value={key}>
                    {projects[key].id} - {projects[key].title}
                  </option>
                ))}
            </select>
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col xs={4}>
            <Label>Applied Rate</Label>
            <select
              className="form-control"
              value={rate}
              onChange={e => setRate(e.target.value)}
            >
              {profiles && profiles[workerId] && profiles[workerId].financials?.rates &&
                profiles[workerId].financials?.rates.map(rate => (
                  <option key={rate.rateID} value={rate.rateID} disabled={rate.isDeleted}>
                    {rate.BHR} {rate.currency} - {rate.comment}
                  </option>
                ))}
            </select>
          </Col>
          <Col xs={4}>
            <Label>Current Cost</Label>
            <Label className="d-block font-weight-normal">{tracker?.financials?.estimatedCharge} {tracker?.financials?.currency ?? 'GBP'}</Label>
          </Col>
          <Col xs={4}>
            <Label>New Cost</Label>
            <Label className="d-block font-weight-normal">{tracker?.totalTimes?.totalTimeInSeconds / 3600 * selectedRate?.BHR} {selectedRate?.currency}</Label>
          </Col>
        </FormGroup>
        
        <FormGroup>
          <Row className="m-t-10">
            <Col xs={10} className="text-left">
              <Label className="d-block font-weight-normal">Note</Label>
              <input
                type="text"
                className="form-control"
                value={notes}
                onChange={e => setNotes(e.target.value)}
              />
            </Col>
            <Col xs={2} className="text-right">
             <Button color= "danger" className="m-t-30" onClick={()=>{setNotes("")}}>Clear</Button>
            </Col>
          </Row>
        </FormGroup>

        <Label className="w-100 d-flex align-items-center justify-content-between">
          Times
          <small className="text-muted">
            Please note times are{' '}
            <a
              href="https://www.timeanddate.com/worldclock/timezone/utc"
              target="_blank"
              rel="noopener noreferrer"
            >
              UTC
            </a>
            .
          </small>
        </Label>

        {times && !times.length && (
          <Button
            color="outline-primary"
            onClick={() => {
              const fromTime = moment.utc().toISOString();
              const toTime = moment
                .utc()
                .add(10, 'minutes')
                .toISOString();
              times.push({ fromTime, toTime });
              errors.push({ fromTime: null, toTime: null });
              setErrors([...errors]);
              setTimes([...times]);
              setTimeout(() => ReactTooltip.rebuild(), 50);
            }}
          >
            Add new time
            <i className="fas fa-stream font-16 m-l-10" />
          </Button>
        )}

        {times &&
          times.map((time, index) => {
            const from = time.fromTime && new Date(time.fromTime).getTime();
            const to = time.toTime && new Date(time.toTime).getTime();
            const diff = from && to ? to - from : 0;
            total += diff;
            const duration = diff >= 0 ? formatDuration(diff, true) : 'Invalid';

            return (
              <Row key={index} className="m-b-5">
                <Col xs={4}>
                  <Label className="font-weight-normal">From</Label>
                  <input
                    className={cn('form-control', { 'border-danger': errors[index].fromTime })}
                    type="datetime-local"
                    disabled={!time.fromTime}
                    value={time.fromTime ? time.fromTime.substr(0, 16) : ''}
                    onChange={e => {
                      if (e.target.value) {
                        const value = moment.utc(e.target.value);
                        const max = moment.utc(time.toTime);

                        const min = times[index - 1] && moment.utc(times[index - 1].toTime);
                        min && console.log('min', min.format());

                        errors[index].fromTime = value.isSameOrAfter(max, 'minute')
                          ? 'Start time cannot happen at or after end time'
                          : min && value.isSameOrBefore(min, 'minute')
                            ? 'Start time cannot happen at or before the previous end time'
                            : null;

                        time.fromTime = value.toISOString();
                        setTimes([...times]);
                        setErrors([...errors]);
                      }
                    }}
                  />
                  {errors[index].fromTime && (
                    <div className="text-danger font-12 m-t-5">
                      <span role="img" aria-label="error">
                        ⛔️
                      </span>
                      {errors[index].fromTime}
                    </div>
                  )}
                </Col>
                <Col xs={4}>
                  <Label className="font-weight-normal">To</Label>
                  <input
                    className={cn('form-control', { 'border-danger': errors[index].toTime })}
                    type="datetime-local"
                    disabled={!time.toTime}
                    value={time.toTime ? time.toTime.substr(0, 16) : ''}
                    onChange={e => {
                      if (e.target.value) {
                        const value = moment.utc(e.target.value);
                        const min = moment.utc(time.fromTime);
                        const max = times[index + 1] && moment.utc(times[index + 1].fromTime);

                        errors[index].toTime = value.isSameOrBefore(min, 'minute')
                          ? 'End time cannot happen at or before start time'
                          : max && value.isSameOrAfter(max, 'minute')
                            ? 'End time cannot happen at or after the next start time'
                            : null;

                        time.toTime = value.toISOString();
                        setTimes([...times]);
                        setErrors([...errors]);
                      }
                    }}
                  />
                  {errors[index].toTime && (
                    <div className="text-danger font-12 m-t-5">
                      <span role="img" aria-label="error">
                        ⛔️
                      </span>
                      {errors[index].toTime}
                    </div>
                  )}
                </Col>
                <Col xs={2}>
                  <Label className="d-block font-weight-normal">Duration</Label>
                  <input disabled type="text" className="form-control" value={duration} />
                </Col>
                <Col xs={2}>
                  <Label className="d-block">&nbsp;</Label>
                  <Button
                    color="outline-primary"
                    className="m-r-10"
                    data-tip="Insert new time below"
                    data-effect="solid"
                    data-delay-show={250}
                    onClick={() => {
                      const fromTime = moment
                        .utc(time.toTime)
                        .add(1, 'minute')
                        .toISOString();
                      const toTime = times[index + 1]
                        ? moment
                          .utc(times[index + 1].fromTime)
                          .subtract(1, 'minute')
                          .toISOString()
                        : moment
                          .utc(time.toTime)
                          .add(11, 'minutes')
                          .toISOString();
                      times.splice(index + 1, 0, { fromTime, toTime });
                      errors.splice(index + 1, 0, { fromTime: null, toTime: null });
                      setErrors([...errors]);
                      setTimes([...times]);
                      setTimeout(() => ReactTooltip.rebuild(), 50);
                    }}
                  >
                    <i className="fas fa-stream font-16" />
                  </Button>
                  <Button
                    color="outline-danger"
                    data-tip="Delete time"
                    data-effect="solid"
                    data-delay-show={250}
                    onClick={() => {
                      times.splice(index, 1);
                      errors.splice(index, 1);
                      setErrors([...errors]);
                      setTimes([...times]);
                    }}
                  >
                    <i className="fas fa-ban font-16" />
                  </Button>
                </Col>
              </Row>
            );
          })}

        <Row className="m-t-40">
          <Col xs={4} className="text-right">
            <div>Original Effort</div>
            <h6>{formatDuration(tracker.allowedEffort * 3600 * 1000, true)}</h6>
          </Col>
          <Col xs={4} className="text-right">
            <div>Remaining Effort</div>
            <h6>{formatDuration(tracker.remainingEffort * 3600 * 1000, true)}</h6>
          </Col>
          <Col xs={4} className="text-right">
            <div>Original Worked Time</div>
            <h6>{formatDuration(tracker.totalTimes.totalTimeInSeconds * 1000, true)}</h6>
          </Col>
        </Row>

        <Row className="m-t-40">
          <Col xs={4} className="text-right">
            <Label className="d-block font-weight-normal">New Allowed Effort</Label>
            <input
              type="text"
              className="form-control"
              value={allowedEffort}
              onChange={e => setAllowedEffort(e.target.value)}
            />
          </Col>
          <Col xs={4} className="text-right">
            <div>New Remaining Effort</div>
            <h6>{newRemainingEffort && formatHourDuration(newRemainingEffort)}</h6>
          </Col>
          <Col xs={4} className="text-right">
            <div>New Worked Time</div>
            <h6>{total > -1 ? formatDuration(total, true) : 'Invalid duration'}</h6>
          </Col>
        </Row>

        <Row className="m-t-20">
          <Col sm={12} className="text-right">
            <Button
              color="primary"
              type="submit"
              disabled={hasErrors || submitting}
              onClick={async () => {
                try {
                  setSubmitting(true);
                  setError(null);
                  const response = await axios.put('/api/timetracker/times', {
                    id: tracker.id,
                    times,
                    projectId,
                    allowedEffort,
                    workerId,
                    notes,
                    rate,
                  });

                  if (response && !response.data.error) {
                    hide(true);
                  }
                } catch (error) {
                  const data = error && error.response && error.response.data;
                  setError(data ? data.message : error ? error.message : 'Something went wrong...');
                }

                setSubmitting(false);
              }}
            >
              {submitting ? 'Please wait...' : 'Update'}
            </Button>
          </Col>
        </Row>
      </ModalBody>
    </Modal>
  );
};

export default TimesModal;
