import React, { useState, useCallback, useEffect } from 'react';
import {
  Input,
  Button,
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  UncontrolledAlert,
} from 'reactstrap';
import axios from 'axios';
import { getNextTimesheetDate, getNextPayDate } from '../../../helpers/utils';

const PaymentDates = ({ settings }) => {
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [timesheetDay, setTimesheetDay] = useState(-1);
  const [payday, setPayday] = useState(-1);

  useEffect(() => {
    const timesheetDaySetting = (settings && settings.timesheetDayRecurrence) || -1;
    const paydaySetting = (settings && settings.paydayRecurrence) || -1;

    setTimesheetDay(timesheetDaySetting);
    setPayday(paydaySetting);
  }, [settings]);

  const onSubmit = useCallback(async () => {
    setIsSubmitting(true);
    setError(null);
    setSuccess(null);

    try {
      const response = await axios({
        method: 'patch',
        url: '/api/settings',
        data: { timesheetDayRecurrence: timesheetDay, paydayRecurrence: payday },
      });

      const data = response && response.data;
      if (data && !data.error) {
        setSuccess(data.message || 'Success!');
      }
      setIsSubmitting(false);
    } catch (error) {
      setError(
        error.response && error.response.data && typeof error.response.data.message === 'string'
          ? error.response.data.message
          : 'Something went wrong :('
      );
      setIsSubmitting(false);
    }
  }, [payday, timesheetDay]);

  return (
    <Row>
      <Col>
        <Card>
          <CardBody>
            <div>
              <span>
                <h6>
                  <img
                    src={require('../../../images/payday.png')}
                    style={{ height: '40px', margin: '20px' }}
                    alt="Revolut Logo"
                  />
                  Payment Dates
                </h6>
              </span>
            </div>
            {success && (
              <UncontrolledAlert color="primary">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  👍
                </span>
                {success}
              </UncontrolledAlert>
            )}

            {error && (
              <UncontrolledAlert color="danger">
                <span role="img" aria-label="no entry" className="font-18 m-r-5">
                  ⛔️
                </span>
                {error}
              </UncontrolledAlert>
            )}
            <p className="text-muted m-b-30">
              Set payment dates here. <strong>Timesheet Day</strong> is the date when timesheets are
              processed, approved or declined, while <strong>Payday</strong> is the day when
              approved timesheets are getting paid from company's account.
            </p>

            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Timesheet Day
              </Label>
              <Col md="4" className="d-flex align-items-center m-b-10">
                <select
                  id="project"
                  className="form-control d-inline-block w-auto m-r-10 align-middle"
                  value={timesheetDay}
                  onChange={e => setTimesheetDay(e.target.value)}
                >
                  <option value="-1">Please select</option>
                  <option value="0">Last Business Day of the Month</option>
                  <option value="1">Last Monday of the Month</option>
                  <option value="2">Last Tuesday of the Month</option>
                  <option value="3">Last Wednesday of the Month</option>
                  <option value="4">Last Thursday of the Month</option>
                  <option value="5">Last Friday of the Month</option>
                  <option value="11">Monday of each Week</option>
                  <option value="12">Tuesday of each Week</option>
                  <option value="13">Wednesday of each Week</option>
                  <option value="14">Thursday of each Week</option>
                  <option value="15">Friday of each Week</option>
                </select>
              </Col>
              <Col sm="4">
                <Input
                  id="timesheet_label"
                  type="text"
                  value={getNextTimesheetDate(timesheetDay)}
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Label htmlFor="example-text-input" md="2">
                Pay Day
              </Label>
              <Col md="4" className="d-flex align-items-center m-b-10">
                <select
                  id="project"
                  className="form-control d-inline-block w-auto m-r-10 align-middle"
                  value={payday}
                  onChange={e => setPayday(e.target.value)}
                >
                  <option value="-1">Please select</option>
                  <option value="0">First Business Day of the Month</option>
                  <option value="1">First Monday of the Month</option>
                  <option value="2">First Tuesday of the Month</option>
                  <option value="3">First Wednesday of the Month</option>
                  <option value="4">First Thursday of the Month</option>
                  <option value="5">First Friday of the Month</option>
                  <option value="11">Monday of each Week</option>
                  <option value="12">Tuesday of each Week</option>
                  <option value="13">Wednesday of each Week</option>
                  <option value="14">Thursday of each Week</option>
                  <option value="15">Friday of each Week</option>
                  <option value="16">Same as Timesheet</option>
                </select>
              </Col>
              <Col sm="4">
                <Input
                  id="payday_label"
                  type="text"
                  value={
                    payday === '16' ? getNextTimesheetDate(timesheetDay) : getNextPayDate(payday)
                  }
                  disabled={true}
                />
              </Col>
            </FormGroup>
            <FormGroup row>
              <Col sm="12" className="offset-2">
                <Button
                  type="submit"
                  color="primary"
                  disabled={isSubmitting || payday < 0 || timesheetDay < 0}
                  onClick={onSubmit}
                >
                  {!isSubmitting ? 'Submit' : 'Submitting...'}
                </Button>
              </Col>
            </FormGroup>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default PaymentDates;
