import React, { useState, useRef } from 'react';
import { Card, CardBody, FormGroup, Label, Modal, ModalBody, Alert, Button } from 'reactstrap';
import axios from 'axios';
import { useDispatch } from 'react-redux';

import { fetchTeam } from '../../store/actions';

const Photo = ({ src, userID }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [uploadError, setUploadError] = useState(null);
  const [filename, setFilename] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const formRef = useRef(null);
  const fileRef = useRef(null);
  const dispatch = useDispatch();

  return (
    <>
      <Card>
        <CardBody>
          <FormGroup>
            <Label for="photo">Photo</Label>
            <div className="text-center">
              <div
                className="photo-container bg-light"
                style={
                  src && {
                    backgroundImage: `url(${src})`,
                  }
                }
              >
                {!src && <img src={require('../../images/logo_150.png')} alt="User" />}
              </div>
            </div>
          </FormGroup>
          <Button color="primary" onClick={() => setIsModalOpen(true)}>
            Change
          </Button>
        </CardBody>
      </Card>

      <Modal centered isOpen={isModalOpen} toggle={() => setIsModalOpen(false)}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Upload your profile photo</h5>
          <button type="button" onClick={() => setIsModalOpen(false)} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <p>Please choose a jpeg file to upload.</p>
          {uploadError && (
            <Alert color="danger">
              <span role="img" aria-label="no entry" className="font-18 m-r-5">
                ⛔️
              </span>
              {uploadError}
            </Alert>
          )}
          <div className="bootstrap-filestyle input-group">
            <form ref={formRef} className="w-100">
              <input
                type="file"
                className="filestyle photo-file-input"
                name="photo"
                ref={fileRef}
                id="photo"
                tabIndex="-1"
                accept="image/jpeg"
                onChange={e => {
                  const file = e.target.files && e.target.files[0];
                  setFilename(file ? file.name : '');
                  setUploadError(null);
                }}
              />
              <div className="bootstrap-filestyle input-group">
                <input type="text" className="form-control" placeholder={filename} disabled />
                <span className="group-span-filestyle input-group-append" tabIndex="0">
                  <label htmlFor="photo" className="btn btn-secondary ">
                    <span className="icon-span-filestyle fas fa-folder-open"></span>
                    <span className="buttonText">Choose file</span>
                  </label>
                </span>
              </div>
            </form>
          </div>

          <div className="m-t-30 text-right">
            <Button
              disabled={isUploading || (fileRef.current && !fileRef.current.files.length)}
              color="success"
              onClick={async () => {
                if (fileRef.current && !fileRef.current.files.length) {
                  setUploadError('Please choose a file to upload.');
                  return;
                }
                setUploadError(null);
                setIsUploading(true);

                try {
                  const data = new FormData(formRef.current);

                  await axios({
                    method: 'put',
                    url: `/api/profiles/${userID}/photo`,
                    data,
                  });

                  setIsModalOpen(false);
                  setIsUploading(false);

                  setTimeout(() => {
                    dispatch(fetchTeam());
                  }, 250);
                } catch (error) {
                  const data = error && error.response && error.response.data;

                  setIsUploading(false);
                  setUploadError(
                    (data && data.message) || 'There was a problem with your upload :('
                  );
                }
              }}
            >
              {isUploading ? 'Uploading...' : 'Upload'}
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Photo;
