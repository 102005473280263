import React, { useEffect, useState } from 'react';
import { Modal, ModalBody, Button, Row, Col, Label, Card, CardBody, Badge } from 'reactstrap';
import { useSelector } from 'react-redux';

import { formatCurrency } from '../../helpers/numbers';
import { statusMapping, PAYMENT_REQUEST_STATUS } from '../../../src/pages/TimesheetStatusMapping';
import Alert from 'reactstrap/lib/Alert';

const PaymentDetailsModal = ({ payment, profiles, isOpen, didTapCloseButton }) => {
  const mappedStatus =
    statusMapping[
      payment?.status?.toUpperCase() ?? PAYMENT_REQUEST_STATUS.NOT_AVAILABLE.toUpperCase()
    ];

  const { data } = useSelector(state => state.Admin);
  const [companies, setCompanies] = useState([]);

  useEffect(() => {
    setCompanies(data.companies);
  }, [data]);

  function findCompany(companyID) {
    return companies.find(company => company.id === companyID);
  }

  return (
    <Modal centered isOpen={isOpen} toggle={didTapCloseButton} style={{ width: '100%' }}>
      <div className="modal-header">
        <h5 className="modal-title mt-0">Payment Details</h5>
        <button type="button" onClick={didTapCloseButton} className="close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      {payment && (
        <ModalBody>
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <Row>
                    <Col>
                      <div className="invoice-title">
                        <img src={require('../../images/pound.png')} alt="logo" height="24" />
                        <span className="m-l-10 p-t-5">
                          Payment <strong>{payment.id}</strong>
                        </span>
                      </div>
                      <hr />
                      <Row>
                        <Col xs="6" lg="6">
                          <p>
                            {new Date(payment.timestamp).toLocaleString(undefined, {
                              timeZone: 'UTC',
                              dateStyle: 'long',
                            })}
                          </p>
                        </Col>
                        <Col xs="6" lg="6" className="text-right">
                          <Badge
                            color={mappedStatus?.color ?? 'dark'}
                            pill
                            className="d-flex align-items-center font-14"
                          >
                            {mappedStatus?.title ?? 'Unknown'}
                          </Badge>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="6" lg="6">
                          <address>
                            <Label for="company" className="card-title">
                              Billing Company
                            </Label>
                            {payment.billingCompanyID && findCompany(payment.billingCompanyID) ? (
                              <p>{findCompany(payment.billingCompanyID)?.legalName ?? 'N/A'}</p>
                            ) : (
                              <p>Not Provided</p>
                            )}
                          </address>
                        </Col>
                        <Col xs="6" lg="6" className="text-right">
                          <address>
                            <Label for="provider" className="card-title">
                              Payment Method
                            </Label>
                            <p>{payment.paymentProvider?.toUpperCase() ?? 'Unknown'}</p>
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="3" lg="3" className="m-t-5">
                          <address>
                            <strong>Payee</strong>
                          </address>
                        </Col>
                        <Col xs="9" lg="9" className="text-right">
                          <address>
                            {payment.employeeFirstName ? (
                              <p>
                                {payment?.employeeFirstName} {payment?.employeeLastName}
                              </p>
                            ) : (
                              <p>Unknown</p>
                            )}
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="3" lg="3">
                          <address>
                            <p className="m-t-5">
                              <strong>Reference</strong>
                            </p>
                          </address>
                        </Col>
                        <Col xs="9" lg="9" className="text-right">
                          <address>
                            <input
                              disabled
                              type="text"
                              value={payment.reference ?? ''}
                              className="form-control"
                            />
                          </address>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs="3" lg="3">
                          <address>
                            <p className="m-t-5">
                              <strong>Description</strong>
                            </p>
                          </address>
                        </Col>
                        <Col xs="9" lg="9" className="text-right">
                          <address>
                            <input
                              disabled
                              type="text"
                              value={payment.description ?? ''}
                              className="form-control"
                            />
                          </address>
                        </Col>
                      </Row>
                      <Row className="m-t-5">
                        <Col xs="6" lg="6" className="total">
                          <address>
                            <strong>Total Amount</strong>
                          </address>
                        </Col>
                        <Col xs="6" lg="6" className="text-right total">
                          {formatCurrency(payment.totalAmount, payment.currency)}
                          <br />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col>
              <Alert className="total text-center" color={payment.isPaid ? 'success' : 'danger'}>
                {payment.isPaid ? 'Paid' : 'Unpaid'}
              </Alert>
            </Col>
          </Row>
          <Row>
            <Col xs="12">
              <Button block color="secondary" onClick={didTapCloseButton}>
                Close
              </Button>
            </Col>
          </Row>
        </ModalBody>
      )}
    </Modal>
  );
};

export default PaymentDetailsModal;
