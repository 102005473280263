import React from 'react';

const pkg = require('../../../package.json');

const Footer = () => (
  <footer className="footer">
    © {new Date().getFullYear()} London App Works LTD{' '}
    <span className="d-none d-sm-inline-block">
      {' '}
      - Crafted with <i className="mdi mdi-heart text-danger"></i> in London
    </span>
    . {pkg && `Version ${pkg.version}`}
  </footer>
);

export default Footer;
