import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Alert,
  Container,
  Row,
  Col,
  Label,
  Button,
  UncontrolledAlert,
  CardBody,
  Card,
  FormGroup,
  Form,
  Input,
} from 'reactstrap';
import ReactTooltip from 'react-tooltip';
import AccessDeniedPoster from '../../../components/AccessDeniedPoster';
import { fetchProjects, fetchCompanies } from '../../../store/admin/actions';
import axios from 'axios';
import GiphySearch from './GiphySearch';

const DailyUpdate = () => {
  const reactTooltip = useRef(null);
  const [company, setCompany] = useState('');
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedProjects, setselectedProjects] = useState([]);

  const [projectStatus, setProjectStatus] = useState({});
  const [projectMessage, setProjectMessage] = useState({});
  const [projectMentions, setProjectMentions] = useState({});
  const [selectedGiphyUrl, setSelectedGiphyUrl] = useState(null);

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const admin = useSelector(state => state.Admin);
  const { profiles } = useSelector(state => state.Team);
  const { data } = useSelector(state => state.Admin);
  const projects = data && data.projects;
  const companies = (data?.companies && Object.values(data.companies)) || [];
  const dispatch = useDispatch();
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    (async function() {
      try {
        const res = await axios.get('/api/settings');
        const settings = res && res.data && res.data.settings;
        setSettings(settings);
      } catch (error) {
        console.log(error);
        setError('There was a problem fetching the settings. Please try reloading the page.');
      }
    })();
  }, []); // eslint-disable-line

  async function sendMessage() {
    try {
      const response = await axios.post('/api/messaging/teamupdate', {
        projectMessages: projectMessage,
        projectMentions: projectMentions,
        projectStatuses: projectStatus,
        giphyUrl: selectedGiphyUrl,
      });

      if (response) {
        const { data } = response;
        if (data && !data.error) {
          setSuccess('Update has been sent to the team!');
        } else {
          setError(data?.error ?? 'Something went wrong!');
        }
      }
    } catch (error) {
      console.log(error);
      setError('Sorry, could not post the update.');
    }
  }

  const cannedMessages = {
    GOOD_NO_NEWS: 'All good, no updates from the client, expecting further info.',
    GOOD_SCHEDULED: 'Work is being scheduled, please expect further updates on assignments.',
    GOOD_START: 'Work planned and assigned, the team may start the work.',
    GOOD_PROGRESS: 'On track, no blockers, team is progressing through backlog.',
    DELAY_CLIENT: 'Slight delay, waiting on the client to provide update/resources/guidance',
    DELAY_TEAM: 'We are behind the schedule.',
    BLOCK_CLIENT: 'We are blocked due to __________ on client side.',
    BLOCK_TEAM: 'Blocker is ______, which we plan to resolve by ________',
    RELEASE_OK: 'We have released the app! Congratulations and thanks to everybody on the team!',
    RELEASE_REVIEW: 'Release is pending review by a customer / Apple / Google.',
    RELEASE_FEEDBACK:
      'We have received feedback about the latest work and need to correct some bugs.',
    QUOTED: 'New work has been quoted and estimated, expecting greenlight from the customer.',
  };

  useEffect(() => {
    if (!data.companies) dispatch(fetchCompanies());
    if (!projects) dispatch(fetchProjects());
  }, []); // eslint-disable-line

  useEffect(() => {
    if (projects) {
      setAvailableProjects(filterProjectsByCompany(company, projects));
    }
  }, [company]); // eslint-disable-line

  useEffect(() => {
    reactTooltip.current &&
      reactTooltip.current.globalRebuild &&
      reactTooltip.current.globalRebuild();
  });

  function updateProjectMessage(projectID, newMessage) {
    let updatedMessage = { ...projectMessage };
    updatedMessage[projectID] =
      (updatedMessage[projectID] ? updatedMessage[projectID] + ' ' : '') + newMessage;
    setProjectMessage(updatedMessage);
  }

  function filterUsersByProject(projectID, projects) {
    const project = projects[projectID];
    const filtered = Object.values(profiles)?.filter(profile => {
      return Object.keys(project.workers)?.includes(profile.userID);
    });
    return filtered;
  }

  function filterProjectsByCompany(company, projects, activeOnly = true) {
    const filtered = Object.values(projects)?.filter(project => {
      return (
        company &&
        project &&
        project.billingCompanyID &&
        project.billingCompanyID === company &&
        (project.status?.toUpperCase() === 'IN PROGRESS' ||
          project.status?.toUpperCase() === 'OVERDUE' ||
          project.status?.toUpperCase() === 'DELIVERED')
      );
    });
    return filtered;
  }

  if (!admin.isAdminUser) return <AccessDeniedPoster />;

  return (
    <Container className="create-timesheet">
      <div className="page-title-box">
        <Row>
          <Col sm="12">
            <h4 className="page-title">Send Team Update</h4>
          </Col>
        </Row>
      </div>
      {success && (
        <UncontrolledAlert color="info">
          <span role="img" aria-label="no entry" className="font-18 m-r-5">
            👍
          </span>
          Update sent successfully!
        </UncontrolledAlert>
      )}
      {error && (
        <Alert color="danger">
          <span role="img" aria-label="see no evil" className="font-18 m-r-5">
            🙈
          </span>
          {error}
        </Alert>
      )}

      <Card>
        <CardBody>
          <Row>
            <Col sm="4" lg="6">
              <Label for="company" className="card-title">
                <span className="order bg-primary">1</span>Select a company
              </Label>
              <select
                id="company"
                className="form-control d-inline-block m-r-10"
                value={company}
                onChange={async e => {
                  const value = e.target.value;
                  setCompany(value);
                  setselectedProjects([]);
                }}
              >
                <option>Please select</option>
                {companies &&
                  Object.values(companies)
                    .sort((a, b) => (b.legalName < a.legalName ? 1 : -1))
                    .map(company => (
                      <option key={company.id} value={company.id}>
                        {company.legalName}
                      </option>
                    ))}
              </select>
            </Col>
            <Col sm="4" lg="6">
              <Label for="projects" className="card-title">
                <span className="order bg-primary">2</span>Select Projects
              </Label>
              {availableProjects?.length > 0 && (
                <select
                  multiple
                  id="project"
                  style={{ height: 'auto' }}
                  className="form-control d-inline-block m-r-10 align-middle"
                  value={selectedProjects}
                  onChange={e => {
                    let value = Array.from(e.target.selectedOptions, option => option.value);
                    setselectedProjects(value);
                  }}
                >
                  {availableProjects &&
                    Object.values(availableProjects)
                      .sort((a, b) => (b.title < a.title ? 1 : -1))
                      .map(project => {
                        return (
                          <option key={project.title} value={project.id}>
                            {project.id} - {project.title}
                          </option>
                        );
                      })}
                </select>
              )}
              {availableProjects?.length === 0 && (
                <Alert color="dark">Please select a company</Alert>
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <Label className="card-title">
            <span className="order bg-primary">3</span>Provide Update by Project
          </Label>
          <>
            {selectedProjects?.length > 0 &&
              selectedProjects.map(selectedProject => {
                return (
                  <div key={'projectDiv' + selectedProject}>
                    <Row>
                      <Col sm={6} lg={12}>
                        <h5 key={'label' + selectedProject}>Update for {selectedProject}</h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} lg={2}>
                        <Label>Canned Message</Label>
                      </Col>
                      <Col sm={4} lg={10}>
                        <select
                          key={'cannedMessages' + selectedProject}
                          id="cannedMessages"
                          style={{ height: 'auto', marginBottom: '5px' }}
                          className="form-control d-inline-block m-r-10 align-middle"
                          onChange={e => {
                            const value = e.target.value;
                            updateProjectMessage(selectedProject, cannedMessages[value]);
                          }}
                        >
                          <option key={'cannedCopyPleaseSelect' + selectedProject} value="">
                            Please Select
                          </option>
                          {cannedMessages &&
                            Object.keys(cannedMessages).map(cannedMessageKey => {
                              return (
                                <option
                                  key={'cannedCopy' + cannedMessageKey + selectedProject}
                                  value={cannedMessageKey}
                                >
                                  {cannedMessageKey.replaceAll('_', ' ')}
                                </option>
                              );
                            })}
                        </select>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} lg={2}>
                        <Label>Status Icon</Label>
                      </Col>
                      <Col sm={4} lg={10}>
                        <Form>
                          <FormGroup>
                            <select
                              key={'projectProgressStatus' + selectedProject}
                              id="projectProgress"
                              style={{ height: 'auto' }}
                              className="form-control d-inline-block m-r-10 align-middle"
                              value={projectStatus[selectedProject]}
                              onChange={e => {
                                const value = e.target.value;
                                let updatedStatus = { ...projectStatus };
                                updatedStatus[selectedProject] = value;
                                setProjectStatus(updatedStatus);
                              }}
                            >
                              <option>Please select</option>
                              <option key="good" value="good">
                                🟢 Good Progress
                              </option>
                              <option key="delays" value="delays">
                                🟡 Some Delays
                              </option>
                              <option key="blockers" value="blockers">
                                🔴 Has Blockers
                              </option>
                              <option key="stopped" value="stopped">
                                ❌ Fully Blocked
                              </option>
                            </select>
                          </FormGroup>
                        </Form>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} lg={2}>
                        <Label>Workers</Label>
                      </Col>
                      <Col>
                        {filterUsersByProject(selectedProject, projects).map(workerProfile => (
                          <Button
                            key={'workerToken' + workerProfile.userID + selectedProject}
                            size="sm"
                            color="info"
                            style={{ marginRight: '5px', marginBottom: '3px' }}
                            onClick={() => {
                              updateProjectMessage(
                                selectedProject,
                                `<at>${workerProfile.firstName}</at>`
                              );
                              if (projectMentions[selectedProject]?.length > 0) {
                                let currentProjectsMentions = { ...projectMentions };
                                let selectedProjectMentions = [
                                  ...currentProjectsMentions[selectedProject],
                                ];
                                selectedProjectMentions.push(workerProfile.userID);
                                const uniqueMentions = [...new Set(selectedProjectMentions)];
                                currentProjectsMentions[selectedProject] = uniqueMentions;
                                setProjectMentions(currentProjectsMentions);
                              } else {
                                let currentProjectsMentions = { ...projectMentions };
                                currentProjectsMentions[selectedProject] = [workerProfile.userID];
                                setProjectMentions(currentProjectsMentions);
                              }
                            }}
                          >
                            {workerProfile.firstName}
                          </Button>
                        ))}
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={2} lg={2}>
                        <Label>Message</Label>
                      </Col>
                      <Col sm={4} lg={10}>
                        <FormGroup>
                          <Input
                            id="projectMessage"
                            key={'update_' + selectedProject}
                            type="textarea"
                            value={projectMessage[selectedProject]}
                            rows="4"
                            cols="250"
                            onChange={e => {
                              let value = e.target.value;
                              let updatedMessage = { ...projectMessage };
                              updatedMessage[selectedProject] = value;
                              setProjectMessage(updatedMessage);
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                );
              })}
          </>
        </CardBody>
      </Card>

      {settings && settings.giphyKey && !selectedGiphyUrl && (
        <Card>
          <CardBody>
            <Row>
              <Col sm="6" lg="12">
                <Label className="card-title">
                  <span className="order bg-primary">4</span>Add Daily GIPHY
                  <p className="m-t-10 m-l-10">
                    To keep it less boring add some visual stimuli to your daily update.
                  </p>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col sm="6" lg="12">
                <GiphySearch
                  webSDKKey={settings.giphyKey}
                  onGifClick={(gif, e) => {
                    setSelectedGiphyUrl(gif?.images?.original?.url);
                    e.preventDefault();
                  }}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}
      {selectedGiphyUrl && (
        <Card>
          <CardBody>
            <Row>
              <Col sm="6" lg="12">
                <Label className="card-title">
                  <span className="order bg-primary">4</span>Add Daily GIPHY
                  <p className="m-t-10 m-l-10">
                    To keep it less boring add some visual stimuli to your daily update.
                  </p>
                </Label>
              </Col>
            </Row>
            <Row>
              <Col sm={4} lg={12}>
                <img src={selectedGiphyUrl} alt="giphy" />
              </Col>
            </Row>
            <Row>
              <Col sm={2} lg={2}>
                <Button
                  style={{ marginTop: '10px' }}
                  color="danger"
                  onClick={() => {
                    setSelectedGiphyUrl(null);
                  }}
                >
                  Reset GIPHY
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      )}

      <Card>
        <CardBody>
          <Row>
            <Col sm="2" lg="6">
              <Label className="card-title">
                <span className="order bg-primary">5.a</span>Publish to Micorosft Teams
              </Label>
              <Button
                color="dark"
                className="m-l-10"
                disabled={isLoading}
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  try {
                    setError(false);
                    await sendMessage();
                    window.scrollTo({ top: 0 });
                  } catch (error) {
                    window.scrollTo({ top: 0 });
                    setError((error && error.message) || 'An unexpected error occurred');
                  }
                  setIsLoading(false);
                }}
              >
                Send to Channel
              </Button>
            </Col>
            <Col sm="2" lg="6">
              <Label className="card-title">
                <span className="order bg-dark">5.b</span>Email Mentioned Workers
              </Label>
              <Button
                color="dark"
                className="m-l-10"
                disabled
                onClick={async e => {
                  setIsLoading(true);
                  setSuccess(false);
                  // try {
                  //   setError(false);
                  // } catch (error) {
                  //   window.scrollTo({ top: 0 });
                  //   setError((error && error.message) || 'An unexpected error occurred');
                  //}
                  setIsLoading(false);
                }}
              >
                Send Emails
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ReactTooltip ref={reactTooltip} />
    </Container>
  );
};

export default DailyUpdate;
