import axios from 'axios';
import {
  FETCH_ADMIN_TIMESHEETS,
  FETCH_ADMIN_TIMESHEETS_SUCCESS,
  FETCH_ADMIN_TIMESHEETS_FAILURE,
  SET_ADMIN_USER,
  FETCH_PROJECTS,
  FETCH_PROJECTS_SUCCESS,
  FETCH_PROJECTS_FAILURE,
  FETCH_ADMIN_TIMETRACKERS,
  FETCH_ADMIN_TIMETRACKERS_SUCCESS,
  FETCH_ADMIN_TIMETRACKERS_FAILURE,
  FETCH_DAILY_REPORT,
  FETCH_DAILY_REPORT_SUCCESS,
  FETCH_DAILY_REPORT_FAILURE,
  FETCH_DAILY_REPORT_UPDATE_FAILURE,
  FETCH_DAILY_REPORT_UPDATE_SUCCESS,
  FETCH_DAILY_REPORT_UPDATE,
  FETCH_COMPANIES,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_FAILURE,
  APPROVE_PAYMENT_REQUEST,
  REJECT_PAYMENT_REQUEST,
  ACTION_PAYMENT_REQUEST_SUCCESS,
  ACTION_PAYMENT_REQUEST_FAILURE,
  FETCH_PAYMENTS,
  FETCH_PAYMENTS_SUCCESS,
  FETCH_PAYMENTS_FAILURE,
  ADD_SCHEDULED_PAYMENT,
  REMOVE_SCHEDULED_PAYMENT,
  FETCH_PAYMENTS_CARDS,
  FETCH_PAYMENTS_CARDS_FAILURE,
  FETCH_PAYMENTS_CARDS_SUCCESS,
} from './actionTypes';
import { signOut } from '../auth/actions';

export const setAdminUser = isAdminUser => ({
  type: SET_ADMIN_USER,
  isAdminUser,
});

export const fetchAdminTimesheets = (filter, userID) => {
  return async dispatch => {
    dispatch(fetchAdminTimesheetsAction());

    try {
      const params = {};
      if (filter) params.filter = filter;
      if (userID) params.userID = userID;

      const response = await axios.get('/api/admin/timesheets', { params });

      if (response) {
        const { data, status } = response;
        if (status === 200 && data && !data.error) {
          dispatch(fetchAdminTimesheetsSuccessAction(data));
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch(fetchAdminTimesheetsFailureAction(data));
    }
  };
};

const fetchAdminTimesheetsAction = () => {
  return {
    type: FETCH_ADMIN_TIMESHEETS,
  };
};

const fetchAdminTimesheetsSuccessAction = data => {
  return {
    type: FETCH_ADMIN_TIMESHEETS_SUCCESS,
    data,
  };
};

const fetchAdminTimesheetsFailureAction = error => {
  return {
    type: FETCH_ADMIN_TIMESHEETS_FAILURE,
    error,
  };
};

export const fetchProjects = () => {
  return async dispatch => {
    dispatch({ type: FETCH_PROJECTS });

    try {
      const response = await axios.get('/api/projects');

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          dispatch({ type: FETCH_PROJECTS_SUCCESS, data: data.projects });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({ type: FETCH_PROJECTS_FAILURE, error: data || (error && error.message) });
    }
  };
};

/**
 * Fetch all timetrackers for admin
 *
 * @param {'active'|'locked'} type
 */
export const fetchAdminTimetrackers = type => {
  return async dispatch => {
    dispatch({ type: FETCH_ADMIN_TIMETRACKERS });

    try {
      const response = await axios.get('/api/timetracker', {
        params: {
          all: '1',
          type,
        },
      });

      if (response) {
        const { data } = response;

        if (data && !data.error) {
          dispatch({ type: FETCH_ADMIN_TIMETRACKERS_SUCCESS, data: data.timers });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({ type: FETCH_ADMIN_TIMETRACKERS_FAILURE, error: data || (error && error.message) });
    }
  };
};

export const fetchDailyReport = date => {
  return async dispatch => {
    dispatch({ type: FETCH_DAILY_REPORT });

    try {
      const response = await axios.get('/api/timetracker/daily-report', {
        params: { date },
      });

      if (response) {
        const { data } = response;

        if (data && !data.error) {
          dispatch({ type: FETCH_DAILY_REPORT_SUCCESS, data: data.report });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({ type: FETCH_DAILY_REPORT_FAILURE, error: data || (error && error.message) });
    }
  };
};

export const fetchDailyReportUpdate = date => {
  return async dispatch => {
    dispatch({ type: FETCH_DAILY_REPORT_UPDATE });

    try {
      const response = await axios.get('/api/timetracker/request-daily-report', {
        params: { date },
      });

      if (response) {
        const { data } = response;

        if (data && !data.error) {
          dispatch({ type: FETCH_DAILY_REPORT_UPDATE_SUCCESS, data: data.message });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({
        type: FETCH_DAILY_REPORT_UPDATE_FAILURE,
        error: data || (error && error.message),
      });
    }
  };
};

export const fetchCompanies = () => {
  return async dispatch => {
    dispatch({ type: FETCH_COMPANIES });

    try {
      const response = await axios.get('/api/companies');

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          dispatch({ type: FETCH_COMPANIES_SUCCESS, data: data.companies });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({ type: FETCH_COMPANIES_FAILURE, error: data || (error && error.message) });
    }
  };
};

export const approvePaymentRequest = bundleId => {
  return async dispatch => {
    dispatch({ type: APPROVE_PAYMENT_REQUEST });

    try {
      const response = await axios.get('/api/payment/approve', {
        params: {
          bundleId,
        },
      });

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          dispatch({ type: ACTION_PAYMENT_REQUEST_SUCCESS, data: data });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({ type: ACTION_PAYMENT_REQUEST_FAILURE, error: data || (error && error.message) });
    }
  };
};

export const rejectPaymentRequest = bundleId => {
  return async dispatch => {
    dispatch({ type: REJECT_PAYMENT_REQUEST });

    try {
      const response = await axios.get('/api/payment/reject', {
        params: {
          bundleId,
        },
      });

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          dispatch({ type: ACTION_PAYMENT_REQUEST_SUCCESS, data: data });
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      console.log(error);
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut());
      dispatch({ type: ACTION_PAYMENT_REQUEST_FAILURE, error: data || (error && error.message) });
    }
  };
};

export const fetchPayments = isPaid => {
  return async dispatch => {
    dispatch(fetchPaymentsAction());

    try {
      const response = await axios.get(`/api/payments?isPaid=${isPaid}`);

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          dispatch(fetchPaymentsSuccessAction(data));
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      let err = error;
      const response = error && error.response;
      if (response) {
        err = {
          status: response.status,
          statusText: response.statusText,
        };
        if (response.status === 401) dispatch(signOut());
      }
      dispatch(fetchPaymentsFailureAction(err));
    }
  };
};

const fetchPaymentsAction = () => {
  return {
    type: FETCH_PAYMENTS,
  };
};

const fetchPaymentsSuccessAction = data => {
  return {
    type: FETCH_PAYMENTS_SUCCESS,
    data,
  };
};

const fetchPaymentsFailureAction = error => {
  return {
    type: FETCH_PAYMENTS_FAILURE,
    error,
  };
};

export const addScheduledPayment = item => {
  return {
    type: ADD_SCHEDULED_PAYMENT,
    item,
  };
};

export const removeScheduledPayment = item => {
  return {
    type: REMOVE_SCHEDULED_PAYMENT,
    item,
  };
};

/**
 * FETCH CARDS FOR DASHBOARD
 */
export const fetchPaymentCards = () => {
  return async dispatch => {
    dispatch(fetchPaymentCardsAction());

    try {
      const response = await axios.get('/api/admin/payments/cards');
      const { data, status } = response;
      if (status === 200 && data && !data.error && data.cards) {
        console.log(data.cards);
        dispatch(fetchPaymentCardsSuccessAction(data.cards));
      }
    } catch (error) {
      const data = error && error.response && error.response.data;
      console.log(error);
      if (data && data.code === 401) dispatch(signOut(data));
      dispatch(fetchPaymentCardsFailureAction(data));
    }
  };
};

const fetchPaymentCardsAction = () => ({ type: FETCH_PAYMENTS_CARDS });

const fetchPaymentCardsSuccessAction = cards => ({ type: FETCH_PAYMENTS_CARDS_SUCCESS, cards });

const fetchPaymentCardsFailureAction = error => ({ type: FETCH_PAYMENTS_CARDS_FAILURE, error });
