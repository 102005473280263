import React from 'react';

import { formatCurrency } from '../../../helpers/numbers';
import SortIndicator from '../Reporting/SortIndicator';

export default [
  {
    Header: ({ column }) => {
      return (
        <>
          Project <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'projectId',
    disableGlobalFilter: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Task Id <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'displayId',
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Task Name <SortIndicator column={column} />
        </>
      );
    },
    accessor: 'taskName',
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Employee <SortIndicator column={column} />
        </>
      );
    },
    accessor: row => (row ? `${row.employeeFirstName} ${row.employeeLastName}` : ''),
    id: 'employee',
    Cell: ({ row }) => {
      const { employeeFirstName, employeeLastName, userID } = row.original;
      return (
        <div data-tip={userID} data-effect="solid">
          {employeeFirstName} {employeeLastName}
        </div>
      );
    },
    disableGlobalFilter: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Created <SortIndicator column={column} />
        </>
      );
    },
    id: 'createdAt',
    sortDescFirst: true,
    accessor: row => new Date(row.updates ? row.updates[0].timestamp : row.createdAt).getTime(),
    Cell: ({ value }) => <>{new Date(value).toLocaleString(undefined, { timeZone: 'UTC' })}</>,
    disableGlobalFilter: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Last Updated <SortIndicator column={column} />
        </>
      );
    },
    id: 'updatedAt',
    sortDescFirst: true,
    accessor: row =>
      new Date(
        row.updates ? row.updates[row.updates.length - 1].timestamp : row.updatedAt
      ).getTime(),
    Cell: ({ value }) => <>{new Date(value).toLocaleString(undefined, { timeZone: 'UTC' })}</>,
    disableGlobalFilter: true,
  },
  {
    Header: 'Total Time',
    accessor: 'totalTimes.totalDisplayTime',
    disableSortBy: true,
    disableGlobalFilter: true,
  },

  {
    Header: '% of Allowed',
    accessor: row =>
      row
        ? Math.round((row.totalTimes.totalTimeInSeconds / 60 / 60 / (row.allowedEffort || 1)) * 100)
        : 0,
    id: 'percentage',
    sortDescFirst: true,
    Cell: ({ value }) => <span className={value > 74 ? 'text-danger font-500' : ''}>{value}%</span>,
    disableSortBy: true,
    disableGlobalFilter: true,
  },
  {
    Header: 'Estimated Cost',
    accessor: 'financials.estimatedCharge',
    Cell: ({ value }) => <>{formatCurrency(value, undefined, 'symbol')}</>,
    disableSortBy: true,
    disableGlobalFilter: true,
  },
  {
    Header: ({ column }) => {
      return (
        <>
          Status <SortIndicator column={column} />
        </>
      );
    },
    id: 'status',
    sortDescFirst: true,
    accessor: row => (row.isCurrentlyRunning ? 1 : 0),
    Cell: ({ value }) =>
      value === 1 ? <span className="text-warning font-weight-bold">Running</span> : <>Stopped</>,
    disableGlobalFilter: true,
  },
];
