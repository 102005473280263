import axios from 'axios';
import {
  FETCH_TEAM,
  FETCH_TEAM_SUCCESS,
  FETCH_TEAM_FAILURE,
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_FAILURE,
} from './actionTypes';
import { signOut } from '../auth/actions';

export const fetchTeam = () => {
  return async (dispatch, getState) => {
    dispatch(fetchTeamAction());

    try {
      const userID = getState().Auth.userID;
      const response = await axios.get('/api/profiles');

      if (response) {
        const { data, status } = response;

        if (status === 200 && data && !data.error) {
          // Do not overwrite own profile which is not a reduced version like the rest
          delete data.profiles[userID];
          dispatch(fetchTeamSuccessAction(data.profiles));
        } else {
          throw new Error(data.error);
        }
      }
    } catch (error) {
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut(data));
      dispatch(fetchTeamFailureAction(data));
    }
  };
};

const fetchTeamAction = () => {
  return {
    type: FETCH_TEAM,
  };
};

const fetchTeamSuccessAction = data => {
  return {
    type: FETCH_TEAM_SUCCESS,
    data,
  };
};

const fetchTeamFailureAction = error => {
  return {
    type: FETCH_TEAM_FAILURE,
    error,
  };
};

const fetchUserProfileAction = () => ({
  type: FETCH_USER_PROFILE,
});

export const fetchUserProfileSuccessAction = profile => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  profile,
});

const fetchUserProfileFailureAction = error => ({
  type: FETCH_USER_PROFILE_FAILURE,
  error,
});

export const fetchUserProfile = userID => {
  return async (dispatch, getState) => {
    const ownUserID = getState().Auth.userID;
    const isAdmin = getState().Team.profiles[ownUserID].isAdmin;

    if (userID !== ownUserID && !isAdmin) {
      dispatch(fetchUserProfileFailureAction('You are not an admin!'));
      return;
    }

    dispatch(fetchUserProfileAction());

    try {
      const response = await axios.get(`/api/profiles/${userID}`);

      if (response.status === 200 && response.data && response.data.profile) {
        return dispatch(fetchUserProfileSuccessAction(response.data.profile));
      } else throw Error(response);
    } catch (error) {
      const data = error && error.response && error.response.data;

      if (data && data.code === 401) dispatch(signOut(data));
      return dispatch(fetchUserProfileFailureAction(data));
    }
  };
};
