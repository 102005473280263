import React, { useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import cn from 'classnames';

import DailyReport from './DailyReport';
import ProjectReport from './ProjectReport';
import BurndownReport from './BurndownReport';

const Timesheets = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="p-t-10">
      <h4 className="page-title m-b-30">Reporting</h4>
      <Nav tabs className="nav-tabs-custom">
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 0 })}
            onClick={() => setActiveTab(0)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-home"></i>
            </span>
            <span className="d-none d-sm-block">Daily Report</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 1 })}
            onClick={() => setActiveTab(1)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Project Report</span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: 'pointer' }}
            className={cn({ active: activeTab === 2 })}
            onClick={() => setActiveTab(2)}
          >
            <span className="d-block d-sm-none">
              <i className="fas fa-user"></i>
            </span>
            <span className="d-none d-sm-block">Burndown Report</span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId={0}>
          <DailyReport />
        </TabPane>

        <TabPane tabId={1}>
          <ProjectReport />
        </TabPane>

        <TabPane tabId={2}>
          <BurndownReport />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Timesheets;
